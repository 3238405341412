import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';

import HomeName from "../../utilities/components/HomeName";
import HttGbvHomeAddress from "../../utilities/components/HttGbvHomeAddress";

import { useTranslation } from "react-i18next";
import DetailService from "../api/services/DetailService";
import { trackPromise } from "react-promise-tracker";
import FiscalYearGbv from "../../utilities/components/FiscalYearGbv";

const GbvServiceCenter = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [fiscalYear, setFiscalYear] = useState("");
    const [quarter, setQuarter] = useState(0);

    const location = useLocation();
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = useForm();
    const toast = useRef(null);
    const getFormErrorMessage = (name) => {
        return (
            errors[name] && <small className="p-error">{errors[name].message}</small>
        );
    };

    const handleFiscalYear = (fiscalYearVal) => {
        setFiscalYear(fiscalYearVal);
      };
      const handleQuarter = (quarterVal) => {
        setQuarter(quarterVal);
      };

    const personalDetailPage = (response) => {
        console.log("returned response", response);
        history.push({
            pathname: "/sims/gbv-service-center-personal",
            state: {
                data: response,
                view: false
            },
        });
    };

    const onSubmit = (data) => {
        data.homeType = "ServiceCenter";
        data.fiscalYear = fiscalYear;
        data.quarter = quarter;
        console.log("data", data);
        
        let validation = false;
        {
            if (data.fiscalYear === "" || data.quarter === 0) {
                toast.current.show({
                    severity: "error",
                    summary: "Error Message",
                    detail: "Please select fiscal year and quarter",
                    life: 3000,
                });
                validation = false;
            } else {
                validation = true;
            }
        }

        if (validation) {
            trackPromise(
                DetailService.submitHomeDetail(data).then((response) => {
                    console.log("response", response);
                    if (response.status == 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Save Successful",
                            life: 3000,
                        });
                        setTimeout(() => personalDetailPage(response.data.data), 2000);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Save UnSuccessful",
                            life: 3000,
                        });
                    }
                })
                    .catch((error) => {
                        // We want to handle globally
                        error.handleGlobally && error.handleGlobally();
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: error.response.data.msg,
                            life: 3000,
                        });
                    })
            );
        }
    };

    return (
        <div style={{ marginLeft: "2.5rem" }}>
            <Toast ref={toast} />
            <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
                <div className=" p-card-content">
                    <h4 className="p-pt-0"> {t("Service Center")} </h4>
                </div>
            </Card>

            <div className="p-grid p-col-12 p-md-12">
                <div className="p-field p-col-12 p-md-3">
                    <Button className="box-shadow"
                        style={{
                            justifyContent: "center",
                            background: "rgb(75 125 181)",
                            color: "#FFF",
                            width: '100%',
                            minHeight: '30px',
                            border: '4px solid rgb(208 0 0)',
                        }}
                        label={t("homeDetails")}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Button className="box-shadow"
                        style={{
                            justifyContent: "center",
                            background: "rgb(75 125 181)",
                            color: "#FFF",
                            width: '100%',
                            minHeight: '30px',
                            border: '4px solid rgb(208 0 0)',
                        }}
                        label={t("list")}
                        onClick={() =>
                            history.push("/sims/gbv-service-center-list")
                        }
                    />
                </div>
            </div>

            <Card className="p-mt-0">
                <form className="p-grid p-fluid"
                    autoComplete="off">
                    <div className="p-field p-col-12 p-md-12 ">
                        <div className="p-field p-col-12 p-md-12 ">
                            <h5 className="HeadingTitle">
                                {t("homeDetails")}
                            </h5>
                        </div>
                    </div>
                    <HomeName register={register} error={errors} setValue={setValue} getValues={getValues} />
                    <HttGbvHomeAddress register={register} error={errors} setValue={setValue} getValues={getValues} />

                    <FiscalYearGbv
                        fiscalYearValue={fiscalYear}
                        handleFiscalYearState={handleFiscalYear}
                        quarterValue={quarter}
                        handleQuarterState={handleQuarter}
                    />

                    <div className="p-field p-col-12 p-md-12 ">
                        <div className="p-field p-col-12 p-md-12 ">
                            <h5 className="HeadingTitle">
                                {t("casesAtServiceCenter")}
                            </h5>
                        </div>
                    </div>

                    <div className="p-grid p-col-12 p-md-12 ">
                        <div class="p-col-12 p-md-12">
                            <div className="p-field p-col-3 p-md-3 float-left">
                                <Controller
                                    name="maleGbvCases"
                                    control={control}
                                    autoFocus
                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                "p-invalid": fieldState.invalid,
                                            })}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                            }}
                                            min="0"
                                            placeholder={t("male")}
                                            tooltip={t("male")}
                                            tooltipOptions={{
                                                position: "bottom"
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="p-field p-col-3 p-md-3 float-left">
                                <Controller
                                    name="femaleGbvCases"
                                    control={control}
                                    autoFocus
                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                "p-invalid": fieldState.invalid,
                                            })}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                            }}
                                            min="0"
                                            placeholder={t("female")}
                                            tooltip={t("female")}
                                            tooltipOptions={{
                                                position: "bottom"
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="p-field p-col-3 p-md-3 float-left">
                                <Controller
                                    name="otherGbvCases"
                                    control={control}
                                    autoFocus
                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                "p-invalid": fieldState.invalid,
                                            })}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                            }}
                                            min="0"
                                            placeholder={t("others")}
                                            tooltip={t("others")}
                                            tooltipOptions={{
                                                position: "bottom"
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="p-field p-col-3 p-md-3 float-left">
                                <Controller
                                    name="totGbvCases"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                "p-invalid": fieldState.invalid,
                                            })}
                                            value={
                                                (getValues("maleGbvCases") ? getValues("maleGbvCases") : 0)
                                                + (getValues("femaleGbvCases") ? getValues("femaleGbvCases") : 0)
                                                + (getValues("otherGbvCases") ? getValues("otherGbvCases") : 0)}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                            }}
                                            min="0"
                                            disabled
                                            tooltip={t("total")}
                                            tooltipOptions={{
                                                position: "bottom"
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    {/* {hideBtn === "No" ? (
                        <> */}
                    <div className="p-grid p-col-12 p-md-12">

                        <div className="p-col-12 p-md-9"></div>
                        <div className="p-col-12 p-md-3">
                            <Button label={t("addPersonalDetails")}
                                className="p-button-sm pull-right submitBtn"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                    {/* </>
                    ) : (
                        <></>
                    )} */}
                </form>
            </Card>
        </div>
    );
};

export default GbvServiceCenter;
