import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";

import Logo1 from "../../scafolding/assets/images/govLogo.png";
import Stamp from "../../scafolding/assets/images/stamp.png";
import Photo from "../../scafolding/assets/images/photo.png";
import Signature from "../../scafolding/assets/images/signature.png";
import "./IDCard.css";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import AddressService from "../../security/api/services/AddressService";
import OrganizationService from "../../security/api/services/OrganizationService";
import { englishToNepaliNumber, nepaliToEnglishNumber } from "nepali-number";
import {
  DISABILITY_BY_NATURE_NEPALI,
  DISABILITY_BY_SEVERITY_NEPALI,
  DISABILITY_TYPE_ENGLISH,
  DISABILITY_TYPE_NEPALI,
  FATHER_MOTHER_GUARDIAN_NEPALI,
  GENDER_NEPALI,
  ID_CATEGORY_ENGLISH,
  ID_CATEGORY_NEPALI,
} from "../../utilities/constants/ITMISConstansts";
import { useMemo } from "react";

function DisabilityIDCard(props) {
  const { t } = useTranslation();
  const idCardDetails = props.location.state.data;
  const [fullAddressEng, setFullAddressEng] = useState();
  const [fullAddressNep, setFullAddressNep] = useState();
  const [dob, setDob] = useState();
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [fullAddressEngOrg, setFullAddressEngOrg] = useState();
  const [fullAddressNepOrg, setFullAddressNepOrg] = useState();
  const [municipalityEng, setMunicipalityEng] = useState();
  const [municipalityNep, setMunicipalityNep] = useState();
  const [cardColor, setCardColor] = useState();
  const [printModal, setPrintModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const footer = (
    <div>
      <Button label={t("ho")} icon="pi pi-check" onClick={async () => {
        await setIsCopy(true);
        await printFunction();
      }} />
      <Button label={t("haina")} icon="pi pi-times" onClick={async () => {
        await setIsCopy(false);
        await printFunction();
      }} />
    </div>
  );

  const printFunction = () => {
    print(document.getElementById("nepali"), document.getElementById('english'));
  }

  const onHide = () => {
    setPrintModal(false);
  }

  const convertIdToNepali = (data) => {
    const idArray = data.split("_");
    let idNepali = "";

    if(idArray.length > 2) {
      idNepali =
        englishToNepaliNumber(idArray[0]) +
        "_" +
        englishToNepaliNumber(idArray[1]) +
        "_" +
        englishToNepaliNumber(idArray[2]) +
        "_" +
        ID_CATEGORY_NEPALI[idArray[3]] +
        "_" +
        englishToNepaliNumber(idArray[4]);
    }  else if(idArray.length = 2) {
      idNepali =
        englishToNepaliNumber(idArray[0]) +
        "_" +
        englishToNepaliNumber(idArray[1]);
    }
    return idNepali;
  };
  const convertDOBNepToNepali = (data) => {
    const dobNepArray = data.split("-");
    const dobNep =
      englishToNepaliNumber(dobNepArray[0]) +
      "-" +
      englishToNepaliNumber(dobNepArray[1]) +
      "-" +
      englishToNepaliNumber(dobNepArray[2]);
    return dobNep;
  };

  useEffect(() => {
    const idCardDetails = props.location.state.data;
    console.log("====", idCardDetails);
    idCardDetails.idCardNepaliNo = convertIdToNepali(idCardDetails.id);
    AddressService.getFullAddressEng(
      props.location.state.data.disabledAddressDetails.province,
      props.location.state.data.disabledAddressDetails.district,
      props.location.state.data.disabledAddressDetails.municipality,
      props.location.state.data.disabledAddressDetails.wardNo
    ).then((response) => {
      let res = response.data.data;
      setFullAddressEng(res.replace(",sl", ", "));
    });
    AddressService.getFullAddressNep(
      props.location.state.data.disabledAddressDetails.province,
      props.location.state.data.disabledAddressDetails.district,
      props.location.state.data.disabledAddressDetails.municipality,
      props.location.state.data.disabledAddressDetails.wardNo
    ).then((response) => {
      let resN = response.data.data;
      setFullAddressNep(resN.replace(",sl", ", "));
    });
  }, [props.location]);

  const resetCardColor = () => {
    switch (props.location.state.data.idCardType) {
      case "ka":
        setCardColor("#ef2222");
        break;
      case "kha":
        setCardColor("#4fa5ef");
        break;
      case "ga":
        setCardColor("#fdfda5");
        break;
      case "gha":
        setCardColor("White");
        break;
      default:
        setCardColor("rgb(255, 255, 255)");
    }
  }

  useEffect(() => {
    // OrganizationService.getLoggedInUserOrganizaitonDetails().then((response) => {
    OrganizationService.getOrganizationById(props.location.state.data.organization).then((response) => {
      console.log("org>>>", response.data.data);
      setOrganizationDetails(response.data.data);
      let wardVal = response.data.data.organizationAddress.ward ? response.data.data.organizationAddress.ward : 0;
      AddressService.getFullAddressEng(
        response.data.data.organizationAddress.province,
        response.data.data.organizationAddress.district,
        response.data.data.organizationAddress.municipality,
        wardVal
      ).then((response) => {
        let engAdd = response.data.data;
        if (engAdd) {
          let eAdd = engAdd.split(",sl");
          console.log(eAdd);
          setMunicipalityEng(eAdd[0]);
          setFullAddressEngOrg(eAdd[1]);
        }
      });
      AddressService.getFullAddressNep(
        response.data.data.organizationAddress.province,
        response.data.data.organizationAddress.district,
        response.data.data.organizationAddress.municipality,
        wardVal
      ).then((response) => {
        let nepAdd = response.data.data;
        if (nepAdd) {
          let nAdd = nepAdd.split(",sl");
          setMunicipalityNep(nAdd[0]);
          setFullAddressNepOrg(nAdd[1]);
        }
      });
    });

    resetCardColor();

    // let dobValue = props.location.state.data.dobEng;
    // let dobArr = dobValue.split("T");
    // setDob(dobArr[0]);
  }, [props.location]);

  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Card
        style={{ display: "flex", justifyContent: "center", margin: "16px", borderRadius: "8px" }}
      >
        <div className="section-to-print">
          <div className="page" id="nepali"
          //  style={{ background: cardColor }}
          >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Image style={{ paddingRight: "7px" }} width={50} height={50} src={Logo1} />
                  </div>
                </div>
                <div style={{ width: "250px" }}>
                  <center>
                    <h4 style={{ paddingTop: "3px", fontSize: 11, fontWeight: "bold", color: "#8c0404" }}>
                      {municipalityNep} <br />
                      {fullAddressNepOrg}
                    </h4>
                    <div
                      style={{
                        flex: 1,
                        marginTop: "5px",
                        // background: "#d00000",
                        background: cardColor,
                        // color: "#FFF",
                        color: "#000",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        fontSize: 14,
                        width: "210px"
                      }}
                    >
                      अपाङ्गता भएका व्यक्तिको परिचयपत्र
                    </div>
                  </center>
                </div>
                <div style={{ flex: 1 }}>
                  <Image
                    style={{ paddingLeft: "15px", paddingTop: "10px" }}
                    width={65}
                    height={72}
                    src={
                      "data:image/jpg;base64," +
                      idCardDetails.disabledIdCardPhotoInformation.fileData
                    }
                  />
                </div>
              </div>
              <div style={{ color: "black", width: "100%", float: "left", fontWeight: "bold", fontSize: 10, paddingLeft: 2, color: "#000000" }}>
                <div style={{ width: "70%", float: "left" }}>
                  परिचयपत्र नं : {idCardDetails.idCardNo ? englishToNepaliNumber(idCardDetails.idCardNo) : idCardDetails.idCardNepaliNo}
                </div>
                <div style={{ width: "30%", float: "right" }}>
                  रक्त समूह : {idCardDetails.bloodGroup}{" "}
                </div>
                <div style={{ width: "70%", float: "left" }}>
                  नाम, थर :{" "}
                  {idCardDetails.firstNameNep +
                    " " +
                    (idCardDetails.middleNameNep ? idCardDetails.middleNameNep : "") +
                    " " +
                    idCardDetails.lastNameNep}
                </div>
                <div style={{ width: "30%", float: "right" }}>
                  लिङ्ग : {GENDER_NEPALI[idCardDetails.gender]}
                </div>
                {idCardDetails.citizenshipNo != null ?
                  <div style={{ width: "70%", float: "left" }}>
                    नागरिकता प्रमाणपत्र नं : {idCardDetails.citizenshipNo != null ? englishToNepaliNumber(idCardDetails.citizenshipNo) : ""}
                  </div> :
                  <div style={{ width: "70%", float: "left" }}>
                    जन्म दर्ता प्रमाणपत्र नं : {idCardDetails.birthCertificateNo != null ? englishToNepaliNumber(idCardDetails.birthCertificateNo) : ""}
                  </div>
                }
                <div style={{ width: "30%", float: "right" }}>
                  जन्म मिति : {convertDOBNepToNepali(idCardDetails.dobNep)}
                </div>
                {/* जन्म दर्ता प्रमाणपत्र नम्बर : {idCardDetails.birthCertificateNo != null ? englishToNepaliNumber(idCardDetails.birthCertificateNo) : ""} <br /> */}
                {/* अपाङ्गता प्रकार : {idCardDetails.disabilityType != null ? DISABILITY_TYPE_NEPALI[idCardDetails.disabilityType] : ""} <br /> */}
                ठेगाना : {englishToNepaliNumber(fullAddressNep)} <br />
                <div style={{ width: "100%" }}>
                  अपाङ्गताको किसिम :{" "}
                  प्रकृतिको आधारमा - {idCardDetails.disabilityType != null ?
                    DISABILITY_TYPE_NEPALI[idCardDetails.disabilityType] :
                    ""}
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  गम्भीरताको आधारमा -  {ID_CATEGORY_NEPALI[idCardDetails.idCardType]}
                </div>
                <div style={{ width: "85%", float: "left" }}>
                  {idCardDetails.fatherMotherGuardian ? FATHER_MOTHER_GUARDIAN_NEPALI[idCardDetails.fatherMotherGuardian] : "बुबा/आमा/अभिभावक"}को नाम :{" "}
                  {idCardDetails.fatherMotherGaurdianfNameNep +
                    " " +
                    (idCardDetails.fatherMotherGaurdianmNameNep
                      ? idCardDetails.fatherMotherGaurdianmNameNep
                      : "") +
                    " " +
                    idCardDetails.fatherMotherGaurdianlNameNep}
                </div>
                {isCopy ?
                  <div style={{ width: "15%", float: "right" }}>
                    <strong>प्रतिलिपि</strong>
                  </div>
                  :
                  <></>
                }



              </div>
              <div style={{ color: "black", width: "100%", fontWeight: "bold", fontSize: 9, paddingLeft: 2, color: "#000000" }}>
                <div style={{ width: "50%", float: "left" }}>
                  {/* <strong style={{ fontSize: 11 }}>अक्षमता को प्रकार</strong> <br />
                  प्रकृतिको आधारमा :{" "}
                  {DISABILITY_BY_NATURE_NEPALI[idCardDetails.typeOfDisabilityByNature]} <br />
                  गम्भीरताको आधारमा :{" "}
                  {DISABILITY_BY_SEVERITY_NEPALI[idCardDetails.typeOfDisabilityBySeverity]} <br /> */}
                  {idCardDetails.holderSignature != null ?
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "10px",
                        paddingTop: "15px",
                        paddingBottom: "20px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <Image
                        style={{}}
                        width={90}
                        height={20}
                        src={
                          "data:image/jpg;base64," +
                          idCardDetails.holderSignature
                        }
                      />
                    </div>
                    :

                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "10px",
                        paddingTop: "15px",
                        paddingBottom: "20px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                    </div>
                  }
                  परिचयपत्र बाहकको हस्ताक्षर
                  <br />
                  जारी मिति : {convertDOBNepToNepali(idCardDetails.disabledApprovedDetails.dateNep)}
                </div>
                <div style={{ width: "50%", float: "right", textAlign: "right", paddingRight: 10 }}>
                  <strong style={{ fontSize: 11 }}>स्वीकृत गर्ने </strong> <br />
                  {idCardDetails.disabledApprovedDetails.approvalSignature != null ?
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "99px",
                        paddingTop: "2px",
                        paddingBottom: "19px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <Image
                        style={{}}
                        width={90}
                        height={20}
                        src={
                          "data:image/jpg;base64," +
                          idCardDetails.disabledApprovedDetails.approvalSignature
                        }
                      />
                    </div>
                    :
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "99px",
                        paddingTop: "2px",
                        paddingBottom: "19px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                    </div>
                  }

                  हस्ताक्षर <br />
                  {idCardDetails.disabledApprovedDetails.nameNep} <br />
                  {idCardDetails.disabledApprovedDetails.designationNep}
                </div>
              </div>
              <div style={{ color: "#000", paddingTop: "2px", fontWeight: "bold", fontSize: 7, textAlign: "center" }}>
                यो परिचयपत्र कसैले पाएमा नजिकको प्रहरी कार्यालय वा स्थानीय तहमा बुझाइदिनुहोला |
              </div>
            </div>
          </div>

          <div style={{ height: "50px" }}></div>


          <div className="page" id="english" style={{
            // background: cardColor, 
            paddingTop: "0px"
          }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Image style={{ paddingRight: "7px" }} width={50} height={50} src={Logo1} />
                  </div>
                </div>
                <div style={{ width: "250px" }}>
                  <center>
                    <h4 style={{ paddingTop: "3px", fontSize: 11, color: "#8c0404" }}>
                      {municipalityEng} <br />
                      {fullAddressEngOrg}
                    </h4>
                    <div
                      style={{
                        flex: 1,
                        marginTop: "5px",
                        // background: "#d00000",
                        background: cardColor,
                        // color: "#FFF",
                        color: "#000",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        fontSize: 14,
                        width: "160px"
                      }}
                    >
                      Disability Identity Card
                    </div>
                  </center>
                </div>
                <div style={{ flex: 1, paddingLeft: "15px", paddingTop: "3px" }}>
                  <Image
                    style={{}}
                    width={65}
                    height={72}
                    src={
                      "data:image/jpg;base64," +
                      idCardDetails.disabledIdCardPhotoInformation.fileData
                    }
                  />
                </div>
              </div>

              <div style={{ color: "black", width: "100%", float: "left", fontWeight: "bold", fontSize: 10, paddingLeft: 2, color: "#000000" }}>
                <div style={{ width: "69%", float: "left" }}>
                  ID No. : {idCardDetails.idCardNo ? idCardDetails.idCardNo : idCardDetails.id}
                </div>
                <div style={{ width: "31%", float: "right" }}>
                  Blood Group : {idCardDetails.bloodGroup}{" "}
                </div>
                <div style={{ width: "69%", float: "left" }}>
                  Name :{" "}
                  {idCardDetails.firstNameEng +
                    " " +
                    (idCardDetails.middleNameEng ? idCardDetails.middleNameEng : "") +
                    " " +
                    idCardDetails.lastNameEng}{" "}
                </div>
                <div style={{ width: "31%", float: "right" }}>
                  Sex : {idCardDetails.gender}{" "}
                </div>
                {idCardDetails.citizenshipNo != null ?
                  <div style={{ width: "69%", float: "left" }}>
                    Citizenship No. : {idCardDetails.citizenshipNo}{" "}
                  </div> :
                  <div style={{ width: "69%", float: "left" }}>
                    Birth Certificate No. : {idCardDetails.birthCertificateNo != null ? idCardDetails.birthCertificateNo : ""}
                  </div>
                }
                <div style={{ width: "31%", float: "right" }}>
                  DOB : {idCardDetails.dobEng}
                </div>
                {/* Birth Certificate No. : {idCardDetails.birthCertificateNo != null ? idCardDetails.birthCertificateNo : ""} <br /> */}

                Address : {fullAddressEng} <br />
                <div style={{ width: "100%" }}>
                  Type of Disability :{" "}
                  By Nature - {idCardDetails.disabilityType != null ?
                    DISABILITY_TYPE_ENGLISH[idCardDetails.disabilityType] : ""}
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  By Severity - {ID_CATEGORY_ENGLISH[idCardDetails.idCardType]}
                </div>
                <div style={{ width: "85%", float: "left" }}>
                  {idCardDetails.fatherMotherGuardian ? idCardDetails.fatherMotherGuardian : "Father/Mother/Guardian"} name :{" "}
                  {idCardDetails.fatherMotherGaurdianfNameEng +
                    " " +
                    (idCardDetails.fatherMotherGaurdianmNameEng
                      ? idCardDetails.fatherMotherGaurdianmNameEng
                      : "") +
                    " " +
                    idCardDetails.fatherMotherGaurdianlNameEng}{" "}
                </div>
                {isCopy ?
                  <div style={{ width: "15%", float: "right" }}>
                    <strong>Copy</strong>
                  </div>
                  :
                  <></>
                }
              </div>

              <div style={{ color: "black", width: "100%", fontWeight: "bold", fontSize: 9, paddingLeft: 2, color: "#000000" }}>
                <div style={{ width: "50%", float: "left" }}>
                  {/* <strong style={{ fontSize: 11 }}>Types of Disability</strong> <br />
                On the basis of Nature: {idCardDetails.typeOfDisabilityByNature} <br />
                On the basis of Severity: {idCardDetails.typeOfDisabilityBySeverity} <br /> */}

                  {/* Signature added */}
                  {idCardDetails.holderSignature != null ?
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "10px",
                        paddingTop: "15px",
                        paddingBottom: "20px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <Image
                        style={{}}
                        width={90}
                        height={20}
                        src={
                          "data:image/jpg;base64," +
                          idCardDetails.holderSignature
                        }
                      />
                    </div>
                    :
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "10px",
                        paddingTop: "15px",
                        paddingBottom: "20px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                    </div>
                  }

                  Signature of ID card holder
                  <br />
                  Issue Date : {idCardDetails.disabledApprovedDetails.dateEng}
                </div>
                <div style={{ width: "50%", float: "right", textAlign: "right", paddingRight: 10 }}>
                  <strong style={{ fontSize: 11 }}>Approved By</strong> <br />
                  {idCardDetails.disabledApprovedDetails.approvalSignature != null ?
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "99px",
                        paddingTop: "2px",
                        paddingBottom: "19px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <Image
                        style={{}}
                        width={90}
                        height={20}
                        src={
                          "data:image/jpg;base64," +
                          idCardDetails.disabledApprovedDetails.approvalSignature
                        }
                      />
                    </div>
                    :
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "99px",
                        paddingTop: "2px",
                        paddingBottom: "19px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                    </div>
                  }
                  Signature <br />
                  {idCardDetails.disabledApprovedDetails.nameEng} <br />
                  {idCardDetails.disabledApprovedDetails.designationEng}
                </div>
              </div>
              <div style={{ color: "#000", paddingTop: "2px", fontWeight: "bold", fontSize: 7, textAlign: "center" }}>
                If somebody finds this ID card, please deposit it in the nearby police station or local level office.
              </div>
            </div>
          </div>
        </div>
      </Card>

      <div style={{ alignContent: "center" }}>
        {/* <Button
          style={{ padding: "10px", margin: "10px" }}
          label="Color Print"
          className="p-button-raised p-button-rounded "
          onClick={() => {
            resetCardColor();
            setPrintModal(true)
          }}
        /> */}
        <Button
          style={{ padding: "10px", margin: "10px" }}
          label="Print"
          className="p-button-raised p-button-rounded "
          onClick={() => {
            // setCardColor("White");
            setPrintModal(true);
          }}
        />

        <Dialog header={t("isIdCardACopy")}
          footer={footer}
          visible={printModal}
          style={{ width: '50vw' }}
          onHide={() => onHide()}>
        </Dialog>
      </div>
    </div>
  );
}

export default DisabilityIDCard;

function print(nepali, english) {
  console.log("nepali", nepali);
  var doc = new jsPDF("l", "mm", [107, 75]); //var doc = new jsPDF("l", "px", [ 390, 271]);

  const html2canvas1 = require('html2canvas');
  var i2 = document.createElement("img");
  html2canvas1(english, { scale: "5" }).then(canvas2 => { i2.src = canvas2.toDataURL(); });

  doc.html(document.createElement("div"),
    {
      callback: function (doc) {
        const html2canvas = require('html2canvas');
        html2canvas(nepali, { scale: "5" }).then(canvas1 => {
          doc.addImage(canvas1, "JPEG", 0, 0, 107, 75);
          doc.addPage();
          doc.addImage(i2.currentSrc, "JPEG", 0, 0, 107, 75, "alias1", 'SLOW');
          doc.output("dataurlnewwindow");
        });
      }
    })
}
