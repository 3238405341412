import React, { useState, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { Card } from "primereact/card";
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from 'primereact/inputtextarea';

import { Dropdown } from 'primereact/dropdown';
import { INT_EXT, LANGUAGE, YES_NO } from '../../../utilities/constants/ITMISConstansts';
import { t } from 'i18next';
import { MultiSelect } from 'primereact/multiselect';
import i18n from '../../../il8n/il8n';
import AddressService from '../../../security/api/services/AddressService';
import { width } from '@mui/system';
import TipFileUpload from './TipFileUpload';

const TipDetail = (props) => {
    ///const [typeOfTipCase, setTypeOfTipCase] = useState();
    const [typeOfTipCaseInternal, setTypeOfTipCaseInternal] = useState();
    const [typeOfTipCaseExternal, setTypeOfTipCaseExternal] = useState();
    const [districtsAllList, setDistrictsAllList] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [flagA, setFlagA] = useState();
    const [flagM, setFlagM] = useState();
    const [flagP, setFlagP] = useState();
    const [val, setVal] = useState();

    useEffect(() => {
        AddressService.getAllDistrict().then((response) => {
            setDistrictsAllList(response.data.data);
        });
    }, []);

    useEffect(() => {
        AddressService.getAllCountry().then((response) => {
            setCountriesList(response.data.data);
        });
    }, []);

    const [tip, setValueTip] = useState('');
    const setTypeOfTipCase = (val) => {
        setValueTip(val)
    };

    const [actOfTipChecbox, setActOfTipChecbox] = useState([]);

    const [actOfTipList, setActOfTipList] = useState([
        { labelEng: " Recruitment", labelNep: " भर्ती", value: "Recruitment" },
        { labelEng: " Transportation", labelNep: " ओसारपसार", value: "Transportation" },
        { labelEng: " Transfer", labelNep: " स्थानान्तरण", value: "Transfer" },
        { labelEng: " Harbouring or receipt of person", labelNep: " आश्रय दिने वा प्राप्त गर्ने कार्य", value: "Harboring or Receipt" },
        { labelEng: " Sell or buy person for any purposes", labelNep: " कुनैपनि उद्धेश्यले मानिस बेच्ने वा किन्ने कार्य", value: "Sell or buy person" },
        { labelEng: " Forced someone into prostitution", labelNep: " कुनैपनि प्रकारको फाइदा लाई वा नलिई बेश्याबृर्तिमा लगाउने कार्य", value: "Force into Prostitution" },
        { labelEng: " To remove human organs except otherwise determined by law", labelNep: " प्रचलित कानुन बमोजिम बाहेक मानिसको अंगहरु झिक्ने कार्य", value: "Remove organs except determined by Law" },
        { labelEng: " To engaged in prostitution", labelNep: " बेश्यागमन गर्ने", value: "Engaged in Prostitution" },
    ]);

    const checkboxActOfTipListFunction = (val) => {
        console.log("actOfTipList", actOfTipList);
        let list = [];
        let newList = actOfTipList.map(a => {
            if (a.value === val) {
                a.checked = !a.checked;

            }
            return a;
        });
        console.log("newlist", newList);
        let newActOfList = newList.map(data => {
            if (data.checked && data.checked === true) {
                console.log("list", list);
                list.push(data.value);
            }
            return list;
        });
        setActOfTipChecbox(newActOfList[0]);
        props.setValue("tipDetail.act", newActOfList[0]);
        setActOfTipList(newList);
    }

    let acts = (props.getValues("tipDetail.act") || []).join(',');

    useEffect(() => {
        if (flagA) return;
        const actData = props.getValues("tipDetail.act") || [];

        if (actData.length > 0) {
            let newList = actOfTipList.map(a => {
                if (actData.find(val => val === a.value)) {
                    if (!a.checked) {
                        a.checked = !a.checked;
                    }
                }
                return a;
            });
            setFlagA(true);
            setActOfTipList(newList);
            setActOfTipChecbox(actData);
        }
    }, [acts]);

    const [means, setValueMeans] = useState([]);

    const [meansOfTipList, setMeansOfTipList] = useState([
        { labelEng: " Threat or use of force or other forms of coercion", 
        labelNep: " धम्की, त्रास वा बल प्रयोग वा अन्य प्रकारको करकाप", value: "Threat Or Force" },
        { labelEng: " Abduction", labelNep: " अपहरण", value: "Abduction" },
        { labelEng: " Fraud", labelNep: " ठगी", value: "Fraud", },
        { labelEng: " Deception", labelNep: " धोखा/झुक्याइ", value: "Deception" },
        { labelEng: " Enticement", labelNep: " प्रलोभनमा पारी", value: "Enticement" },
        { labelEng: " Hostage", labelNep: " शरीर बन्धक राखी", value: "Hostage" },
        { labelEng: " Abuse of Power", labelNep: " शक्तिको दुरुपयोग", value: "Abuse of Power" },
        { labelEng: " Giving or receiving of payments or benefits to achieve the consent of a person having control over another person", 
        labelNep: " भुक्तानी वा लाभको लेनदेनका लागि एक व्यक्तिको सहमतिमा अर्को व्यक्तिमाथि नियन्त्रण गर्ने", 
        value: "Giving Payments To Achieve Consent" },
    ]);

    const checkboxMeansOfTipFunction = (val) => {
        let list = [];
        let newList = meansOfTipList.map(a => {
            if (a.value === val) {
                a.checked = !a.checked;
            }
            return a;
        });
        console.log("newlist", newList);
        let meansNewList = newList.map(data => {
            if (data.checked && data.checked === true) {
                console.log("list", list);
                list.push(data.value);
            }
            return list;
        });
        setValueMeans(meansNewList[0]);
        props.setValue("tipDetail.means", meansNewList[0]);
        setMeansOfTipList(newList);
    }

    let meansVal = (props.getValues("tipDetail.means") || []).join(',');

    useEffect(() => {
        if (flagM) return;
        const meansData = props.getValues("tipDetail.means") || [];

        if (meansData.length > 0) {
            let newList = meansOfTipList.map(a => {
                if (meansData.find(val => val === a.value)) {
                    if (!a.checked) {
                        a.checked = !a.checked;
                    }
                }
                return a;
            });
            setFlagM(true);
            setMeansOfTipList(newList);
            setValueMeans(meansData);
        }
    }, [meansVal]);

    //new
    const [purpose, setValuePurpose] = useState([]);

    const [purposeOfTipList, setValuePurposeList] = useState([
        {
            labelEng: " The exploitation of the prostitution of others or other forms of sexual exploitation",
            labelNep: " वेश्यावृत्तिमाथिको शोषण वा अन्य स्वरूपका यौन शोषण",
            value: "Prostitution Exploitation"
        },
        {
            labelEng: " Forced labour or services",
            labelNep: " जबरजस्ती श्रम वा सेवामा लगाउने कार्य",
            value: "Forced Labor Services"
        },
        {
            labelEng: " Slavery or practices similar to slavery",
            labelNep: " दासता वा दासता सरहका अभ्यास",
            value: "Slavery"
        },
        {
            labelEng: " Servitude or the removal of organs",
            labelNep: " बन्धक बनाउने वा अंगहरू झिक्ने कार्य",
            value: "Servitude"
        },
    ]);

    const dataPurposeOfTipListFunction = (val, data) => {
        let list = [];
        let newList = purposeOfTipList.map(a => {
            if (a.value === val) {
                a.checked = !a.checked;
            }
            return a;
        });
        console.log("newList.val".val);
        let purposeTipListCheckBox = newList.map(data => {
            if (data.checked && data.checked === true) {
                console.log("list", list);
                list.push(data.value);
            }
            return list;
        });
        setValuePurpose(purposeTipListCheckBox[0]);
        props.setValue("tipDetail.purpose", purposeTipListCheckBox[0]);
        setValuePurposeList(newList);
    }

    let purposeVal = (props.getValues("tipDetail.purpose") || []).join(',');

    useEffect(() => {
        if (flagP) return;
        const purposeData = props.getValues("tipDetail.purpose") || [];

        if (purposeData.length > 0) {
            let newList = purposeOfTipList.map(a => {
                if (purposeData.find(val => val === a.value)) {
                    if (!a.checked) {
                        a.checked = !a.checked;
                    }
                }
                return a;
            });
            setFlagP(true);
            setValuePurposeList(newList);
            setValuePurpose(purposeData);
        }
    }, [purposeVal]);

    const stakeholdersList = [
        { label: t("ocmc"), value: "OCMC" },
        { label: t("ahtb"), value: "AHTB" },
        { label: t("nepalPolice"), value: "Nepal Police" },
        { label: t("localGovernment"), value: "Local Government" },
        { label: t("ncrc"), value: "NCRC" },
        { label: t("nwc"), value: "NWC" },
        { label: t("csos"), value: "CSOs" },
        { label: t("embassy"), value: "Embassy" },
        { label: t("migrantResourceCenter"), value: "Migrant Resource Center" },
        { label: t("outreachOfficesOfDestinationCountries"), value: "Outreach Offices of Destination Countries" },
        { label: t("iom"), value: "IOM" },
        { label: t("dofe"), value: "DOFE" },
        { label: t("feb"), value: "FEB" },
        { label: t("familyCommunity"), value: "Family or Community" },
        { label: t("school"), value: "School" },
        { label: t("youthChildClub"), value: "Youth or Child Club" },
        { label: t("media"), value: "Media" },
        { label: t("othersPleaseSpecify"), value: "Others" },
    ];

    const [referredByVal, setReferredByVal] = useState("No");
    const [interceptedByVal, setInterceptedByVal] = useState("No");
    const [ccIndiaReferredByVal, setCcIndiaReferredByVal] = useState("No");
    const [ccChinaReferredByVal, setCcChinaReferredByVal] = useState("No");
    const [ccOthersReferredByVal, setCcOthersReferredByVal] = useState("No");

    const handleCcIndiaReferredBy = (value) => {
        (value.includes("Others") ?
            setCcIndiaReferredByVal("Yes") :
            setCcIndiaReferredByVal("No")
        )
    }

    const handleCcChinaReferredBy = (value) => {
        (value.includes("Others") ?
            setCcChinaReferredByVal("Yes") :
            setCcChinaReferredByVal("No")
        )
    }

    const handleCcOthersReferredBy = (value) => {
        (value.includes("Others") ?
            setCcOthersReferredByVal("Yes") :
            setCcOthersReferredByVal("No")
        )
    }

    return (
        <>
            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-12">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("typeOfTipCase")}<span style={{ color: "#d00000" }}> * </span>
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            {...props.register("tipDetail.typeOfTipCase"
                                , {
                                    required: "Type of TIP Case is Required",
                                }
                            )}
                            value={INT_EXT.Internal}
                            name={INT_EXT.Internal}
                            onChange={(e) => {
                                props.setValue("tipDetail.typeOfTipCase", INT_EXT.Internal);
                                setTypeOfTipCase(e.value);
                                props.register("tipDetail.typeOfTipCase");

                            }}
                            checked={props.getValues("tipDetail.typeOfTipCase") === INT_EXT.Internal}
                        /> {t("internal")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            {...props.register("tipDetail.typeOfTipCase"
                                , {
                                    required: "Type of TIP Case is Required",
                                }
                            )}
                            value={INT_EXT.External}
                            name={INT_EXT.External}
                            onChange={(e) => {
                                props.setValue("tipDetail.typeOfTipCase", INT_EXT.External);
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.typeOfTipCase") === INT_EXT.External}
                        /> {t("external")}
                    </div>
                    {props.error.tipDetail && props.error.tipDetail.typeOfTipCase.type === "required" && (
                        <small className="p-error">{props.error.tipDetail.typeOfTipCase.message}</small>
                    )}
                </div>
            </div>

            {/* If selected internal */}
            {props.getValues("tipDetail.typeOfTipCase") === 'Internal'
                && <>

                    <div className="p-grid p-col-12 p-md-12 ">
                        <div className="p-col-12 p-md-12">
                            <div className="p-field p-col-12 p-md-4 float-left">
                                <Checkbox

                                    value="Referred"
                                    name="Referred"
                                    //added
                                    //  placeholder={t("province")}                                   

                                    required="Referred is Required"

                                    // onChange={(e) => {
                                    //     props.setValue("tipDetail.typeOfTipCaseInternal", "Referred");
                                    //     setTypeOfTipCaseInternal(e.value);
                                    // }}
                                    onChange={(e) => {
                                        let selectedTypeOfTipCaseInternal = props.getValues("tipDetail.typeOfTipCaseInternal") || [];
                                        if (e.checked) {
                                            selectedTypeOfTipCaseInternal.push(e.value);
                                        } else {
                                            selectedTypeOfTipCaseInternal = selectedTypeOfTipCaseInternal.filter(form => form !== e.value);
                                        }
                                        props.setValue("tipDetail.typeOfTipCaseInternal", selectedTypeOfTipCaseInternal);
                                    }}
                                    checked={props.getValues("tipDetail.typeOfTipCaseInternal")?.includes("Referred")}
                                /> {t("referred")}
                            </div>
                            <div className="p-field p-col-12 p-md-4 float-left">
                                <Checkbox

                                    // {...props.register("tipDetail.typeOfTipCaseInternal"
                                    //     , {
                                    //         required: "Internal sub-checkbox is Required",
                                    //     }
                                    // )}
                                    value="Rescued"
                                    name="Rescued"
                                    // onChange={(e) => {
                                    //     props.setValue("tipDetail.typeOfTipCaseInternal", "Rescued");
                                    //     setTypeOfTipCaseInternal(e.value);
                                    // }}
                                    // checked={props.getValues("tipDetail.typeOfTipCaseInternal") === "Rescued"}
                                    onChange={(e) => {
                                        let selectedTypeOfTipCaseInternal = props.getValues("tipDetail.typeOfTipCaseInternal") || [];
                                        if (e.checked) {
                                            selectedTypeOfTipCaseInternal.push(e.value);
                                        } else {
                                            selectedTypeOfTipCaseInternal = selectedTypeOfTipCaseInternal.filter(form => form !== e.value);
                                        }
                                        props.setValue("tipDetail.typeOfTipCaseInternal", selectedTypeOfTipCaseInternal);
                                    }}
                                    checked={props.getValues("tipDetail.typeOfTipCaseInternal")?.includes("Rescued")}
                                /> {t("rescued")}
                            </div>
                            <div className="p-field p-col-12 p-md-4 float-left">
                                <Checkbox

                                    // {...props.register("tipDetail.typeOfTipCaseInternal"
                                    //     , {
                                    //         required: "Internal sub-checkbox is Required",
                                    //     }
                                    // )}
                                    value="Intercepted"
                                    name="Intercepted"
                                    // onChange={(e) => {
                                    //     props.setValue("tipDetail.typeOfTipCaseInternal", "Intercepted");
                                    //     setTypeOfTipCaseInternal(e.value);
                                    // }}
                                    // checked={props.getValues("tipDetail.typeOfTipCaseInternal") === "Intercepted"}
                                    onChange={(e) => {
                                        let selectedTypeOfTipCaseInternal = props.getValues("tipDetail.typeOfTipCaseInternal") || [];
                                        if (e.checked) {
                                            selectedTypeOfTipCaseInternal.push(e.value);
                                        } else {
                                            selectedTypeOfTipCaseInternal = selectedTypeOfTipCaseInternal.filter(form => form !== e.value);
                                        }
                                        props.setValue("tipDetail.typeOfTipCaseInternal", selectedTypeOfTipCaseInternal);
                                    }}
                                    checked={props.getValues("tipDetail.typeOfTipCaseInternal")?.includes("Intercepted")}
                                /> {t("intercepted")}
                            </div>
                        </div>

                        {/* {props.error.tipDetail && props.error.tipDetail.typeOfTipCaseInternal.type === "required" && (
                            <small className="p-error">{props.error.tipDetail.typeOfTipCaseInternal.message}</small>
                        )} */}
                    </div>


                    {props.getValues("tipDetail.typeOfTipCaseInternal")?.includes("Referred")
                        //  === 'Referred'
                        ? <>
                            <div className="p-field p-col-12 p-md-12 ">
                                <h3 style={{ textAlign: "center" }}>{t("referred")}</h3>
                            </div>

                            <div className="p-grid p-col-12 p-md-12 ">
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                                        {t("referredBy")}
                                    </div>
                                    <div className="p-field p-col-12 p-md-12 float-left">
                                        <Dropdown
                                            name="referredBy"
                                            filter
                                            placeholder={t("select")}
                                            {...props.register("tipDetail.referredBy")}
                                            value={props.getValues("tipDetail.referredBy")}
                                            options={stakeholdersList}
                                            onChange={(e) => {
                                                props.setValue("tipDetail.referredBy", e.value);
                                                setReferredByVal(e.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                {(props.getValues("tipDetail.referredBy") === "Others") ?
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                                            {t("pleaseSpDetails")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-12 float-left">
                                            <InputText
                                                name="referredByOthers"
                                                {...props.register("tipDetail.referredByOthers")}
                                                value={props.getValues("tipDetail.referredByOthers")}
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.referredByOthers", e.target.value);
                                                    setVal(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </>
                        : <></>
                    }

                    {props.getValues("tipDetail.typeOfTipCaseInternal")?.includes("Rescued")
                        //  === 'Rescued'
                        ? <>
                            <div className="p-field p-col-12 p-md-12 ">
                                <h3 style={{ textAlign: "center" }}>{t("rescued")}</h3>
                            </div>

                            <div className="p-grid p-col-12 p-md-12 ">
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                                        {t("rescuedFrom")}
                                    </div>
                                    <div className="p-field p-col-12 p-md-12 float-left">
                                        <Dropdown
                                            name="rescuedFrom"
                                            filter
                                            placeholder={t("select")}
                                            {...props.register("tipDetail.rescuedFrom")}
                                            value={props.getValues("tipDetail.rescuedFrom")}
                                            options={districtsAllList}
                                            optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                            optionValue="id"
                                            onChange={(e) => {
                                                props.setValue("tipDetail.rescuedFrom", e.value);
                                                setTypeOfTipCase(e.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                                        {t("rescuedTo")}
                                    </div>
                                    <div className="p-field p-col-12 p-md-12 float-left">
                                        <Dropdown
                                            name="rescuedTo"
                                            filter
                                            placeholder={t("select")}
                                            {...props.register("tipDetail.rescuedTo")}
                                            value={props.getValues("tipDetail.rescuedTo")}
                                            options={districtsAllList}
                                            optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                            optionValue="id"
                                            onChange={(e) => {
                                                props.setValue("tipDetail.rescuedTo", e.value);
                                                setTypeOfTipCase(e.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>
                    }

                    {props.getValues("tipDetail.typeOfTipCaseInternal")?.includes("Intercepted")
                        //  === 'Intercepted'
                        ? <>
                            <div className="p-field p-col-12 p-md-12 ">
                                <h3 style={{ textAlign: "center" }}>{t("intercepted")}</h3>
                            </div>

                            <div className="p-grid p-col-12 p-md-12 ">
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                                        {t("interceptedBy")}
                                    </div>
                                    <div className="p-field p-col-12 p-md-12 float-left">
                                        <Dropdown
                                            name="interceptedBy"
                                            filter
                                            placeholder={t("select")}
                                            {...props.register("tipDetail.interceptedBy")}
                                            value={props.getValues("tipDetail.interceptedBy")}
                                            options={stakeholdersList}
                                            onChange={(e) => {
                                                props.setValue("tipDetail.interceptedBy", e.value);
                                                setInterceptedByVal(e.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                {(props.getValues("tipDetail.interceptedBy") === "Others") ?
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                                            {t("pleaseSpDetails")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-12 float-left">
                                            <InputText
                                                name="interceptedByOthers"
                                                {...props.register("tipDetail.interceptedByOthers")}
                                                value={props.getValues("tipDetail.interceptedByOthers")}
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.interceptedByOthers", e.target.value);
                                                    setVal(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                                        {t("interceptedAt")}
                                    </div>
                                    <div className="p-field p-col-12 p-md-12 float-left">
                                        <Dropdown
                                            name="interceptedAt"
                                            filter
                                            placeholder={t("select")}
                                            {...props.register("tipDetail.interceptedAt")}
                                            value={props.getValues("tipDetail.interceptedAt")}
                                            options={districtsAllList}
                                            optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                            optionValue="id"
                                            onChange={(e) => {
                                                props.setValue("tipDetail.interceptedAt", e.value);
                                                setTypeOfTipCase(e.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>
                    }
                </>

            }


            {/* If selected external  */}
            {props.getValues("tipDetail.typeOfTipCase") === 'External'
                ? <>
                    <div className="p-grid p-col-12 p-md-12 ">
                        <div className="p-col-12 p-md-12">
                            <div className="p-field p-col-12 p-md-4 float-left">
                                <RadioButton
                                    value="Cross country (India)"
                                    name="Cross country (India)"
                                    onChange={(e) => {
                                        props.setValue("tipDetail.typeOfTipCaseExternal", "Cross country (India)");
                                        setTypeOfTipCaseExternal(e.value);
                                    }}
                                    checked={props.getValues("tipDetail.typeOfTipCaseExternal") === "Cross country (India)"}
                                /> {t("crossCountryIndia")}
                            </div>
                            <div className="p-field p-col-12 p-md-4 float-left">
                                <RadioButton
                                    value="Cross country (China)"
                                    name="Cross country (China)"
                                    onChange={(e) => {
                                        props.setValue("tipDetail.typeOfTipCaseExternal", "Cross country (China)");
                                        setTypeOfTipCaseExternal(e.value);
                                    }}
                                    checked={props.getValues("tipDetail.typeOfTipCaseExternal") === "Cross country (China)"}
                                /> {t("crossCountryChina")}
                            </div>
                            <div className="p-field p-col-12 p-md-4 float-left">
                                <RadioButton
                                    value="Cross country (Others)"
                                    name="Cross country (Others)"
                                    onChange={(e) => {
                                        props.setValue("tipDetail.typeOfTipCaseExternal", "Cross country (Others)");
                                        setTypeOfTipCaseExternal(e.value);
                                    }}
                                    checked={props.getValues("tipDetail.typeOfTipCaseExternal") === "Cross country (Others)"}
                                /> {t("otherCountry")}
                            </div>
                        </div>
                    </div>

                    {props.getValues("tipDetail.typeOfTipCaseExternal") === 'Cross country (India)'
                        ? <>
                            <div className="p-field p-col-12 p-md-12 ">
                                <h3 style={{ textAlign: "center" }}>{t("crossCountryIndia")}</h3>
                            </div>

                            <div className="p-grid p-col-12 p-md-12 ">
                                <div className="p-col-12 p-md-12">
                                    <div className="p-field p-col-12 p-md-4 float-left">
                                        <Checkbox
                                            value="CC India Rescued"
                                            name="CC India Rescued"
                                            onChange={(e) => {
                                                let selectedExternalccIndia = props.getValues("tipDetail.ccIndia") || [];
                                                if (e.checked) {
                                                    selectedExternalccIndia.push(e.value);
                                                } else {
                                                    selectedExternalccIndia = selectedExternalccIndia.filter(form => form !== e.value);
                                                }
                                                props.setValue("tipDetail.ccIndia", selectedExternalccIndia);
                                                setTypeOfTipCaseExternal(e.value);
                                            }}
                                            checked={props.getValues("tipDetail.ccIndia")?.includes("CC India Rescued")}
                                        /> {t("rescuedRepatriated")}
                                    </div>
                                    <div className="p-field p-col-12 p-md-4 float-left">
                                        <Checkbox
                                            value="CC India Referred"
                                            name="CC India Referred"
                                            onChange={(e) => {
                                                let selectedExternalccIndia = props.getValues("tipDetail.ccIndia") || [];
                                                if (e.checked) {
                                                    selectedExternalccIndia.push(e.value);
                                                } else {
                                                    selectedExternalccIndia = selectedExternalccIndia.filter(form => form !== e.value);
                                                }
                                                props.setValue("tipDetail.ccIndia", selectedExternalccIndia);
                                            }}
                                            checked={props.getValues("tipDetail.ccIndia")?.includes("CC India Referred")}
                                        /> {t("referredBy")}
                                    </div>
                                </div>
                            </div>

                            {props.getValues("tipDetail.ccIndia")?.includes("CC India Referred")
                                ? <>
                                    <div className="p-field p-col-12 p-md-12 ">
                                        <h3 style={{ textAlign: "center" }}>{t("referredBy")}</h3>
                                    </div>

                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("referredBy")}
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left">
                                                <MultiSelect
                                                    name="ccIndiaReferredBy"
                                                    placeholder={t("select")}
                                                    {...props.register("tipDetail.ccIndiaReferredBy")}
                                                    value={props.getValues("tipDetail.ccIndiaReferredBy")}
                                                    filter
                                                    options={stakeholdersList}
                                                    onChange={(e) => {
                                                        props.setValue("tipDetail.ccIndiaReferredBy", e.value);
                                                        handleCcIndiaReferredBy(e.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {props.getValues("tipDetail.ccIndiaReferredBy")?.includes("Others") ?
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                    {t("pleaseSpDetails")}
                                                </div>
                                                <div className="p-field p-col-12 p-md-12 float-left">
                                                    <InputText
                                                        name="ccIndiaReferredByOthers"
                                                        {...props.register("tipDetail.ccIndiaReferredByOthers")}
                                                        value={props.getValues("tipDetail.ccIndiaReferredByOthers")}
                                                        onChange={(e) => {
                                                            props.setValue("tipDetail.ccIndiaReferredByOthers", e.target.value);
                                                            setVal(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </>
                                : <></>
                            }
                        </>
                        : <></>
                    }

                    {props.getValues("tipDetail.typeOfTipCaseExternal") === 'Cross country (China)'
                        ? <>
                            <div className="p-field p-col-12 p-md-12 ">
                                <h3 style={{ textAlign: "center" }}>{t("crossCountryChina")}</h3>
                            </div>

                            <div className="p-grid p-col-12 p-md-12 ">
                                <div className="p-col-12 p-md-12">
                                    <div className="p-field p-col-12 p-md-4 float-left">
                                        <Checkbox
                                            value="CC China Rescued"
                                            name="CC China Rescued"
                                            onChange={(e) => {
                                                setTypeOfTipCaseExternal(e.value);
                                                let selectedExternalccChina = props.getValues("tipDetail.ccChina") || [];
                                                if (e.checked) {
                                                    selectedExternalccChina.push(e.value);
                                                } else {
                                                    selectedExternalccChina = selectedExternalccChina.filter(form => form !== e.value);
                                                }
                                                props.setValue("tipDetail.ccChina", selectedExternalccChina);
                                            }}
                                            checked={props.getValues("tipDetail.ccChina")?.includes("CC China Rescued")}
                                        /> {t("rescuedRepatriated")}
                                    </div>
                                    <div className="p-field p-col-12 p-md-4 float-left">
                                        <Checkbox
                                            value="CC China Referred"
                                            name="CC China Referred"
                                            onChange={(e) => {
                                                let selectedExternalccChina = props.getValues("tipDetail.ccChina") || [];
                                                if (e.checked) {
                                                    selectedExternalccChina.push(e.value);
                                                } else {
                                                    selectedExternalccChina = selectedExternalccChina.filter(form => form !== e.value);
                                                }
                                                props.setValue("tipDetail.ccChina", selectedExternalccChina);
                                                setTypeOfTipCaseExternal(e.value);
                                            }}
                                            checked={props.getValues("tipDetail.ccChina")?.includes("CC China Referred")}
                                        /> {t("referredBy")}
                                    </div>
                                </div>
                            </div>

                            {props.getValues("tipDetail.ccChina")?.includes("CC China Referred")
                                ? <>
                                    <div className="p-field p-col-12 p-md-12 ">
                                        <h3 style={{ textAlign: "center" }}>{t("referredBy")}</h3>
                                    </div>

                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("referredBy")}
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left">
                                                <MultiSelect
                                                    name="ccChinaReferredBy"
                                                    placeholder={t("select")}
                                                    {...props.register("tipDetail.ccChinaReferredBy")}
                                                    value={props.getValues("tipDetail.ccChinaReferredBy")}
                                                    filter
                                                    options={stakeholdersList}
                                                    onChange={(e) => {
                                                        props.setValue("tipDetail.ccChinaReferredBy", e.value);
                                                        handleCcChinaReferredBy(e.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {props.getValues("tipDetail.ccChinaReferredBy")?.includes("Others") ?
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                    {t("pleaseSpDetails")}
                                                </div>
                                                <div className="p-field p-col-12 p-md-12 float-left">
                                                    <InputText
                                                        name="ccChinaReferredByOthers"
                                                        {...props.register("tipDetail.ccChinaReferredByOthers")}
                                                        value={props.getValues("tipDetail.ccChinaReferredByOthers")}
                                                        onChange={(e) => {
                                                            props.setValue("tipDetail.ccChinaReferredByOthers", e.target.value);
                                                            setVal(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </>
                                : <></>
                            }
                        </>
                        : <></>
                    }


                    {props.getValues("tipDetail.typeOfTipCaseExternal") === 'Cross country (Others)'
                        ? <>
                            <div className="p-field p-col-12 p-md-12 ">
                                <h3 style={{ textAlign: "center" }}>{t("otherCountry")}</h3>
                            </div>

                            <div className="p-grid p-col-12 p-md-12 ">
                                <div className="p-col-12 p-md-12">
                                    <div className="p-field p-col-12 p-md-4 float-left">
                                        <Checkbox
                                            value="CC Others Rescued"
                                            name="CC Others Rescued"
                                            onChange={(e) => {
                                                let selectedExternalccOthers = props.getValues("tipDetail.ccOthers") || [];
                                                if (e.checked) {
                                                    selectedExternalccOthers.push(e.value);
                                                } else {
                                                    selectedExternalccOthers = selectedExternalccOthers.filter(form => form !== e.value);
                                                }
                                                props.setValue("tipDetail.ccOthers", selectedExternalccOthers);
                                                setTypeOfTipCaseExternal(e.value);
                                            }}
                                            checked={props.getValues("tipDetail.ccOthers")?.includes("CC Others Rescued")}
                                        /> {t("rescuedRepatriated")}
                                    </div>
                                    <div className="p-field p-col-12 p-md-4 float-left">
                                        <Checkbox
                                            value="CC Others Referred"
                                            name="CC Others Referred"
                                            onChange={(e) => {
                                                let selectedExternalccOthers = props.getValues("tipDetail.ccOthers") || [];
                                                if (e.checked) {
                                                    selectedExternalccOthers.push(e.value);
                                                } else {
                                                    selectedExternalccOthers = selectedExternalccOthers.filter(form => form !== e.value);
                                                }
                                                props.setValue("tipDetail.ccOthers", selectedExternalccOthers);
                                                setTypeOfTipCaseExternal(e.value);
                                            }}
                                            checked={props.getValues("tipDetail.ccOthers")?.includes("CC Others Referred")}
                                        /> {t("referredBy")}
                                    </div>
                                </div>
                            </div>

                            {props.getValues("tipDetail.ccOthers")?.includes("CC Others Rescued")
                                ? <>
                                    <div className="p-field p-col-12 p-md-12 ">
                                        <h3 style={{ textAlign: "center" }}>{t("rescuedRepatriated")}</h3>
                                    </div>

                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("rescuedRepatriated")}
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left">
                                                <Dropdown
                                                    name="ccOthersRescuedBy"
                                                    filter
                                                    placeholder={t("select")}
                                                    {...props.register("tipDetail.ccOthersRescuedBy")}
                                                    value={props.getValues("tipDetail.ccOthersRescuedBy")}
                                                    options={countriesList}
                                                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "countryDescEng" : "countryDescNep"}
                                                    optionValue="id"
                                                    onChange={(e) => {
                                                        props.setValue("tipDetail.ccOthersRescuedBy", e.value);
                                                        setTypeOfTipCase(e.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : <></>
                            }

                            {props.getValues("tipDetail.ccOthers")?.includes("CC Others Referred")
                                //  === 'CC Others Referred'
                                ? <>
                                    <div className="p-field p-col-12 p-md-12 ">
                                        <h3 style={{ textAlign: "center" }}>{t("referredBy")}</h3>
                                    </div>

                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("referredBy")}
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left">
                                                <MultiSelect
                                                    name="ccOthersReferredBy"
                                                    placeholder={t("select")}
                                                    {...props.register("tipDetail.ccOthersReferredBy")}
                                                    value={props.getValues("tipDetail.ccOthersReferredBy")}
                                                    filter
                                                    options={stakeholdersList}
                                                    onChange={(e) => {
                                                        props.setValue("tipDetail.ccOthersReferredBy", e.value);
                                                        handleCcOthersReferredBy(e.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {props.getValues("tipDetail.ccOthersReferredBy")?.includes("Others") ?
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                    {t("pleaseSpDetails")}
                                                </div>
                                                <div className="p-field p-col-12 p-md-12 float-left">
                                                    <InputText
                                                        name="ccOthersReferredByOthers"
                                                        {...props.register("tipDetail.ccOthersReferredByOthers")}
                                                        value={props.getValues("tipDetail.ccOthersReferredByOthers")}
                                                        onChange={(e) => {
                                                            props.setValue("tipDetail.ccOthersReferredByOthers", e.target.value);
                                                            setVal(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </>
                                : <></>
                            }
                        </>
                        : <></>
                    }
                </>
                : <></>
            }


            <div className="p-grid p-col-12 p-md-12 ">
                <br /><br />
                <div className="p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("formsOfTip")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <Checkbox
                            value="Sex Exploitation"
                            name="Sex Exploitation"
                            onChange={(e) => {
                                let selectedFormsOfTip = props.getValues("tipDetail.formsOfTip") || [];
                                if (e.checked) {
                                    selectedFormsOfTip.push(e.value);
                                } else {
                                    selectedFormsOfTip = selectedFormsOfTip.filter(form => form !== e.value);
                                }
                                props.setValue("tipDetail.formsOfTip", selectedFormsOfTip);
                            }}
                            checked={props.getValues("tipDetail.formsOfTip")?.includes("Sex Exploitation")}
                        /> {t("sexExploitation")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <Checkbox
                            value="Labor Exploitation"
                            name="Labor Exploitation"
                            onChange={(e) => {
                                let selectedFormsOfTip = props.getValues("tipDetail.formsOfTip") || [];
                                if (e.checked) {
                                    selectedFormsOfTip.push(e.value);
                                } else {
                                    selectedFormsOfTip = selectedFormsOfTip.filter(form => form !== e.value);
                                }
                                props.setValue("tipDetail.formsOfTip", selectedFormsOfTip);
                            }}
                            checked={props.getValues("tipDetail.formsOfTip")?.includes("Labor Exploitation")}
                        /> {t("laborExploitation")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <Checkbox
                            value="Organ Extraction"
                            name="Organ Extraction"
                            onChange={(e) => {
                                let selectedFormsOfTip = props.getValues("tipDetail.formsOfTip") || [];
                                if (e.checked) {
                                    selectedFormsOfTip.push(e.value);
                                } else {
                                    selectedFormsOfTip = selectedFormsOfTip.filter(form => form !== e.value);
                                }
                                props.setValue("tipDetail.formsOfTip", selectedFormsOfTip);
                            }}
                            checked={props.getValues("tipDetail.formsOfTip")?.includes("Organ Extraction")}
                        /> {t("organExtraction")}
                    </div>
                </div>
            </div>

            <div className="p-field p-col-12 p-md-12 ">
                <h3 style={{ textAlign: "center" }}>{t("possibleTipCaseDescription")}</h3>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6" style={{ width: "100%" }} >
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("actOfTip")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left" style={{ marginTop: "10px" }}>
                        <div className="p-col-12 p-md-6 float-left">
                            {actOfTipList.map((actOfTip) => {
                                return (
                                    <div key={actOfTip.key} className="col-12 p-mb-6 flex align-items-center">
                                        <Checkbox
                                            name="act"
                                            placeholder={t("select")}
                                            {...props.register("tipDetail.act")}
                                            value={props.getValues("tipDetail.act")}
                                            // checked={actOfTip.val
                                            checked={actOfTip.checked}
                                            onChange={(e) => {
                                                checkboxActOfTipListFunction(actOfTip.value);
                                                console.log(actOfTip);

                                            }}
                                        />
                                        <label>{i18n.language == LANGUAGE.ENGLISH ? actOfTip.labelEng : actOfTip.labelNep}</label>

                                    </div>

                                );
                            })}
                        </div>
                        <div className="p-col-12 p-md-6 float-right">
                            {actOfTipChecbox?.includes("Recruitment") ?
                                <InputText
                                    name="recruitmentStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.recruitmentStory")}
                                    {...props.register("tipDetail.recruitmentStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.recruitmentStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ height: " 60px" }}
                                    tooltip={t("recruitment")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {actOfTipChecbox?.includes("Transportation") ?
                                <InputText
                                    name="transportationStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.transportationStory")}
                                    {...props.register("tipDetail.transportationStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.transportationStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("transportation")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {actOfTipChecbox?.includes("Transfer") ?
                                <InputText
                                    name="transferStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.transferStory")}
                                    {...props.register("tipDetail.transferStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.transferStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("transfer")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {actOfTipChecbox?.includes("Harboring or Receipt") ?
                                <InputText
                                    name="harboringStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.harboringStory")}
                                    {...props.register("tipDetail.harboringStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.harboringStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("harboringOrReceipt")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {actOfTipChecbox?.includes("Sell or buy person") ?
                                <InputText
                                    name="sellBuyPersonStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.sellBuyPersonStory")}
                                    {...props.register("tipDetail.sellBuyPersonStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.sellBuyPersonStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("sellOrBuyPerson")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {actOfTipChecbox?.includes("Force into Prostitution") ?
                                <InputText
                                    name="forceProstitutionStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.forceProstitutionStory")}
                                    {...props.register("tipDetail.forceProstitutionStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.forceProstitutionStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("forceIntoProstitution")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {actOfTipChecbox?.includes("Remove organs except determined by Law") ?
                                <InputText
                                    name="removeOrgansStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.removeOrgansStory")}
                                    {...props.register("tipDetail.removeOrgansStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.removeOrgansStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "15px", height: " 60px" }}
                                    tooltip={t("removeOrgansExceptDeterminedByLaw")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "15px", height: " 60px", border: "none" }} disabled />
                            }
                            {actOfTipChecbox?.includes("Engaged in Prostitution") ?
                                <InputText
                                    name="engagedInProstitutionStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.engagedInProstitutionStory")}
                                    {...props.register("tipDetail.engagedInProstitutionStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.engagedInProstitutionStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("engagedInProstitution")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }

                        </div>
                        {/* {actOfTip.checked &&
                                        <div className="p-field p-md-7 float-right" style={{ padding: "0px" }}>
                                            <InputTextarea
                                                placeholder={"Write a story"}
                                                value={actOfTip.data}
                                                onChange={(e) => dataActOfTipListFunction(actOfTip.value, e.target.value)}
                                                rows={2} cols={100} />

                                        </div>
                                    } */}
                    </div>

                </div>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6 " style={{ width: "100%" }}>
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("meansOfTip")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left" style={{ marginTop: "10px" }}>
                        <div className="p-col-12 p-md-6 float-left">
                            {meansOfTipList.map((means) => {
                                return (
                                    <div key={means.key} className="col-12 p-mb-6 flex align-items-center">
                                        <Checkbox
                                            name="means"
                                            value={props.getValues("tipDetail.means")}
                                            {...props.register("tipDetail.means")}
                                            checked={means.checked}
                                            onChange={(e) => {
                                                checkboxMeansOfTipFunction(means.value);
                                                console.log(means);
                                            }}
                                        />
                                        <label>{i18n.language == LANGUAGE.ENGLISH ? means.labelEng : means.labelNep}</label>

                                    </div>
                                );
                            })}
                        </div>
                        <div className="p-col-12 p-md-6 float-right">
                            {means?.includes("Threat Or Force") ?
                                <InputText
                                    name="threatStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.threatStory")}
                                    {...props.register("tipDetail.threatStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.threatStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ height: " 60px" }}
                                    tooltip={t("threatOrForce")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ height: " 60px", border: "none" }} disabled />
                            }
                            {means?.includes("Abduction") ?
                                <InputText
                                    name="abductionStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.abductionStory")}
                                    {...props.register("tipDetail.abductionStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.abductionStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("abduction")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {means?.includes("Fraud") ?
                                <InputText
                                    name="fraudStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.fraudStory")}
                                    {...props.register("tipDetail.fraudStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.fraudStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("fraud")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {means?.includes("Deception") ?
                                <InputText
                                    name="deceptionStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.deceptionStory")}
                                    {...props.register("tipDetail.deceptionStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.deceptionStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("deception")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {means?.includes("Enticement") ?
                                <InputText
                                    name="enticementStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.enticementStory")}
                                    {...props.register("tipDetail.enticementStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.enticementStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("enticement")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {means?.includes("Hostage") ?
                                <InputText
                                    name="hostageStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.hostageStory")}
                                    {...props.register("tipDetail.hostageStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.hostageStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("hostage")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {means?.includes("Abuse of Power") ?
                                <InputText
                                    name="abuseOfPowerStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.abuseOfPowerStory")}
                                    {...props.register("tipDetail.abuseOfPowerStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.abuseOfPowerStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("abuseOfPowerOrPosition")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {means?.includes("Giving Payments To Achieve Consent") ?
                                <InputText
                                    name="givingPaymentsStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.givingPaymentsStory")}
                                    {...props.register("tipDetail.givingPaymentsStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.givingPaymentsStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("givingPaymentsToAchieveConsent")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6 " style={{ width: "100%" }}>
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("purposeOfTip")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left" style={{ marginTop: "10px" }}>
                        <div className="p-col-12 p-md-6 float-left">
                            {purposeOfTipList.map((purpose) => {
                                return (
                                    <div key={purpose.key} className="col-12 p-mb-6 flex align-items-center">
                                        <Checkbox
                                            name="purpose"
                                            placeholder={t("select")}
                                            {...props.register("tipDetail.purpose")}
                                            value={props.getValues("tipDetail.purpose")}
                                            // checked={Purpose.value === Purpose}
                                            checked={purpose.checked}
                                            onChange={(e) => {
                                                dataPurposeOfTipListFunction(purpose.value);
                                                console.log(purpose);
                                            }}
                                        />
                                        <label>{i18n.language == LANGUAGE.ENGLISH ? purpose.labelEng : purpose.labelNep}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="p-col-12 p-md-6 float-right">
                            {purpose?.includes("Prostitution Exploitation") ?
                                <InputText
                                    name="prostitutionExploitationStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.prostitutionExploitationStory")}
                                    {...props.register("tipDetail.prostitutionExploitationStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.prostitutionExploitationStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ height: " 60px" }}
                                    tooltip={t("prostitutionExploitation")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ height: " 60px", border: "none" }} disabled />
                            }
                            {purpose?.includes("Forced Labor Services") ?
                                <InputText
                                    name="forcedLaborStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.forcedLaborStory")}
                                    {...props.register("tipDetail.forcedLaborStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.forcedLaborStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("forcedLaborServices")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {purpose?.includes("Slavery") ?
                                <InputText
                                    name="slaveryPracticesStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.slaveryPracticesStory")}
                                    {...props.register("tipDetail.slaveryPracticesStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.slaveryPracticesStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("slaveryPractices")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }
                            {purpose?.includes("Servitude") ?
                                <InputText
                                    name="servitudeStory"
                                    placeholder={t("writeBriefDetail")}
                                    value={props.getValues("tipDetail.servitudeStory")}
                                    {...props.register("tipDetail.servitudeStory")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.servitudeStory", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "7px", height: " 60px" }}
                                    tooltip={t("servitude")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "7px", height: " 60px", border: "none" }} disabled />
                            }

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default TipDetail;