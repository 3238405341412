
import React, { useState } from "react";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { bsToAd } from "@sbmdkl/nepali-date-converter";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";

const ReportDate = (props) => {
    const { t } = useTranslation();
    const fiscalYearList = [
        { label: t("2078/79"), value: '2078-79' },
        { label: t("2079/80"), value: '2079-80' },
        { label: t("2080/81"), value: '2080-81' },
        { label: t("2081/82"), value: '2081-82' }
    ];
    const quarterList = [
        { label: t("ShrawanAshwin"), value: '1' },
        { label: t("KartikPoush"), value: '2' },
        { label: t("MaghChaitra"), value: '3' },
        { label: t("BaishakAshad"), value: '4' }
    ];

    const handleFromDate = (value) => {
        console.log("content  ", value);
        console.log("bs to ad  ", bsToAd(value));
        props.handleFromDateBs(value);
        props.handleFromDateAd(bsToAd(value));
    }

    const handleToDate = (value) => {
        console.log("content  ", value);
        console.log("bs to ad  ", bsToAd(value));
        props.handleToDateBs(value);
        props.handleToDateAd(bsToAd(value));
    }

    const [fiscalYearFrom, setFiscalYearFrom] = useState(props.getValues("fiscalYearFrom"));
    const [quarterFrom, setQuarterFrom] = useState(props.getValues("quarterFrom"));
    const [fiscalYearTo, setFiscalYearTo] = useState(props.getValues("fiscalYearTo"));
    const [quarterTo, setQuarterTo] = useState(props.getValues("quarterTo"));

    return (
        <>
            <div class="p-field p-col-12 p-md-12" style={{ justifyContent: "center" }}>
                <div className="p-field p-col-12 p-md-3 float-left main-label"> {t("fromDate")}</div>
                <div className="p-field p-col-12 p-md-3 float-left">
                    <Dropdown
                        filter
                        name="fiscalYearFrom"
                        placeholder={t("fiscalYear")}
                        {...props.register("fiscalYearFrom")}
                        options={fiscalYearList}
                        value={props.getValues("fiscalYearFrom")}
                        onChange={(e) => {
                            props.setValue("fiscalYearFrom", e.value);
                            setFiscalYearFrom(e.value);
                        }}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3 float-left">
                    <Dropdown
                        filter
                        name="quarterFrom"
                        placeholder={t("quarter")}
                        {...props.register("quarterFrom")}
                        options={quarterList}
                        value={props.getValues("quarterFrom")}
                        onChange={(e) => {
                            props.setValue("quarterFrom", e.value);
                            setQuarterFrom(e.value);
                        }}
                    />
                </div>
            </div>
            <div class="p-field p-col-12 p-md-12" style={{ justifyContent: "center" }}>
                <div className="p-field p-col-12 p-md-3 float-left main-label">{t("toDate")}</div>
                <div className="p-field p-col-12 p-md-3 float-left">
                    <Dropdown
                        filter
                        name="fiscalYearTo"
                        placeholder={t("fiscalYear")}
                        {...props.register("fiscalYearTo")}
                        options={fiscalYearList}
                        value={props.getValues("fiscalYearTo")}
                        onChange={(e) => {
                            props.setValue("fiscalYearTo", e.value);
                            setFiscalYearTo(e.value);
                        }}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3 float-left">
                    <Dropdown
                        filter
                        name="quarterTo"
                        placeholder={t("quarter")}
                        {...props.register("quarterTo")}
                        options={quarterList}
                        value={props.getValues("quarterTo")}
                        onChange={(e) => {
                            props.setValue("quarterTo", e.value);
                            setQuarterTo(e.value);
                        }}
                    />
                </div>
            </div>
        </>
    )

}
export default ReportDate;