import axios from "axios";
import AppProps from "../../../AppProps";
import errorComposer from "../../../utilities/components/errorComposer";
import HomeLookup from "../../Components/HomeLookup";
class HomeLookupService {
  save(data) {
    console.log(JSON.stringify(data), "inside saveUser post api");
    return axios.post(AppProps.API_BASE_URL + "/security/home-lookup/", data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }
  
  // getLookupList() {
  //   return axios.get(AppProps.API_BASE_URL + "/security/lookup/lookup-list", {
  //     headers: {
  //       Authorization: "Bearer " + sessionStorage.getItem("token"),
  //     },
  //   });
  // }
  
  getLookupDetails(data) {
    return axios.post(AppProps.API_BASE_URL + "/security/home-lookup/search-by-address",data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getAllHomeDetails() {
    return axios.get(AppProps.API_BASE_URL + "/security/home-lookup/home-details-list", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  update(data, homeId) {
    return axios.put(AppProps.API_BASE_URL + "/security/home-lookup/"+ homeId, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  delete(homeId) {
    return axios.delete(AppProps.API_BASE_URL + "/security/home-lookup/" +homeId, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  
}
export default new HomeLookupService();
