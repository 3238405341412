import React, { useState, useRef, useEffect } from "react";

import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import FiscalQuarterReport from "../../../utilities/components/FiscalQuarterReport";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { trackPromise } from "react-promise-tracker";


import { useForm, Controller } from "react-hook-form";
import { t } from "i18next";

import AddressService from "../../../security/api/services/AddressService";
import i18n from "../../../il8n/il8n";
import { LANGUAGE } from "../../../utilities/constants/ITMISConstansts";
import SourceForFirTip from "../../../utilities/components/SourceForFirTip";
import FirTipCaseRegistrationService from "../api/services/FirTipCaseRegistrationService";

function FirTipCaseRegistration(props) {

    const [fiscalYear, setFiscalYear] = useState("");
    const [quarter, setQuarter] = useState(0);
    const [enableForm, setEnableForm] = useState(true);
    const [update, setUpdate] = useState(false);
    const [firTipId, setFirTipId] = useState();
    const handleFiscalYear = (fiscalYearVal) => {
        setFiscalYear(fiscalYearVal);
    };
    const handleQuarter = (quarterVal) => {
        setQuarter(quarterVal);
    };
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        getValues,
        trigger
    } = useForm({});
    const toast = useRef(null);

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const [districtsAllList, setDistrictsAllList] = useState([]);


    const [displayValue, setDisplayValue] = useState("No");
    const searchData = () => {
        let searchData = {
            fiscalYear: fiscalYear,
            quarter: quarter
        };
        if (fiscalYear !== "") {
            setDisplayValue("Yes");
            trackPromise(
                FirTipCaseRegistrationService.getData(searchData).then((response) => {
                    console.log("response>>>>.", response.data);
                    if (response.data.length > 0) {
                        setUpdate(true);
                        setFirTipId(response.data[0].firTipRegistrationId);
                        reset({
                            casesRegistered: response.data[0].casesRegistered,
                            casesRegisteredSource:
                                response.data[0].casesRegisteredSource[0] != "" &&
                                    response.data[0].casesRegisteredSource != ""
                                    ? response.data[0].casesRegisteredSource
                                    : null,
                            districtNames:
                                response.data[0].districtNames[0] != "" &&
                                    response.data[0].districtNames != ""
                                    ? response.data[0].districtNames
                                    : null,

                            districtCasesList: response.data[0].districtCasesList,
                            casesRegisteredByDistrict: response.data[0].casesRegisteredByDistrict,
                            casesRegisteredByDistrictSource: response.data[0].casesRegisteredByDistrictSource[0] != "" &&
                                response.data[0].casesRegisteredByDistrictSource != ""
                                ? response.data[0].casesRegisteredByDistrictSource
                                : null,


                            maleVictimsInfo: response.data[0].maleVictimsInfo,
                            femaleVictimsInfo: response.data[0].femaleVictimsInfo,
                            othersVictimsInfo: response.data[0].othersVictimsInfo,
                            genderVictimsInfoTot: response.data[0].minorVictimsInfo,
                            minorVictimsInfo: response.data[0].minorVictimsInfo,
                            adultVictimsInfo: response.data[0].adultVictimsInfo,
                            ageVictimsInfoTot: response.data[0].adultVictimsInfo,


                            victimsInfoSource: 
                            response.data[0].victimsInfoSource[0] != "" &&
                                    response.data[0].victimsInfoSource != ""
                                    ? response.data[0].victimsInfoSource
                                    : null,

                            malePerpetratorInfo: response.data[0].malePerpetratorInfo,
                            femalePerpetratorInfo: response.data[0].femalePerpetratorInfo,
                            othersPerpetratorInfo: response.data[0].othersPerpetratorInfo,
                            genderPerpetratorInfoTot: response.data[0].genderPerpetratorInfoTot,
                            minorPerpetratorInfo: response.data[0].minorPerpetratorInfo,
                            adultPerpetratorInfo: response.data[0].adultPerpetratorInfo,
                            agePerpetratorInfoTot: response.data[0].agePerpetratorInfoTot,

                            perpetratorInfoSource: response.data[0].perpetratorInfoSource[0] != "" &&
                                response.data[0].perpetratorInfoSource != ""
                                ? response.data[0].perpetratorInfoSource
                                : null,

                            casesRegisteredSourceOthers: response.data[0].casesRegisteredSourceOthers,
                            casesRegisteredByDistrictSourceOthers: response.data[0].casesRegisteredByDistrictSourceOthers,
                            victimsInfoSourceOthers: response.data[0].victimsInfoSourceOthers,
                            perpetratorInfoSourceOthers: response.data[0].perpetratorInfoSourceOthers
                        });
                        setSelectedDistrictList(response.data[0].districtCasesList);
                    } else {
                        reset({
                            casesRegisteredSource: [],
                            perpetratorInfoSource: [],
                            districtNames: [],
                            casesRegisteredByDistrictSource: [],
                            victimsInfoSource: [],
                            perpetratorInfoSource: [],
                        });
                        setSelectedDistrictList([]);
                        setUpdate(false);
                    }
                })
            )
        } else {
            setDisplayValue("No");
            toast.current.show({
                severity: "warn",
                summary: t("selectFiscalYear"),
                life: 3000,
            });
        }
    };

    const [selectedDistrictList, setSelectedDistrictList] = useState([]);

    const selectedDistricts = (e, name) => {
        const newList = e.map((data) => {
            const existingDistrict = selectedDistrictList.find((d) => d.districtName === data);
            if (existingDistrict) {
                return existingDistrict;
            } else {
                return {
                    districtName: data,
                    totCases: "",
                };
            }
        });
        setSelectedDistrictList(newList);
    };

    useEffect(() => {
        AddressService.getAllDistrict().then((response) => {
            setDistrictsAllList(response.data.data);
        });
    }, []);

    const districtCaseEditor = (colName, props) => {
        return (
            <InputText
                type="text"
                style={{ width: "80%" }}
                value={props.rowData[colName]}
                onChange={(e) => addFinalDistrictCaseData(e.target.value, props, colName, props.rowData.districtName)}
            />
        );
    };

    const inputTextBody = (rowData) => {
        return <InputNumber value={rowData.totCases} min={0} />;
    };

    const addFinalDistrictCaseData = (optvalue, props, colName, districtName) => {
        const newList = selectedDistrictList.map(data => {
            if (data.districtName === districtName) {
                return {
                    ...data,
                    [colName]: optvalue
                };
            }
            return data;
        });
        //to update selectedDistrictList with the new list
        setSelectedDistrictList(newList);
    };

    //function to calculate sum of totalCases
    const [casesRegisteredByDistrict, setCasesRegisteredByDistrict] = useState(0);
    const calculateTotalCases = () => {
        let totalCases = 0;
        selectedDistrictList.forEach((district) => {
            totalCases += parseInt(district.totCases) || 0;
        });
        setValue("casesRegisteredByDistrict", totalCases);
        return totalCases;
    };

    //To update casesRegisteredByDistrict whenever selectedDistrictList changes
    useEffect(() => {
        const totalCases = calculateTotalCases();
        setCasesRegisteredByDistrict(totalCases);
    }, [selectedDistrictList]);


    //To save data
    const saveData = (e) => {
        e.preventDefault();
        let data = getValues();
        // data.fiscalYear = fiscalYear;
        // data.quarter = quarter;
        data.status = "Save";
        console.log("data", data);
        // FirTipCaseRegistrationService.saveData(data).then((response) => {
        //       console.log("response", response);
        //       if (response.status === 200) {
        //         toast.current.show({
        //           severity: "success",
        //           summary: "Success Message",
        //           detail: "Save Successful",
        //           life: 3000,
        //         });
        //        // window.location.reload(false);
        //       } else {
        //         toast.current.show({
        //           severity: "error",
        //           summary: "Error Message",
        //           detail: "Save UnSuccessful",
        //           life: 3000,
        //         });
        //       }
        //     })
    };

    const submitData = (data) => {
        data.fiscalYear = fiscalYear;
        data.quarter = quarter;
        data.status = "Submit";
        data.districtCasesList = selectedDistrictList;
        console.log("data", data);
        if (update) {
            data.firTipRegistrationId = firTipId;
            trackPromise(
                FirTipCaseRegistrationService.editData(data,firTipId).then((response) => {
                    console.log("response", response);
                    if (response.status == 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Submit Successful",
                            life: 3000,
                        });
                        window.location.reload(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Submit UnSuccessful",
                            life: 3000,
                        });
                    }
                })
                    .catch((error) => {
                        // We want to handle globally
                        error.handleGlobally && error.handleGlobally();
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: error.response.data.msg,
                            life: 3000,
                        });
                    })
            )
        } else {
            trackPromise(
                FirTipCaseRegistrationService.saveData(data).then((response) => {
                    console.log("response", response);
                    if (response.status == 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Submit Successful",
                            life: 3000,
                        });
                        window.location.reload(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Submit UnSuccessful",
                            life: 3000,
                        });
                    }
                })
                    .catch((error) => {
                        // We want to handle globally
                        error.handleGlobally && error.handleGlobally();
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: error.response.data.msg,
                            life: 3000,
                        });
                    })
            )
        }
    }

    return (
        <div style={{ marginLeft: "2.5rem" }}>
            <Toast ref={toast} />
            <Card
                className="p-mb-1"
                style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}
            >
                <div className=" p-card-content">
                    <h4 className="p-pt-0">{t("FIR/TIP Case Registration")}</h4>
                </div>
            </Card>
            <Card className="p-mt-0"
            // style={{ height: "72vh", overflowY: "auto" }}
            >
                <div className=" p-card-content">
                    <form className="p-grid p-fluid " autoComplete="off">

                        <FiscalQuarterReport
                            fiscalYearValue={fiscalYear}
                            handleFiscalYearState={handleFiscalYear}
                            quarterValue={quarter}
                            handleQuarterState={handleQuarter}
                        />
                        <div className="p-field p-col-12 p-md-12 ">
                            <div className="p-field p-col-10 p-md-10 float-left"></div>
                            <div className="p-field p-col-2 p-md-2 float-right">
                                <Button
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: "#1c80cf",
                                        color: "#FFF",
                                    }}
                                    label={t("search")}
                                    onClick={handleSubmit(searchData)}
                                />
                            </div>
                        </div>


                        {displayValue === "Yes" ?
                            <>

                                <div className="p-field p-col-12 p-md-12 ">
                                    <div className="p-field p-col-12 p-md-12 ">
                                        <hr style={{ marginTop: "5px" }}></hr>
                                    </div>
                                </div>

                                <div className="main-form" >
                                    {/* onClick={fiscalYearValidation} disabled={enableForm} > */}
                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div class="p-col-12 p-md-9">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("casesRegistered")}
                                                <i
                                                    className="pi pi-question-circle tooltip-style"
                                                    title={t("casesRegistered")}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="casesRegistered"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                        />
                                                    )}
                                                />
                                            </div>

                                        </div>
                                        <div class="p-col-12 p-md-3">
                                            <Controller
                                                name="casesRegisteredSource"
                                                control={control}
                                                //  rules={{ required: "Source is required" }}
                                                render={({ field, fieldState }) => (
                                                    <SourceForFirTip
                                                        id={field.name}
                                                        {...field}
                                                        className={classNames({
                                                            "p-invalid": fieldState.invalid,
                                                        })}
                                                        value={field.value}
                                                        handleSourceState={(e) => {
                                                            console.log("e", e);
                                                            field.onChange(e);
                                                        }}
                                                        sourceOtherValue={getValues("casesRegisteredSourceOthers")}
                                                        handleSourceOtherState={(e) => {
                                                            setValue("casesRegisteredSourceOthers", e);
                                                        }}
                                                    />
                                                )}
                                                defaultValue={[]}
                                            />
                                            {/* <div class="p-col-12 p-md-12">
                                        {getFormErrorMessage("casesRegisteredSource")}
                                    </div> */}
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div className="p-field p-col-12 p-md-12 ">
                                            <hr
                                                style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                                            ></hr>
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div class="p-col-12 p-md-9">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("casesRegisteredByDistrict")}
                                                <i
                                                    className="pi pi-question-circle tooltip-style"
                                                    title={t("casesRegisteredByDistrict")}
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-md-9 float-left">
                                                <Controller
                                                    name="districtNames"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <MultiSelect
                                                            id={field.name}
                                                            value={field.value}
                                                            placeholder={t("select")}
                                                            filter
                                                            onChange=
                                                            {(e) => {
                                                                field.onChange(e.value);
                                                                // displayValue(e.value)
                                                                selectedDistricts(e.value, "districtName");
                                                                // console.log("selectedDistricts", e.value);
                                                            }}
                                                            // style={{ width: "100%" }}
                                                            options={districtsAllList}
                                                            optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                                            // optionValue="id"
                                                            optionValue="districtDescEng"
                                                            display="chip"
                                                        />
                                                    )}
                                                />
                                                {/* <div className="p-field p-col-12 p-md-9 float-left">{displayValue + ",  "}</div> */}
                                                <br />
                                                <div className="p-field p-col-12 p-md-12">
                                                    <DataTable value={selectedDistrictList}
                                                        responsiveLayout="scroll"
                                                        editMode="cell"
                                                        resizableColumns
                                                        className="editable-cells-table p-datatable-sm"
                                                        showGridlines>
                                                        <Column field="districtName" header={t("district")}></Column>
                                                        <Column field="totCases"
                                                            header={t("total")}
                                                            body={inputTextBody}
                                                            editor={(options) => districtCaseEditor("totCases", options)}
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            </div>
                                            <div className="p-field p-col-12 p-md-3 float-left">
                                                <Controller
                                                    name="casesRegisteredByDistrict"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={casesRegisteredByDistrict}
                                                            onChange={(e) => setCasesRegisteredByDistrict(e.target.value)}
                                                            min="0"
                                                            disabled
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-3">
                                            <Controller
                                                name="casesRegisteredByDistrictSource"
                                                control={control}
                                                // rules={{ required: "Source is required" }}
                                                render={({ field, fieldState }) => (
                                                    <SourceForFirTip
                                                        id={field.name}
                                                        {...field}
                                                        className={classNames({
                                                            "p-invalid": fieldState.invalid,
                                                        })}
                                                        value={field.value}
                                                        handleSourceState={(e) => {
                                                            console.log("e", e);
                                                            field.onChange(e);
                                                        }}
                                                        sourceOtherValue={getValues("casesRegisteredByDistrictSourceOthers")}
                                                        handleSourceOtherState={(e) => {
                                                            setValue("casesRegisteredByDistrictSourceOthers", e);
                                                        }}
                                                    />
                                                )}
                                                defaultValue={[]}
                                            />
                                            {/* <div class="p-col-12 p-md-12">
                                        {getFormErrorMessage("casesRegisteredByDistrictSource")}
                                    </div> */}
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div className="p-field p-col-12 p-md-12 ">
                                            <hr
                                                style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                                            ></hr>
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div class="p-col-12 p-md-9">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("victimsSurvivorsInformation")}
                                                <i
                                                    className="pi pi-question-circle tooltip-style"
                                                    title={t("victimsSurvivorsInformation")}
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left sub-label">
                                                {t("byGender")}
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="maleVictimsInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("male")}
                                                            tooltip={t("male")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="femaleVictimsInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("female")}
                                                            tooltip={t("female")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="othersVictimsInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("others")}
                                                            tooltip={t("others")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="genderVictimsInfoTot"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={
                                                                (getValues("maleVictimsInfo") ? getValues("maleVictimsInfo") : 0)
                                                                + (getValues("femaleVictimsInfo") ? getValues("femaleVictimsInfo") : 0)
                                                                + (getValues("othersVictimsInfo") ? getValues("othersVictimsInfo") : 0)}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            disabled
                                                            tooltip={t("total")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left sub-label">
                                                {t("byAge")}
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left"></div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="minorVictimsInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("minorUnder18")}
                                                            tooltip={t("minorUnder18")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="adultVictimsInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("adultAbove18")}
                                                            tooltip={t("adultAbove18")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="ageVictimsInfoTot"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={(getValues("minorVictimsInfo") ? getValues("minorVictimsInfo") : 0)
                                                                + (getValues("adultVictimsInfo") ? getValues("adultVictimsInfo") : 0)}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            disabled
                                                            tooltip={t("total")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-3">
                                            <Controller
                                                name="victimsInfoSource"
                                                control={control}
                                                // rules={{ required: "Source is required" }}
                                                render={({ field, fieldState }) => (
                                                    <SourceForFirTip
                                                        id={field.name}
                                                        {...field}
                                                        className={classNames({
                                                            "p-invalid": fieldState.invalid,
                                                        })}
                                                        value={field.value}
                                                        handleSourceState={(e) => {
                                                            console.log("e", e);
                                                            field.onChange(e);
                                                        }}
                                                        sourceOtherValue={getValues("victimsInfoSourceOthers")}
                                                        handleSourceOtherState={(e) => {
                                                            setValue("victimsInfoSourceOthers", e);
                                                        }}
                                                    />
                                                )}
                                                defaultValue={[]}
                                            />
                                            {/* <div class="p-col-12 p-md-12">
                                        {getFormErrorMessage("victimsInfoSource")}
                                    </div> */}
                                        </div>
                                    </div>

                                    <div className="p-field p-col-12 p-md-12 ">
                                        <div className="p-field p-col-12 p-md-12 ">
                                            <hr
                                                style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                                            ></hr>
                                        </div>
                                    </div>
                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div class="p-col-12 p-md-9">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("perpetratorInformation")}
                                                <i
                                                    className="pi pi-question-circle tooltip-style"
                                                    title={t("perpetratorInformation")}
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left sub-label">
                                                {t("byGender")}
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="malePerpetratorInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("male")}
                                                            tooltip={t("male")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="femalePerpetratorInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("female")}
                                                            tooltip={t("female")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="othersPerpetratorInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("others")}
                                                            tooltip={t("others")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="genderPerpetratorInfoTot"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={(getValues("malePerpetratorInfo") ? getValues("malePerpetratorInfo") : 0)
                                                                + (getValues("femalePerpetratorInfo") ? getValues("femalePerpetratorInfo") : 0)
                                                                + (getValues("othersPerpetratorInfo") ? getValues("othersPerpetratorInfo") : 0)}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            disabled
                                                            tooltip={t("total")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left sub-label">
                                                {t("byAge")}
                                            </div>
                                            <div className="p-field p-col-12 p-md-12 float-left"></div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="minorPerpetratorInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("minorUnder18")}
                                                            tooltip={t("minorUnder18")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="adultPerpetratorInfo"
                                                    control={control}
                                                    autoFocus
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            placeholder={t("adultAbove18")}
                                                            tooltip={t("adultAbove18")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="agePerpetratorInfoTot"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={(getValues("minorPerpetratorInfo") ? getValues("minorPerpetratorInfo") : 0)
                                                                + (getValues("adultPerpetratorInfo") ? getValues("adultPerpetratorInfo") : 0)
                                                            }
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                            disabled
                                                            tooltip={t("total")}
                                                            tooltipOptions={{
                                                                position: "bottom"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-3">
                                            <Controller
                                                name="perpetratorInfoSource"
                                                control={control}
                                                // rules={{ required: "Source is required" }}
                                                render={({ field, fieldState }) => (
                                                    <SourceForFirTip
                                                        id={field.name}
                                                        {...field}
                                                        className={classNames({
                                                            "p-invalid": fieldState.invalid,
                                                        })}
                                                        value={field.value}
                                                        handleSourceState={(e) => {
                                                            console.log("e", e);
                                                            field.onChange(e);
                                                        }}
                                                        sourceOtherValue={getValues("perpetratorInfoSourceOthers")}
                                                        handleSourceOtherState={(e) => {
                                                            setValue("perpetratorInfoSourceOthers", e);
                                                        }}
                                                    />
                                                )}
                                                defaultValue={[]}
                                            />
                                            {/* <div class="p-col-12 p-md-12">
                                        {getFormErrorMessage("perpetratorInfoSource")}
                                    </div> */}
                                        </div>
                                    </div>

                                </div>
                                <div className="p-grid p-col-12 p-md-12">

                                    <div className="p-col-12 p-md-10"></div>
                                    <div className="p-col-12 p-md-2">
                                        <Button label={t("submit")}
                                            className="p-button-sm pull-right submitBtn"
                                            // onClick={() => { saveData() }}
                                            onClick={handleSubmit(submitData)}
                                        />
                                    </div>
                                </div>
                            </> : ""
                        }

                    </form>
                </div>
            </Card>
        </div>
    );
}

export default FirTipCaseRegistration;