import axios from 'axios';
import AppProps from '../../../../AppProps';

class HttDetailService {
  saveData(data) {
    console.log(data, "inside post api");
    return axios.post(AppProps.API_BASE_URL + AppProps.HTT_DETAIL, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getDetailById(id, homeType) {
    return axios.get(AppProps.API_BASE_URL + AppProps.HTT_DETAIL + "/by-id?caseId=" + id + "&homeType=" + homeType, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  editData(data, caseId) {
    console.log(data, "inside post api");
    return axios.put(AppProps.API_BASE_URL + AppProps.HTT_DETAIL + caseId, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getListByAddress(data) {
    console.log("data>>>", data);
    return axios.post(AppProps.API_BASE_URL + AppProps.HTT_DETAIL + "/search-by-address", data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getVerifyingListByAddress(data) {
    console.log("data>>>", data);
    return axios.post(AppProps.API_BASE_URL + AppProps.HTT_DETAIL + "search-by-address/verify", data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  localLevelVerification(caseId) {
    return axios.post(AppProps.API_BASE_URL + AppProps.HTT_DETAIL + "verification/" + caseId, caseId,{
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  
  sendBackForCorrection(data) {
    return axios.post(AppProps.API_BASE_URL + AppProps.HTT_DETAIL + "send-back-for-correction" , data,{
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }
}

export default new HttDetailService()