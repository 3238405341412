import axios from 'axios';
import AppProps from '../../../../AppProps';

class FirTipCaseRegistrationService{      
      getData(data) {
        console.log("data",data);
        return axios.post(AppProps.API_BASE_URL + AppProps.FIR_TIP_REGISTRATION+"indicator",data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
      }
    
      saveData(data) {
        console.log(data, "inside post api");
        return axios.post(AppProps.API_BASE_URL + AppProps.FIR_TIP_REGISTRATION, data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
      }
    
      submitData(data) {
        console.log(data, "inside put api");
        return axios.put(AppProps.API_BASE_URL + AppProps.FIR_TIP_REGISTRATION, data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
      } 

      editData(data,firTipId) {
        console.log(data, "inside put api");
        return axios.put(AppProps.API_BASE_URL + AppProps.FIR_TIP_REGISTRATION+firTipId, data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
      }   
}
export default new FirTipCaseRegistrationService()