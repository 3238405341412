import React, { useEffect, useState } from 'react';
import AppProps from "../../AppProps";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputTextarea } from 'primereact/inputtextarea';
import axios from "axios";
import userlogo from "../assets/images/user.jpg";
import { t } from 'i18next';

function FeedbackUpload() {
	const [feedback, setfeedback] = useState([]);

	useEffect(() => {
		//console.log(sessionStorage.getItem("userName"));
		axios.post(AppProps.API_BASE_URL + "/feedback/get",
			{
				entry_by: sessionStorage.getItem("userName"),
			}, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token"), } })
			.then(res => {
				if (JSON.stringify(res.data.feedback) === undefined)
					document.getElementById("previousFeedback").style.display = "none";
				else
					setfeedback(res.data);
			})
			.catch(err => { console.log(err); })
	}, []);

	const submit = () => {
		//console.log(document.getElementById("feedback").value);
		//console.log(sessionStorage.getItem("userName"));
		var data;
		if (feedback.id === undefined) // need to save
			data = { entry_by: sessionStorage.getItem("userName"), feedback: document.getElementById("feedback").value };
		else // need to update
			data = { entry_by: sessionStorage.getItem("userName"), feedback: document.getElementById("feedback").value, id: feedback.id };

		axios.post(AppProps.API_BASE_URL + "/feedback/save", data,
			{ headers: { Authorization: "Bearer " + sessionStorage.getItem("token"), }, })
			.then(res => {
				alert(res.data.msg);
				if (res.data.msg === "success") {
					document.getElementById("previousFeedback").style.display = "block";
					var date = new Date();
					data.entry_date = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
					setfeedback(data);
				}
			})
			.catch(err => { console.log(err); })
	};

	return (
		<div style={{ marginLeft: "2.5rem" }}>
			<Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }} >
				<div className=" p-card-content">
					<h4 className="p-pt-0">{t("Feedback")} </h4>
				</div>
			</Card>

			<Card className="p-mt-1" id="previousFeedback">
				<div className="p-card-content p-grid" >
					<div className="p-field p-col-12 p-md-1"  >
						<img src={userlogo} alt="PIC" style={{ width: "4vw", height: "4vw" }} />
					</div>
					<div className="p-field p-col-12 p-md-11"  >
						<div style={{ float: "left", fontWeight: "bold" }} > {t("previousFeedback")} </div>
						<div style={{ float: "right", textAlign: "right", }} > {feedback.entry_date} </div>
						<div style={{ clear: "both" }} > {feedback.feedback} </div>
					</div>
				</div>
			</Card>

			<Card className="p-mt-1">
				<div className="p-card-content" >
					<p style={{ fontSize: "16px", fontWeight: "bold" }}>{t("feedbackData")} </p>
					<InputTextarea rows={10} maxlength="500"
						cols={50}
						autoResize
						id="feedback"
						style={{ width: "100%", fontSize: 16, font: "Times New Roman" }} />

					<div className="p-grid p-col-12 p-md-12">
						<div className="p-col-12 p-md-10"></div>
						<div className="p-col-12 p-md-2">
							<Button label={t("submit")}
								className="p-button-sm pull-right submitBtn"
								onClick={(e) => submit(e)}
							/>
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default FeedbackUpload;