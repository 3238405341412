import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

var langList = [
  ["english", "English", "अंग्रेजी"],
  ["nepali", "Nepali", "नेपाली"],
  //Menu
  ["Dashboard", "Dashboard", "ड्यासबोर्ड"],
  ["Security", "Security", "सुरक्षा"],
  ["User Management", "User Management", "प्रयोगकर्ता व्यवस्थापन"],
  ["User Ward Management", "User Ward Management", "वडा प्रयोगकर्ता व्यवस्थापन"],
  ["User Creation", "User Creation", "प्रयोगकर्ता सिर्जना"],
  ["User List", "User List", "प्रयोगकर्ता सूची"],
  ["Role Management", "Role Management", "भूमिका व्यवस्थापन"],
  ["Role Creation", "Role Creation", "भूमिका सिर्जना"],
  ["Role List", "Role List", "भूमिका सूची"],
  ["Women and Minorities", "Women, Sexual & Gender Minorities", "महिला, यौनिक तथा लैङ्गिक अल्पसंख्यक"],
  ["Children", "Children", "बालबालिका तथा किशोरकिशोरी"],
  ["Disabled", "People with disabilities", "अपाङ्गता भएका व्यक्ति"],
  ["Disabled ID Card", "Disability ID Card", "अपाङ्गता परिचयपत्र"],
  ["Senior Citizen", "Senior Citizens", "जेष्ठ नागरिक"],
  ["Senior Citizen ID Card", "Senior Citizens ID Card", "जेष्ठ नागरिक परिचयपत्र"],
  ["Social Service Management", "Social Service Management", "सामाजिक सेवा व्यवस्थापन"],
  [
    "Entertainment Sector Management",
    "Entertainment Sector Management",
    "मनोरञ्जन क्षेत्र व्यवस्थापन",
  ],
  ["Service Directory", "Service Directory", "सेवा निर्देशिका"],
  ["Social Service", "Social Service", "सामाजिक सेवा"],
  ["Shelter Home", "Shelter Home", "सुरक्षित आवास गृह"],
  ["Sewa Kendra", "Sewa Kendra", "सेवा केन्द्र"],
  ["Shelter Home Indicator", "Shelter Home Indicator", "सुरक्षित आवास गृह सूचक"],
  ["Sewa Kendra Indicator", "Sewa Kendra Indicator", "सेवा केन्द्र सूचक"],
  ["Shelter Home Personal Detail", "Shelter Home Personal Detail", "सुरक्षित आवास गृह व्यक्तिगत विवरण"],
  ["Child Home", "Child Home", "बालगृह"],
  ["Juvenile Correction Home", "Juvenile Reform Home", "बाल सुधार गृह"],
  ["Old Age Home", "Senior Citizens Care Home", "जेष्ठ नागरिक हेरचाह केन्द्र"],
  ["Trafficking In Person", "Trafficking In Person", "मानव बेचबिखन तथा ओसारपसार र लैङ्गिक हिंसा"],
  ["Complaint Registration", "Complaint Registration", "गुनासो/उजुरी दर्ता"],
  ["Referral", "Referral", "प्रेषण"],
  ["TIP in Entertainment", "TIP in Entertainment", "मनोरञ्जन क्षेत्रमा मानव बेचबिखन तथा ओसारपसार"],
  ["Foreign Labour Migration", "Foreign Labour Migration", "श्रम आप्रवासन (आन्तरिक/वाह्य)"],
  [
    "Control for Human Trafficking",
    "Control for Human Trafficking",
    "मानव बेचबिखन तथा ओसारपसार नियन्त्रण",
  ],
  ["Labour Migration", "Labour Migration", "श्रम आप्रवासन"],
  ["Human Trafficking", "Human Trafficking", "मानव बेचबिखन तथा ओसारपसार र लैङ्गिक हिंसा"],
  ["Child Correction Home", "Juvenile Reform Home", "बाल सुधार गृह"],
  ["ID Card", "ID Card", "परिचयपत्र"],
  ["Disabled ID Card List", "Disability ID Card List", "अपाङ्गता परिचयपत्र सूची"],
  ["Senior Citizen ID Card List", "Senior Citizens ID Card List", "ज्येष्ठ नागरिक परिचयपत्र सूची"],
  ["Shelter Home List", "Shelter Home List", "सुरक्षित आवास गृह सूची"],
  ["Child Home List", "Child Home List", "बालगृह सूची"],
  ["Juvenile Correction Home List", "Juvenile Reform Home List", "बाल सुधार गृह सूची"],
  ["Old Age Home List", "Senior Citizens Care Home List", "जेष्ठ नागरिक हेरचाह केन्द्र सूची"],
  ["Report", "Report", "प्रतिवेदन"],
  ["Consolidated", "Consolidated", "समष्टीकृत"],
  ["consolidatedReport", "Consolidated Report", "समष्टीकृत प्रतिवेदन"],

  ["Indicator", "Indicator", "सूचक"],
  ["Case form", "Case Form", "घटना फर्म"],
  ["Case form List", "Case Form List", "घटना फर्म सूची"],
  ["SetUp", "SetUp", "सेटअप"],

  //Module Indicator
  ["title", "Title", "शिर्षक"],
  ["description", "Description", "विवरण"],
  ["subTitle", "Sub Title", "उप शिर्षक"],

  ["Personal Information", "Personal Information", "व्यक्तिगत विवरण"],
  ["fName", "First Name", "पहिलो नाम"],
  ["mName", "Middle Name", "बीचको नाम"],
  ["lName", "Last Name", "थर"],
  ["organizationName", "Organization's Name", "संस्थाको नाम"],
  ["organizationId", "Organization Id", "संस्थाको परिचय"],
  ["address", "Address", "ठेगाना"],
  ["designation", "Designation", "पद"],
  ["companyName", "Company Name", "कम्पनीको नाम"],
  ["selectDesignation", "Select Designation", "पद चयन गर्नुहोस्"],
  ["selectRelationship", "Select Relationship", "सम्बन्ध चयन गर्नुहोस्"],
  ["selectCountry", "Select Country", "देश चयन गर्नुहोस्"],
  ["selectState", "Select State", "प्रदेश चयन गर्नुहोस्"],
  ["selectDistrict", "Select District", "जिल्ला चयन गर्नुहोस्"],
  ["selectMunicipality", "Select Local Level", "नगरपालिका/गाउँपालिका छान्नुहोस"],
  ["selectWard", "Select Ward", "वडा नं चयन गर्नुहोस्"],
  ["tole", "Tole", "टोल"],
  ["telephoneNo", "Telephone No", "टेलिफोन नं"],
  ["emailId", "Email Id", "इमेल आईडी"],
  ["mobileNo", "Mobile No", "मोबाइल नम्बर"],
  ["blockNo", "Block No", "ब्लक नं"],
  ["fax", "Fax", "फ्याक्स"],
  ["dateInAd", "Date In AD", "ई. सं. मिति"],
  ["dateInBs", "Date In BS", "बि.सं. मिति"],
  ["issuedDistrict", "Issued District", "जारी जिल्ला"],
  ["citizenshipNo", "Citizenship No.", "नागरिकता प्रमाणपत्र नं"],
  ["nationalIdentificationNo", "National Identification No.", "राष्ट्रिय परिचयपत्र नं"],
  ["nrnAddress", "NRN Address", "गैरआवासिय नेपालीको ठेगाना"],
  ["nrnId", "NRN ID", "गैरआवासिय नेपालीको परिचयपत्र नं "],
  ["pan", "PAN", "प्यान"],
  ["selectNationality", "Select Nationality", "राष्ट्रियता चयन गर्नुहोस्"],
  ["selectDp", "Select DP", "DP चयन गर्नुहोस्"],
  ["cName", "Name Of Company", "कम्पनीको नाम"],
  ["source", "Data Source", "स्रोत/सन्दर्भ"],
  ["incorporationDate", "Incorporation Date", "कम्पनी स्थापना मिति"],
  ["selectCompanyType", "Select Company Type", "कम्पनीको किसिम चयन गर्नुहोस्"],
  [
    "selectCountryOfRegistration",
    "Select Country of Registration",
    "कम्पनी दर्ता भएको देश चयन गर्नुहोस्",
  ],
  ["registrationOffice", "Registration Office", "दर्ता गर्ने कार्यालय"],
  ["registrationNo", "Registration No", "दर्ता नं."],
  ["registrationDate", "Registration Date", "दर्ता मिति"],
  ["panVatRegistrationNo", "PAN No / VAT Registration No", "मूल्य अभिवृद्धि कर नं /स्थायी लेखा नं"],
  [
    "nameAddressOfCompany",
    "Name and Address of Main Company",
    "सहायक कम्पनी भएमा मुख्य कम्पनीको नाम र ठेगाना",
  ],
  [
    "selectBusinessType",
    "Select Types of Business of the Company",
    "कम्पनीको व्यवसायको किसिम चयन गर्नुहोस्",
  ],
  ["areaOfWork", "Area Of Work", "कार्य क्षेत्र"],
  ["isListed", "Is Listed", "सूचिकरण भए/नभएका"],
  ["listingDate", "Listing Date", "सूचिकरण मिति"],
  ["panNo", "Pan No", "प्यान नम्बर"],
  ["orgAddress", "Organization Address", "संस्थाको ठेगाना"],
  ["dpId", "DP ID", "DP आईडी"],
  ["basicInfo", "Basic Info", "आधारभूत जानकारी"],
  ["citizenship", "Citizenship", "नागरिकता"],
  ["nameEnglish", "Name(English)", "नाम (अंग्रेजी)"],
  ["nameNepali", "Name(Nepali)", "नाम (नेपाली)"],
  ["dateOfBirth", "Date of Birth", "जन्ममिति"],
  ["gender", "Sex", "लिङ्ग"],
  ["male", "Male", "पुरुष"],
  ["female", "Female", "महिला"],
  ["other", "Other", "अन्य"],
  ["nationality", "Nationality*", "राष्ट्रियता*"],
  ["nrn", "NRN", "गैरआवासिय नेपाली"],
  ["ho", "Yes", "हो"],
  ["haina", "No", "होइन"],
  ["minor", "Minor", "नाबालक"],
  ["notMinor", "Not Minor", "नाबालक होइन"],
  ["boid", "BOID", "BOID"],
  ["haveBoid", "Do you have BOID?*", "तपाईं सँग BOID छ?"],
  ["forwardToDp", "Forward To DP*", ""],
  ["createBoid", "Create BOID?", "BOID सिर्जना गर्नुहोस् "],
  ["typesOfBankAccount", "Types of Bank Account*", "बैंक खाताको प्रकार*"],
  ["bank", "Bank*", "बैँक*"],
  ["bankAccountNumbers", "Bank Account Number*", "बैँक खाता नम्बर*"],
  ["country", "Country", "देश"],
  ["state", "State*", "प्रदेश*"],
  ["municipality", "Municipality*", "न.पा.*"],
  ["ward", "Ward*", "वडा नं"],
  ["number", "Number", "नम्बर"],
  ["occupationDetails", "Occupation Details", "पेशागत विवरण"],
  ["occupationType", "Occupation Type*", "पेशाको प्रकार*"],
  ["businessType", "Business Type*", "व्यापारको प्रकार*"],
  ["financialDetails", "FinancialDetails", "आर्थिक विवरण"],
  ["nameSurname", "Name/Surname", "नाम/थर"],
  ["applicantRelationship", "Relationship with Applicant", "निवेदकसँगको सम्बन्ध"],
  ["correspondenceAddress", "Correspondence Address", "पत्राचार ठेगाना"],
  ["ceoName", "Chief Executive Officer's Name", "प्रमुख कार्यकारी अधिकृतको नाम"],
  ["secretaryName", "Company Secretary's Name", "कम्पनी सचिवको नाम"],
  ["typesOfCompany", "Types of Company", "कम्पनीको किसिम"],
  ["countryOfRegistration", "Country Of Registration", "कम्पनी दर्ता भएको देश"],
  ["typesOfBusinessCompany", "Types of business of the company", "कम्पनीको व्यवसायको किसिम"],
  ["userPhoto", "User Photo", "प्रयोगकर्ताको फोटो"],
  ["currentAddress", "Current Address", "हालको ठेगाना"],
  ["permanentAddress", "Permanent Address", "स्थायी ठेगाना"],
  ["name", "Name", "नाम"],
  ["fatherName", "Father Name", "बाबुको नाम"],
  ["grandFatherName", "Grand Father Name", "वाजेको नाम"],
  ["fileId", "File Id", "फाइल आईडी"],
  ["docId", "Document Id", "कागजात आईडी"],
  ["issuedFrom", "Issued From", "जारी भएको ठेगाना"],
  ["issuedDate", "Issued Date", "जारी मिति"],
  ["issuedDateBs", "Issued Date BS", "जारी मिति बि.सं."],
  ["selectDocumentType", "Select Document Type", "कागजात प्रकार चयन गर्नुहोस्"],
  ["uploadFile", "Upload File", "फाइल अपलोड गर्नुहोस् "],
  ["parentOrg", "Parent Organization", "अभिभावक संगठन"],
  ["hasBranch", "Has Branch", "शाखा छ "],
  ["code", "Code", "कोड"],
  ["descEng", "Description English", "विवरण"],
  ["descNep", "Description Nepali", "विवरण"],
  ["type", "Type", "प्रकार"],
  ["fullName", "Full Name", "पूरा नाम"],
  ["email", "Email", "इमेल"],
  ["userName", "User Name", "प्रयोगकर्ताको नाम"],
  ["userLevel", "User Level", "प्रयोगकर्ता स्तर"],
  ["role", "Role", "भूमिका"],
  ["password", "Password", "पासवर्ड"],
  ["oldPassword", "Old Password", "पुरानो पासवर्ड"],
  ["newPassword", "New Password", "नयाँ पासवर्ड"],
  ["rePassword", "Re-Type Password", "पासवर्ड पुन: टाइप गर्नुहोस् "],
  ["selectRole", "Select Role", "भूमिका चयन गर्नुहोस्"],
  ["selectOrganization", "Select Organization", "संस्था चयन गर्नुहोस्"],
  ["phoneNo", "Phone No", "फोन नम्बर "],
  ["hasFullAccess", "Has Full Access ?", "पूर्ण पहुँच छ?"],
  ["roleDescription", "Role Description", "भूमिकाको विवरण"],
  ["userId", "User Id", "प्रयोगकर्ता आईडी"],
  ["verifierType", "Verifier Type", "प्रमाणीकरणकर्ताको प्रकार"],
  ["funCd", "Function Code", "कार्य सँकेत"],
  ["level", "Level", "स्तर"],
  ["selectUser", "Select User", "प्रयोगकर्ता चयन गर्नुहोस्"],
  ["selectVerifierType", "Select Verifier Type", "प्रमाणिकरणकर्ता प्रकार चयन गर्नुहोस्"],
  ["order", "Order", "क्रम"],
  ["icon", "Icon", "आइकन"],
  ["label", "Label", "लेबल"],
  ["type", "Type", "प्रकार"],
  ["possibleValues", "Possible Values", "सम्भाव्य मान्यता"],
  ["parent", "Parent", "अभिभावक"],
  ["section", "Section", "खण्ड"],
  ["regex", "Regex", "Regex"],
  ["mandatory", "Mandatory", "अनिवार्य"],
  ["isMultiple", "Is Multiple?", "धेरै छ ?"],
  ["usedFor", "Used For", "प्रयोग"],
  ["selectUsedFor", "Select Used For", "प्रयोग चयन गर्नुहोस्"],
  ["verificationCode", "Verification Code", "प्रमाणिकरण कोड"],
  ["spouseName", "Spouse Name", "पति/पत्नीको नाम"],
  ["incorporationDateBs", "Incorporation Date Bs", "कम्पनी स्थापना मिति "],
  ["registrationDateBs", "Registration Date Bs", " दर्ता मिति"],
  ["boidDetails", "Boid Details", "BOID विवरण"],
  ["status", "Status", "स्थिति"],
  ["active", "Active", "सक्रिय"],
  ["inactive", "Inactive", "निष्क्रिय"],
  ["selectAll", "Select All", "सबै चयन गर्नुहोस्"],
  ["add", "Add", "थप्नुहोस्"],
  ["edit", "Edit", "सम्पादन गर्नुहोस्"],
  ["search", "Search", "खोज्नुहोस्"],
  ["delete", "Delete", "मेटाउनुहोस्"],
  ["roleId", "Role Id", "भूमिका परिचय"],
  ["moduleName", "Module Name", "मोड्युलको नाम"],
  ["organizationList", "Organization List", "संस्थाको सूची"],
  ["userForm", "User Form", "प्रयोगकर्ता फर्म"],
  ["userModule", "User Module", "प्रयोगकर्ता मोड्युल"],
  ["userVerificationForm", "User Verification Form", "प्रयोगकर्ता प्रमाणीकरण फर्म"],
  ["userList", "User List", "प्रयोगकर्ता सूची"],
  ["roleForm", "Role Form", "भूमिका फर्म"],
  ["roleList", "Role List", "भूमिका सूची"],
  ["organization", "Organization Form", "संस्थाको फर्म "],
  ["organizationDetails", "Organization Details", "संस्थाको विवरण"],
  ["orgContactPerson", "Organization Contact Person", "संस्थाको सम्पर्क व्यक्ति"],
  ["orgDocumentDetail", "Organization Document Details", "संस्थाको कागजात विवरण"],

  //Button

  ["save", "Save", "संरक्षण गर्नुहोस्"],
  ["submit", "Submit", "पेश गर्नुहोस्"],
  ["requestForVerification", "Request for verification", "प्रमाणीकरणको लागि अनुरोध"],
  ["create", "Create", "सिर्जना गर्नुहोस्"],
  ["verify", "Verify", "प्रमाणित गर्नुहोस्"],
  ["reject", "Reject", "अस्वीकार गर्नुहोस्"],
  ["sendBackForCorrection", "Send back for correction", "सुधारको लागि फिर्ता पठाउनुहोस्"],
  ["print", "Print", "मुद्रण गर्नुहोस्"],
  ["export", "Export to Excel", "एक्सेलमा हेर्नुहोस्"],
  ["generate", "Generate", "उत्पन्न गर्नुहोस्"],
  ["goBack", "Back to previous page", "अघिल्लो पृष्ठमा फर्कनुहोस्"],
  ["getDetailsAndSynchronize", "Get Details and Synchronize", "विवरण प्राप्त र समायोजन गर्नुहोस्"],
  ["getDetails", "Get Details", "विवरण प्राप्त गर्नुहोस्"],
  ["displayName", "Display Individual's Name", "व्यक्तिको नाम देखाउनुहोस्"],


  //Landing Page
  ["sims", "Social Information Management System", "सामाजिक सूचना व्यवस्थापन प्रणाली"],
  ["signInToAccessPortal", "Login to access the portal", "पोर्टलमा प्रवेश गर्न लग-इन गर्नुहोस्"],
  ["forgetPassword", "Forget Password", "पासवर्ड बिर्सनुभयो"],
  ["login", "Login", "लग-इन गर्नुहोस्"],
  ["nepGov", "Nepal Government", "नेपाल सरकार"],
  [
    "ministryOfWCS",
    "Ministry of Women, Children and Senior Citizens ",
    "महिला, बालबालिका तथा ज्येष्ठ नागरिक मन्त्रालय",
  ],
  [
    "mowcscAddress",
    "Singha Durbar, Kathmandu",
    "सिंहदरबार, काठमाण्डौ",
  ],
  [
    "mowcscContact",
    "01-4200082",
    "०१-४२०००८२",
  ],
  [
    "technicalHead",
    "Technical Head",
    "प्राविधिक प्रमुख",
  ],
  ["FAQ", "FAQ", "बारम्बार सोधिने प्रश्नहरू"],
  ["resources", "Resources", "साधनहरू"],
  ["underConstruction", "Under Construction", "निर्माणाधिन"],
  ["allRightReserved", "All Rights Reserved", "सर्बाधिकार सुरक्षित"],
  ["poweredBy", "Developed By", "सफ्टवेयर निर्माता"],
  ["thisYear", "2022", "२०२२"],
  ["userManual", "User's Manual", "प्रयोगकर्ता पुस्तिका"],
  ["api", "API's used to develop this system", "यस प्रणाली विकास गर्ने क्रममा प्रयोग गरिएको APIs"],
  ["view", "View", "हेर्नुहोस्"],
  ["videoTutorial", "System Video Tutorial", "प्रणाली भिडियो ट्यूटोरियल"],

  // Senior Citizens
  ["seniorCitizen", "Senior Citizens", "जेष्ठ नागरिक"],
  ["nameOfSrCitizen", "Name of Senior Citizen", "जेष्ठ नागरिकको नाम"],
  ["tempAddress", "Temporary Address", "अस्थायी ठेगाना"],
  ["thirdGender", "Others", "अन्य"],
  ["age", "Age", " उमेर"],
  ["educational", "Educational", "शैक्षिक"],
  ["educationLevel", "Education Level", " शैक्षिक स्तर"],
  ["disability", "People with disabilities", "अपाङ्गता भएका व्यक्ति"],
  ["schoolName", "School Name", "विध्यालयको नाम"],
  ["dateOfAdmission", "Date of admission", "भर्ना भएको मिति"],
  ["typesOfCase", "Types of case", "घटनाका प्रकारहरू"],
  ["disease", "Disease", "रोग"],
  ["diseaseDetails", "Specify type of disease", "रोगको प्रकार उल्लेख गर्नुहोस्"],
  ["diseaseType", "Disease type", "रोगको प्रकार"],
  ["relatives", "Relatives", "आफन्त"],
  ["receivingSSA", "Receiving Social security allowance", "सामाजिक सुरक्षा भत्ता प्राप्त गर्नेको संख्या"],
  ["rehabilitation", "Rehabilitation", "पुन:स्थापना"],
  ["casteEthnicity", "Caste/Ethnicity", "जात/जाति"],
  ["ethnicity", "Ethnicity", "जातियता"],
  ["byEthnicity", "Ethnicity", "जातियता"],
  ["byMaritalStatus", "Marital Status", "वैवाहिक स्थिति"],
  ["byGender", "Sex", "लिङ्ग"],
  ["byAge", "Age", "उमेर"],
  ["byCase", "Case", "प्रकार"],
  ["byCategory", "Category", "वर्ग"],
  ["byCategoryKa", "Category Ka", "वर्ग क"],
  ["byCategoryKha", "Category Kha", "वर्ग ख"],
  ["byCategoryGa", "Category Ga", "वर्ग ग"],
  ["byCategoryGha", "Category Gha", "वर्ग घ"],
  ["byRehabilitation", "Rehabilitated place", "पुन:स्थापित भएको स्थान "],

  //Marital status
  ["maritalStatus", "Marital Status", "वैवाहिक स्थिति"],
  ["divorcee", "Divorced", "सम्बन्धविच्छेद भएको"],
  ["widow", "Widowed", "विधवा"],
  ["unmarried", "Unmarried", "अविवाहित"],
  ["singled", "Single", "एकल"],
  ["separated", "Separated", "छुट्टिएको"],
  ["married", "Married", "विवाहित"],
  ["singleUnmarried", "Single/Unmarried", "एकल/अविवाहित"],
  ["noDisclose", "Don't want to disclose", "खुलाउन नचाहने"],
  ["otherPartnerships", "Other Partnerships", "अन्य"],

  //BloodGroup
  ["A+", "A+", "ए+"],
  ["A-", "A-", "ए-"],
  ["B+", "B+", "बि+"],
  ["B-", "B-", "बि-"],
  ["AB+", "AB+", "एबि+"],
  ["AB-", "AB-", "एबि-"],
  ["O+", "O+", "ओ+"],
  ["O-", "O-", "ओ-"],

  //No Of Children
  ["noOfChildren", "Number of children", "बालबालिकाको संख्या"],
  ["son", "Son", "छोरा"],
  ["daughter", "Daughter", "छोरी"],
  ["sonInLaw", "Son-in-law", "ज्वाईं"],
  ["daughterInLaw", "Daughter-in-law", "बुहारी"],
  ["cousin", "Cousin", "भतिज"],
  ["niece", "Niece", "भतिजी"],
  ["nieceInLaw", "Niece-in-law", "भतिज बुहारी"],
  ["nephew", "Nephew", "भदा"],
  ["nephewInLaw", "Nephew-in-law", "भदा बुहारी"],
  ["bestFriend", "Meet", "मित"],
  ["bestFriendSonInLaw", "Meet Chora", "मित छोरा"],
  ["bestFriendDaughterInLaw", "Meet Buhari", "मित बुहारी"],
  ["bestFriendDaughter", "Meet Chori", "मित छोरी"],
  ["FaternalUncle", "Faternal Uncle", "काका"],
  ["FaternalAuntInLaw", "Faternal Aunt In Law", "काकी"],
  ["fatherMother", "Father/Mother", "बाबु/आमा"],
  ["brother", "Brother", "दाजुभाइ"],
  ["sister", "Sister", "दिदी/बहिनी"],
  ["Father", "Father", "बाबु"],
  ["Mother", "Mother", "आमा"],
  ["Guardian", "Guardian", "संरक्षक"],
  ["grandMother", "GrandMother", "हजुरआमा"],
  ["grandFather", "GrandFather", "हजुरबुबा"],
  ["maternalUncle", "Maternal Uncle", "मामा"],
  ["mathernalAuntInLaw", "Maternal Aunt In Law", "माइजू"],
  ["FaternalAunt", "Faternal Aunt", "फूपू"],
  ["FaternalAuncleInLaw", "Faternal Auncle InLaw", "फूपाजू"],

  //age group
  ["ageGroup", "Age Group", "उमेर समूह"],
  ["children", "Children", "बालबालिका"],
  ["adult", "Adult", "वयस्क"],
  ["childrenBelow18", "Children (0-17 years)", "बालबालिका (०-१७ वर्ष)"],
  ["adult18To60", "Adult (18-59 years)", "वयस्क (१८-५९ वर्ष)"],
  ["eldersAbove60", "Senior Citizens (60 years and above)", "जेष्ठ नागरिक (६० वर्ष वा माथि)"],
  ["age60To68", "Age(60-68 years)", "उमेर (६० - ६८)"],
  ["age68To80", "Age(68-80 years)", "उमेर (६८ - ८०)"],
  ["age81To99", "Age(81-99 years)", "उमेर (८१ - ९९)"],
  ["age68To99", "Age(68-99 years)", "उमेर (६८ - ९९)"],
  ["ageAbove99", "Age(above 99 years)", "उमेर (९९ वर्षभन्दा माथि)"],

  ["age6065", "60-65", "६०-६५"],
  ["age6570", "65-70", "६५-७०"],
  ["age7080", "70-80", "७०-८०"],
  ["age8090", "80-90", "८०-९०"],
  ["age90Above", "90 and Above", "९० वर्षभन्दा माथि"],
  ["age6068", "60-68", "६०-६८"],
  ["age6975", "69-75", "६९-७५"],
  ["age7690", "76-90", "७६-९०"],
  ["age9199", "91-99", "९१-९९"],
  ["age99Above", "99 and Above", "९९ वर्षभन्दा माथि"],

  //Caste/Ethnicity
  ["dalit", "Dalit", " दलित"],
  ["ethnicMinorities", "Ethnic Minorities", "जातिय अल्पसंख्यक"],
  ["muslim", "Muslim", " मुस्लिम"],
  ["madhesi", "Madhesi", "मधेशी "],
  ["janajati", "Janajati", " जनजाति"],
  ["brahminOther", "Brahmin/Chhetri ", "ब्राह्मण/क्षेत्री"],

  ["hillBrahman", "Hill Brahman/Chhetri", "पहाडी ब्राह्मण/क्षेत्री"], 
  ["khasArya", "Khas-Arya", "खस-आर्य"],
  ["hillJanajati", "Janajati (Hills)", "जनजाति (पहाड)"],
  ["newar", "Newar", "नेवार"],
  ["hillDalit", "Dalit (Hills)", "दलित (पहाड)"],
  ["teraiMadhesiBrahman", "Terai Madhesi Brahman/Chhetri", "तराई मधेशी ब्राह्मण/क्षेत्री"],
  // ["teraiJanajati", "Terai Adivasi/Janajati", "तराई आदिवासी/जनजाति"],
  ["teraiJanajati", "Janajati (Tarai)", "जनजाति (तराई)"],
  // ["teraiMadhesiOther", "Terai Madhesi Other Caste", "तराई मधेशी अन्य जाति"],
  ["teraiMadhesiOther", "Mid-Caste (Tarai)", "मध्यम-जाति (तराई)"],
  ["teraiDalit", "Dalit (Tarai)", "दलित (तराई)"],
  ["tharu", "Tharu", "थारू"],
  // ["otherCaste", "Others (Giri, Puri, Yogi, Sanyasi)", "अन्य (गिरी, पुरी, योगी, सन्यासी)"],
  ["otherCaste", "Others", "अन्य जाति"],

  //orphanOrNot
  ["orphan/singleParent", "Orphan/Single Parent", "अनाथ/एकल अभिभावक"],
  ["parentGuardian", "Parent/Guardian", "अभिभावक/संरक्षक"],
  ["parentGuardianPresent", "Parent Guardian Present", "अभिभावक/संरक्षक भएको"],
  ["singleParentChild", "Single Parent Child", "एकल अभिभावकको भएको"],
  ["orphan", "Orphan", "अनाथ"],
  ["guardianPresent", "Present", "भएको"],
  ["singleGuardian", "Single Guardian Present", "एकल अभिभावक भएको"],
  ["present", "Present", "उपस्थित हुनु"],

  //Occupation
  ["occupation", "Occupation", "पेशा"],
  ["yearsOfExperience", "Years of experience", "कार्य अनुभव (वर्ष)"],

  //rehabilitation
  ["rehabilitatedTo", "Rehabilitated", "पुन:स्थापित"],
  ["home", "Home", "घर"],
  ["ojt", "On the Job Training", "प्रशिक्षार्थी कर्मचारी"],
  ["outOfCommunity", "Out of community", "समुदायभन्दा बाहिरको"],
  ["adoption", "Adoption", "धर्मपुत्र वा धर्मपुत्री बनाएको "],
  ["fosterHome", "Foster parents/home", "धर्म मातापिता/घर"],

  //Disability Type
  ["typesOfDisability", "Types of Disability", "अपाङ्गताको प्रकार"],
  ["physicallyDisabled", "Physical disability", "शारीरिक अपाङ्गता"],
  ["visuallyImpaired", "Visually Impaired", "दृष्टिसम्बन्धी अपाङ्गता"],
  ["hearingImpaired", "Hearing Impaired", "सुनाइसम्बन्धी अपाङ्गता"],
  ["deafBlind", "Deaf Blind", "श्रवण-दृष्टिविहीन अपाङ्गता"],
  ["speechAndHearingDisability", "Speech and hearing disability", "स्वर-बोलाइसम्बन्धी अपाङ्गता"],
  ["mentalDisability", "Mental/Psycho-social disability", "मानसिक/मनोसामाजिक अपाङ्गता"],
  ["intellectuallyDisabled", "Intellectual disablility", "बौद्धिक अपाङ्गता"],
  ["hemophelia", "Hemophilia", "अनुवंशीय रक्तस्राव"],
  ["autism", "Autism", "अटिजम"],
  ["multiple", "Multiple disability", "बहुअपाङ्गता"],
  ["partiallyVisuallyImpaired", "Partially Visually impaired", "आंशिक दृष्टिविहीन"],
  ["fullyVisuallyImpaired", "Fully Visually impaired", "पूर्ण दृष्टि विहीन"],
  ["partiallyDeaf", "Partially Deaf", "आंशिक श्रवण विहिन"],
  ["deaf", "Deaf", "पूर्ण श्रवण विहिन"],

  ["province", "Province", "प्रदेश "],
  ["district", "District", "जिल्ला"],
  ["municipality", "Local Level", "नगरपालिका / गाउँपालिका "],
  ["wardNo", "Ward No.", " वडा नम्बर."],
  ["select", "Select", "छनोट गर्नुहोस्"],
  ["classAdmittedIn", "Class Admitted In", "कक्षा भर्ना "],
  ["home", "Home", "घर"],
  ["pleaseSpDetails", "Please Specify Details", "कृपया विवरण उल्लेख गर्नुहोस्"],
  ["ifAnySpecify", "If Any Specify", "यदि अन्य कुनै भएमा उल्लेख गर्नुहोस्"],

  //Disability ID Card Form
  ["disabilityIdCard", "Disability ID Card", "अपाङ्गता परिचयपत्र"],
  ["nameOfPerson", "Name of Person", "नाम "],
  ["idCardNo", "ID Card Number", "परिचयपत्र नम्बर"],
  ["enteredIdCardNo", "User entered ID Card Number", "प्रयोगकर्ताले प्रवेश गरिएको परिचयपत्र नम्बर"],
  ["generatedIdCardNo", "System generated ID Card Number", "प्रणालीबाट उत्पन्न गरिएको परिचयपत्र नम्बर"],
  ["idCardType", "ID Card Type", "परिचयपत्रको प्रकार "],
  ["photo", "Photo", " फोटो"],
  ["localLevel", "Local Level", "स्थानीय तह"],
  ["bloodGroup", "Blood Group", "रक्त समूह"],
  ["typeOnTheBasisOfNature", "Type of Disability (By Nature)", "अपाङ्गताको किसिम (प्रकृतिको आधारमा)"],
  ["typeOnTheBasisOfSeverity", "Type of Disability (By Severity)", "अपाङ्गताको किसिम (गम्भीरताको आधारमा)"],
  ["fatherMotherGuardian", "Father/Mother/Guardian", " बाबु/आमा/संरक्षक"],
  ["fatMotGaurdName", "Father/Mother/Guardian name", " बाबु/आमा/अभिभावकको नाम"],
  ["signOfIdCardHolder", "Signature of ID card holder", "परिचयपत्र बाहकको हस्ताक्षर"],
  ["approvedBy", "Approved by", "स्वीकृत गर्ने"],
  ["signature", "Signature", "हस्ताक्षर"],
  ["date", "Date", "मिति"],

  // Disability Id Card
  ["idCardNo", "ID Card No.", " परिचयपत्र नम्बर"],
  ["idCardType", "ID Card Type", "परिचयपत्रको प्रकार "],
  ["disabilityIdCard", "Disability ID Card", "अपाङ्गता परिचयपत्र"],
  ["fullNamePerson", "Name", "नाम, थर"],
  ["fatMotGaurdNameNep", "बाबु/आमा/अभिभावकको नाम", "बाबु/आमा/अभिभावकको नाम"],
  ["isIdCardACopy", "Is this ID Card a copy of original?", "के यो परिचयपत्र प्रतिलिपि हो ?"],

  //Senior Citizen ID Card Form
  ["seniorCitizenIdCard", "Senior Citizens ID Card", "जेष्ठ नागरिक परिचयपत्र"],
  [
    "availableConcession",
    "Available Concession and facilities",
    "सरकारबाट उपलब्ध गराइएका सेवा सुविधाहरू",
  ],
  [
    "careTakerSenCitHome",
    "Name of Care taker home/Senior Citizens home",
    "हेरचाह केन्द्रमा बसेको भए सोको विवरण",
  ],
  ["husbandWifeName", "Husband/Wife Name", "पति/पत्नीको नाम"],
  ["nameOfDrugs", "Name of drugs taken", "सेवन गरिरहेको औषधीको विवरण"],
  ["verifyingOfficer", "Verifying Officer", "प्रमाणित गर्ने व्यक्ति"],
  ["contactPerson", "Contact Person", "सम्पर्क व्यक्ति"],
  ["contactPersonSeniorCitizen", "Contact Person", "सम्पर्क व्यक्ति (संरक्षकको नाम)"],
  ["contactPersonNo", "Contact Person Phone No.", "सम्पर्क व्यक्ति फोन नं"],
  ["office", "Office", "कार्यालय"],
  // Module
  ["childrenAdolescentModule", "Children and Adolescent Module", "बालबालिका तथा किशोरकिशोरी "],

  //WomenGenderAndSexualMinorities 
  [
    "womenGenderAndSexualMinorities",
    "Women Sexual & Gender Minorities",
    "महिला, यौनिक तथा लैङ्गिक अल्पसंख्यक",
  ],
  ["generalInfoForm", "General information", " सामान्य जानकारी"],
  [
    "totalPopulation",
    "Total population (Excluding institutional families)",
    "कुल जनसंख्या (संस्थागत परिवारबाहेक)",
  ], [
    "totalPopulationDesc",
    "Total population excluding institutional families at the local level",
    "स्थानीय तहको संस्थागत परिवारबाहेकको कुल जनसंख्या",
  ],
  ["totalWomenPopulation", "No. of women", " महिलाको संख्या"],
  [
    "totalWomenPopulationDesc",
    "Number of women (18 years or above) recorded in the official document of the municipality",
    "स्थानीय तहको आधिकारिक दस्तावेजमा उल्लेख महिलाको (१८ वर्षाभन्दा माथि) संख्या",
  ],
  ["totalGirlsPopulation", "No. of girls", "किशोरीहरूको संख्या"],
  [
    "totalGirlsPopulationDesc",
    "Number of girls (below 18 years) recorded in the official document of the municipality",
    "स्थानीय तहको आधिकारिक कागजमा उल्लेख किशोरीहरूको (१८ बर्षभन्दा मुनि) संख्या",
  ],
  [
    "totSexualGenderMinorities",
    "No. of sexual and gender minorities",
    "यौनिक तथा लैङ्गिक अल्पसंख्यकको संख्या",
  ],
  [
    "totSexualGenderMinoritiesDesc",
    "Number of LGBTIQ recorded in the municipality",
    "नगरपालिकामा यौनिक अल्पसंख्यकको संख्या",
  ],
  // ["totalSingleWomen", "No. of single women", "एकल महिलाको संख्या"],
  // [
  //   "totalSingleWomenDesc",
  //   "Number of registered Divorced, widow, separated women in municipalities",
  //   "स्थानीय तहमा पारपाचुके गरेका, बिधवा र छुट्टीएर बसेका महिलाको संख्या ",
  // ],
  // ["total10plusWomen", "No. of women aged 10 years and above", "१० वर्ष वा सोभन्दा माथिका महिलाको संख्या"],
  // [
  //   "total10plusWomenDesc",
  //   "Number of registered married, divorced, widow, separated women aged 10 years and above in the municipality",
  //   "स्थानीय तहमा १० वर्ष वा सोभन्दा माथिका विवाह गरेका, पारपाचुके गरेका, बिधवा र छुट्टीएर बसेका महिलाको संख्या",
  // ],
  ["total10plusWomen", "No. of women by marital status", "वैवाहिक स्थिति अनुसार महिलाको संख्या"],
  [
    "total10plusWomenDesc",
    "Number of registered married, divorced, widow, separated women in the municipality",
    "स्थानीय तहमा विवाह गरेका, पारपाचुके गरेका, बिधवा र छुट्टीएर बसेका महिलाको संख्या",
  ],
  ["womenLiteracyRate", "Women Literacy Rate (%)", "महिला साक्षरता दर (%)"],
  [
    "womenLiteracyRateDesc",
    "Percentage of literate women (who can read and write)",
    "साक्षर महिला (पढ्न, लेख्न सक्ने) प्रतिशत",
  ],
  [
    "electedWomenRep",
    "Percentage of elected women representatives",
    "निर्वाचित महिला जनप्रतिनिधिको प्रतिशत",
  ],
  [
    "electedWomenRepDesc",
    "Percent of elected women representatives in municipality and ward offices",
    "स्थानीय तह  र वडा कार्यलयहरूमा निर्वाचित महिला जनप्रतिनिधिको संख्या",
  ],
  [
    "totWomenReceivingSSA",
    "No. of women receving SPA",
    "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने महिलाको संख्या",
  ],
  [
    "totWomenReceivingSSADesc",
    "Number of women (Singled, divorced, widow, separated) registered for social protection allowance in the municipality",
    "स्थानीय तह मा (एकल, पारपाचुके गरेका, बिधवा, अलग भएर बसेका) सामाजिक सुरक्षा भत्ताको लागि दर्ता भएका महिलाको संख्या ",
  ],
  [
    "totRegGBVincidents",
    "No. of registered GBV incidents against women",
    "महिला हिंसासम्बन्धी दर्ता भएका घटना",
  ],
  [
    "totRegGBVincidentsDesc",
    "Number of GBV incidents recorded by police",
    "प्रहरी, स्थानीय तह तथा वडा कार्यालयले दर्ता गरेका लैङ्गिकतामा आधारित हिँसाका घटना",
  ],
  ["totMissingWomen", "No. of missing women", "बेपत्ता/हराइरहेका महिलाको संख्या"],
  ["missing", "Missing", "बेपत्ता/हराइरहेका"],
  [
    "totMissingWomenDesc",
    "Number of missing cases recorded by police",
    "प्रहरीले दर्ता गरेका बेपत्ता व्यक्तिको संख्या",
  ],
  ["totAbsenteeWomen", "No. of women who has gone in search for employment", "रोजगारीको लागि बाहिर गएका महिलाको संख्या"],
  [
    "totAbsenteeWomenDesc",
    "Number of women who has gone outside of local level in search for employment ",
    "रोजगारीको लागि बाहिर गएका महिलाको संख्या",
  ],
  [
    "totAffectedByNaturalCalamities",
    "No. of women affected by calamities",
    "विपत्‌बाट प्रभावित महिलाको संख्या",
  ],
  [
    "totAffectedByNaturalCalamitiesDesc",
    "Number of people affected by pandemic, earthquake, floods, landslides, fire etc",
    "स्थानीय तहमा महामारी, भुइँचालो, बाढी/पहिरो, आगजनी आदिले प्रभावितहरूको संख्या",
  ],
  ["others", "Others", "अन्य"],
  ["entryBy", "Entry By", "प्रविष्ट गर्ने "],
  ["entryDate", "Entry Date", "प्रविष्ट मिति "],
  ["reportingOrgId", "Reporting Organization ID", "रिपोर्टिङ गर्ने निकायको परिचय"],

  //Children and Adolescent Module
  ["fiscalYear", " Fiscal Year", "आर्थिक वर्ष "],
  ["quarter", "Quarter", "त्रैमासिक"],
  ["boys", "Boy", "बालक"],
  ["girls", "Girl", "बालिका"],
  ["total", "Total", "जम्मा"],
  ["eloped", "Married by Own choice", "आफुखुसी विवाह गरेको"],
  ["forcedMarriageDesc", "Forced Marriage by Parents", "अभिभावकको करकापमा विवाह गरेको"],
  ["totChildrenPop", "Total population of children", "बालबालिकाको संख्या"],
  [
    "totBirthCertIsssued",
    "No. of birth certificates (below 5 years) issued",
    "जन्म दर्ता भएका ५ वर्षमुनिका बालबालिकाको संख्या",
  ],
  [
    "totChildrenReceivingSSA",
    "No. of children receiving SSA",
    "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने बालबालिकाको संख्या",
  ],
  ["primarySchoolEnroll", "School enrollment rate", "विद्यालय भर्ना हुने बालबालिकाको प्रतिशत"],
  [
    "secondarySchoolDropout",
    "Secondary school dropout rate",
    "माध्यमिक तहमा विद्यालय छोड्ने विद्यार्थीको प्रतिशत",
  ],
  [
    "outOfSchoolChildren",
    "Percentage of Out of school children (4-10 years age)",
    "विद्यालयबाहिर रहेका बालबालिका (४-१० वर्ष) को प्रतिशत",
  ],
  [
    "totEarlyMarriage",
    "No. of early marriage (under 20 years) cases reported",
    "बालविवाह (२० वर्षमुनि) का घटना संख्या",
  ],
  ["totChildAbuse", "No. of child abuse cases reported", "बालदुराचारका घटना संख्या"],
  ["totOrphanChildren", "No. of orphan children reported", "अनाथ बालबालिकाको संख्या"],
  ["totStreetChildren", "No. of street children reported", "सडक बालबालिकाको संख्या"],
  [
    "totMissingChildren",
    "No. of missing children",
    "बेपत्ता बालबालिकाको संख्या",
  ],
  [
    "totFoundChildren",
    "No. of found children",
    "भेट्टाइएका बालबालिकाको संख्या",
  ],
  [
    "totChildDevCenter",
    "No. of Child Development Center registered",
    "दर्ता भएका बाल विकास केन्द्रको संख्या",
  ],
  [
    "perChildBudgetAllocated",
    "Percentage of budget allocated for children development and welfare",
    "बाल विकास र बाल हितको लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],
  [
    "totChildrenAffByNatCal",
    "No. of children affected by calamities",
    "विपत्‌बाट प्रभावित बालबालिकाको संख्या",
  ],
  [
    "totAffectedByNaturalCalamities",
    "No. of women affected by calamities",
    "विपत्‌बाट प्रभावित महिलाको संख्या",
  ],
  [
    "totChildrenPopDesc",
    "Number of children (below 18 years) recorded in the official document of the municipality",
    "स्थानीय तहको आधिकारिक लिखतमा दर्ता भएका बालबालिकाको संख्या (१८ वर्षमुनि)",
  ],
  [
    "totBirthCertIsssuedDesc",
    "Number of children (below 5 years) received a birth certificate from the municipality and its respective wards",
    "स्थानीय तह र यसका सम्बन्धित वडाहरूबाट जन्म दर्ता प्रमाण पत्र प्राप्त गरेका बालबालिकाको संख्या (५ वर्षभन्दा मुनि)",
  ],
  [
    "totChildrenReceivingSSADesc",
    "Number of children (below 18 years) registered for social security allowance in the municiaplity",
    "स्थानीय तहमा सामाजिक सुरक्षा भत्ताको लागि दर्ता गरिएका बालबालिकाको संख्या (१८ वर्षभन्दा मुनि)",
  ],
  [
    "primarySchoolEnrollDesc",
    "Percentage of children enrolled in schools (0-5 grades) in the municipality",
    "स्थानीय तहको विद्यालयहरूमा ० देखि ५ कक्षामा भर्ना भएका बालबालिकाको प्रतिशत",
  ],
  [
    "secondarySchoolDropoutDesc",
    "Percentage of girls and boys dropout from secondary schools (8-12 grades) in the municipality",
    "स्थानीय तहमा माध्यमिक तहमा स्कुल छोड्ने छात्र तथा छात्राहरूको प्रतिशत (८-१२)",
  ],
  [
    "outOfSchoolChildrenDesc",
    "Number of boys and girls (4-10 years of ages) who are out of school in the municipality",
    "स्थानीय तहमा बिद्यालय बाहिर रहेका बालबालिकाको (४-१०) संख्या",
  ],
  [
    "totEarlyMarriageDesc",
    "Number of early marriage recorded before 20 years of age",
    "२० वर्ष नपुग्दै विवाह भएकाहरूको कुल संख्या",
  ],
  [
    "totChildAbuseDesc",
    "Number of reported cases of child abuse as domestic worker, AES workers and others",
    "घरेलु कामदार, मनोरञ्जन क्षेत्रमा कार्यरत र अन्य बाल दुराचारका उल्लेख गरिएका घटनाहरू",
  ],
  [
    "totOrphanChildrenDesc",
    "Number of children whose parents/families are  either missing or expired",
    "बाबु आमा दुवै नभएकोहरूको संख्या",
  ],
  [
    "totStreetChildrenDesc",
    "Number of children living in the street and reported as street children in the municipality",
    "सडकमा रहेका र सडक बालबालिका भनेर स्थानीय तहमा उल्लिखित बालबालिकाको संख्या",
  ],
  [
    "totMissingChildrenDesc",
    "Number of reported cases of children who are currently missing from their families",
    "प्रहरीमा दर्ता भएको हालसम्म परिवारसँग सम्पर्कविहीन बालबालिकाको संख्या",
  ],
  [
    "totFoundChildrenDesc",
    "Number of reported cases of children who are currently found ",
    "प्रहरीको रेकर्डमा भएका र परिवारमा पुर्नमिलन भएका बालबालिकाको संख्या",
  ],
  [
    "totChildDevCenterDesc",
    "Total number of child development centers registered and operated in the municipality",
    "स्थानीय तहमा दर्ता भएका बाल विकास केन्द्रको संख्या",
  ],
  [
    "perChildBudgetAllocatedDesc",
    "The ratio of budget allocated for children's development and welfare in the annual budget of municipality",
    "स्थानीय तहको वार्षिक बजेटमा बालविकास र बालकल्याणको लागि छुट्ट्याइएको बजेटको प्रतिशत",
  ],

  //SeniorCitizenModule
  ["totSeniorCitizen", "Total population of senior citizens", "जेष्ठ नागरिकको संख्या"],
  // [
  //   "perSeniorCitizenWithNoCare",
  //   "Percentage of senior citizens with no care and poor economic status",
  //   "कमजोर आर्थिक स्थिति भएका र हेरचाह गर्ने व्यक्ति नभएका जेष्ठ नागरिकको प्रतिशत",
  // ],
  [
    "perSeniorCitizenWithNoCare",
    "Percentage of senior citizens with no care-taker",
    "हेरचाह गर्ने व्यक्ति नभएका जेष्ठ नागरिकको प्रतिशत",
  ],
  [
    "totSeniorCitGettingSPA",
    "No. of senior citizens getting Social Protection Allowance",
    "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने जेष्ठ नागरिकको संख्या",
  ],
  [
    "totSeniorCitRecevingPension",
    "No. of senior citizens receiving pension",
    "पेन्सन प्राप्त गर्ने जेष्ठ नागरिकको संख्या",
  ],
  [
    "perSeniorCitBudgetAllocated",
    "Percentage of budget allocated for service and welfare of senior citizens",
    "जेष्ठ नागरिकको क्षेत्रको लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],
  [
    "totSeniorCitAffByNatCal",
    "No. of senior citizens affected by calamities",
    "विपत्‌बाट प्रभावित जेष्ठ नागरिकको संख्या",
  ],
  [
    "totSeniorCitizenDesc",
    "Number of senior citizens (above 60 years) recorded in the official document of the municipality",
    "स्थानीय तहको आधिकारिक रेकर्ड अनुसार जेष्ठ नागरिकको संख्या (६० वर्ष माथि)",
  ],
  [
    "perSeniorCitizenWithNoCareDesc",
    "Number of senior citizens with no care and poor economic status (no family and economic support) in the municipality",
    "कमजोर आर्थिक अवस्था र सेवा गर्ने कोही नभएका (आर्थिक र पारिवारिक सहयोग) नभएका जेष्ठ नागरिकको संख्या",
  ],
  [
    "totSeniorCitGettingSPADesc",
    "Total number of senior citizens receiving social protection allowance from the ward or municipality",
    "वडा अथवा स्थानीय तहबाट सामाजिक सुरक्षा भत्ता प्राप्त गर्ने जेष्ठ नागरिकको कुल संख्या",
  ],
  [
    "totSeniorCitRecevingPensionDesc",
    "Number of senior citizens receiving Nepal government service pension",
    "नेपाल सरकारबाट पेन्सन प्राप्त गर्ने जेष्ठ नागरिकको संख्या",
  ],
  [
    "perSeniorCitBudgetAllocatedDesc",
    "The ratio of the total budget allocated for senior citizens in the annual budget of municipality",
    "स्थानीय तहको वार्षिक बजेटमा जेष्ठ नागरिकलाई छुट्ट्याइएको बजेटको प्रतिशत",
  ],

  //Disabled Module
  ["disabledModule", "Disabled", "अपाङ्गता"],
  ["disabilityCategoryKa", "Disability category(Ka)", "अपाङ्गता वर्ग (क)"],
  ["categoryKa", "Category Ka", "वर्ग क"],
  ["categoryKha", "Category Kha", "वर्ग ख "],
  ["categoryGa", "Category Ga", "वर्ग ग"],
  ["categoryGha", "Category Gha", "वर्ग घ"],
  ["categoryTotalDes", "Overall", "जम्मा"],
  ["categoryKaDes", "Completely disabled", "पूर्ण अशक्त"],
  ["categoryKhaDes", "Extremely disabled", "अति अशक्त"],
  ["categoryGaDes", "Moderate", "मध्यम"],
  ["categoryGhaDes", "General", "सामान्य"],
  ["categoryTotalDesc", "Overall disability", "जम्मा अपाङ्गता"],
  ["categoryKaDesc", "Completely disabled disability", "पूर्ण अशक्त अपाङ्गता"],
  ["categoryKhaDesc", "Extremely disabled disability", "अति अशक्त अपाङ्गता"],
  ["categoryGaDesc", "Moderate disability", "मध्यम अपाङ्गता"],
  ["categoryGhaDesc", "General disability", "सामान्य अपाङ्गता"],
  [
    "totDisPeop",
    "No. of total people with disability",
    "अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "totDisPeopDesc",
    "Number of people with disability registered in the official document of the municipality ",
    "स्थानीय तहको रेकर्डमा अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "totDisPeopCatKa",
    "No. of completely people with disability(category-Ka) ",
    "पूर्ण अशक्त अपाङ्गता भएका व्यक्तिको संख्या (वर्ग क)",
  ],
  [
    "totDisPeopCatKaDesc",
    "Number of people with disability(category-Ka) registered in the official document of the municipality ",
    "स्थानीय तहको रेकर्डमा पूर्ण अशक्त अपाङ्गता (वर्ग क) भएका व्यक्तिको संख्या",
  ],
  [
    "totDisPeopCatKha",
    "No. of extremely people with disability(category-Kha) ",
    "अति अशक्त अपाङ्गता भएका व्यक्तिको संख्या (वर्ग ख)",
  ],
  [
    "totDisPeopCatKhaDesc",
    "Number of people with disability(category-Kha) registered in the official document of the municipality ",
    "स्थानीय तहको रेकर्डमा अति अशक्त अपाङ्गता (वर्ग ख) भएका व्यक्तिको संख्या ",
  ],
  [
    "totDisPeopCatGa",
    "No. of moderate people with disability(category-Ga) ",
    "मध्यम अपाङ्गता भएका व्यक्तिको संख्या (वर्ग ग)",
  ],
  [
    "totDisPeopCatGaDesc",
    "Number of people with disability(category-Ga) registered in the official document of the municipality ",
    "स्थानीय तहको रेकर्डमा मध्यम अपाङ्गता (वर्ग ग) भएका व्यक्तिको संख्या",
  ],
  [
    "totDisPeopCatGha",
    "No. of general people with disability(category-Gha) ",
    "सामान्य अपाङ्गता भएका व्यक्तिको संख्या (वर्ग घ)",
  ],
  [
    "totDisPeopCatGhaDesc",
    "Number of people with disability(category-Gha) registered in the official document of the municipality ",
    "स्थानीय तहको रेकर्डमा सामान्य अपाङ्गता (वर्ग घ) भएका व्यक्तिको संख्या",
  ],

  [
    "disPeopRecSkillTraining",
    "No. of people with disability who received skill training",
    "सीपमूलक तालिम प्राप्त गरेका अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "disPeopRecSkillTrainingDesc",
    "Recorded number of people with disability at municipality who received skill training ",
    "सीपमूलक तालिम प्राप्त गरेका अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSA",
    "No. of people with disability receiving social security allowance",
    "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSADesc",
    "Number of people with disability receiving social security allowance in the municipality",
    "अपाङ्गता अन्तर्गत सामाजिक सुरक्षा भत्ता प्राप्त गर्ने व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSAUnderKa",
    "No. of people with disability receiving social security allowance under category (Ka)",
    "पूर्ण अशक्त अपाङ्गता (वर्ग क) अन्तर्गत सामाजिक सुरक्षा भत्ता प्राप्त गर्ने व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSAUnderKaDesc",
    "Number of people with disability receiving social security allowance under category (Ka) in the municipality",
    "पूर्ण अशक्त अपाङ्गता (वर्ग क) अन्तर्गत सामाजिक सुरक्षा भत्ता प्राप्त गर्ने व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSAUnderKha",
    "No. of people with disability receiving social security allowance under category (Kha)",
    "अति अशक्त अपाङ्गता (वर्ग ख) अन्तर्गत सामाजिक सुरक्षा भत्ता पाउने अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSAUnderKhaDesc",
    "Number of people with disability receiving social security allowance under category (Kha) in the municipality",
    "अति अशक्त अपाङ्गता (वर्ग ख) अन्तर्गत सामाजिक सुरक्षा भत्ता पाउने अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSAUnderGa",
    "No. of people with disability receiving social security allowance under category (Ga)",
    "मध्यम अपाङ्गता (वर्ग ग) अन्तर्गत सामाजिक सुरक्षा भत्ता पाउने अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSAUnderGaDesc",
    "Number of people with disability receiving social security allowance under category (Ga) in the municipality",
    "मध्यम अपाङ्गता (वर्ग ग) अन्तर्गत सामाजिक सुरक्षा भत्ता पाउने अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSAUnderGha",
    "No. of people with disability receiving social security allowance under category (Gha)",
    "सामान्य अपाङ्गता (वर्ग घ) अन्तर्गत सामाजिक सुरक्षा भत्ता पाउने अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "disPeopReceivingSSAUnderGhaDesc",
    "No. of people with disability receiving social security allowance under category (Gha)",
    "सामान्य अपाङ्गता वर्ग (घ) अन्तर्गत सामाजिक सुरक्षा भत्ता पाउने अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "noDisabledRehabCenter",
    "No. of rehabilitation centers for disabled person",
    "अपाङ्गता पुन:स्थापना केन्द्रहरूको संख्या",
  ],
  [
    "noDisabledRehabCenterDesc",
    "Number of rehabilitation centre registered and operated in the municipality in fiscal year",
    "स्थानीय तहभित्र आर्थिक वर्षभित्र दर्ता भएका र सञ्चालन भएका अपाङ्गता पुन:स्थापना केन्द्रहरूको संख्या",
  ],
  [
    "totDisPeopRecServices",
    "No. of people with disability receiving services and care in the rehabilitation center",
    "अपाङ्गता पुन:स्थापना केन्द्र मार्फत सेवा र सुविधा पाउने व्यक्तिको संख्या",
  ],
  [
    "totDisPeopRecServicesDesc",
    "Number of people with disability registered and residing in the rehabilitation center ",
    "अपाङ्गता पुन:स्थापना केन्द्र मार्फत सेवासुविधा पाउने व्यक्तिको संख्या",
  ],
  [
    "perBudgetAllocatedForDisPeop",
    "Percentage of municipal budget allocated for people with disability service and welfare",
    "अपाङ्गता भएका व्यक्तिको क्षेत्रको लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],
  [
    "perBudgetAllocatedForDisPeopDesc",
    "The ratio of the total budget allocated for people with disability in the annual budget of the municipality",
    "अपाङ्गता भएका व्यक्तिको क्षेत्रको लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],
  [
    "perOfDisPeopJob",
    "Number of people with disability seeking job opportunities",
    "रोजगारीका अवसरको खोजीमा रहेका अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "perOfDisPeopJobDesc",
    "Number of people wanting to do work or expressing desire for work with municipality",
    "रोजगारीका अवसरहरूको खोजीमा रहेका अपाङ्गता भएका व्यक्तिको संख्या",
  ],
  [
    "totDisabledAffByNatCal",
    "No. of disabled affected by calamities",
    "विपत्‌बाट प्रभावित अपाङ्गता भएका व्यक्तिको संख्या",
  ],


  ["typesOfWork", "Types of work", "कामका प्रकारहरू"],
  ["causeOfDisability", "Cause of disablility", "अपाङ्गताको कारण"],

  //ShelterHomeIndicator
  ["shelterHomeSewaKendra", "Shelter Home/Sewa Kendra", "सुरक्षित आवास गृह/सेवा केन्द्र"],
  ["shelterHomeIndicator", "Shelter Home Indicator", "सुरक्षित आवास गृह"],
  [
    "totShelterHome",
    "No. of Sheltered Homes registered",
    "दर्ता भएका सुरक्षित आवास गृहहरूको संख्या",
  ],
  [
    "totSurvivorsShelterHome",
    "No. of survivors/victims receiving shelter service and support in the shelter home",
    "सुरक्षित आवास गृहको सेवा पाइरहेका आश्रितहरूको संख्या ",
  ],
  [
    "totTIPVictims",
    "No. of TIP victims receiving shelter service and support in the shelter home",
    "सुरक्षित आवास गृहको सेवा पाइरहेका मानव बेचबिखन पीडितहरूको संख्या",
  ],
  [
    "totShelterHomeDesc",
    "Number of registered shelter homes in the municipality",
    "दर्ता भएका सुरक्षित आवास गृहहरूको संख्या",
  ],
  [
    "totSurvivorsShelterHomeDesc",
    "Total number of survivors/victims rescued, suspected and intercepted who are receiving shelter service and support in the shelter home",
    "सुरक्षित आवास गृहको सेवा पाइरहेका आश्रितहरूको संख्या ",
  ],
  [
    "totTIPVictimsDesc",
    "Total number of TIP victims who are receiving shelter service and support in the shelter home",
    "सुरक्षित आवास गृहको सेवा पाइरहेका मानव बेचबिखन पीडितहरूको संख्या",
  ],

  //SewaKendraIndicator
  ["sewaKendraIndicator", "Sewa Kendra Indicator", "सेवा केन्द्र"],
  [
    "totSewaKendra",
    "No. of Sewa Kendras registered",
    "दर्ता भएका सेवा केन्द्रहरूको संख्या",
  ],
  [
    "totSurvivorsSewaKendra",
    "No. of TIP survivors/victims receiving shelter service and support in the sewa kendra",
    "सेवा केन्द्रको सेवा पाइरहेका आश्रितहरूको संख्या ",
  ],
  [
    "totGbvVictims",
    "No. of GBV victims receiving shelter service and support in the sewa kendra",
    "सेवा केन्द्रको सेवा पाइरहेका लैङ्गिक हिंसा पीडितहरूको संख्या",
  ],
  [
    "totSewaKendraDesc",
    "Number of registered sewa kendras in the municipality",
    "दर्ता भएका सेवा केन्द्रहरूको संख्या",
  ],
  [
    "totSurvivorsSewaKendraDesc",
    "Total number of survivors/victims rescued, suspected and intercepted who are receiving shelter service and support in the sewa kendra",
    "सेवा केन्द्रको सेवा पाइरहेका आश्रितहरूको संख्या ",
  ],
  [
    "totGbvVictimsDesc",
    "Total number of GBV victims who are receiving shelter service and support in the sewa kendra",
    "सेवा केन्द्रको सेवा पाइरहेका लैङ्गिक हिंसा पीडितहरूको संख्या",
  ],

  //ChildHome 
  ["childHome", "Child Home", "बालगृह"],
  ["indChildHome", "Individual Child Form", "व्यक्तिगत विवरण"],
  ["tipCase", "TIP Case", "मानव बेचबिखन तथा ओसारपसारसँग सम्बन्धित घटना"],
  ["gbvCase", "GBV Case", "लैङ्गिक हिंसासँग सम्बन्धित घटना"],
  ["atRisk", "At Risk", "जोखिममा"],
  [
    "humanTraffickingAndTransportation",
    "Human Trafficking And Transportation",
    "मानव बेचबिखन तथा ओसारपसार",
  ],
  ["tipInformation", "Tip Information", "मानव बेचबिखन तथा ओसारपसारसँग सम्बन्धित जानकारी"],
  ["gbvInformation", "GBV Information", "लैङ्गिक हिंसासँग सम्बन्धित जानकारी"],
  ["education", "Education", "शिक्षा"],
  ["parentGaurd", "Parent / Gaurdain", "अभिभावक / संरक्षक"],
  ["recommendedBy", "Recommended by", "सिफारिस गर्ने"],
  ["referralStatus", "Referral Status", "सिफारिसको स्थिति"],
  ["investigationStatus", "Investigation Status", "अनुसन्धानको स्थिति"],

  //ChildHomeIndicator
  ["childHomeIndicator", "Child Home", "बालगृह"],
  ["tip", "TIP", "मानब बेचबिखन"],
  ["gbv", "GBV", "लैङ्गिक हिंसा"],
  ["trappedInAddiction", "Trapped in addiction", "कुलतमा फसेको"],
  ["drugAddict", "Drug Addict", "लागुऔषध सेवन गर्ने"],
  ["streetChildren", "Street children", "सडक बालबालिका"],
  [
    "totChildHome",
    "No. of Child Homes registered",
    "दर्ता भएका बालगृहको संख्या",
  ],
  [
    "totChildReceivingServices",
    "No. of child staying and receiving Child Home services during the reporting period",
    "बालगृहमा आश्रित र सेवा पाइरहेका बालबालिकाको संख्या",
  ],
  [
    "totChildRehabilitated",
    "No. of child rehabilitated during the reporting period",
    "बालगृहबाट पुन:स्थापित बालबालिकाको संख्या",
  ],
  [
    "perBudgetForChildHome",
    "Percentage of budget allocated for Child home",
    "बालगृहको लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],
  [
    "totChildHomeDesc",
    "Number of registered Child homes in the municipality",
    "दर्ता भएका बालगृहहरूको संख्या",
  ],
  [
    "totChildReceivingServicesDesc",
    "Number child (TIPS and GBV etc.) documented by Child homes.",
    "प्रतिवेदन अवधिमा बालगृहमा आश्रित र सेवा पाइरहेका बालबालिकाको संख्या",
  ],
  [
    "totChildRehabilitatedDesc",
    "Number of child successfully rehabilitated",
    "प्रतिवेदन अवधिमा बालगृहबाट पुन:स्थापित बालबालिकाको संख्या",
  ],
  [
    "perBudgetForChildHomeDesc",
    "The ratio of budget allocated for Child home in the annual budget of municipality",
    "बालगृहको लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],

  // Shelter Home

  ["shelterHome", "Shelter Home", "सुरक्षित आवास गृह"],
  ["id", "ID", "परिचयपत्र"],
  ["uniqueIdOfSurvivor", "Unique ID of the survivor", "अद्वितीय परिचय"],
  [
    "typesOfShelterServiceReceived",
    "Type of shelter service received",
    "प्राप्त सेवाहरूको प्रकार",
  ],
  ["rescuedFrom", "Rescued from", "उद्धार गरिएको स्थान"],
  ["rescuedBy", "Rescued by", "उद्धार कर्ता"],
  ["name", "Name", "नाम"],
  ["organization", "Organization", "कार्यालय"],
  ["rescued", "Rescued", "उद्धार"],
  ["suspected", "Suspected", "संदिग्ध"],
  ["intercepted", "Intercepted", "जाँचबुझको क्रममा रोकिएको"],
  ["case", "Case", "घटना"],
  ["inCaseOfRescued", "In Case Of Rescued", "उद्धार गरिएको खण्डमा"],
  ["inCaseOfVulnerable", "In Case Of Vulnerable", "जोखिमको अवस्थामा"],
  ["vulnerable", "Vulnerable", "जोखिममा रहेका"],
  ["identified", "Identified", "पहिचान गरिएका"],
  ["vulnerableSuspected", "Vulnerable Suspected", "जोखिम तथा संदिग्ध"],
  ["dateOfEntry", " Date of entry ", "प्रविष्ट मिति"],
  ["dateOfExit", "Date of exit", "वहिर्गमनको मिति"],
  ["dateFormat", "YYYY-MM-DD", "साल-महिना-गते"],
  ["period", " Period ", "अवधि"],
  ["typesOfCases", "Type of cases", "घटनाहरूको प्रकार"],
  ["suspects", "Suspects", "संदिग्ध"],
  ["serviceProvider", "Service Providers", "सेवा प्रदायकहरू"],
  ["reasonLeavingHome", " Reason for leaving home", "घर छोड्नुको कारण"],
  ["physicalState", " Physical state", "शारीरिक अवस्था"],
  ["mentalStatus", "Mental state", "मानसिक अवस्था"],
  ["weeks", "Weeks", "हप्ता"],

  //Juvenile Child Home Indicator
  ["juvenileChildHomeIndicator", "Juvenile Reform Home", "बाल सुधार गृह"],
  [
    "totChildCorrectionHome",
    "No. of Juvenile Reform Homes registered",
    "दर्ता भएका बाल सुधार गृहको संख्या",
  ],
  [
    "totChildReceivingServicesInCch",
    "No. of child staying and receiving service and support in Juvenile Reform Home",
    "बाल सुधार गृहको सेवा पाइरहेका बालबालिकाको संख्या ",
  ],
  [
    "totChildRehabilitatedFromCch",
    "No. of child rehabilitated from Juvenile Reform Home to their and other families",
    "बाल सुधार गृहबाट पुन:स्थापित बालबालिकाको संख्या",
  ],
  [
    "perBudgetForChildCorrectionHome",
    "Percentage of municipal budget allocated for Juvenile Reform home",
    "बाल सुधार गृहका लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],
  [
    "totChildCorrectionHomeDesc",
    "Number of registered Juvenile Reform homes in the municipality",
    "दर्ता भएका बाल सुधार गृहहरूको संख्या",
  ],
  [
    "totChildReceivingServicesInCchDesc",
    "Number of boys and girls registered in Juvenile Reform Homes ",
    "बाल सुधार गृहको सेवा पाइरहेका बालबालिकाको संख्या ",
  ],
  [
    "totChildRehabilitatedFromCchDesc",
    "Number of child rehabilitated in their families and communities",
    "बाल सुधार गृहबाट आफ्नो वा अरूको घरमा पुन:स्थापित बालबालिकाको संख्या",
  ],
  [
    "perBudgetForChildCorrectionHomeDesc",
    "The ratio of the total budget allocated for Juvenile Reform home in the annual budget of the municipality",
    "बाल सुधार गृहका लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],

  //Juvenile Child Home
  ["juvenileChildHome", "Juvenile Reform Home", "बाल सुधार गृह"],
  ["photo", "Photo", "फोटो"],
  ["birthCertificateNo", "Birth Certificate No.", "जन्म दर्ता प्रमाणपत्र नं"],
  ["birthCertIssuePlace", "Birth Certificate Issue Place", "जन्म दर्ता प्रमाणपत्र जारी स्थान"],
  ["birthCertIssueDate", "Birth Certificate Issue Date", "जन्म दर्ता प्रमाणपत्र जारी मिति"],
  ["issuePlace", "Issue Place", "जारी स्थान"],
  ["issueDate", "Issue Date", "जारी मिति"],
  ["sentenced", "Sentenced", "सजाय सुनाइएको"],
  ["timeSpentAtJCH", "Time spent at JCH", "बाल सुधार गृहमा बिताएको समय"],
  ["offenderType", "Offender Type ", "अपराधको प्रकार"],
  ["habitual", "Habitual", "अभ्यस्त"],
  ["typeOfCase", "Type of Case", "घटनाको प्रकार"],
  ["oneTime", "One Time", "एक पटक"],
  // ["fromDate", "From Date", "सुरू मिति"],
  // ["toDate", "To Date", "अन्तिम सम्म"],
  ["rehabilitationSection", "Rehabilitation section", "पुन:स्थापना खण्ड"],

  //Old Age Home
  ["fundedBy", "Funded by", "आर्थिक सहायताको स्रोत"],
  ["reasonForStay", "Reason for stay", "आश्रय लिनुको कारण"],
  ["receivingSSA", "Receiving social security allowance", "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने"],
  ["timeSpent", "Time spent", "आश्रय अवधि"],
  ["dead", "Dead", "मृत्यु "],
  ["dateOfDeath", "Date of death", "मृत्यु भएको मिति"],
  ["rehabilitatedToHome", "Rehabilitated To Home", "घरमा पुन:स्थापना भएको"],
  ["livingWithFamilyRelatives", "Living with family or relatives", "परिवार वा आफन्तसँग बस्ने"],
  ["relation", "Relation", "सम्बन्ध"],

  //OldAgeHomeIndicator
  ["oldAgeHomeIndicator", "Senior Citizens Care Home", "जेष्ठ नागरिक हेरचाह केन्द्र"],
  [
    "totOldAgeHome",
    "No. of Senior Citizens Care Homes registered",
    "दर्ता भएका जेष्ठ नागरिक हेरचाह केन्द्रको संख्या",
  ],
  [
    "totSeniorCitReceivingServices",
    "No. of senior citizens receiving service and support in Senior Citizens Care Home",
    "जेष्ठ नागरिक हेरचाह केन्द्रबाट सेवासुविधा पाइरहेका जेष्ठ नागरिकको संख्या",
  ],
  [
    "totSeniorCitRehabilitated",
    "No. of senior citizens rehabilitated",
    "जेष्ठ नागरिक हेरचाह केन्द्रबाट पुन:स्थापित जेष्ठ नागरिकको संख्या",
  ],
  [
    "perBudgetForOldAgeHome",
    "Percentage of budget allocated for senior citizens care home",
    "जेष्ठ नागरिक हेरचाह केन्द्रका लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],
  [
    "totOldAgeHomeDesc",
    "Number of registered senior citizens care home in the municipality",
    "दर्ता भएका जेष्ठ नागरिक हेरचाह केन्द्रको संख्या",
  ],
  [
    "totSeniorCitReceivingServicesDesc",
    "Number male and female senior citizens supported by senior citizens care home",
    "जेष्ठ नागरिक हेरचाह केन्द्रबाट सेवासुविधा पाइरहेका जेष्ठ नागरिकको संख्या",
  ],
  [
    "totSeniorCitRehabilitatedDesc",
    "Number of senior citizens rehabilitated to their own or other families",
    "जेष्ठ नागरिक हेरचाह केन्द्रबाट आफ्नो वा अरूको घरमा पुन:स्थापित जेष्ठ नागरिकको संख्या",
  ],
  [
    "perBudgetForOldAgeHomeDesc",
    "The ratio of budget allocated for Oldage home in the annual budget of municipality",
    "स्थानीय तहले जेष्ठ नागरिक हेरचाह केन्द्रका लागि छुट्ट्याइएको बजेट प्रतिशत",
  ],
  ["ownHome", "Own Home", "आफ्नो घर"],
  ["relativeHome", "Relative Home", "आफन्तको घर"],

  //Complaint Registration
  ["complaintRegistration", "Complaint Registration", "गुनासो/उजुरी दर्ता"],
  ["typeOfComplaint", "Type of complaint", "गुनासो/उजुरीको प्रकार"],
  [
    "crInJudicialCommittee",
    "Total complaints registered",
    "दर्ता भएका उजुरीको संख्या",
  ],
  [
    "crInJudicialCommitteeDesc",
    "Total complaints registered",
    "दर्ता भएका उजुरीको संख्या",
  ],
  [
    "crUnder47Part1",
    "No. of complaints registered under Local Government Operation Act, 2074 Section 47 (1)",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (१) अन्तर्गत दर्ता भएका उजुरीको संख्या",
  ],
  [
    "crUnder47Part1Desc",
    "Number of registered complaints registered  in the judical committee of the Municipality",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (१) अन्तर्गत दर्ता भएका उजुरीको संख्या",
  ],
  [
    "crToWages",
    "No. of complaints relating to wages and labor",
    "ज्याला र श्रम सम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToWagesDesc",
    "Number of wage and labor related registered complained in the judical committee as documented by the Municipality",
    "ज्याला र श्रम सम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToSeniorCitizen",
    "No. of complaints relating to care of senior citizens(including the neglegance in caring of senior citizens)",
    "ज्येष्ठ नागरिकको हेरचाह सम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToSeniorCitizenDesc",
    "Number of registered complaints relating to care of senior citizens (including the neglegance by son/daughter in caring of sr. citizens) in the judical committee as documented by the Municipality",
    "ज्येष्ठ नागरिकको हेरचाह सम्बन्धी उजुरीको संख्या (छोरा छोरीले पालन पोषण नगरेको अवहेलना गरेको)",
  ],
  [
    "crToMinors",
    "No. of complaints relating to care of minors (food, cloths and education)",
    "नाबालिगहरूको हेरचाह (खाना, कपडा र शिक्षा) सम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToMinorsDesc",
    "Number of registered complaints relating to care of minors (food, cloths and education) in the judical committee as documented by the Municipality",
    "नाबालिगहरूको हेरचाह (खाना, कपडा र शिक्षा) सम्बन्धी उजुरीको संख्या",
  ],
  [
    "crUnder47Part2",
    "No. of complaints registered under Local Government Operation Act, 2074 Section 47 (2)",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (२) अन्तर्गत दर्ता भएका उजुरीको सङ्ख्या",
  ],
  [
    "crUnder47Part2Desc",
    "Number of registered complaints under Local Government Operation Act, 2074 Section 47 (2) in the judical committee as documented by the Municipality",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (२) अन्तर्गत दर्ता भएका उजुरीको सङ्ख्या",
  ],
  [
    "crToDivorce",
    "No. of complaints relating to divorce",
    "सम्बन्धविच्छेदसम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToDivorceDesc",
    "Number of registered complained related to divorce in the judical committee as documented by the Municipality",
    "सम्बन्धविच्छेदसम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToBattery",
    "No. of complaints relating to battery",
    "दुर्व्यवहारसम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToBatteryDesc",
    "Number of registered complained related to battery in the judical committee as documented by the Municipality",
    "दुर्व्यवहारसम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToDefamation",
    "No. of complaints relating to defamation",
    "मानहानिसम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToDefamationDesc",
    "Number of registered complained related to defamation in the judical committee as documented by the Municipality",
    "मानहानिसम्बन्धी उजुरीको संख्या",
  ],
  [
    "dsUnder47Part1",
    "No. of disputes settled under Local Government Operation Act, 2074 Section 47 (1)",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (१) अन्तर्गत समाधान भएका विवादहरूको सङ्ख्या",
  ],
  [
    "dsUnder47Part1Desc",
    "Number of registered complained realted to settlement of disputes in the judical committee as documented by the municipality ",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (१) अन्तर्गत समाधान भएका विवादहरूको सङ्ख्या",
  ],
  [
    "dsMedicationUnder47Part2",
    "No. of disputes settled through mediation under Local Government Operation Act, 2074 Section 47 (2)",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (२) अन्तर्गत मेलमिलापबाट समाधान भएका विवादहरूको सङ्ख्या",
  ],
  [
    "dsMedicationUnder47Part2Desc",
    "Number of registered disputes settled through mediation under Local Government Operation Act, 2074 Section 47 (2)  in the judical committee as documented by the Municipality",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (२) अन्तर्गत मेलमिलापबाट समाधान भएका विवादहरूको सङ्ख्या",
  ],
  [
    "crBeyondJurisdiction",
    "No. of other complaints received ",
    "स्थानीय तहको अधिकार भन्दा बाहिरबाट प्राप्त अन्य उजुरीको संख्या",
  ],
  [
    "crBeyondJurisdictionDesc",
    "Number of complaints received beyond the jurisdiction of judicial committee as recorded by the municipality ",
    "क्षेत्राधिकार स्थानीय तहको अधिकार भन्दा बाहिरबाट प्राप्त अन्य उजुरीको संख्या",
  ],
  [
    "crRelevantInstitutions",
    "No. of complaints referred to relevant institutions",
    "सम्बन्धित संस्थाहरूमा प्रेषण गरिएका/पठाइएका उजुरीहरू",
  ],
  [
    "crRelevantInstitutionsDesc",
    "Number of Total complaints referred to relevant institutions by the judicial committee as recorded by the municipality",
    "सम्बन्धित संस्थाहरूमा प्रेषण गरिएका/पठाइएका उजुरीहरू",
  ],
  [
    "crToLegalAidService",
    "No. of complaints referred to legal aid service",
    "कानूनी सहायता प्रदान गर्ने संस्थाहरूमा प्रेषण गरिएका/पठाइएका उजुरीको संख्या",
  ],
  [
    "crToLegalAidServiceDesc",
    "Number of complaints referred to legal aid providing institutions by the judical committee as documented by the Municipality",
    "कानूनी सहायता प्रदान गर्ने संस्थाहरूमा प्रेषण गरिएका/पठाइएका उजुरीको संख्या",
  ],
  [
    "crToPsychoSocioCounselling",
    "No. of complaints referred to psycho-socio counselling",
    "मनोसामाजिक परामर्शका लागि प्रेषण गरिएका/पठाइएका उजुरीको संख्या",
  ],
  [
    "crToPsychoSocioCounsellingDesc",
    "Number of complaints referred to psycho-socio counselling by the judical committee as documented by the Municipalit",
    "मनोसामाजिक परामर्शका लागि रेफर गरिएका/पठाइएका उजुरीको संख्या",
  ],
  [
    "crToMedicalInstitutions",
    "No. of complaints referred to medical institutions",
    "स्वास्थ्य संस्थाहरूमा रेफर गरिएका/पठाइएका उजुरीको संख्या",
  ],
  [
    "crToMedicalInstitutionsDesc",
    "Number of complaints referred to medical institutions by the judical committee as documented by the Municipality",
    "स्वास्थ्य संस्थाहरूमा रेफर गरिएका/पठाइएका उजुरीको संख्या",
  ],
  [
    "crAboutDisabilityCard",
    "No. of complaints about the disability card",
    "अपाङ्गता भएका व्यक्तिको परिचयपत्रसम्बन्धी उजुरीको संख्या",
  ],
  [
    "crAboutDisabilityCardDesc",
    "Number of registered complained in the judical committee about disputes of disability card as documented by the Municipality",
    "अपाङ्गता भएका व्यक्तिको परिचयपत्रसम्बन्धी दर्ता भएका उजुरीको संख्या",
  ],
  [
    "firRegistered",
    "No. of FIR registered in police",
    "प्रहरीमा दर्ता भएका जाहेरी (FIR) संख्या"
  ],
  [
    "firRegisteredDesc",
    "No. of FIR registered in police",
    "प्रहरीमा दर्ता भएका जाहेरी (FIR) संख्या"
  ],
  [
    "caseProsecutedByCourt",
    "Number of case prosecuted by the court",
    "अदालतबाट चलाइका मुद्दाको संख्या",
  ],
  [
    "caseProsecutedByCourtDesc",
    "Number of case prosecuted by the court",
    "अदालतबाट चलाइका मुद्दाको संख्या",
  ],
  [
    "caseDecidedByCourt",
    "No. of case decided by the court",
    "अदालतबाट फैसला गरिएका मुद्दाको संख्या"
  ],
  [
    "caseDecidedByCourtDesc",
    "Number of case decided by the court",
    "अदालतबाट फैसला गरिएका मुद्दाको संख्या"
  ],

  //Referral
  ["referral", "Referral", "प्रेषण (रिफरल) सेवाहरू"],
  ["name", "Name", "नाम"],
  ["contact", "Contact", "सम्पर्क"],
  ["referralDate", "Date of referral", "सिफारिसको मिति"],
  ["referredOrganization", "Name of referred organization", "प्रेषण गरिएको संस्थाको नाम"],
  ["referralType", "Referral Type", "सिफारिसको प्रकार"],
  ["refLegalAid", "No of refferal to Legal aid", "कानूनी सहायताको लागि प्रेषण गरिएको संख्या"],
  ["refService", "No of refferal to service", "सेवाको लागि प्रेषण गरिएको संख्या"],
  [
    "refHealthService",
    "No of refferal to Health service",
    "स्वास्थ्य सेवाको लागि प्रेषण गरिएको संख्या",
  ],
  [
    "refPsychologicalService",
    "No of refferal to Psychological Counseling service",
    "मनोवैज्ञानिक परामर्श सेवाको लागि प्रेषण गरिएको संख्या",
  ],
  [
    "refSkillDevService",
    "No of refferal to Skill development training",
    "सीप विकास तालिमको लागि प्रेषण गरिएको संख्या",
  ],
  [
    "refJobPlacementService",
    "No of refferal to Job placement training",
    "रोजगारी तालिमको लागि प्रेषण गरिएको संख्या",
  ],
  ["refLFCTraining", "No of refferal to LFC training", "परिवर्तनका लागि नेतृत्व (LFC) तालिमको लागि प्रेषण गरिएको संख्या"],
  [
    "refOJT",
    "No of refferal to On the jobtraining (OJT)",
    "रोजगारी प्रशिक्षण (OJT) को लागि प्रेषण गरिएको संख्या",
  ],
  [
    "refJobCounsellingService",
    "No of refferal to Job Counseling service",
    "रोजगारी परामर्श सेवाको लागि प्रेषण गरिएको संख्या",
  ],
  [
    "refRescueService",
    "No of refferal to Rescue and Repatriation services",
    "उद्धार र स्वदेश फिर्ता सेवाहरूको लागि प्रेषण गरिएको संख्या",
  ],
  [
    "refHalfWayHome",
    "No of refferal to Referred to half-way home",
    "घर फर्कनका लागि प्रेषण गरिएको संख्या",
  ],

  //Control for human trafficking
  [
    "controlHumanTrafficking",
    "Control for Human Trafficking",
    "मानव बेचबिखन तथा ओसारपसार नियन्त्रण",
  ],
  ["noOfWccht", "No. of WCCHT (Municipality)", "मानव बेचबिखन तथा ओसारपसार नियन्त्रणसम्बन्धी वडा स्तरीय समिति (WCCHT) को संख्या (नगरपालिका / गाउँपालिका)"],
  ["noOfLccht", "No. of LCCHT (District)", "मानव बेचबिखन तथा ओसारपसार नियन्त्रणसम्बन्धी स्थानीय तह स्तरीय समिति (LCCHT) को कुल संख्या (जिल्ला)"],
  ["noOfDccht", "No. of DCCHT (Province)", "मानव बेचबिखन तथा ओसारपसार नियन्त्रणसम्बन्धी जिल्ला स्तरीय समिति (DCCHT) को कुल संख्या (प्रदेश)"],
  ["formationDate", "Date of formation", "गठन भएको मिति"],
  ["totalNoMembers", "Total No. of members", "सदस्य संख्या"],
  ["totalNoOfMeetings", "Total No. of meetings", "बैठकको संख्या"],
  ["listKeyDecision", "List of key decision", "प्रमुख निर्णयहरू"],
  ["listCompletedActivities", "List of completed activities", "सम्पन्न क्रियाकलापहरू"],
  ["nIdentifiedTIPCase", "No. of identifed TIP cases", "पहिचान गरिएका मानव बेचबिखन तथा ओसारपसारका घटनाहरूको संख्या"],
  ["nReferredCases", "No. of referred cases", "प्रेषण गरिएका घटनाहरूको संख्या"],
  [
    "listTIPPolicy",
    "List of TIP related policy and guideline",
    "मानव बेचबिखन तथा ओसारपसारसँग सम्बन्धित नीति र निर्देशिकाहरूको सूची",
  ],
  ["budgetAllocation", "Budget allocation (%)", "विनियोजित बजेट (%)"],

  //Labour Migration
  ["labourMigration", "Labour Migration(Interal/Foreign)", "श्रम आप्रवासन (आन्तरिक/वाह्य)"],
  ["IndicatorDetails", "Indicator Detail", "सूचक विवरण"],
  ["internal", "Internal", "आन्तरिक"],
  ["external", "External", "वाह्य"],
  [
    "totMigrantLabour",
    "No. of migrant labour from the municipality",
    "स्थानीय तहबाट रोजगारीका लागि बाहिर गएका व्यक्तिको संख्या",
  ],
  [
    "totMigrantLabourToIndia",
    "No. of migrant labour to India",
    "रोजगारीका लागि भारत जानेहरूको संख्या",
  ],
  [
    "totReturneeMigrant",
    "No. of returnee migrant from the foreign labour migration",
    "वैदेशिक रोजगारीबाट फर्केकाहरूको संख्या",
  ],
  [
    "totMigrantLabourDesc",
    "Number of male and female migrated for work to different part of Nepal, India and foerign coutries",
    "स्थानीय तहबाट रोजगारीका लागि बाहिर गएका व्यक्तिको संख्या",
  ],
  [
    "totMigrantLabourToIndiaDesc",
    "Number of people migrated for work to India ",
    "स्थानीय तहबाट रोजगारीका लागि भारत जानेहरूको संख्या",
  ],
  [
    "totReturneeMigrantDesc",
    "Number of male and female returned from the foreign labor migration",
    "वैदेशिक रोजगारीबाट फर्किएकाहरूको संख्या",
  ],
  ["migrantInformation", "Migrant Information", "आप्रवासीको जानकारी"],
  ["skills", "Skills", "सीप"],
  ["certificates", "Certificates", "प्रमाणपत्र"],
  [
    "haveReceivedLoanForReIntegration",
    " Have received loan for reintegration",
    "पुनर्मिलनका लागि ऋण प्राप्त भएको",
  ],
  ["destMigration", "Destination of migration", "गन्तव्य देश"],
  ["reasonForMigration", "Reason for migration", "आप्रवासनको कारण"],
  [
    "durationOfStay",
    "Duration of stay in foreign labour migration",
    "वैदेशिक श्रम आप्रवासनमा बिताएको अवधि",
  ],
  ["empBeforeFLM", "Employment before FLM", "वैदेशिक रोजगारी अघिको रोजगारीको अवस्था"],
  ["empAfterFLM", "Employment after FLM", "वैदेशिक रोजगारी पछिको रोजगारीको अवस्था"],
  ["year", "Year", "वर्ष"],
  ["month", "Month", "महिना"],
  ["noDependentFamily", "Number of Dependent family", "आश्रित परिवार संख्या"],
  ["incomeRange", "Income range", "आयको दायरा"],
  ["remittanceTransferMethod", "Remittance transfer method", "विप्रेषण पठाउने विधि"],
  ["issuesDuringMigration", "Issues during migration", "आप्रवासन अवधिका समस्याहरू"],
  [
    "salaryRangeDuringMigration",
    " Salary range during migration period",
    "आप्रवासनको अवधिमा प्राप्त गरेको तलबको दायरा",
  ],
  ["remittance", "Remittance (% in terms of salary)", "विप्रेषण (पाएको तलवको कति प्रतिशत पठाएको)"],
  ["amtPaidToManpower", "Amount paid to Manpower", "वैदेशिक रोजगारी कम्पनीलाई भुक्तानी गरिएको रकम"],
  ["skillPlaceholder", "Please clarify", "कृपया खुलाउनुहोस"],
  ["certificatePlaceholder", "Please clarify if you have skill related certificate", "सीपमूलक तालिमको प्रमाणपत्र भएमा खुलाउनुहोस"],
  ["visaType", "Visa type", "भिसाको प्रकार"],
  ["visitVisa", "Visit Visa", "पर्यटन भिसा"],
  ["manpower", "Manpower", "वैदेशिक रोजगारी कम्पनीमार्फत"],
  ["individual", "Individual", "व्यक्तिगत"],
  ["futurePlans", "Future plans", "भावी योजना"],
  ["occFamily", "Occupation of family", "पारिवारिक पेशा"],
  ["transitRoutes", "Transit routes", "ट्रान्जिट"],
  ["agentDetail", "Agent Detail", "एजेण्टको विवरण"],
  ["contactDetail", "Contact Detail", "सम्पर्क विवरण"],
  ["contactNumber", "Contact number", "सम्पर्क नम्बर"],
  ["recAgencyName", "Recruitment Agency name", "भर्ना गर्ने एजेन्सीको (म्यान पावर कम्पनीको) नाम"],
  ["recAgencyAddress", "Recruitment Agency address", "भर्ना गर्ने एजेन्सीको (म्यान पावर कम्पनीको) ठेगाना"],
  ["recAgencyContact", "Recruitment Agency contact number", "भर्ती गर्ने एजेन्सीको सम्पर्क नम्बर"],
  ["chgAmtFLM", "Charged amount for FLM", "श्रम आप्रवासनका लागि लिइएको रकम"],
  ["receiptAmtPaid", "Receipt for the amount paid", "भुक्तान गरिएको रकमको रसिद"],
  ["taken", "Taken", "लिएको"],
  ["notTaken", "Not Taken", "नलिएको "],
  ["departureDate", "Departure Date", "प्रस्थान मिति"],
  ["arrivalDate", "Arrival Date", "आगमन मिति"],
  ["documentation", "Documentation", "अभिलेखीकरण"],
  ["documentated", "Documentated", "अभिलेख गरिएको"],
  ["undocumentated", "Undocumentated", "अभिलेख नगरिएको"],
  ["compNameDest", "Company Name at Destination Country", "गन्तव्य देशको कम्पनीको नाम"],
  ["addOfCompany", "Address of company", "कम्पनीको ठेगाना"],
  ["contactNoOfCoompany", "Contact Number of Company", "कम्पनीको सम्पर्क नम्बर"],
  ["formalContract", "Formal contract", "औपचारिक सम्झौता"],
  ["received", "Received", "प्राप्त भएको"],
  ["notReceived", " Not received", "प्राप्त नभएको"],
  ["workingSector", "Working sector", "कार्य क्षेत्र"],
  ["salaryAmt", "Salary amount", "तलब"],
  ["salaryCurrency", "Salary currency", "मुद्रा"],
  ["workingHours", "Working hours", "काम गर्ने अवधि (घण्टामा)"],
  ["receivingShelterSupport", "Receiving shelter support", "आश्रय सहायता प्राप्त गरिरहेको"],
  ["relWithAgent", "Relationship with agent", "दलाल सँगको सम्बन्ध"],
  [
    "rehabProvAfterDisability",
    "Rehabilitation provided after disability or diseases",
    "रोग वा अपाङ्गताको कारणले पुन:स्थापना गरिएको",
  ],
  [
    "receivedHelpFromWelfareFunds",
    " Received help from welfare funds",
    "कल्याणकारी कोषबाट सहयोग प्राप्त गरेको",
  ],
  ["caseStatus", " Case Status", "घटना स्थिति"],

  // Service Directory
  ["serviceDirectory", " Service Directory", "सेवा निर्देशिका"],
  ["focalPerson", " Focal Person", "फोकल पर्सन"],
  ["mobile", " Mobile", "मोबाइल"],
  ["email", " Email", "इमेल"],
  ["landLine", " Landline", "ल्याण्डलाइन"],
  ["extension", " Extension", "फोन एक्स्टेन्सन"],
  ["department", " Department", "विभाग"],
  ["roomNo", "  Room No.", "कोठा नं"],
  ["add", " Add", "थप्नुहोस्"],
  ["sn", "  SN", "सिरियल नं"],
  ["serviceName", "  Service Name", "सेवा नाम"],
  ["govtService", "  Govt Service", "सरकारी सेवा"],
  ["privateSector", "  Private Sector", "निजी क्षेत्र"],
  ["targetBeneficiaries", "  Target Beneficiaries", "लक्षित समूह"],
  ["document", "  Document", "कागजात"],
  ["time", "  Time", "समय"],
  ["serviceLamination", "  Service Limitation", "सेवा सीमा"],

  //Pre Decision Training
  ["orgDetails", "Organization Detail", "संगठन विवरण"],
  ["durationOfTraining", "Duration of training", "तालिमको अवधि"],
  ["nameOfFacilitator", "Name Of Facilitator", "सहजकर्ताको नाम"],
  ["name", "Name", "नाम"],
  ["address", "Address", "ठेगाना"],
  ["contact", "Contact", "सम्पर्क"],
  ["email", "Email", "इमेल"],
  ["landline", "Landline", "ल्याण्ड फोन"],
  ["extension", "Extension", "एक्स्टेन्सन"],
  ["department", "Department", "विभाग"],
  ["roomNo", "Room No.", "कोठा नं"],
  ["trainingDetails", "Training Details", "तालिम विवरण"],
  ["startDate", "Start Date", "सुरू मिति"],
  ["endDate", "End Date", "अन्त्य मिति"],
  ["venueName", "Venue Name", "स्थानको नाम"],
  ["participantDetails", "Participant Details", "सहजकर्ताको नाम"],
  ["levelOfSkill", "Level of Skill", "सीपको स्तर"],
  ["skilled", "Skilled", "दक्ष"],
  ["unSkilled", "Unskilled", "अदक्ष"],
  ["workExperience", "Work Experience(in years) ", "कार्य अनुभव (वर्षमा)"],
  ["typeOfMigration", " Type of Migration :", "आप्रवासनको प्रकार"],
  ["potentialMigrants", "Potential Migrants", "सम्भावित आप्रवासीहरू"],
  ["reMigrant", "Re-migrant", "पुन: प्रवासी"],
  ["dateMigration", "Date of Migration", "आप्रवासनको मिति"],
  ["departureDate", "Departure Date", "प्रस्थान मिति"],
  ["returnDate", "Return Date", "फिर्ता मिति"],
  ["duration", "Duration", "अवधि"],
  ["placeMigration", "Place of Migration", "आप्रवासनको स्थान"],
  ["recruitmentAgency", "Recruitment Agency", "कर्मचारी भर्ना गर्ने संस्था"],
  ["agentDetail", "Agent Detail", "एजेण्ट विवरण"],

  //Entertainment sector
  ["entertainmentSector", "Entertainment Sector", "मनोरञ्जन क्षेत्र"],
  ["ownerName", "Name Of Owner", "सञ्चालकको नाम"],
  [
    "entertainmentServiceProvided",
    "Type of entertainment service provided",
    "प्रदान गरिएको मनोरञ्जन सेवाहरू",
  ],
  ["typeOfEntertainmentServices", "Type of entertainment services", "मनोरञ्जन सेवाहरूको प्रकार"],

  //TipInEntertainmnet
  ["detailOfStaff", "Detail of Staff", "कर्मचारी विवरण"],
  ["totalNoOfStaff", "No. of staff", "कर्मचारी संख्या"],
  ["noOfMaleStaff", " No. of male staff", "पुरुष "],
  ["noOfFemaleStaff", " No. of female staff", "महिला "],
  ["noOfLGBQTStaff", "  No. of LGBQT staff", "अन्य"],
  ["ageOfStaff", "Age of staff", "उमेर"],
  ["over18", "over 18", "१८ वर्ष भन्दा माथि"],
  ["under18", "under 18", "१८ वर्ष भन्दा मुनि"],
  ["educationLevel", " Education level", "शैक्षिक स्तर"],
  [
    "idCardProvided",
    " ID card provided",
    "परिचयपत्र उपलब्ध गराइएको"
  ],
  [
    "idCardProvided2",
    " Dress provided",
    "पोशाक उपलब्ध गराइएको",
  ],
  ["workingHours", " Working hours", "काम गर्ने अवधि  (घण्टामा)"],
  ["avgSalary", "Average salary", " तलब (औसत)"],
  [
    "weeklyWorkingDaysForM",
    " Weekly working days for male",
    "पुरुषका लागि कार्य दिनहरू (हप्तामा)"
  ],
  [
    "weeklyWorkingDaysForF",
    " Weekly working days for female",
    "महिलाका लागि कार्य दिनहरू (हप्तामा)",
  ],
  ["dropOffFacility", "Drop-off facility", "घर पुर्‍याउने सुविधा"],
  ["positionOfStaff", "Position of staff", "पद्"],
  ["dependentOfStaff", "Dependent of staff", "आश्रित परिवार"],
  [
    "specifyChildrenSpouseinfo",
    "If Yes  please specify children (boy/girl), spouse info.",
    "यदि हो भने कृपया बालबालिका, जीवनसाथीको जानकारी उल्लेख गर्नुहोस्।",
  ],
  ["legalDocuments", "Legal document present with individual", "व्यक्तिसँग कानूनी कागजातहरू भए / नभएको"],
  ["livingTogether", "Living togther", "लिभिङ्ग टुगेदर"],
  [
    "noOfMissingStaff",
    "No of missing staffs (if any)",
    "हराएका कर्मचारीहरूको संख्या (यदि छ भने)",
  ],
  [
    "noOfViolenceIncident",
    " No of incident of violence/ exploitation (if any)",
    "हिंसा / शोषणका घटनाहरूको संख्या (यदि छ भने)",
  ],
  ["labor", "Labor", "श्रम"],
  ["sexual", "Sexual", "यौनीक"],
  ["physical", "Physical", "शारीरिक"],
  ["psycological", " Psycological", "मनोवैज्ञानिक"],
  ["abroadVisit", " Abroad visit", "वैदेशिक भ्रमण"],
  ["contractPaperEmployee", " Contract paper of employee", "कर्मचारीको श्रम सम्झौता पत्र"],
  ["forMale", "For Male", "पुरुषको लागि"],
  ["forFemale", "For Female", "महिलाको लागि"],
  [
    "ifMarriedSpecifyCertificateNo",
    "If married please provide marriage certificate No. ",
    "यदि विवाहित हुनुहुन्छ भने कृपया विवाह दर्ता प्रमाणपत्र नम्बर प्रदान गर्नुहोस्।",
  ],
  ["othersSpecify", "others (specify…)", "अन्य (उल्लेख गर्नुहोस्…)"],
  //SocialServiceRegistration
  [
    "regOfShelter",
    "Registration of Shelter / Child / Juvenile Reform / Senior Citizens Care Home",
    "आश्रय / बाल / बाल सुधार / जेष्ठ नागरिक हेरचाह केन्द्र को दर्ता",
  ],
  ["category", "Category", "श्रेणी"],
  ["name", "Name", "नाम"],
  ["typeOfService", " Types of Service ", "सेवाका प्रकारहरू"],
  ["action", "Action", "कार्य छनोट गर्नुहोस्"],
  ["shelterCreation", " Shelter Creation", "नयाँ सुरक्षित आवास गृहको नाम बनाउनुहोस्"],
  ["shelterHome", "Shelter Home", "सुरक्षित आवास गृह"],
  ["childHome", "Child Home ", "बालगृह"],
  ["childCorrection", "Juvenile Reform Home", "बाल सुधार गृह"],
  ["oldAgeHome", " Senior Citizens Care Home", "जेष्ठ नागरिक हेरचाह केन्द्र"],
  ["province", "Province", "प्रदेश"],
  ["district", "District", "जिल्ला"],
  ["localLevel", " Local Level", "स्थानीय तह"],
  ["ward", "Ward", "वडा"],
  ["phoneNo", "Phone Number", "फोन नम्बर"],
  ["email", "Email", "इमेल"],
  ["website", "Website", "वेबसाइट"],
  ["contactOfHead", "Contact of Head", "प्रमुखको सम्पर्क"],
  ["nameOfHead", "Name of Head :", "प्रमुखको नाम"],
  ["registrationDetails", " Registration Details", "दर्ता विवरण"],
  ["number", "Number", "नम्बर"],
  ["otherDetails", "Other Details", "अन्य विवरण"],
  ["ownBuilding", "Own building", "आफ्नै भवन भएको"],
  ["typeOfService", "Types of service : ", "सेवाका प्रकारहरू"],
  ["physicalInfrastructure", " Physical Infrastructure", "भौतिक संरचनाहरू"],
  ["sourceOfFunding", " Source of Funding", " आर्थिक स्रोत"],
  ["mgmtType", "Management Type", "व्यवस्थापनको प्रकार"],
  ["list", "List", "सूची"],
  ["noOfStaff", "Number Of Staff", "कर्मचारी संख्या"],
  ["donation", "Donation", "दान"],
  ["board", "Board", "बोर्ड"],
  ["place", "Place", "स्थान"],

  //TIP Details Form
  ["sourceOfTip", "Source of TIP", "मानब बेचबिखनको उद्गम स्थान"],
  ["destinationOfTip", "Destination of TIP", "मानब बेचबिखनको गन्तव्य स्थान"],
  ["countryOfExploitation", "Country of Exploitation", "शोषणमा परिएको देश"],
  ["actOfTip", "Act of TIP Case", "मानब बेचबिखन घटनाको कार्यहरू"],
  ["meansOfTip", "Means of TIP Case", "मानब बेचबिखन घटनाको माध्यमहरू"],
  ["purposeOfTip", "Purpose of TIP Case", "मानब बेचबिखन घटनाको उद्देश्य"],
  ["tipCaseStatus", "TIP Case Status", "मानब बेचबिखन घटनाको अवस्था"],
  ["reasonForLeavingHome", "Reason for Leaving Home", "घर छोड्नुको कारण"],
  ["relationWithTrafficker", "Relation with Trafficker", "मानब तस्करसँगको सम्बन्ध"],
  ["recruitingFee", "Recruiting fee", "भर्ना शुल्क"],
  ["shelterServiceReceived", "Shelter Service Received", "आश्रय सेवा प्राप्त गरे / नगरेको"],
  ["nameOfServiceProvider", "Name of Service Providers", "सेवा प्रदायकहरूको नाम"],
  ["typeOfServiceProvider", "Type of Service Providers", "सेवा प्रदायकहरूको प्रकार"],
  ["typeOfServiceReceived", "Types of Service Received", " प्राप्त सेवाको प्रकार"],
  ["typeOfViolence", "Types of violence", "हिंसाको प्रकार"],
  ["referralServiceReceived", "Referral Service Received", "सिफारिस सेवा प्राप्त भए / नभएको"],
  ["typesOfReferralService", "Types of referral service", "सिफारिस सेवाको प्रकार"],
  ["caseOfReferral", "Case of referral", "सिफारिसको घटना"],
  ["caseStatus", "Case Status", "घटनाको स्थिति"],
  ["referredService", "Referred Service", "सिफारिस गरिइएको सेवा"],
  ["providerName", "Provider Name", "सेवा प्रदायकको नाम"],

  //others
  ["nameNep", "देवनागरी", "देवनागरी"],
  ["fNameNep", "पहिलो नाम", "पहिलो नाम"],
  ["mNameNep", "बीचको नाम", "बीचको नाम"],
  ["lNameNep", "थर", "थर"],
  ["nameEng", "Name", "Name"],
  ["fNameEng", "First Name", "First Name"],
  ["mNameEng", "Middle Name", "Middle Name"],
  ["lNameEng", "Last Name", "Last Name"],
  ["husbandWifeNameNep", "पति/पत्नीको नाम", "पति/पत्नीको नाम"],
  ["contactPersonNep", "सम्पर्क व्यक्ति", "सम्पर्क व्यक्ति"],
  ["noDataFound", "No matching records found", "कुनै पनि मिल्दो रेकर्ड फेला परेन"],

  //forget Password
  ["Enter your Email", "Enter your Email", "आफ्नो इमेल प्रविष्ट गर्नुहोस्"],
  ["Forgot Password", "Forgot Password", "पासवर्ड बिर्सनुभयो ?"],
  ["Reset Password", "Reset Password", "पासवर्ड रिसेट गर्नुहोस्"],
  ["backToLogin", "Back To Login", "पुन लगइनमा फर्कनुहोस्"],
  ["changePassword", "Change Password", "पासवर्ड परिवर्तन गर्नुहोस्"],
  ["Change Password", "Change Password", "पासवर्ड परिवर्तन गर्नुहोस्"],
  ["sendEmail", "Send Email", "इमेल पठाउनुहोस्"],

  ["cha", "Yes", "छ "],
  ["chaina", "No", "छैन "],
  ["yes", "Yes", "छ "],
  ["no", "No", "छैन "],

  ["oldIdExist", "Old Id Exist", "पुरानो परिचयपत्र छ?"],
  ["oldIdCardNo", "Old Id Card No.", "पुरानो परिचयपत्र नं"],
  ["oldIdCardImage", "Old Id Card Image", "पुरानो परिचयपत्रको फोटो"],
  ["module", "Module (English)", "मोड्युल (अंग्रेजी)"],
  ["moduleNep", "Module (Nepali)", "मोड्युल (नेपाली)"],
  ["Organization List", "Organization List", "कार्यालय सूची"],
  ["synchronizationModule", "Synchronization Module", "समायोजन मोड्युल"],
  ["Synchronization Module", "Synchronization Module", "समायोजन मोड्युल"],
  ["notification", "Notification", "सूचना"],
  ["synchronize", "Synchronize", "समायोजन गर्नुहोस्"],
  ["synchronizeData", "Synchronize Data", "तथ्याङ्क समायोजन गर्नुहोस्"],
  ["synchronizeDataOfModules", "Synchronize Data of the following modules", "निम्न मोड्युलहरूको तथ्याङ्क समायोजन गर्नुहोस्"],
  ["revertedDataOfModules", "Please check the data and resubmit", "कृपया तथ्याङ्क जाँच गर्नुहोस् र पुन: पेश गर्नुहोस्"],
   ["revertData", "Revert Data", "तथ्याङ्कलाई फर्काउनुहोस"],
  ["synchronizeNote", "If all the data inserted are correct, synchronize the data to get report",
    "यदि सम्मिलित गरिएका सबै तथ्याङ्क सही छन् भने, रिपोर्ट प्राप्त गर्नका निम्ति तथ्याङ्क समायोजन गर्नुहोस्"],
  ["revertNote", "If any data inserted are wrong, request local level user to insert the data again",
    "यदि सम्मिलित कुनै तथ्याङ्क गलत छन् भने, स्थानीय स्तरका प्रयोगकर्तालाई पुन: तथ्याङ्क हाल्न अनुरोध गर्नुहोस्"],
  ["needCorrection", "Please recheck the following individual details", "कृपया निम्न व्यक्तिगत विवरणहरू पुन: जाँच गर्नुहोस्"],
  ["verificationOfHomeDetails", "Please verify the following home's individual details", 
  "कृपया निम्न गृहको व्यक्तिगत विवरणहरू प्रमाणित गर्नुहोस्"],

  //Dashboard
  [
    "population",
    "Population",
    "जनसंख्या",
  ],
  [
    "sexualGenderMinorities",
    "Sexual And Gender minorities",
    "यौनिक तथा लैङ्गिक अल्पसंख्यक"
  ], [
    "birthCertificateissued",
    "Birth Certificate Issued (below 5 years)",
    "जन्म दर्ता प्रमाणपत्र जारी गरिएको (५ वर्षमुनिका)",
  ], [
    "citizenGettingSSA",
    "Citizens Getting SSA",
    "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने जेष्ठ नागरिकहरू",
  ], [
    "migrantLabour",
    "Migrant Labour",
    "रोजगारीका लागि बाहिर गएका",
  ],
  ["shelterHomeTIP", "Shelter Home", "आश्रय घर"],
  [
    "compRegUnder47Part1",
    "Complaints registered under Local Government Operation Act, 2074 Section 47 (1)",
    "स्थानीय सरकार सञ्चालन ऐन, २०७४ दफा ४७ (१) अन्तर्गत दर्ता भएका उजुरीहरू",
  ],
  [
    "totalNo",
    "Total number",
    "कुल संख्या",
  ],

  ["bhayeko", "Yes", "भएको"],
  ["nabhayeko", "No", "नभएको"],
  ["Personal Detail", "Personal Detail", "व्यक्तिगत विवरण"],
  ["Personal Detail List", "Personal Detail List", "व्यक्तिगत विवरण सूची"],
  ["personalDetails", "Personal Detail", "व्यक्तिगत विवरण"],
  ["oldAgeHomeDetails", "Senior Citizens Care Home Detail", "जेष्ठ नागरिक हेरचाह केन्द्रको विवरण"],
  ["homeDetails", "Home Detail", "गृहको विवरण"],
  ["personalDetailsList", "Personal Detail List", "व्यक्तिगत विवरण सूची"],
  ["provinceChildrenDetail", "Province Wise Children Detail", "प्रदेशगत बालबालिका विवरण"],
  ["districtChildrenDetail", "District Wise Children Detail", "जिल्लागत बालबालिका विवरण"],
  ["localLevelChildrenDetail", "Local level Wise Children Detail", "नगरपालिका / गाउँपालिकागत बालबालिका विवरण"],
  ["provinceWiseDetail", "Province Wise Detail", "प्रदेशगत विवरण"],
  ["districtWiseDetail", "District Wise Detail", "जिल्लागत विवरण"],
  ["localLevelWiseDetail", "Local level Wise Detail", "नगरपालिका / गाउँपालिकागत विवरण"],
  ["action", "Action", "कार्य"],
  ["boysPop", "Total Boys", "बालकहरूको संख्या"],
  ["girlsPop", "Total Girls", "बालिकाहरूको संख्या"],
  ["othersPop", "Others Total", "अन्यको संख्या"],
  ["boysBirthCertIssued", "Boys below 5 years Birth Certificate Issued", "५ वर्षमुनिका बालकहरूको जारी गरिएको जन्म दर्ता प्रमाणपत्र"],
  ["girlsBirthCertIssued", "Girls below 5 years Birth Certificate Issued", "५ वर्षमुनिका बालिकाहरूको जारी गरिएको जन्म दर्ता प्रमाणपत्र"],
  ["othersBirthCertIssued", "Others Birth Certificate Issued", "अन्यको जारी गरिएको जन्म दर्ता प्रमाणपत्र"],
  ["malePop", "Total Male", "पुरुषको संख्या"],
  ["femalePop", "Total Female", "महिलाको संख्या"],
  ["maleGettingSSA", "Male Citizens Getting SPA", "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने पुरुष"],
  ["femaleGettingSSA", "Female Citizens Getting SPA", "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने महिला"],
  ["othersGettingSSA", "Others Citizens Getting SPA", "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने अन्य"],
  ["childrenKa", "Children (Category Ka)", "बालबालिका (वर्ग क)"],
  ["adultKa", "Adult (Category Ka)", "वयस्क (वर्ग क)"],
  ["seniorCitKa", "Senior Citizens (Category Ka)", "जेष्ठ नागरिक (वर्ग क)"],
  ["childrenKha", "Children (Category Kha)", "बालबालिका (वर्ग ख)"],
  ["adultKha", "Adult (Category Kha)", "वयस्क (वर्ग ख)"],
  ["seniorCitKha", "Senior Citizens (Category Kha)", "जेष्ठ नागरिक (वर्ग ख)"],
  ["childrenGa", "Children (Category Ga)", "बालबालिका (वर्ग ग)"],
  ["adultGa", "Adult (Category Ga)", "वयस्क (वर्ग ग)"],
  ["seniorCitGa", "Senior Citizens (Category Ga)", "जेष्ठ नागरिक (वर्ग ग)"],
  ["childrenGha", "Children (Category Gha)", "बालबालिका (वर्ग घ)"],
  ["adultGha", "Adult (Category Gha)", "वयस्क (वर्ग घ)"],
  ["seniorCitGha", "Senior Citizens (Category Gha)", "जेष्ठ नागरिक (वर्ग घ)"],
  ["femaleRescued", "Female TIP Survivors Rescued", "उद्धार गरिएको आश्रित महिला"],
  ["femaleIntercepted", "Female TIP Survivors Intercepted", "रोकिएको आश्रित महिला"],
  ["femaleSuspected", "Female TIP Survivors Suspected", "संदिग्ध आश्रित महिला"],
  ["othersRescued", "Others TIP Survivors Rescued", "उद्धार गरिएको आश्रित अन्य"],
  ["othersIntercepted", "Others TIP Survivors Intercepted", "रोकिएको आश्रित अन्य"],
  ["othersSuspected", "Others TIP Survivors Suspected", "संदिग्ध आश्रित अन्य"],
  [
    "tipCaseProsecutedByCourt",
    "TIP case prosecuted by the court",
    "अदालतबाट चलाइका मानव बेचबिखन मुद्दा",
  ],
  [
    "tipCaseDecidedByCourt",
    "TIP case decided by the court",
    "अदालतबाट फैसला गरिएका मानव बेचबिखन मुद्दा"
  ],
  [
    "gbvCaseProsecutedByCourt",
    "GBV case prosecuted by the court",
    "अदालतबाट चलाइका लैङ्गिक हिंसाका मुद्दा",
  ],
  [
    "gbvCaseDecidedByCourt",
    "GBV case decided by the court",
    "अदालतबाट फैसला गरिएका लैङ्गिक हिंसाका मुद्दा"
  ],

  //Fiscal year१ २ ३ ४ ५ ६ ७ ८ ९ 
  ["2065/66", "2065/66", "२०६५/६६"],
  ["2066/67", "2066/67", "२०६६/६७"],
  ["2067/68", "2067/68", "२०६७/६८"],
  ["2068/69", "2068/69", "२०६८/६९"],
  ["2069/70", "2069/70", "२०६९/७०"],
  ["2070/71", "2070/71", "२०७०/७१"],
  ["2071/72", "2071/72", "२०७१/७२"],
  ["2072/73", "2072/73", "२०७२/७३"],
  ["2073/74", "2073/74", "२०७३/७४"],
  ["2074/75", "2074/75", "२०७४/७५"],
  ["2075/76", "2075/76", "२०७५/७६"],
  ["2076/77", "2076/77", "२०७६/७७"],
  ["2077/78", "2077/78", "२०७७/७८"],
  ["2078/79", "2021/22", "२०७८/७९"],
  ["2079/80", "2022/23", "२०७९/८०"],
  ["2080/81", "2023/24", "२०८०/८१"],
  ["2081/82", "2024/25", "२०८१/८२"],

  //Quarter
  ["ShrawanAshwin", "Shrawan-Ashwin", "श्रावण-आश्विन"],
  ["KartikPoush", "Kartik-Poush", "कार्तिक-पौष"],
  ["MaghChaitra", "Magh-Chaitra", "माघ-चैत्र"],
  ["BaishakAshad", "Baishak-Ashad", "वैशाख-आषाढ़"],

  //Occupation
  ["agriculture", "Agriculture", "कृषि"],
  ["domesticWorkCare", "Household chores", "घरेलु काम र हेरचाह"],
  ["manufacturingIndustry", "Manufacturing/Industry", "निर्माण/उद्योग"],
  ["hospitalityEntertainment", "Hospitality and Entertainment", "आतिथ्य र मनोरन्जन"],
  ["vocationalSkillBasedIndustry", "Vocational/Skill based Industry", "व्यावसायिक/सीपमा आधारित उद्योग"],
  ["gloveMaker", "Glove maker", "पन्जा बनाउने"],
  ["security", "Security", "सुरक्षा गार्ड"],
  ["forklift", "Forklift operator", "फोर्कलिफ्ट अपरेटर"],
  ["furniture", "Furniture manufacturing worker", "फर्निचर बनाउने कामदार"],
  ["electrician", "Electrician", "इलेक्ट्रिशियन"],
  ["cook", "Cook", "कुक"],
  ["waiter", "Waiter", "वेटर"],
  ["domesticLabor", "Domestic labor", "घरेलु कामदार"],
  ["machineOperator", "Machine operator", "मेसिन अपरेटर"],
  ["parkingBoy", "Parking boy", "पार्किङ बोइ"],

  ["householdChores", "Household chores", "घरेलु काम र हेरचाह"],
  ["informalSectorWorker", "Informal sector worker", "अनौपचारिक क्षेत्रको कामदार"],
  ["entertainmentSectorWorker", "Entertainment sector worker", "आतिथ्य र मनोरन्जन क्षेत्रको कामदार"],
  ["unskilledLabor", "Unskilled labor", "अदक्ष कामदार"],
  ["semiSkilledLabor", "Semi-skilled labor", "अर्धदक्ष कामदार"],
  ["skilledLabor", "Skilled labor", "दक्ष कामदार"],
  ["govJobHolder", "Government Job Holder", "सरकारी जागिरे"],
  ["privateJobHolder", "Private Job Holder", "निजी क्षेत्रको जागिरे"],
  ["selfEmployed", "Self-employed", "स्वरोजगार "],

  //Education
  ["primaryTillGrade5", "Primary (till Grade 5)", "प्राथमिक (कक्षा ५ सम्म)"],
  ["basicTillGrade8", "Basic (Grade 5 to Grade 8)", "आधारभूत (कक्षा ५ देखि कक्षा ८ सम्म)"],
  ["secondaryTillGrade12", "Secondary (Grade 9 to Grade 12)", "माध्यमिक (कक्षा ९ देखि कक्षा १२ सम्म)"],
  ["higherSecondaryTillGrade12", "Higher Secondary (Grade 9 to Grade 12)", "उच्च माध्यमिक (कक्षा ९ देखि कक्षा १२ सम्म)"],
  ["bachelor", "Bachelor", "स्नातक"],
  ["masters", "Masters", "स्नाकोत्तर"],
  ["phd", "Doctor of Philosophy (PhD)", "विध्यावारिधि (पीएचडी)"],
  ["mphil", "Master of Philosophy (M.Phil)", "दर्शनाचार्य (एमफिल)"],
  ["literate", "Literate (with no formal education)", "साक्षार (औपचारिक शिक्षा बिनाको)"],
  ["illiterate", "Illiterate", "निरक्षर"],

  //TIPCaseForm
  //AddressOfTIP
  ["crossCountryIndia", "Cross country (India)", "सीमानाकामा हुने मानव बेचबिखन तथा ओसारपसार  (भारत)"],
  ["crossCountryChina", "Cross country (China)", "सीमानाकामा हुने मानव बेचबिखन तथा ओसारपसार  (चीन)"],
  ["crossCountryOthers", "Cross country (Others)", "अन्तरदेशीय (अन्य)"],
  ["otherCountry", "Others Countries", "अन्य देशहरू"],

  //ActOfTIP
  ["recruitment", "Recruitment", "भर्ती"],
  ["transportation", "Transportation", "ओसारपसार"],
  ["transfer", "Transfer", "स्थानान्तरण"],
  ["harboring", "Harboring", "लुकाउने"],
  ["tipReceived", "Received", "राखेको"],
  ["harboringOrReceipt", "Harbouring or receipt of person", "आश्रय दिने वा प्राप्त गर्ने कार्य"],
  ["sellOrBuyPerson", "Sell or buy person for any purposes", "कुनैपनि उद्देश्यले मानिस बेच्ने वा किन्ने कार्य"],
  ["forceIntoProstitution", "Forced someone into prostitution", "कुनैपनि प्रकारको फाइदा लिई वा नलिई वेश्याबृत्तिमा लगाउने कार्य"],
  ["removeOrgansExceptDeterminedByLaw", "To remove human organs except otherwise determined by law",
    "प्रचलित कानुनबमोजिम बाहेक मानिसको अंगहरू झिक्ने कार्य"],
  ["engagedInProstitution", "To engaged in prostitution", "वेश्यागमन गर्ने"],


  //MeansOfTIP
  ["threatOrForce", "Threat or use of force or other forms of coercion", "धम्की, त्रास वा बल प्रयोग वा अन्य प्रकारको करकाप"],
  ["abduction", "Abduction", "अपहरण"],
  ["fraud", "Fraud", "ठगी"],
  ["deception", "Deception", "धोखा/झुक्याइ"],
  ["enticement", "Enticement", "प्रलोभनमा पारी"],
  ["hostage", "Hostage", "शरीर बन्धक राखी"],
  ["abuseOfPowerOrPosition", "The abuse of power or of a position of vulnerability", "अख्तियारको दुरूपयोग गरी वा नाजुक/जोखिम अवस्थाको फाईदा लिई"],
  ["givingPaymentsToAchieveConsent", "Giving or receiving of payments or benefits to achieve the consent of a person having control over another person", "भुक्तानी वा लाभको लेनदेनका लागि एक व्यक्तिको सहमतिमा अर्को व्यक्तिमाथि नियन्त्रण गर्ने"],
  ["paymentsDebt", "Payments/Debt", "भुक्तानी/ऋण"],
  ["threat", "Threat", "धम्क्याउनु "],
  ["coercion", "Coercion", "जबरजस्ती"],
  ["abuseOfPower", "Abuse of Power", "शक्तिको दुरुपयोग"],
  ["abuseOfPositionOfVulnerability", "Abuse of Position of vulnerability", "जोखिमको दुरुपयोग"],

  //PurposeOfTIP
  ["prostitutionExploitation",
    "The exploitation of the prostitution of others or other forms of sexual exploitation",
    "वेश्यावृत्तिमाथिको शोषण वा अन्य स्वरूपका यौन शोषण"],
  ["forcedLaborServices", "Forced labour or services", "जबरजस्ती श्रम वा सेवामा लगाउने कार्य"],
  ["slaveryPractices", "Slavery or practices similar to slavery", "दासता वा दासता सरहका अभ्यास"],
  ["servitude", "Servitude or the removal of organs", "बन्धक बनाउने वा अंगहरू झिक्ने कार्य"],


  ["internalLaborTrafficking", "Internal Labor Trafficking", "आन्तरिक रोजगारी"],
  ["foreignLaborTrafficking", "Foreign Labor Trafficking", "वैदेशिक रोजगारी"],
  ["sexTrafficking", "Sex Trafficking", "यौन तस्करी"],
  ["labourTrafficking", "Labour Trafficking", "श्रमको दौरानमा मानव बेचबिखन"],
  ["organHarvestingTrafficking", "Organ (Harvesting) Trafficking", "अंग झिक्ने बेचबिखन"],
  ["servileMarriage", "Servile Marriage", "यौन दासी "],
  ["internalLaborTraffickingAndForeignLaborTrafficking",
    "Internal Labor Trafficking and Foreign Labor Trafficking",
    "आन्तरिक र वैदेशिक रोजगारीमा हुने मानव बेचबिखन तथा ओसारपसार"],
  ["internalLaborTraffickingAndSexTrafficking",
    "Internal Labor Trafficking and Sex Trafficking",
    "आन्तरिक रोजगारी र यौन शोषणका लागि हुने मानव बेचबिखन तथा ओसारपसार"],
  ["internalLaborTraffickingAndOrganHarvestingTrafficking",
    "Internal Labor Trafficking and Organ (Harvesting) Trafficking",
    "आन्तरिक रोजगारी र अंग झिक्नका लागि हुने मानव बेचबिखन तथा ओसारपसार"],
  ["internalLaborTraffickingAndServileMarriage",
    "Internal Labor Trafficking and Servile Marriage",
    "आन्तरिक रोजगारी र यौन दासीका लागि हुने मानव बेचबिखन तथा ओसारपसार"],
  ["foreignLaborTraffickingAndSexTrafficking",
    "Foreign Labor Trafficking and Sex Trafficking",
    "वैदेशिक रोजगारी र यौनका लागि हुने मानव बेचबिखन तथा ओसारपसार"],
  ["foreignLaborTraffickingAndOrganHarvestingTrafficking",
    "Foreign Labor Trafficking and Organ (Harvesting) Trafficking",
    "वैदेशिक रोजगारी र अंग झिक्नका लागि हुने मानव बेचबिखन तथा ओसारपसार"],
  ["foreignLaborTraffickingAndServileMarriage",
    "Foreign Labor Trafficking and Servile Marriage",
    "वैदेशिक रोजगारी र यौन दासीका लागि हुने मानव बेचबिखन तथा ओसारपसार"],
  ["sexTraffickingAndOrganHarvestingTrafficking",
    "Sex Trafficking and Organ (Harvesting) Trafficking",
    "यौन शोषण र अंग बेचबिखनका लागि मानव बेचबिखन तथा ओसारपसार "],
  ["sexTraffickingAndServileMarriage",
    "Sex Trafficking and Servile Marriage",
    "यौन शोषण र यौन दासीका लागि मानव बेचबिखन तथा ओसारपसार"],
  ["foreignLabor", "Foreign Labor", "वैदेशिक श्रम"],
  ["sexAndOrganTrafficking", "Sex and Organ Trafficking",
    "यौन तथा अंग झिक्ने (बेचबिखन)"],
  ["interceptedSuspected", "Intercepted (Suspected)", "रोकिएको (शङ्कास्पद)"],
  ["identifiedByCsoWorkerSuspected", "Identified by CSO worker (Suspected)", "नागरिक संस्थाहरू कार्यकर्ताद्वारा पहिचान (शङ्कास्पद)"],
  ["rescuedSuspected", "Rescued (Suspected)", "उद्धार गरिएको (शङ्कास्पद)"],
  ["repatriatedSuspected", "Repatriated (Suspected)", "पुनस्थापित (शङ्कास्पद) "],
  ["underPoliceInvestigationSuspected", "Under Police Investigation (Suspected)", "प्रहरी अनुसन्धान अन्तर्गत (शङ्कास्पद)"],
  ["reintegratedFamilyReunitedSuspected", "Reintegrated/Family Reunited (Suspected)", "पुनर्मिलन/परिवार पुनर्मिलन  (शङ्कास्पद)"],
  ["policeInvestigationCompletedConfirmed", "Police Investigation Completed (Confirmed)", "प्रहरी अनुसन्धान सम्पन्न (पुष्टि)"],
  ["reintegratedFamilyReunitedConfirmed", "Reintegrated/Family Reunited (Confirmed)", "पुनर्मिलन/परिवार पुनर्मिलन (पुष्टि)"],
  ["selfIdentifiedConfirmed", "Self-Identified (Confirmed)", "स्व-पहिचान (पुष्टि)"],
  ["caseRegisteredAtCourtConfirmed", "Case registered at Court (Confirmed)", "अदालतमा मुद्दा दर्ता (पुष्टि)"],
  ["prospectOfAJob", "Prospect of a Job", "जागिरको सम्भावना"],
  ["escapeThreatViolenceAbuse", "Escape Threat/Violence/Abuse", "धम्की/हिंसा/दुर्व्यवहारबाट उम्किन"],
  ["coercionForceDebtBondage", "Coercion/Force/Debt bondage", "जबरजस्ती / बल / ऋण बन्धक"],
  ["promiseOfBetterLifestyle", "Promise of better lifestyle", "राम्रो जीवनशैलीको वाचा"],
  ["completeStranger", "Complete Stranger", "अपरिचित"],
  ["friendsOfFriendsRelatives", "Friends of friends/Relatives", "साथीको साथी/नातेदार"],
  ["friendsOrRelatives", "Friends or Relatives", "साथी वा नातेदार"],
  ["veryCloseRelatives", "Very Close Relatives", "एकदमै नजिकका नातेदार"],
  ["employer", "Employer", "रोजगारीदाता"],
  ["neighbour", "Neighbour", "छिमेकी"],
  ["police", "Police", "प्रहरी"],
  ["governmentAttorney", "Government Attorney", "सरकारी वकिल"],

  ["csoShelter", "CSO/Shelter", "नागरिक संस्थाहरू/आश्रय"],
  ["tribunals", "Tribunals", "न्यायाधीकारणहरू"],
  ["court", "Court", "अदालत"],
  ["lccht", "lCCHT", "मानव बेचबिखन तथा ओसारपसार नियन्त्रणका लागि स्थानीय समिति"],
  ["ccht", "CCHT", "मानव बेचबिखन तथा ओसारपसार नियन्त्रणका लागि समिति"],
  ["nccht", "NCCHT", "मानव बेचबिखन तथा ओसारपसार नियन्त्रणका लागि राष्ट्रिय समिति"],
  ["pccht", "PCCHT", "मानव बेचबिखन तथा ओसारपसार नियन्त्रणका लागि प्रदेश समिति"],
  ["nhrc", "NHRC", "राष्ट्रिय मानव अधिकार आयोग"],
  ["federalMinistriesDepartments", "Federal Ministries/Departments", "संघीय मन्त्रालय/विभागहरू"],
  ["provincialMinistriesDepartments", "Provincial Ministries/Departments", "प्रादेशिक मन्त्रालय/विभागहरू"],
  ["localGovernmentDepartmentUnits", "Local Government Department/Units", "स्थानीय सरकार विभाग/इकाइहरू"],
  ["privateSectorBusiness", "Private Sector Business", "निजी क्षेत्रको व्यापार"],
  ["legalAndParalegal", "Legal and Paralegal", "कानूनी र प्यारालिगल"],
  ["shelterFoodClothingAccommodation", "Shelter (Food/Clothing/Accommodation)", "आश्रय (खाना / लुगा / आवास)"],
  ["livelihood", "Livelihood", "जीविकोपार्जन"],
  ["psychoSocialCounseling", "Psycho-social Counseling", "मनोसामाजिक परामर्श"],
  ["medical", "Medical", "चिकित्सा"],
  ["education", "Education", "शिक्षा"],
  ["transportation", "Transportation", "यातायात"],
  ["familyReunion", "Family Reunion", "पारिवारिक पुनर्मिलन"],

  //typesofviolence
  ["homeBased", "Home based violence", "घरेलु हिंसा"],
  ["publicOfficesOrPlacesBased", "Public offices or places based exploitation", "सार्वजनिक कार्यालय वा स्थानहरूमा हुने दुर्व्यवहार"],
  ["schoolBased", "School based exploitation", "विद्यालयमा हुने दुर्व्यवहार"],
  ["officeBased", "Office based exploitation", "कार्यालयमा हुने दुर्व्यवहार"],
  ["workPlaceBased", "Work place based exploitation", "कार्यस्थलमा हुने दुर्व्यवहार"],

  ["jobPlacement", "Job-placement", "रोजगारी - नियुक्ति"],
  ["legalAid", "Legal-aid", "कानूनी सहायता"],
  ["skillTraining", "Skill training", "सीपमूलक तालिम"],
  ["rescue", "Rescue", "उद्धार गर्नु"],
  ["repartiation", "Repartiation", "स्वदेश फिर्ता"],
  ["reIntegration", "Re-integration", "पुन: एकीकरण"],
  ["referred", "Referred", "प्रेषण (रिफर) गरिएको"],
  ["firRegisteredInPolice", "FIR registered in police", "प्रहरीमा FIR दर्ता"],
  ["caseFiledInTheCourt", "Case filed in the court", "अदालतमा मुद्दा दायर"],
  ["caseDecided", "Case decided", "मुद्दा फैसला भएको "],
  ["compensationReceived", "Compensation received", "क्षतिपूर्ति भएको"],
  ["food", "Food", "खाना"],
  ["accommodation", "Accommodation", "आवास"],
  ["forcedLabour", "Forced Labour", "बधुवा मजदुर"],
  ["childLabour", "Child Labour", "बाल मजदुर"],
  ["domesticViolence", "Domestic Violence", "घरेलु हिंसा"],
  ["rape", "Rape", "बलात्कार"],
  ["streetChild", "Street Child/Beggar", "सडक बालबालिका/भिखारी"],
  ["health", "Health", "स्वास्थ्य"],
  ["travelCostToShelter", "Travel cost to Shelter", "आश्रयको लागि यात्रा लागत"],
  ["travelCostToHome", "Travel cost to Home", "घर यात्रा लागत"],

  ["FIRReported", "FIR Reported", "जाहेरी (FIR) रिपोर्ट गरियो"],
  ["caseInCourt", "Case In Court", "अदालतमा मुद्दा"],
  ["courtVerdictProvided", "Court verdict provided", "फैसला भएको"],
  ["compensationReceived", "Compensation received after court verdict", "फैसला भएको र क्षतिपूर्ति भएको"],

  ["Admitted by family members",
    "Admitted by family members", "परिवारका सदस्यहरूले भर्ना गरेको"],
  ["Self admitted", "Self admitted", "स्वयम् भर्ना भएको"],
  ["Self", "Self", "स्वयम"],
  ["Government", "Government", "सरकारी"],
  ["Community", "Community", "सामुदायिक"],
  ["NGO", "Non-Governmental Organisation", "गैर-सरकारी संस्था"],
  ["Development Organization",
    "Development Organization", "विकास संगठन"],
  ["UN", "UN", "संयुक्त राष्ट्र संघ"],
  ["Family", "Family", "परिवार"],
  ["relativesPresent", "Present", "छ"],
  ["abandoned", "Abandoned", "त्यागिएको"],
  ["noFamilyAlive", "No family alive", "परिवार जीवित नरहेको"],

  ["remarks", "Remarks for revert", "फिर्ता पठाउनुको कारण"],
  ["revertModule", "Revert Module", "मोड्युललाई फर्काउनुहोस"],
  ["Revert Module", "Revert Module", "मोड्युललाई फर्काउनुहोस"],

  //SocialServiceCreation
  ["counselling", "Counselling", "परामर्श"],
  ["medicalFacilities", "Medical Facilities", "स्वास्थ उपचार"],
  ["lifeSkill", "Life Skill Development", "सीप विकास"],
  ["governmentFund", "Government Fund", "सरकारी कोष"],
  ["communityBased", "Community Based", "समुदायबाट संचालित"],
  ["buySellAssets", "Buy Sell Assets", "चल/अचल सम्पत्तिको बेचबिखन"],

  ["private", "Private", "निजी"],
  //Reports
  ["sn", "S.N.", "क्र.सं."],
  ["details", "Details", "विवरण"],
  ["1", "1", "१"],
  ["2", "2", "२"],
  ["3", "3", "३"],
  ["4", "4", "४"],
  ["5", "5", "५"],
  ["6", "6", "६"],
  ["7", "7", "७"],
  ["8", "8", "८"],
  ["9", "9", "९"],
  ["10", "10", "१०"],
  ["11", "11", "११"],
  ["12", "12", "१२"],
  ["13", "13", "१३"],
  ["14", "14", "१४"],
  ["15", "15", "१५"],
  ["16", "16", "१६"],
  ["17", "17", "१७"],
  ["18", "18", "१८"],
  ["19", "19", "१९"],
  ["20", "20", "२०"],
  ["21", "21", "२१"],
  ["fromDate", "From Date", "मितिदेखि"],
  ["toDate", "To Date", "मितिसम्म"],

  //remittance
  ["hundi", "Hundi", "हुन्डी"],
  ["bankTransfer", "Bank transfer", "ब्याङ्कमार्फत पठाउने"],
  ["globalIME", "Global IME", "आइ एम इ"],
  ["westernUnion", "Western Union", "वेस्टर्न युनियन"],

  //Validation
  ["selectFiscalYear", "Please select Fiscal Year.", "कृपया आर्थिक वर्ष छनोट गर्नुहोस् |"],
  ["selectFiscalYearQuarter", "Please select Fiscal Year and Quarter.", "कृपया आर्थिक वर्ष र त्रैमासिक छनोट गर्नुहोस् |"],

  //Complaint registration added later
  [
    "crToWomenAndChildren",
    "No. of complaints related to women and children",
    "महिला तथा बालबालिका सम्बन्धी उजुरीको संख्या",
  ],
  [
    "crToWomenAndChildrenDesc",
    "Number of registered complained related to women and children by the Municipality",
    "महिला तथा बालबालिका सम्बन्धी उजुरीको संख्या",
  ],
  [
    "crMiscellaneous",
    "No. of other complaints",
    "अन्य उजुरीको संख्या",
  ], [
    "crMiscellaneousDesc",
    "Number of other complaints",
    "अन्य उजुरीको संख्या",
  ],

  ["polygamy", "Polygamy", "बहुविवाह"],
  ["forcedAction", "Forced Action", "जबरजस्ती करणी"],
  ["forcedActionIndustry", "Forced Action Industry", "जबरजस्ती करणी उद्योग"],
  ["unnaturalIntercourse", "Unnatural intercourse", "अप्राकृतिक मैथुन"],
  ["childSexualAbuse", "Child sexual abuse", "बालयौन दुराचार"],
  ["sexualAbuse", "Sexual abuse", "यौन दुर्व्यवहार "],
  ["accusedOfWitchcraft", "Accused of witchcraft", "बोक्सीको आरोप"],

  ["tipHumanOrganTransplantation", "TIP and Human organ transplantation", "मानव बेचबिखन तथा मानव अंग प्रत्यारोपण"],
  ["forcedTip", "Forced TIP", "मानव बेचबिखन तथा जबरजस्ती करणी"],
  ["beatenAndMiscarried", "Beaten and miscarried", "कुटपिट गरी गर्भ तुहाएको"],
  ["throwingAliveChild", "Throwing Alive Child", "जिउदो वच्चा जन्माई फालेको"],
  ["kidnappingAndRape", "Kidnapping and rape", "अपहरण तथा जबरजस्ती करणी"],
  ["dutyByForce", "Duty by force", "जबरजस्ती करणी गरी कर्तब्य"],
  ["abductionAndDuty", "Abduction and duty", "अपहरण तथा कर्तब्य"],

  ["lifeRelated", "Related to life", "ज्यानसम्बन्धी"],
  ["suicideRelated", "Related to suicide", "आत्महत्यासम्बन्धी"],
  ["theftRelated", "Related to theft", "चोरीसम्बन्धी"],
  ["concerningOrganizedEconomicCrime", "Concerning organized and economic crime", "सँगठित तथा आर्थिक अपराधसम्बन्धी"],
  ["socialCrime", "Social crime", "सामाजिक अपराध"],
  ["womenChildrenRelated", "Related to women and children", "महिला तथा बालबालिकासम्बन्धी"],
  ["transportRelated", "Related to transport", "सवारीसम्बन्धी"],
  ["miscellaneous", "Miscellaneous", "विविध"],

  //Source
  ["localLevelProfile", "Local Level Profile", "स्थानीय तह प्रोफाइल"],
  ["censusData", "Census Data", "जनगणना तथ्याङ्क"],
  ["civilRegistrationSection", "Civil Registration", "पञ्जीकरण"],
  ["womenChildrenServiceCenter", "Women children service center (Nepal Police)", "महिला बालबालिका सेवा केन्द्र (नेपाल प्रहरी)"],
  ["llProvinceSurvey", "Local Level/Province Survey", "स्थानीय तह/प्रदेश सर्वेक्षण"],
  ["attorneyGeneralOffice", "Office of the Attorney General", "महान्यायाधिवक्ताको कार्यालय"],
  ["publicProsecutorOffice", "District Public Prosecutor Office", "जिल्ला सरकारी वकिलको कार्यालय"],

  ["actions", "Actions", "छान्नुहोस्"],

  //Notes
  ["singleWomenReceivingSpaNote",
    "Note: Number should be less than or equal to the no. of women entered above.",
    "नोट: माथि भरिएको महिलाको संख्याभन्दा कम वा बराबर हुनुपर्छ |"],
  ["seniorCitizenGettingSpaNote",
    "Note: Number should be less than or equal to the no. of senior citizens entered above.",
    "नोट: माथि भरिएको जेष्ठ नागरिकको संख्याभन्दा कम वा बराबर हुनुपर्छ |"],
  ["childrenNote",
    "Note: Number should be less than or equal to the no. of children entered above.",
    "नोट: माथि भरिएको बालबालिकाको संख्याभन्दा कम वा बराबर हुनुपर्छ |"],
  ["disabledCategoryKaNote",
    "Note: Number should be less than or equal to the no. of people with disability(category-Ka) entered above.",
    "नोट: माथि भरिएको पूर्ण अशक्त अपाङ्गता भएका व्यक्तिको संख्या (वर्ग क) भन्दा कम वा बराबर हुनुपर्छ |"],
  ["disabledCategoryKhaNote",
    "Note: Number should be less than or equal to the no. of people with disability(category-Kha) entered above.",
    "नोट: माथि भरिएको अति अशक्त अपाङ्गता भएका व्यक्तिको संख्या (वर्ग ख) भन्दा कम वा बराबर हुनुपर्छ |"],
  ["disabledCategoryGaNote",
    "Note: Number should be less than or equal to the no. of people with disability(category-Ga) entered above.",
    "नोट: माथि भरिएको मध्यम अपाङ्गता भएका व्यक्तिको संख्या (वर्ग ग) भन्दा कम वा बराबर हुनुपर्छ  |"],
  ["disabledCategoryGhaNote",
    "Note: Number should be less than or equal to the no. of people with disability(category-Gha) entered above.",
    "नोट: माथि भरिएको अपाङ्गता भएका व्यक्तिको संख्या (वर्ग घ) भन्दा कम वा बराबर हुनुपर्छ |"],
  ["disabledCategoryTotalNote",
    "Note: Number should be less than or equal to the no. of disabled peopleentered above.",
    "नोट: माथि भरिएको अपाङ्गता भएका व्यक्तिको संख्याभन्दा कम वा बराबर हुनुपर्छ |"],
  ["photoNote",
    "Note: Size 300X350, jpeg, png, jpg formats, Max: 1MB",
    "नोट: आकार 300X350, jpeg, png, jpg formats, Max: 1MB"],
  ["signatureNote",
    "Note: Size 140 X 60 px, jpeg, png, jpg formats, Max: 100KB",
    "नोट: आकार 140 X 60 px, jpeg, png, jpg formats, Max: 100KB"],
  ["fileNote",
    "Note: Max: 1MB",
    "नोट: Max: 1MB"],
  ["dalitEthnicityNote",
  "Note: Only Karnali province local levels are supposed to fill the above 'Dalit' field under the 'Ethnicity' category.",
  "नोट: कर्णाली प्रदेशका स्थानीय तहले मात्रै माथिको 'दलित' क्षेत्र 'जातीय' वर्गमा भर्नुपर्ने हुन्छ।"],
  ["passwordUsernameNote",
  "Note: Password will be the same as the username.",
  "नोट: पासवर्ड र प्रयोगकर्ता नाम एउतै हुनेछ।"],

  //Feedback
  ["Feedback", "Feedback", "प्रतिक्रिया"],
  ["Feedback List", "Feedback List", "प्रतिक्रिया सूची"],
  ["previousFeedback", "Your Previous Feedback", "तपाईंको अघिल्लो प्रतिक्रिया"],
  ["feedbackData",
    "If you have any problem or want to give any suggestion/feedback, please write below :",
    "केही समस्या वा कुनै सुझाव / प्रतिक्रिया छ भने तल लेख्नुहोस् :"],

  //FAQs
  ["question1",
    "Where is synchronization and revert module in the sidebar?",
    "साइडबारमा समायोजन र रिभर्ट मोड्युल कहाँ छ? "],
  ["answer1",
    "Synchronization and revert module is inside securtiy menu of sidebar.",
    "समायोजन र रिभर्ट मोड्युल साइडबारको सुरक्षा मेनुभित्र छ।"],
  ["question2",
    "What is the use of 'save' and 'submit' button in the indicator form?",
    "सूचक फर्ममा 'संरक्षण गर्नुहोस्' र 'पेश गर्नुहोस्' बटनको प्रयोग के हो?"],
  ["answer2",
    "Save button is used to save partially filled data and submit button is used to store completely finalised data.",
    "'संरक्षण गर्नुहोस्' बटन आंशिक रूपमा भरिएको तथ्याङ्क संरक्षण गर्न प्रयोग गरिन्छ र 'पेश गर्नुहोस्' बटन पूर्ण रूपमा भरिएको अन्तिम तथ्याङ्क संरक्षण गर्न प्रयोग गरिन्छ।"],
  ["question3",
    "What should the Local Level user do when they mistakenly click the 'submit button' before inserting the completed data?",
    "स्थानीय स्तरका प्रयोगकर्ताले पूरा तथ्याङ्क हाल्नु अघि गल्तीले 'पेश गर्नुहोस्' बटनमा क्लिक गर्दा के गर्नुपर्छ?"],
  ["answer3",
    "Local level user can request local level verifier user to revert the module so that the Local Level user can be able to edit the data again.",
    "स्थानीय स्तरका प्रयोगकर्ताले स्थानीय स्तर प्रमाणीकरण प्रयोगकर्तालाई मोड्युललाई फिर्ता पठाउन अनुरोध गर्न सक्नुहुन्छ ताकि स्थानीय स्तरका प्रयोगकर्ताले फेरि तथ्याङ्क सम्पादन गर्न सक्नेछन् |"],
  ["question4",
    "What happens when user click submit button in indicator form?",
    "प्रयोगकर्ताले सूचक फर्ममा 'पेश गर्नुहोस्' क्लिक गर्दा के हुन्छ?"],
  ["answer4",
    "Submit button is used to store completely finalised data, so when users click the submit button they aren't allowed to edit the data again.",
    "'पेश गर्नुहोस्' भरिएको अन्तिम तथ्याङ्क संरक्षण गर्न प्रयोग गरिन्छ, त्यसैले जब प्रयोगकर्ताहरूले 'पेश गर्नुहोस्' बटन क्लिक गर्छन् उनीहरूसँग फेरि तथ्याङ्क सम्पादन गर्न अनुमति हुँदैन|"],
  ["question5",
    "Why users cannot see the submited data in Report?",
    "किन प्रयोगकर्ताहरूले रिपोर्टमा पेश गरिएको तथ्याङ्क देख्न सक्दैनन्?"],
  ["answer5",
    "Users are able to see the report of submitted data only when Local Level Verifier user synchronize the data from synchronization module.",
    "स्थानीय स्तर प्रमाणीकरणकर्ता प्रयोगकर्ताले समायोजन मोड्युलबाट तथ्याङ्क समायोजन गर्दा मात्र प्रयोगकर्ताहरूले पेश गरिएको तथ्याङ्कको रिपोर्ट हेर्न सक्षम हुन्छन्।"],

  //New module menu
  ["Human Trafficking And Transportation", "Human Trafficking and Transportation", "मानव बेचबिखन तथा ओसारपसार"],
  ["TIP Case Registration", "TIP Case Registration", "दर्ता भएका मानब बेचबिखनका मुद्दाहरू"],
  ["Possible TIP Case Registration", "Possible/TIP Case Registration", "सम्भावित/मानब बेचबिखनका घटना"],
  ["FIR/TIP Case Registration", "First Information Report (FIR)/TIP Case Registration", "जाहेरी दरखास्तसम्बन्धी जानकारी/मानब बेचबिखनका मुद्दा दर्ता"],
  ["Prosecution", "Prosecution", "अभियोजन"],
  ["Child Care Home", "Child Care Home", "बाल सेवा गृह"],
  ["Gender Based Violence", "Gender Based Violence Cases", "लैङ्गिक हिंसाका घटनाहरू"],
  ["Judicial Committee", "Judicial Committee", "न्यायिक समिति"],

  //Fir Tip Registration
  ["casesRegistered", "Total number of cases registered", "दर्ता भएका मुद्दाहरूका कुल संख्या "],
  ["casesRegisteredByDistrict", "Total cases registered by district ", "जिल्लागत रूपमा दर्ता भएका मुद्दाहरू"],
  ["victimsSurvivorsInformation", "Victims/Survivors Information", "पीडित/प्रभावितहरूको विवरण"],
  ["perpetratorInformation", "Perpetrator Information", "पीडकहरूको विवरण"],
  ["minorUnder18", "Minor (under 18 years)", "नाबालक (१८ वर्षभन्दा कम)"],
  ["adultAbove18", "Adult (18 or above)", "वयस्क (१८ वा १८ वर्षभन्दा माथि)"],

  //Prosecution
  ["casesProsecuted", "Total number of cases prosecuted", "दर्ता भएका मुद्दाहरूका कुल संख्या "],
  ["casesProsecutedByDistrict", "Total cases prosecuted by district ", "जिल्लागत रूपमा दर्ता भएका मुद्दाहरू"],

  //GBVModule-Judicial Comittee
  ["Gender Based Violence Cases", "Gender Based Violence Cases", "लैङ्गिक हिंसाका घटनाहरू"],
  ["TotalGBVregistered", "Total number of GBV cases registered in Judicial Committee", "न्यायिक समितिमा दर्ता भएका लैङ्गिक हिंसाका कुल घटनाहरू"],
  ["casesAtShelterHome", "Total Number of GBV cases at Shelter Home", "सुरक्षित आवास गृहमा रहेका लैङ्गिक हिंसाका जम्मा घटनाहरू"],
  ["casesAtServiceCenter", "Total Number of GBV cases at Service Center", "सेवा केन्द्रहरूमा रहेका लैङ्गिक हिंसाका जम्मा घटनाहरू"],
  ["gbvType", "Type of GBV", "हिंसाका प्रकार"],
  ["totalCases", "Total Cases", "जम्मा घटनाहरू "],
  ["gbvPerpetrator", "Perpetrator of GBV", "लैङ्गिक हिंसाका पीडक"],
  ["caseHistory", "Case History", "घटनाको विवरण"],
  ["Shelter Home", "Shelter Home", "सुरक्षित आवास गृह"],
  ["Service Center", "Service Center", "सेवा केन्द्र"],
  ["Total Male Victims", "Total Male Victims", "कुल पुरूष पीडित/प्रभावितहरू"],
  ["Total Female Victims", "Total Female Victims", "कुल महिला पीडित/प्रभावितहरू"],
  ["Total Others Victims", "Total Others Victims", "कुल अन्य पीडित/प्रभावितहरू"],
  ["Total Number of GBV cases mediated in Judicial Committee",
    "Total Number of GBV cases mediated in Judicial Committee",
    "न्यायिक समितिमा मेलमिलाप भएका लैङ्गिक हिंसाका कुल घटनाहरू"],

  //List of stakeholders
  ["ocmc", "One Stop Crisis Management Centre (OCMC)", "एकद्वार संकट व्यवस्थापन  केन्द्र (ओसीएमसी)"],
  ["ahtb", "Anti-Human Trafficking Bureau (AHTB)", "मानब बेचबिखन अनुसन्धान ब्यूरो"],
  ["nepalPolice", "Nepal Police", "नेपाल प्रहरी"],
  ["localGovernment", "Local Government", "स्थानीय सरकार"],
  ["ncrc", "National Child Rights Council (NCRC)", "राष्ट्रिय बाल अधिकार परिषद्"],
  ["nwc", "National Women Commission (NWC)", "राष्ट्रिय महिला आयोग"],
  ["csos", "Civil Society Organizations (CSOs)", "नागरिक समाज संस्था"],
  ["embassy", "Embassy", "दूतावास"],
  ["migrantResourceCenter", "Migrant Resource Center", "प्रवासी स्रोत केन्द्र"],
  ["outreachOfficesOfDestinationCountries", "Outreach Offices of Destination Countries",
    "गन्तव्य देशका सम्पर्क कार्यालयहरू"],
  ["iom", "International Organization of Migration (IOM)", "अन्तर्राष्ट्रिय आप्रवासन संगठन"],
  ["dofe", "Department of Foreign Employment (DOFE)", "वैदेशिक रोजगार विभाग"],
  ["feb", "Foreign Employment Board (FEB)", "वैदेशिक रोजगार बोर्ड"],
  ["familyCommunity", "Family/Community", "परिवार/समुदाय"],
  ["school", "School", "विद्यालय"],
  ["youthChildClub", "Youth/Child Club", "युवा/बाल क्लब"],
  ["media", "Media", "सञ्चार माध्यम"],
  ["othersPleaseSpecify", "Others (Please specify)", "अन्य (कृपया उल्लेख गर्नुहोस्)"],

  //Push factors for TIP
  ["landless", "Landless", "भूमिहीन हुनु"],
  ["debt", "Debt", "ऋण लाग्नु"],
  ["familyPeerPressure", "Family/peer pressure", "पारिवारिक/साथीको दबाब"],
  ["houseLandPurchase", "House/land purchase", "धर वा जग्गा किन्ने चाहना हुनु"],
  ["noIncomeSources", "No income sources", "आम्दानीको वैकल्पिक श्रोत नहुनु"],
  ["noEmploymentOpportunity", "No employment opportunity", "रोजगारीका अवसरहरू नहुनु"],
  ["displacement", "Displacement", "विस्थापित हुनु"],
  ["domesticGenderBasedViolence", "Domestic violence/gender-based violence", "घरेलु/लैङ्गिक हिंसा"],
  ["divorce", "Divorce", "सम्बन्धविच्छेद"],
  ["childMarriage", "Child marriage", "बालविवाह"],
  ["forcedMarriage", "Forced marriage", "जबरजस्ती विवाह"],
  ["sexualViolence", "Sexual violence", "यौन हिंसा"],
  ["orphanAbandoned", "Orphan/ abandoned", "अनाथ/परित्याग गरिएको"],
  ["socialPressure", "Social pressure", "सामाजिक दबाब"],
  ["peerPressure", "Peer pressure", "साथीहरूको दबाब"],
  ["politicalInstability", "Political instability", "राजनीतिक अस्थिरता"],

  //Pull factors for TIP
  ["betterEmployment", "Opportunity for better employment", "रोजगारीको अवसरहरू"],
  ["higherWages", "Higher wages", "उच्च ज्याला"],
  ["additionalFacilities", "Additional facilities", "अतिरिक्त सुविधाहरू"],
  ["personalInterest", "Personal interest", "व्यक्त्तिगत चाहना"],
  ["modernLifestyle", "Desire for modern lifestyle", "आधुनिक जीवनशैलीको चाहना"],
  ["economicIndependency", "Needs for economic independency", "आर्थिक रुपमा स्व-निर्भरहुने चाहना"],
  ["exaggeratedInfoAboutPlace", "Exaggerated information about destination place and work",
    "गन्तव्य स्थान र काम बारे बढाइ चढाइ सूचना पाउनु"],

  ["districtCourt", "District court", "जिल्ला अदालत"],
  ["highCourt", "High court", "उच्च अदालत"],
  ["supremeCourt", "Supreme court", "सर्वोच्च अदालत"],

  //Directly provided services list 
  ["shelterAccommodation", "Shelter/Accommodation", "आश्रय/आवास"],
  ["food", "Food", "खाना"],
  ["clothes", "Clothes", "लुगा/लत्ताकपडा"],
  ["psychosocialCounseling", "Psychosocial counseling", "मनोसामाजिक परामर्श"],
  ["skillOrCapacityDevelopmentTraining", "Skill or capacity development training", "सीप तथा क्षमता विकास तालिम"],
  ["formalEducation", "Formal education", "औपचारिक शिक्षा"],
  ["informalEducationMotivationalSession", "Informal education/ motivational session", "अनौपचारिक शिक्षा/प्रेरणा सत्र"],
  ["healthAndMedicalService", "Health and medical service", "स्वास्थ्य र चिकित्सा"],
  ["jobPlacement", "Job placement", "जागिर नियुक्ति"],
  ["businessSupport", "Business support", "पेसा व्यवसाय सहायता"],
  ["legalServices", "Legal and paralegal services", "कानूनी र प्यारालीगल सेवाहरू"],

  ["notReferredYet", "Not referred yet", "सेवाका लागि प्रेषण गरिएको छैन"],
  ["independentlyLiving", "Independently living", "आत्मनिर्भर"],
  ["selfSustain", "Self-sustain", "आत्मनिर्भर"],
  ["livingAlone", "Living alone", "एक्लै बसेको"],
  ["Community", "Community", "समुदायमा"],
  ["outsideOfCommunity", "Stay outside of community", "आफ्नो समुदायभन्दा बाहिर बसेको"],

  //Type of GBV
  ["physicalViolence", "Physical violence", "शारीरिक हिंसा"],
  ["verbalAbuseViolence", "Verbal abuse/violence", "मौखिक दुर्व्यवहार/हिंसा"],
  ["psychologicalViolence", "Psychological violence", "मनोवैज्ञानिक हिंसा"],
  ["socioEconomicViolence", "Socio-economic violence", "सामाजिक-आर्थिक हिंसा"],
  ["domesticViolence", "Domestic violence", "घरेलु हिंसा"],
  ["sexualViolenceAndHarassment", "Sexual violence and harassment", "यौन हिंसा र उत्पीडन"],
  ["forcedMarriage", "Forced marriage", "जबरजस्ती विवाह"],
  //  ["childMarriage", "child marriage", "बालविवाह"],

  //Perpetrator of GBV
  ["husbandWife", "Husband/wife", "श्रीमान/श्रीमती"],
  ["immediateFamily", "Immediate family members", "पररितरको नजिकको सदस्य"],
  ["unknowPerson", "Unknown person", "नचिनेको व्यक्ति"],
  ["intimatePartner", "Intimate partner", "श्रीमान/श्रीमती"],
  ["inLaws", "In-laws (immediate family members) ", "परिवारको नजिकको सदस्य (सासु, ससुरा, दाजु, भाइ, देवर, जेठाजु, नन्द, आमाजु, जेठानी, देउरानी, छोराछोरी)"],
  ["neighbors", "Neighbors", "छिमेकी"],

  //Others related to TIP
  ["newCase", "New Case", "नयाँ घटना"],
  ["oldCase", "Old Case", "पुरानो घटना"],
  ["oldCaseId", "ID of old case", "पुरानो घटनाको परिचय"],
  ["typeOfTipCase", "Type of TIP Case", "मानब बेचबिखनका घटनाको प्रकार"],
  ["rescuedRepatriated", "Rescued/Repatriated", "उद्धार/फिर्ता"],
  ["referredBy", "Referred By", "प्रेषण गर्ने संस्था/निकायको नाम"],
  ["referredTo", "Referred To", "प्रेषण गरिएको संस्था/निकाय"],
  ["rescuedTo", "Rescued to", "उद्धार गरिएको स्थान"],
  ["repatriatedTo", "Repatriated to", "फिर्ता गरिएको स्थान"],
  ["interceptedBy", "Intercepted by", "जाँचबुझको क्रममा रोक्ने संस्था/निकाय"],
  ["interceptedAt", "Intercepted at", "जाँचबुझको क्रममा रोकिएको स्थान"],

  ["formsOfTip", "Forms of TIP", "मानब बेचबिखनको स्वरूप"],
  ["sexExploitation", "Sex Exploitation", "यौन शोषण"],
  ["laborExploitation", "Labor Exploitation", "श्रम शोषण"],
  ["organExtraction", "Organ Extraction ", "अंग झिक्ने"],
  ["possibleTipCaseDescription", "Possible TIP case Description", "सम्भावित मानब बेचबिखनका घटनाको विवरण"],
  ["riskOfVulnerability", "Risk of Vulnerability", "जोखिमको अवस्था"],
  ["pushFactors", "List of push factors of TIP", "मानव बेचबिखनलाई बढावा दिने तत्वहरू"],
  ["pullFactors", "List of pull factors of TIP", "मानव बेचबिखनलाई प्रेत्साहन गर्ने तत्वहरू"],
  ["statusOfTipCase", "Status of TIP case ", "बेचबिखन घटनाको स्थिति"],
  ["firTipCaseRegistration", "FIR/TIP Case Registration in Police", "प्रहरीमा जाहेरी दरखास्त परेको"],
  ["caseRegistered", "Case Registered", "जाहेरी दरखास्त परेको"],
  ["caseNotRegistered", "Case Not Registered", "जाहेरी दरखास्त नपरेको"],
  ["formalTipCaseRegistrationStatus", "Formal TIP Case Registration Status", "औपचारिक बेचबिखन घटनाको स्थिति"],
  ["tipCaseRegistrationStatus", "TIP Case Registration Status", "बेचबिखन घटनाको स्थिति"],
  ["prosecution", "Prosecution", "मुद्दा कारबाही प्रक्रियामा"],
  ["prosecutionAt", "Prosecution at", "मुद्दा कारबाही प्रक्रियामा रहेको"],
  ["caseDecidedBy", "Case Decided By", "मुद्दा फैसला भएको"],
  ["servicesRegistered", "Service received", "प्राप्त सेवाहरू"],
  ["directServices", "Direct services", "प्रत्यक्ष सेवाहरू"],
  ["caseReintegrationStatus", "Case reintegration status", "घटना पुनर्मिलनको अवस्था"],
  ["caseDiscussedWithLg", "Case discussed with LG for reintegration", "पुनर्मिलनका लागि स्थानीय सरकार (स्थानीय तह) सँग छलफल"],
  ["caseReferredToShelterHome", "Case referred to another home", "अन्य सुरक्षित गृहमा प्रेषण गरिएको"],
  ["reintegratedTo", "Reintegrated to", "पुनर्मिलन कहाँ भयो"],
  ["reintegratedDate", "Reintegrated Date", "पुनर्मिलन भएको मिति"],
  ["whyNot", "Why not? Please describe the reason", "घटना पुनर्मिलन नहुनुको कारण उल्लेख गर्नुहोस्"],
  ["anotherShelterHomeName", "Name of home where case referred", "प्रेषित सुरक्षित गृहको नाम"],
  ["homeName", "Home Name", "गृहको नाम"],
  ["homeNameEng", "Home Name (English)", "गृहको नाम (अंग्रेजी)"],
  ["homeNameNep", "Home Name (Nepali)", "गृहको नाम (नेपाली)"],
  ["homeType", "Home Type", "गृहको प्रकार"],

  ["uniqueCaseCode", "Unique Case Code", "यूनिक केस कोड"], //अद्वित्तीय घटना 
  ["personalCode", "Unique Case Code", "यूनिक केस कोड"],
  ["fileUpload", "Upload file", "फाइल अपलोड गर्नुहोस्"],
  ["gbvFileUpload",
    "Please upload documents (scan documents/photographs) related to this case history (if any)",
    "सम्भावित घटनाको विवरण खुल्ने दस्तावेज भएमा अपलोड गर्नुहोस्"],
  ["tipFileUpload",
    "Please upload documents (scan documents/photographs) related to this possible TIP case (if any)",
    "सम्भावित मानव बेचबिखनका घटनाको विवरण खुल्ने दस्तावेज भएमा अपलोड गर्नुहोस्"],

  ["writeBriefDetail", "Write Brief Detail", "संक्षिप्त विवरण लेख्नुहोस्"],
  ["realProxyName", "Real/Proxy Name", "वास्तविक/परिवर्तित नाम"],
  ["specifyOtherReferral", "Please Specify other referral", "कृपया अन्य प्रेषण सेवाहरू उल्लेख गर्नुहोस्"],
  ["addPersonalDetails", "Add Personal Details", "व्यक्तिगत विवरणहरू थप्नुहोस्"],

  ["maleGbvCase", "Male GBV Cases", "पुरुष लैङ्गिक हिंसासँग सम्बन्धित घटनाहरू"],
  ["femaleGbvCase", "Female GBV Cases", "महिला लैङ्गिक हिंसासँग सम्बन्धित घटनाहरू"],
  ["otherGbvCase", "Others GBV Cases", "अन्य लैङ्गिक हिंसासँग सम्बन्धित घटनाहरू"],
  ["ageNote", "Note: Age should be greater than 60.", "नोट: उमेर ६० भन्दा माथि हुनुपर्छ|"],

  //Employment status
  ["employmentStatus", "No. of women according to their employment status", "रोजगारीको स्थिति अनुसार महिलाको संख्या"],
  ["unemployed", "Unemployed", "बेरोजगार"],
  ["employed", "Employed", "रोजगार"],
  ["selfEmployed", "Self employed", "स्वरोजगार"],
  ["foreignEmployed", "Foreign employment", "वैदेशिक रोजगारी"],

  //Kada rog - disease
  ["heart", "Heart", "मुटु रोग"],
  ["kidney", "Kidney", "मिर्गौला"],
  ["cancer", "Cancer", "क्यान्सर"],
  ["alzheimer", "Alzheimer", "अल्जाइमर"],
  ["parkinson", "Parkinson", "पार्किन्सन्स"],
  ["headInjury", "Head Injury", "हेड इन्जुरी"],
  ["spinalInjury", "Spinal Injury", "स्पाइनल इन्जुरी"],
  ["sickleCellAnemia", "Sickle Cell Anemia", "सिकलसेल एनीमिया"],

  
  ["reason", "Reason", "कारण"],

  //Lookup Type
  ["Lookup", "Lookup", "लुकअप"],
  ["Home Lookup", "Lookup", "गृह लुकअप"],
  ["Shelter Home Lookup", "Shelter Home Lookup", "सुरक्षित आवास गृह लुकअप"],
  ["Service Center Lookup", "Service Center Lookup", "सेवा केन्द्र लुकअप"],
  ["Child Care Home Lookup", "Child Care Home Lookup", "बाल सेवा गृह लुकअप"],
  ["Child Correction Home Lookup", "Juvenile Reform Home Lookup", "बाल सुधार गृह लुकअप"],

];

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: langList.reduce((a, v) => {
        a[v[0]] = v[1];

        return a;
      }, {}),
    },
    np: {
      translations: langList.reduce((a, v) => {
        a[v[0]] = v[2] || v[1];

        return a;
      }, {}),
    },
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  // keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },

  react: {
    wait: true,
  },
});

export default i18n;
