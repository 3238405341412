import React, { useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";

import { useTranslation } from "react-i18next";

import TipDetailsOld from "./TipDetailsOld";
import HomeName from "../../../utilities/components/HomeName";
import HttGbvHomeAddress from "../../../utilities/components/HttGbvHomeAddress";
import HttOldDetailService from "../api/services/HttOldDetailService";
import { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";

const HttServiceCenterOld = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const [update, setUpdate] = useState(false);
    const [showBtn, setShowBtn] = useState("Yes");
    const [hideBtn, setHideBtn] = useState("No");

    const location = useLocation();
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = useForm();
    const toast = useRef(null);
    const getFormErrorMessage = (name) => {
        return (
            errors[name] && <small className="p-error">{errors[name].message}</small>
        );
    };

    useEffect(() => {
        if (props.location.state && props.location.state.caseId) {
            HttOldDetailService.getDetailById(
                props.location.state && props.location.state.caseId, "ServiceCenter"
            ).then((response) => {
                if (response.status == 200) {
                    console.log("detail response", response.data.data);
                    reset({
                        homeName: response.data.homeName,
                        homeProvince: parseInt(response.data.homeProvince),
                        homeDistrict: parseInt(response.data.homeDistrict),
                        homeMunicipality: parseInt(response.data.homeMunicipality),
                        homeWardNo: parseInt(response.data.homeWardNo),
                        oldCaseId: response.data.data.oldCaseId,
                        tipDetail: response.data.data.tipDetail
                    });
                    setUpdate(true);
                } else {
                    setUpdate(false);
                }
            });
        }
    }, [props.location.state && props.location.state.caseId]);

    const onSubmit = (data) => {
        data.homeType = "ServiceCenter";
        console.log("data", data);
        update ?
            trackPromise(
                HttOldDetailService.editData(data, props.location.state.caseId).then((response) => {
                    console.log("response", response);
                    if (response.status === 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Save Successful",
                            life: 3000,
                        });
                        // window.location.reload(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Save UnSuccessful",
                            life: 3000,
                        });
                    }
                })
            )
            :
            trackPromise(
                HttOldDetailService.saveData(data).then((response) => {
                    console.log("response", response);
                    if (response.status === 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Save Successful",
                            life: 3000,
                        });
                        // window.location.reload(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Save UnSuccessful",
                            life: 3000,
                        });
                    }
                })
            )
    };

    return (
        <div style={{ marginLeft: "2.5rem" }}>
            <Toast ref={toast} />
            <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
                <div className=" p-card-content">
                    <h4 className="p-pt-0"> {t("Service Center")} </h4>
                </div>
            </Card>

            <div className="p-grid p-col-12 p-md-12">
                <div className="p-field p-col-12 p-md-3">
                    <Button className="box-shadow"
                        style={{
                            justifyContent: "center",
                            background: "rgb(75 125 181)",
                            color: "#FFF",
                            width: '100%',
                            minHeight: '30px',
                            border: '4px solid rgb(208 0 0)',
                        }}
                        label={t("newCase")}
                        onClick={() =>
                            history.push("/sims/htt-service-center")
                        }
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Button className="box-shadow"
                        style={{
                            justifyContent: "center",
                            background: "rgb(75 125 181)",
                            color: "#FFF",
                            width: '100%',
                            minHeight: '30px',
                            border: '4px solid rgb(208 0 0)',
                        }}
                        label={t("oldCase")}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Button className="box-shadow"
                        style={{
                            justifyContent: "center",
                            background: "rgb(75 125 181)",
                            color: "#FFF",
                            width: '100%',
                            minHeight: '30px',
                            border: '4px solid rgb(208 0 0)',
                        }}
                        label={t("list")}
                        onClick={() =>
                            history.push("/sims/htt-service-center-list-new")
                        }
                    />
                </div>
            </div>

            <Card className="p-mt-0">
                <form className="p-grid p-fluid"
                    autoComplete="off">

                    <div className="p-field p-col-12 p-md-12 ">
                        <div className="p-field p-col-12 p-md-12 ">
                            <h5 className="HeadingTitle">
                                {t("homeDetails")}
                            </h5>
                        </div>
                    </div>
                    <HomeName register={register} error={errors} setValue={setValue} getValues={getValues} />
                    <HttGbvHomeAddress register={register} error={errors} setValue={setValue} getValues={getValues} />

                    <div className="p-col-6 p-md-6">
                        <div class="p-field p-col-12 p-md-12 float-left main-label">{t("oldCaseId")}</div>
                        <div class="p-field p-col-6 p-md-6 float-left">
                            <Controller
                                name="oldCaseId"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        value={field.value}
                                        className="rounded-input p-mb-1"
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <TipDetailsOld register={register} error={errors} setValue={setValue} getValues={getValues} />

                    {hideBtn === "No" ? (
                        <>
                            <div className="p-grid p-col-12 p-md-12">

                                <div className="p-col-12 p-md-10"></div>
                                <div className="p-col-12 p-md-2">
                                    <Button label={t("submit")}
                                        className="p-button-sm pull-right submitBtn"
                                        onClick={handleSubmit(onSubmit)}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </form>
            </Card>
        </div>
    );
}

export default HttServiceCenterOld;