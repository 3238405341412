import React, { useEffect, useState } from "react";
import AppProps from "../../AppProps";
import axios from "axios";
import { Card } from "primereact/card";
import userlogo from "../assets/images/user.jpg";
import { t } from "i18next";

export default function FeedbackList() {
	const [userData, setUserData] = useState([]);
	
	function compare(a, b)
	{
		if(a.entry_date.valueOf() > b.entry_date.valueOf()) return -1;
		else return 1;
	}

	useEffect(() => {
		axios.get(AppProps.API_BASE_URL + "/feedback/get-all", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }})
			.then(res => {
				//console.log(res.data);
				setUserData(res.data.sort(compare));
			})
			.catch(err => { console.log(err); })
	}, []);
	
	//var userData = [{entry_by:"This ", feedback:"that uis "}];

	return (
		<div style={{marginLeft: "2.5rem"}}>
			<Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }} >
				<div className=" p-card-content">
					<h4 className="p-pt-0">{t("Feedback List")}</h4>
				</div>
			</Card>
	
			{userData.map((data) => {
			return <Card className="p-mt-2">
				<div className="p-card-content p-grid" >
					<div className="p-field p-col-12 p-md-1"  >
						<img src={userlogo} alt="PIC" style={{ width: "4vw", height: "4vw" }} />
					</div>
					<div className="p-field p-col-12 p-md-11"  >
						<div style={{ float: "left", }} > <b> {data.entry_by} </b> </div>
						<div style={{ float: "right", textAlign:"right" }} > {data.entry_date} </div>
						<div style={{ clear: "both" }}> {data.feedback} </div>
					</div>
			 </div>
			</Card>
			})}

			{/* <div style={{ marginTop: "3%", }}>
				<div style={{ float: "left", }} >
					<img src={userlogo} alt="PIC" style={{ width: "60px", height: "60px" }} />
				</div>
				<div style={{ marginLeft: "7%" }}>
					<div> <b>User Name</b> </div>
					<div> Feedback </div>
				</div>
			</div> */}
		</div>
	)
}