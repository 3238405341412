import React, { useState, useRef, useEffect } from "react";

import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { MultiSelect } from "primereact/multiselect";

import { useForm, Controller } from "react-hook-form";
import { t } from "i18next";

import AddressService from "../../../security/api/services/AddressService";
import i18n from "../../../il8n/il8n";
import { LANGUAGE } from "../../../utilities/constants/ITMISConstansts";
import SourceForProsecution from "../../../utilities/components/SourceForProsecution";
import FiscalQuarterReport from "../../../utilities/components/FiscalQuarterReport";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { trackPromise } from "react-promise-tracker";
import ProsecutionService from "../api/services/ProsecutionService";

function Prosecution() {
    const [fiscalYear, setFiscalYear] = useState("");
    const [quarter, setQuarter] = useState(0);
    const [update, setUpdate] = useState(false);
    const [prosecutionId, setProsecutionId] = useState();
    // const [enableForm, setEnableForm] = useState(true);
    const handleFiscalYear = (fiscalYearVal) => {
        setFiscalYear(fiscalYearVal);
    };
    const handleQuarter = (quarterVal) => {
        setQuarter(quarterVal);
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        getValues,
        trigger
    } = useForm({});
    const toast = useRef(null);

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const [districtsAllList, setDistrictsAllList] = useState([]);

    const [displayValue, setDisplayValue] = useState("No");
    const searchData = () => {
        let searchData = {
            fiscalYear: fiscalYear,
            quarter: quarter
        };
        if (fiscalYear !== "") {
            setDisplayValue("Yes");
            trackPromise(
                ProsecutionService.getData(searchData).then((response) => {
                    console.log("response>>>>.", response.data);
                    if (response.data.length > 0) {
                        
                    console.log("response>", response.data[0]);
                        setProsecutionId(response.data[0].prosecutionId);
                        setUpdate(true);
                        reset({
                            casesProsecuted: response.data[0].casesProsecuted,
                            casesProsecutedSource:
                                response.data[0].casesProsecutedSource[0] != "" &&
                                    response.data[0].casesProsecutedSource != ""
                                    ? response.data[0].casesProsecutedSource
                                    : null,

                            districtName:
                                response.data[0].districtName[0] != "" &&
                                    response.data[0].districtName != ""
                                    ? response.data[0].districtName
                                    : null,

                            casesProsecutedByDistrictSource:
                                response.data[0].casesProsecutedByDistrictSource[0] != "" &&
                                    response.data[0].casesProsecutedByDistrictSource != ""
                                    ? response.data[0].casesProsecutedByDistrictSource
                                    : null,
                            casesProsecutedSourceOthers: response.data[0].casesProsecutedSourceOthers,
                            casesProsecutedByDistrictSourceOthers: response.data[0].casesProsecutedByDistrictSourceOthers,
                        })
                        setSelectedDistrictList(response.data[0].prosecutionDistrictCasesList);
                    } else {
                        reset({
                            casesProsecutedSource: [],
                            districtName: [],
                            casesProsecutedByDistrictSource: [],
                        });
                        setSelectedDistrictList([]);
                        setUpdate(false);
                    }
                })
            )
        } else {
            setDisplayValue("No");
            toast.current.show({
                severity: "warn",
                summary: t("selectFiscalYear"),
                life: 3000,
            });
        }
    };
    const [selectedDistrictList, setSelectedDistrictList] = useState([]);
    const selectedDistricts = (e, name) => {
        const newList = e.map((data) => {
            const existingDistrict = selectedDistrictList.find((d) => d.districtName === data);
            if (existingDistrict) {
                return existingDistrict;
            } else {
                return {
                    districtName: data,
                    totCases: "",
                };
            }
        });
        setSelectedDistrictList(newList);
    };

    useEffect(() => {
        AddressService.getAllDistrict().then((response) => {
            setDistrictsAllList(response.data.data);
        });
    }, []);

    //function to calculate sum of totalCases
    const [casesProsecutedByDistrict, setCasesProsecutedByDistrict] = useState(0);
    const calculateTotalCases = () => {
        let totalCases = 0;
        selectedDistrictList.forEach((district) => {
            totalCases += parseInt(district.totCases) || 0;
        });
        setValue("casesProsecutedByDistrict", totalCases);
        return totalCases;
    };

    //To update casesProsecutedByDistrict whenever selectedDistrictList changes
    useEffect(() => {
        const totalCases = calculateTotalCases();
        setCasesProsecutedByDistrict(totalCases);
    }, [selectedDistrictList]);

    const inputTextBody = (rowData) => {
        return <InputNumber value={rowData.totCases} min={0} />;
    };

    const districtCaseEditor = (colName, props) => {
        return (
            <InputText
                type="text"
                style={{ width: "80%" }}
                value={props.rowData[colName]}
                onChange={(e) => addFinalDistrictCaseData(e.target.value, props, colName, props.rowData.districtName)}
            />
        );
    };

    const addFinalDistrictCaseData = (optvalue, props, colName, districtName) => {
        const newList = selectedDistrictList.map(data => {
            if (data.districtName === districtName) {
                return {
                    ...data,
                    [colName]: optvalue
                };
            }
            return data;
        });
        // Update the selectedDistrictList state with the new list
        setSelectedDistrictList(newList);
    };

    const submitData = (data) => {
        data.fiscalYear = fiscalYear;
        data.quarter = quarter;
        data.status = "Submit";
        data.prosecutionDistrictCasesList = selectedDistrictList;
        console.log("data", data);
        if (update) {
            trackPromise(
                ProsecutionService.editData(data,prosecutionId).then((response) => {
                    console.log("response", response);
                    if (response.status == 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Submit Successful",
                            life: 3000,
                        });
                        window.location.reload(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Submit UnSuccessful",
                            life: 3000,
                        });
                    }
                })
                    .catch((error) => {
                        // We want to handle globally
                        error.handleGlobally && error.handleGlobally();
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: error.response.data.msg,
                            life: 3000,
                        });
                    })
            )
        } else {
            trackPromise(
                ProsecutionService.saveData(data).then((response) => {
                    console.log("response", response);
                    if (response.status == 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Submit Successful",
                            life: 3000,
                        });
                        window.location.reload(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Submit UnSuccessful",
                            life: 3000,
                        });
                    }
                })
                    .catch((error) => {
                        // We want to handle globally
                        error.handleGlobally && error.handleGlobally();
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: error.response.data.msg,
                            life: 3000,
                        });
                    })
            );
        }
    }

    return (
        <div style={{ marginLeft: "2.5rem" }}>
            <Toast ref={toast} />
            <Card
                className="p-mb-1"
                style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}
            >
                <div className=" p-card-content">
                    <h4 className="p-pt-0">{t("Prosecution")}</h4>
                </div>
            </Card>
            <Card className="p-mt-0"
            // style={{ height: "72vh", overflowY: "auto" }}
            >
                <div className=" p-card-content p-col-12 p-md-12">
                    <form className="p-grid p-fluid" autoComplete="off">
                        <FiscalQuarterReport
                            fiscalYearValue={fiscalYear}
                            handleFiscalYearState={handleFiscalYear}
                            quarterValue={quarter}
                            handleQuarterState={handleQuarter}
                        />
                        <div className="p-field p-col-12 p-md-12 ">
                            <div className="p-field p-col-10 p-md-10 float-left"></div>
                            <div className="p-field p-col-2 p-md-2 float-right">
                                <Button
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: "#1c80cf",
                                        color: "#FFF",
                                    }}
                                    label={t("search")}
                                    onClick={handleSubmit(searchData)}
                                />
                            </div>
                        </div>
                        {displayValue === "Yes" ?
                            <>
                                <div className="p-field p-col-12 p-md-12 ">
                                    <div className="p-field p-col-12 p-md-12 ">
                                        <hr style={{ marginTop: "35px" }}></hr>
                                    </div>
                                </div>

                                <div className="main-form p-col-12 p-md-12">
                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div class="p-col-12 p-md-9">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("casesProsecuted")}
                                                <i
                                                    className="pi pi-question-circle tooltip-style"
                                                    title={t("casesProsecuted")}
                                                />
                                            </div>
                                            <div className="p-field p-col-2 p-md-2 float-left">
                                                <Controller
                                                    name="casesProsecuted"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                            }}
                                                            min="0"
                                                        />
                                                    )}
                                                />
                                            </div>

                                        </div>
                                        <div class="p-col-12 p-md-3">
                                            <Controller
                                                name="casesProsecutedSource"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <SourceForProsecution
                                                        id={field.name}
                                                        {...field}
                                                        className={classNames({
                                                            "p-invalid": fieldState.invalid,
                                                        })}
                                                        value={field.value}
                                                        handleSourceState={(e) => {
                                                            console.log("e", e);
                                                            field.onChange(e);
                                                        }}
                                                        sourceOtherValue={getValues("casesProsecutedSourceOthers")}
                                                        handleSourceOtherState={(e) => {
                                                            setValue("casesProsecutedSourceOthers", e);
                                                        }}
                                                    />
                                                )}
                                                defaultValue={[]}
                                            />
                                            {/* <div class="p-col-12 p-md-12">
                                        {getFormErrorMessage("casesProsecutedSource")}
                                    </div> */}
                                        </div>
                                    </div>
                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div className="p-field p-col-12 p-md-12 ">
                                            <hr
                                                style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                                            ></hr>
                                        </div>
                                    </div>
                                    <div className="p-grid p-col-12 p-md-12 ">
                                        <div class="p-col-12 p-md-9">
                                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                                {t("casesProsecutedByDistrict")}
                                                <i
                                                    className="pi pi-question-circle tooltip-style"
                                                    title={t("casesProsecutedByDistrict")}
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-md-9 float-left">
                                                <Controller
                                                    name="districtName"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <MultiSelect
                                                            id={field.name}
                                                            value={field.value}
                                                            placeholder={t("select")}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                                selectedDistricts(e.value, "districtName");
                                                            }}
                                                            style={{ width: "100%" }}
                                                            options={districtsAllList}
                                                            optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                                            optionValue="districtDescEng"
                                                            display="chip"
                                                        />
                                                    )}
                                                />
                                                <div className="p-field p-col-12 p-md-12">
                                                    <DataTable value={selectedDistrictList}
                                                        responsiveLayout="scroll"
                                                        editMode="cell"
                                                        resizableColumns
                                                        className="editable-cells-table p-datatable-sm"
                                                        showGridlines>
                                                        <Column field="districtName" header={t("district")}></Column>
                                                        <Column field="totCases"
                                                            header={t("total")}
                                                            body={inputTextBody}
                                                            editor={(options) => districtCaseEditor("totCases", options)}
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            </div>
                                            <div className="p-field p-col-12 p-md-3 float-left">
                                                <Controller
                                                    name="casesProsecutedByDistrict"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <InputNumber
                                                            id={field.name}
                                                            {...field}
                                                            className={classNames({
                                                                "p-invalid": fieldState.invalid,
                                                            })}
                                                            // value={field.value}
                                                            // onChange={(e) => {
                                                            //     field.onChange(e.value);
                                                            // }}
                                                            value={casesProsecutedByDistrict}
                                                            onChange={(e) => setCasesProsecutedByDistrict(e.target.value)}
                                                            min="0"
                                                            disabled
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-md-3">
                                            <Controller
                                                name="casesProsecutedByDistrictSource"
                                                control={control}
                                                // rules={{ required: "Source is required" }}
                                                render={({ field, fieldState }) => (
                                                    <SourceForProsecution
                                                        id={field.name}
                                                        {...field}
                                                        className={classNames({
                                                            "p-invalid": fieldState.invalid,
                                                        })}
                                                        value={field.value}
                                                        handleSourceState={(e) => {
                                                            console.log("e", e);
                                                            field.onChange(e);
                                                        }}
                                                        sourceOtherValue={getValues("casesProsecutedByDistrictSourceOthers")}
                                                        handleSourceOtherState={(e) => {
                                                            setValue("casesProsecutedByDistrictSourceOthers", e);
                                                        }}
                                                    />
                                                )}
                                                defaultValue={[]}
                                            />
                                            {/* <div class="p-col-12 p-md-12">
                                        {getFormErrorMessage("casesProsecutedByDistrictSource")}
                                    </div> */}
                                        </div>
                                    </div>

                                </div>
                                <div className="p-grid p-col-12 p-md-12">
                                    <div className="p-col-12 p-md-10"></div>
                                    <div className="p-col-12 p-md-2">
                                        <Button label={t("submit")}
                                            className="p-button-sm pull-right submitBtn"
                                            // onClick={() => { saveData() }}
                                            onClick={handleSubmit(submitData)}
                                        />
                                    </div>
                                </div>
                            </> : ""}

                    </form>
                </div>
            </Card>

        </div>
    );
}

export default Prosecution;