import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { trackPromise } from "react-promise-tracker";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import UserService from "../../../security/api/services/UserService";
import { USER_LEVEL } from "../../../utilities/constants/ITMISConstansts";
import HttGbvHomeAddress from "../../../utilities/components/HttGbvHomeAddress";
import HttDetailService from "../api/services/HttDetailService";
import HomeDetailLookup from "../../../utilities/components/HomeDetailLookup";

const HttServiceCenterVerification = () => {
  const { t } = useTranslation();
  const [responseList, setResponseList] = useState([]);
  const [view, setView] = useState(true);
  const history = useHistory();

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    register
  } = useForm();

  const options = {
    filter: true,
    print: false,
    filterType: "dropdown",
    responsive: "vertical",
    fixedSelectColumn: false,
    selectableRows: false,
  };

  // useEffect(() => {
  //   UserService.getUserLevel().then((response) => {
  //     if (response.data.data === USER_LEVEL.LOCAL_LEVEL) {
  //       setView(true);
  //     } else {
  //       setView(true);
  //     }
  //   });
  // });

  const searchData = (data) => {
    data.homeType = "ServiceCenter";
    console.log("data ", data);
    trackPromise(
      HttDetailService.getVerifyingListByAddress(data).then((response) => {
        console.log("response.data", response.data.data);
        setResponseList(response.data.data);
      })
    );
  };

  const columns = [
    {
      name: "caseId",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    }, {
      name: "homeName",
      label: t("Child Care Home"),
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "firstName",
      label: t("name"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[2] + " " + tableMeta.rowData[3] + " " + tableMeta.rowData[4];
        }
      },
    },
    {
      name: "middleName",
      label: "Name",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "lastName",
      label: "Name",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "personalCode",
      label: t("personalCode"),
      options: {},
    },
    {
      name: "gender",
      label: t("gender"),
      options: {},
    },
    {
      name: "age",
      label: t("age"),
      options: {},
    },
    {
      name: "caste",
      label: t("casteEthnicity"),
      options: {},
    },
    {
      name: "status",
      label: t("status"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "Request") {
            return "Requested for Verification";
          } else if (value === "Verified") {
            return "Verified";
          } else if(value==="NeedCorrection") {
            return "Send back for Correction";
          }
        }
      },
    },
    {
      name: "Actions",
      label: t("actions"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <React.Fragment>
              {
                  <Button
                    className="pi pi-eye"
                    tooltip="View Detail"
                    tooltipOptions={{
                      position: "bottom"
                    }}
                    style={{ marginRight: "4px", padding: "5px", width: "28px" }}
                    onClick={() => {
                      history.push({
                        pathname: "/sims/htt-service-center-view",
                        state: {
                          caseId: tableMeta.rowData[0],
                          view: true
                        },
                      })
                    }}
                  />
              }
            </React.Fragment>
          );
        },
      },
    },
  ];


  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
        <div className=" p-card-content">
          <h4 className="p-pt-0">{t("Service Center")}</h4>
        </div>
      </Card>

      <Card className="p-mt-0">
        <div>
          <form className="p-grid p-fluid ">
          <HomeDetailLookup homeType="ServiceCenter" register={register} error={errors} setValue={setValue} getValues={getValues} />

            <div className="p-field p-col-12 p-md-12 ">
              <div className="p-field p-col-10 p-md-10 float-left"></div>
              <div className="p-field p-col-2 p-md-2 float-right">
                <Button
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#1c80cf",
                    color: "#FFF",
                  }}
                  label={t("search")}
                  onClick={handleSubmit(searchData)}
                />
              </div>
            </div>
          </form>
        </div>

        <hr style={{ margin: "10px" }}></hr>

        <div className=" p-card-content">
          <ThemeProvider
            theme={createTheme({
              overrides: {
                MUIDataTableToolbar: { root: { display: "none" } },
              },
            })}
          >
            <MUIDataTable
              data={responseList}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>
      </Card>
    </div>
  );
};
export default HttServiceCenterVerification;

