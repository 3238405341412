import axios from "axios";
import AppProps from "../../../AppProps";
class DisabilityCardDetailService {
    saveDate(data) {
        console.log(data, "inside post api disabilitycardDetail");
        return axios.post(AppProps.API_BASE_URL + AppProps.DISABILITY_CARD_DETAIL, data, {
            headers: {
                Authorization: "Bearer" + sessionStorage.getItem("token"),
            }
        })
    }

    save(data) {
        console.log(data, "inside post api Senior Citizen And Details");
        return axios.post(AppProps.API_BASE_URL + AppProps.DISABILITY_CARD_DETAIL, data, {
            headers: {
                Authorization: "Bearer" + sessionStorage.getItem("token"),
            }
        })
    }
}
export default new DisabilityCardDetailService()