
import React, { useState, useRef } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from "react-i18next";

const Occupation = (props) => {
    const { t } = useTranslation();
    const occupationList = [
        { label: t("agriculture"), value: 'Agriculture' },
        { label: t("householdChores"), value: 'Household chores' },
        { label: t("informalSectorWorker"), value: 'Informal Sector' },
        { label: t("entertainmentSectorWorker"), value: 'Entertainment Sector' },
        { label: t("unskilledLabor"), value: 'Unskilled Labor' },
        { label: t("semiSkilledLabor"), value: 'Semi skilled Labor' },
        { label: t("skilledLabor"), value: 'Skilled Labor' },
        { label: t("govJobHolder"), value: 'Government Job Holder' },
        { label: t("privateJobHolder"), value: 'Private Job Holder' },
        { label: t("selfEmployed"), value: 'Selfemployed' },
        { label: t("othersPleaseSpecify"), value: 'Others' }
    ];
    const [occupation, setOccupation] = useState();
    const [val, setVal] = useState();
    return (
        <div className="p-grid p-col-12 p-md-12 ">
            <div class="p-col-12 p-md-6">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("occupation")} <span style={{ color: "#d00000" }}> * </span>
                </div>
                <div className="p-field p-col-12 p-md-12 float-left">
                    <Dropdown
                        filter
                        name="occupation"
                        placeholder={t("occupation")}
                        {...props.register("occupation", {
                            required: "Occupation is Required",
                        }
                        )}
                        value={props.getValues("occupation")}
                        options={occupationList}
                        onChange={(e) => {
                            props.setValue("occupation", e.value);
                            setOccupation(e.value);
                        }}
                    />
                    {props.error.occupation && props.error.occupation.type === "required" && (
                        <small className="p-error">{props.error.occupation.message}</small>
                    )}
                </div>
            </div>
            {props.getValues("occupation") === "Others" ? (
                <div class="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("pleaseSpDetails")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <InputText
                            name="occupationOthers"
                            value={props.getValues("occupationOthers")}
                            {...props.register("occupationOthers")}
                            onChange={(e) => {
                                props.setValue("occupationOthers", e.target.value);
                                setVal(e.target.value);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div class="p-col-12 p-md-6">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("yearsOfExperience")} :
                </div>
                <div className="p-field p-col-12 p-md-12 float-left">
                    <InputText
                        name="yearsOfExperience"
                        value={props.getValues("yearsOfExperience")}
                        {...props.register("yearsOfExperience"
                            //   , {
                            //     required: "Years Of Experience is Required",
                            //   }
                        )}
                        onChange={(e) => {
                            props.setValue("yearsOfExperience", e.target.value);
                            setVal(e.target.value);
                        }}
                    />
                    {props.error.yearsOfExperience && props.error.yearsOfExperience.type === "required" && (
                        <small className="p-error">{props.error.yearsOfExperience.message}</small>
                    )}
                </div>
            </div>
        </div>
    )

}
export default Occupation;