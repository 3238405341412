
import React, { useState, useRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import i18n from "../../il8n/il8n";
import { LANGUAGE, USER_LEVEL } from "../constants/ITMISConstansts";
import { Dropdown } from "primereact/dropdown";
import OrganizationService from "../../security/api/services/OrganizationService";
import HomeLookupService from "../../Lookup/api/services/HomeLookupService";
import UserService from "../../security/api/services/UserService";

const HomeName = (props) => {
    const { t } = useTranslation();
    const [homeName, setHomeName] = useState();
    const [homeNameList, setHomeNameList] = useState();
    const [userLevel, setUserLevel] = useState();

    useEffect(() => {
        let organizationAddress = {};
        OrganizationService.getLoggedInUserHomeDetails().then((response) => {
            console.log("home data>>>>>>", response.data.data);
            let homeDetail = response.data.data;
            organizationAddress = homeDetail.organization.organizationAddress;
            if (response.data.data.homeType) {
                props.setValue("homeProvince", parseInt(organizationAddress.province));
                props.setValue("homeDistrict", parseInt(organizationAddress.district));
                props.setValue("homeMunicipality", parseInt(organizationAddress.municipality));
                props.setValue("homeType", homeDetail.homeType);

                getHomeDetails();
                UserService.getUserLevel().then((response) => {
                    setUserLevel(response.data.data);
                });
                props.setValue("homeId", homeDetail.homeId);
            }else {
                getAllHomeDetails();
            }
        });
    }, []);

    const getHomeDetails = () => {
        let data = {};
        data.province = props.getValues("homeProvince");
        data.district = props.getValues("homeDistrict");
        data.municipality = props.getValues("homeMunicipality");
        data.homeType = props.getValues("homeType");

        HomeLookupService.getLookupDetails(data).then((response) => {
            console.log("response.data", response.data.data);
            setHomeNameList(response.data.data);
        });
    }

    const getAllHomeDetails = () => {
        HomeLookupService.getAllHomeDetails().then((response) => {
            console.log("response.data", response.data.data);
            setHomeNameList(response.data.data);
        });
    }

    return (
        <div className="p-grid p-col-12 p-md-12 ">
            <div class="p-col-12 p-md-6">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("name")}<span style={{ color: "#d00000" }}> * </span>
                </div>
                <div className="p-field p-col-12 p-md-12 float-left">
                    {/* <InputText
                        name="homeName"
                        value={props.getValues("homeName")}
                        {...props.register("homeName"
                              , {
                                required: "Home Name is Required",
                              }
                        )}
                        onChange={(e) => {
                          props.setValue("homeName", e.target.value);
                          setHomeName(e.target.value);
                        }}
                    /> */}
                    <Dropdown
                        filter
                        name="homeId"
                        value={props.getValues("homeId")}
                        {...props.register("homeId"
                            , {
                                required: "Home Name is Required",
                            }
                        )}
                        onChange={(e) => {
                            props.setValue("homeId", e.target.value);
                            setHomeName(e.target.value);
                        }}
                        style={{ width: "100%" }}
                        options={homeNameList}
                        optionLabel={
                            i18n.language == LANGUAGE.ENGLISH ? "nameEng" : "nameNep"
                        }
                        optionValue="homeId"
                        disabled
                    />
                    {props.error.homeId && props.error.homeId.type === "required" && (
                        <small className="p-error">{props.error.homeId.message}</small>
                    )}
                </div>
            </div>
        </div>
    )

}
export default HomeName;