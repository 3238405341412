import { t } from "i18next";

export const USER_LEVEL = {
  MINISTRY: "MINISTRY",
  PROVINCE: "PROVINCE",
  DISTRICT: "DISTRICT",
  LOCAL_LEVEL: "LOCAL LEVEL",
  LOCAL_LEVEL_VERIFIER: "LOCAL LEVEL VERIFIER",
  WARD: "WARD",
  SOCIAL_SERVICE: "SOCIAL SERVICE",
  OTHERS: "OTHERS",
};

export const STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const MODULE_FUNCTION_VALUE = {
  1: "view",
  2: "add",
  3: "edit",
  4: "delete",
};
export const MODULE_FUNCTION_FIELD = {
  VIEW: "view",
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
};

export const SOCIAL_SERVICE_MODULES = {
  SHELTER_HOME: "Shelter Home",
  CHILD_HOME: "Child Home",
  CHILD_CORRECTION: "Juvenile Correction Home",
  OLD_AGE_HOME: "Old Age Home",
};

export const YES_NO = {
  YES: "Yes",
  NO: "No",
  OTHERS: "Others",
};

export const TRUE_FALSE = {
  TRUE: "true",
  FALSE: "false",
};

export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
};

export const AGE_GROUP = {
  CHILDREN: "Children",
  ADULT: "Adult",
  ELDER: "Elder",
};

export const AGE_GROUP_OLD = {
  Age6065: "60-65",
  Age6068: "60-68",
  Age6975: "69-75",
  Age6570: "65-70",
  Age7690: "76-90",
  Age7080: "70-80",
  Age8090: "80-90",
  Age9199: "91-99",
  Age90Above: "90 and Above",
  Age99Above: "99 and Above",
};

export const CASTE = {
  HILL_BRAHMAN: "Hill Brahman",
  HILL_JANAJATI: "Janajati (Hills)",
  NEWAR: "Newar",
  HILL_DALIT: "Dalit (Hills)",
  TERAI_MADHESI_BRAHMAN: "Terai Madhesi Brahman",
  TERAI_JANAJATI: "Janajati (Tarai)",
  TERAI_MADHESI_OTHER: "Mid-Caste (Tarai)",
  TERAI_DALIT: "Dalit (Tarai)",
  MUSLIM: "Muslim",
  OTHER: "Other",
  THARU: "Tharu",
  KHAS_ARYA: "Khas-Arya"
};

export const ORPHAN_OR_NOT = {
  PARENT_GUARIDIAN_PRESENT: "Parent Guardian Present",
  SINGLE_PARENT: "Single Parent",
  ORPHAN: "Orphan",
};

export const REHABILITATED_TO = {
  HOME: "Home",
  OJT: "OJT",
  OUT_OF_COMMUNITY: "Out Of Community",
  ADOPTION: "Adoption",
  FOSTER_HOME: "Foster Home",
};

export const LABOUR_MIGRATION_VISA_TYPE = {
  MANPOWER: "Manpower",
  INDIVIDUAL: "Individual",
  VISIT_VISA: "Visit Visa",
};

export const LABOUR_MIGRATION_RECEIPT_AMT_PAID = {
  TAKEN: "Taken",
  NOT_TAKEN: "Not Taken",
};

export const LABOUR_MIGRATION_DOCUMENTED = {
  DOCUMENTED: "Documentated",
  UNDOCUMENTED: "Undocumentated",
};

export const LABOUR_MIGRATION_FORMAL_CONTRACT = {
  RECEIVED: "Received",
  NOT_RECEIVED: "NotReceived",
};

export const ACTION = {
  VIEW: "VIEW",
  UPDATE: "UPDATE",
};

export const LANGUAGE = {
  ENGLISH: "en",
  NEPALI: "np",
};

export const MODULE_LIST = [
  { moduleName: t("Women and Minorities"), moduleId: "WomenAndMinorities" },
  { moduleName: t("Children"), moduleId: "Children" },
  { moduleName: t("Disabled"), moduleId: "Disabled" },
  { moduleName: t("Senior Citizen"), moduleId: "SeniorCitizen" },
  {
    moduleName: t("Complaint Registration"),
    moduleId: "ComplaintRegistration",
  },
  { moduleName: t("Shelter Home"), moduleId: "ShelterHome" },
  { moduleName: t("Sewa Kendra"), moduleId: "SewaKendra" },
  { moduleName: t("Child Home"), moduleId: "ChildHome" },
  { moduleName: t("Child Correction Home"), moduleId: "ChildCorrectionHome" },
  { moduleName: t("Old Age Home"), moduleId: "OldAgeHome" },
  { moduleName: t("Labour Migration"), moduleId: "LabourMigrationIndicator" },
];

export const ID_CATEGORY_NEPALI = {
  ka: "(क) पूर्ण अशक्त (रातो)",
  kha: "(ख) अति अशक्त (निलो)",
  ga: "(ग) मध्यम (पहेंलो)",
  gha: "(घ) सामान्य (सेतो)",
};
export const ID_CATEGORY_ENGLISH = {
  ka: "(Ka) Completely disabled (Red)",
  kha: "(Kha) Extremely disabled (Blue)",
  ga: "(Ga) Moderate (Yellow)",
  gha: "(Gha) General (White)",
};

export const ID_CARD_TYPE_NEPALI = {
  ka: "क",
  kha: "ख",
  ga: "ग",
  gha: "घ",
};

export const GENDER_NEPALI = {
  Male: "पुरुष",
  Female: "महिला",
  Other: "अन्य",
};
export const DISABILITY_BY_NATURE_NEPALI = {
  BLINDNESS: "दृष्टि विहीन",
  LOCOMOTOR_DISABILITY: "लोकोमोटर असक्षमता",
};
export const DISABILITY_BY_SEVERITY_NEPALI = {
  HIGH: "उच्च",
  MEDIUM: "मध्यम",
  LOW: "कम",
};
export const FORM_MODE = {
  NEW: "NEW",
  UPDATE: "UPDATE",
};

export const ID_CARD_SIZE = {
  SMART: "SMART",
  NORMAL: "NORMAL",
};
export const AVAILABLE_CONCESSION_NEPALI = {
  Discount_Medical_Expenses: "उपचार खर्चमा छुट",
  Discount_Air_Service_And_Car_Rental: "हवाई सेवा र गाडी भाडामा छुट",
  Discount_Air_Service_And_Car_Seat: "हवाई सेवा र गाडी सीटमा  बेबस्था",
};
export const AVAILABLE_CONCESSION_ENGLISH = {
  Discount_Medical_Expenses: "Discount on medical expenses",
  Discount_Air_Service_And_Car_Rental: "Discount on air service and car rental",
  Discount_Air_Service_And_Car_Seat: "Discount in air service and car seat",
};


export const INT_EXT = {
  Internal: "Internal",
  External: "External"
};

export const FILE_TYPE = {
  PDF: ".pdf",
  WORD: ".word",
  IMAGE: ".jpg,.png",
};

export const DISABILITY_TYPE_ENGLISH = {
  Physically_disabled: "Physical disability",
  Visually_impaired: "Visually Impaired",
  Hearing_impaired: "Hearing Impaired",
  Deaf_Blind: "Deaf Blind",
  Speech_hearing_disability: "Speech and hearing disability",
  Mental_disability: "Mental or psycho-social disability",
  Intellectually_disabled: "Intellectual disablility",
  Haemophelia: "Disability associated with Haemophilia",
  Autism: "Autism",
  Multiple: "Multiple disability",
};

export const DISABILITY_TYPE_NEPALI = {
  Physically_disabled: "शारीरिक अपाङ्गता",
  Visually_impaired: "दृष्टिसम्बन्धी अपाङ्गता",
  Hearing_impaired: "सुनाइसम्बन्धी अपाङ्गता",
  Deaf_Blind: "श्रवण-दृष्टिविहीन अपाङ्गता",
  Speech_hearing_disability: "स्वर र बोलाइसम्बन्धी अपाङ्गता",
  Mental_disability: "मानसिक वा मनोसामाजिक अपाङ्गता",
  Intellectually_disabled: "बौद्धिक अपाङ्गता",
  Haemophelia: "अनुवंशीय रक्तश्राव (हेमोफिलिया) सम्बन्धी अपाङ्गता",
  Autism: "अटिजमसम्बन्धी अपाङ्गता",
  Multiple: "बहुअपाङ्गता",
};

export const FATHER_MOTHER_GUARDIAN_NEPALI = {
  Father: "बाबु",
  Mother: "आमा",
  Guardian: "संरक्षक",
};
