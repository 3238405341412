import React, { useState } from "react";

import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from "react-i18next";

const FiscalYearGbv = (props) => {
    const { t } = useTranslation();
    const fiscalYearList = [
        { label: t("2078/79"), value: '2078-79' },
        { label: t("2079/80"), value: '2079-80' },
        { label: t("2080/81"), value: '2080-81' },
        { label: t("2081/82"), value: '2081-82' }
    ];
    const quarterList = [
        { label: t("ShrawanAshwin"), value: '1' },
        { label: t("KartikPoush"), value: '2' },
        { label: t("MaghChaitra"), value: '3' },
        { label: t("BaishakAshad"), value: '4' }
    ];

    const handleFiscalYear = (e) => {
        console.log("content  ", e.value);
        props.handleFiscalYearState(e.value);
    }

    const handleQuarter = (e) => {
        console.log("content  ", e.value);
        props.handleQuarterState(e.value);
    }

    return (
        <div className="p-grid p-col-12 p-md-12 ">
            <div className="p-col-12 p-md-6">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("fiscalYear")}<span style={{ color: "#d00000" }}> * </span>
                </div>
                <div className="p-field p-col-12 p-md-12 float-left">
                    <Dropdown
                        filter value={props.fiscalYearValue}
                        options={fiscalYearList}
                        onChange={handleFiscalYear}
                        placeholder={t("select")} />
                </div>
            </div>

            <div className="p-col-12 p-md-6">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("quarter")}<span style={{ color: "#d00000" }}> * </span>
                </div>
                <div className="p-field p-col-12 p-md-12 float-left">
                    <Dropdown
                        filter value={props.quarterValue}
                        options={quarterList}
                        onChange={handleQuarter}
                        placeholder={t("select")} />
                </div>
            </div>
        </div>
    )

}
export default FiscalYearGbv;