import React, { useState, useRef, useEffect, useLayoutEffect } from "react";

import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import AddressService from "../../security/api/services/AddressService";
import i18n from "../../il8n/il8n";
import { LANGUAGE, USER_LEVEL } from "../constants/ITMISConstansts";
import OrganizationService from "../../security/api/services/OrganizationService";
import UserService from "../../security/api/services/UserService";

const LookupAddress = (props) => {
    const [userLevel, setUserLevel] = useState();
    const [provincesList, setProvincesList] = useState([]);
    const [districtsList, setDistrictList] = useState([]);
    const [municipalitiesList, setMunicipalitiesList] = useState([]);
    const [addressValue, setAddressValue] = useState();
   

    const { t } = useTranslation();
    useEffect(() => {
        AddressService.getAllProvinces().then((response) => {
            setProvincesList(response.data.data);
        });

        let organizationAddress = {};
        OrganizationService.getLoggedInUserOrganizaitonDetails().then((response) => {
            console.log("response.data.data.name", response.data);
            organizationAddress = response.data.data.organizationAddress;
            UserService.getUserLevel().then((response) => {
                if (response.data.data == USER_LEVEL.PROVINCE) {
                    getDistrictValue(organizationAddress.province);
                    props.setValue("province", parseInt(organizationAddress.province));
                } else if (response.data.data == USER_LEVEL.DISTRICT) {
                    props.setValue("province", parseInt(organizationAddress.province));
                    props.setValue("district", parseInt(organizationAddress.district));
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                } else if (response.data.data == USER_LEVEL.LOCAL_LEVEL || response.data.data == USER_LEVEL.LOCAL_LEVEL_VERIFIER) {
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                    props.setValue("province", parseInt(organizationAddress.province));
                    props.setValue("district", parseInt(organizationAddress.district));
                    props.setValue("municipality", parseInt(organizationAddress.municipality));
                } 
                setUserLevel(response.data.data);
            });
        });
    }, []);

    const getDistrictValue = (provinceId) => {
        setMunicipalitiesList([]);
        AddressService.getAllDistrictsByProvinceId(provinceId).then((response) => {
            setDistrictList(response.data.data);
        });
    };

    const getMunicipalitiesList = (districtId) => {
        console.log("called", districtId);
        if (districtId !== undefined && districtId !== NaN) {
            AddressService.getAllMunicipalitiessByDistrictId(districtId).then((response) => {
                setMunicipalitiesList(response.data.data);
            });
        }
    };

    

    return (
        <div className="p-field p-col-12 p-md-12 ">
            <div className="p-field p-col-12 p-md-12 float-left main-label">{t("organization")}</div>
            <div className="p-field p-col-12 p-md-4 float-left">
                <Dropdown
                    filter
                    name="province"
                    placeholder={t("province")}
                    {...props.register("province")}
                    value={props.getValues("province")}
                    options={provincesList}
                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "provinceDescEng" : "provinceDescNep"}
                    optionValue="id"
                    onChange={(e) => {
                        props.setValue("province", e.value);
                        getDistrictValue(e.value);
                    }}
                    disabled={
                        userLevel == USER_LEVEL.PROVINCE ||
                        userLevel == USER_LEVEL.DISTRICT ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER
                    }
                />
            </div>
            <div className="p-field p-col-12 p-md-4 float-left">
                <Dropdown
                    filter
                    name="district"
                    value={props.getValues("district")}
                    placeholder={t("district")}
                    {...props.register("district")}
                    onChange={(e) => {
                        props.setValue("district", e.value);
                        getMunicipalitiesList(e.value);
                    }}
                    options={districtsList}
                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                    optionValue="id"
                    disabled={
                        userLevel == USER_LEVEL.DISTRICT ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER
                    }
                />
            </div>
            <div className="p-field p-col-12 p-md-4 float-left">
                <Dropdown
                    filter
                    name="municipality"
                    value={props.getValues("municipality")}
                    placeholder={t("municipality")}
                    {...props.register("municipality")}
                    onChange={(e) => {
                        props.setValue("municipality", e.value);
                        setAddressValue(e.value);
                    }}
                    options={municipalitiesList}
                    optionLabel={
                        i18n.language == LANGUAGE.ENGLISH ? "municipalityDescEng" : "municipalityDescNep"
                    }
                    optionValue="id"
                    disabled={
                        userLevel == USER_LEVEL.LOCAL_LEVEL ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER 
                    }
                />
            </div>
        </div>
    );
};
export default LookupAddress;
