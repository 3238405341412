import React, { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Image } from "primereact/image";
import LogoSmall from "../../scafolding/assets/images/logo-small.png";
import NepalFlag from "../../scafolding/assets/images/nepal_flag.gif";
import i18n from "../../il8n/il8n";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Card } from "primereact/card";
import { t } from 'i18next';

import { useHistory } from "react-router";
import { Button } from "primereact/button";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .10)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faq() {
  const [expanded, setExpanded] = React.useState();
  const [nepLanguage, setNepLanguage] = useState(i18n.language == "en" ? false : true);
  const history = useHistory();
  const onClick = data => console.log(data);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="wrapper" >
      <div className="topBar p-col-12 p-md-12" style={{ overflow: "hidden", display: "flex", flexDirection: "row", height: '18vh', alignItems: 'center' }}>
        <div className="p-col" style={{ float: "left", color: "#fff", flex: "1" }}>
          <a
            href="https://mowcsc.gov.np/"
            target="_blank"
          >
            <Image height={95} src={LogoSmall} />
          </a>
        </div>
        <div className="officeName" style={{ flex: "4" }}>

          <h4 class="gov" >{t("nepGov")}</h4>
          <h1 class="office-name" >{t("ministryOfWCS")}</h1>
          <h4 class="office-address" >{t("mowcscAddress")}</h4>

        </div>
        <div
          className="p-col"
          style={{ color: "#000", flex: "1", display: 'flex', flexDirection: 'row' }}
        >
          <Image height={95} src={NepalFlag} style={{ color: "#000", flex: "1" }} />
          <div className="landing" style={{ marginTop: 0, marginRight: 0, textAlign: 'right', flex: "1", right: 12 }}>
            <span className="en "> EN </span>
            <InputSwitch
              checked={nepLanguage}
              onChange={(e) => {
                setNepLanguage(e.value);
                if (e.value) {
                  changeLanguage("np");
                } else {
                  changeLanguage("en");
                }
              }}

            />
            <span className="np"> ने </span>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>

      <div className="p-col-12 p-md-12" style={{ paddingRight: "0px", marginTop: '17vh' }}>
        <Card
          className="p-mb-1"
          style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8", margin: "5px" }}
        >
          <div className=" p-card-content">
            <div style={{ marginBottom: "0.5rem" }}>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>{t("question1")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t("answer1")}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ marginBottom: "0.5rem" }}>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>{t("question2")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t("answer2")}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ marginBottom: "0.5rem" }}>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>{t("question3")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t("answer3")}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ marginBottom: "0.5rem" }}>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography>{t("question4")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t("answer4")}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ marginBottom: "0.5rem" }}>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <Typography>{t("question5")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t("answer5")}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <Button label={t("goBack")}
              className="p-button-sm pull-right submitBtn"
              onClick={() => history.push("/")} />
          </div>
        </Card>
      </div>
    </div>
  );
}