import axios from 'axios';
import AppProps from '../../../AppProps';

class JudicialCommitteeService {

  getData(data) {
    console.log("data",data);
    return axios.post(AppProps.API_BASE_URL + AppProps.JUDICIAL_COMMITTEE+"indicator",data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  saveData(data) {
    console.log(data, "inside post api");
    return axios.post(AppProps.API_BASE_URL + AppProps.JUDICIAL_COMMITTEE, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  submitData(data) {
    console.log(data, "inside put api");
    return axios.put(AppProps.API_BASE_URL + AppProps.JUDICIAL_COMMITTEE, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  editData(data,judicialCommitteeId) {
    console.log(data, "inside put api");
    return axios.put(AppProps.API_BASE_URL + AppProps.JUDICIAL_COMMITTEE+judicialCommitteeId, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }
}
export default new JudicialCommitteeService()