import axios from 'axios';
import AppProps from '../../../AppProps';
class NotificationService {

    getToBeSynchronizedList() {
        return axios.get(AppProps.API_BASE_URL + AppProps.NOTIFICATION + 'synchronize', {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        });
    }

    getRevertedList() {
        return axios.get(AppProps.API_BASE_URL + AppProps.NOTIFICATION + 'revert', {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        });
    }

    getRequestForVerificationList() {
        return axios.get(AppProps.API_BASE_URL + AppProps.NOTIFICATION + 'verification', {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        });
    }

    sendBackForCorrectionList(homeId) {
        return axios.get(AppProps.API_BASE_URL + AppProps.NOTIFICATION + 'correction/'+ homeId, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        });
    }

    
}
export default new NotificationService()