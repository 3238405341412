import axios from "axios";
import AppProps from "../../../AppProps";

class IdCardSequence {

  getIdCardSequence(data) {
    console.log("inside IdCardSequence get api");
    return axios.get(AppProps.API_BASE_URL + "/id-card/sequence/", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }
}
export default new IdCardSequence();
