import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";

const ChildFormTip = (props) => {
    const { t } = useTranslation();
    const [tipCaseType, setTipCaseType] = useState();

    // const tipCaseList = [
    //     { label: t("homeBased"), value: "Home Based" },
    //     { label: t("publicOfficesOrPlacesBased"), value: "Public offices or places Based" },
    //     { label: t("schoolBased"), value: "School Based" },
    //     { label: t("officeBased"), value: "Office Based" },
    //     { label: t("workPlaceBased"), value: "Work place Based" },
    //     { label: t("others"), value: "Others" },
    // ];

    const tipCaseList = [
        { label: t("sexTrafficking"), value: "Sex Trafficking" },
        { label: t("labourTrafficking"), value: "Labour Trafficking" },
        { label: t("organHarvestingTrafficking"), value: "Organ Trafficking" },
        { label: t("others"), value: "Others" },
    ];

    return (<>
        <Card className='p-mb-1' style={{ borderRadius: '8px 8px 0px 0px', background: '#f7f7f8' }}>
            <div className=' p-card-content'>
                <h3 className='p-pt-0'> {t("tip")}</h3>
            </div>
        </Card>

        <Card className='p-mt-0'>
            <div className=' p-card-content'>
                <div className="p-grid p-col-12 p-md-12 ">
                    <div className="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("humanTraffickingAndTransportation")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <Dropdown
                                filter
                                name="tipCaseType"
                                placeholder={t("select")}
                                {...props.register("tipCaseType"
                                    // , {
                                    //     required: "TipCase Type is Required",
                                    // }
                                )}
                                value={props.getValues("tipCaseType")}
                                options={tipCaseList}
                                onChange={(e) => {
                                    props.setValue("tipCaseType", e.value);
                                    setTipCaseType(e.value);
                                }}
                            />
                            {props.error.tipCaseType && props.error.tipCaseType.type === "required" && (
                                <small className="p-error">{props.error.tipCaseType.message}</small>
                            )}
                        </div>
                    </div>

                    {(props.getValues("tipCaseType") === "Others") ?
                        <div className="p-col-12 p-md-6">
                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                {t("pleaseSpDetails")}
                            </div>
                            <div className="p-field p-col-12 p-md-12 float-left">
                                <InputText
                                    name="tipCaseTypeDetail"
                                    {...props.register("tipCaseTypeDetail"
                                        // , {
                                        //   required: "TipCase Type Detail is Required",
                                        // }
                                    )}
                                    onChange={(e) => {
                                        props.setValue("tipCaseTypeDetail", e.target.value);
                                    }}
                                />
                                {props.error.tipCaseTypeDetail && props.error.tipCaseTypeDetail.type === "required" && (
                                    <small className="p-error">{props.error.tipCaseTypeDetail.message}</small>
                                )}
                            </div>
                        </div>
                        : <></>
                    }

                </div>
            </div>
        </Card>
    </>

    );
};
export default ChildFormTip;
