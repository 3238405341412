import React, { useState, useRef, useEffect, useLayoutEffect } from "react";

import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import AddressService from "../../security/api/services/AddressService";
import i18n from "../../il8n/il8n";
import { LANGUAGE, USER_LEVEL } from "../constants/ITMISConstansts";
import OrganizationService from "../../security/api/services/OrganizationService";
import UserService from "../../security/api/services/UserService";

const HttGbvHomeAddress = (props) => {
    const [userLevel, setUserLevel] = useState();
    const [provincesList, setProvincesList] = useState([]);
    const [districtsList, setDistrictList] = useState([]);
    const [municipalitiesList, setMunicipalitiesList] = useState([]);
    const [addressValue, setAddressValue] = useState();
    const [wardList, setWardList] = useState([
        { wardId: 1 },
        { wardId: 2 },
        { wardId: 3 },
        { wardId: 4 },
        { wardId: 5 },
    ]);

    const { t } = useTranslation();
    useEffect(() => {
        AddressService.getAllProvinces().then((response) => {
            setProvincesList(response.data.data);
        });
    }, []);

    useEffect(() => {
        let organizationAddress = {};
        OrganizationService.getLoggedInUserOrganizaitonDetails().then((response) => {
            console.log("response.data.data.name", response.data);
            organizationAddress = response.data.data.organizationAddress;
            UserService.getUserLevel().then((response) => {
                if (response.data.data == USER_LEVEL.PROVINCE) {
                    getDistrictValue(organizationAddress.province);
                    props.setValue("homeProvince", parseInt(organizationAddress.province));
                } else if (response.data.data == USER_LEVEL.DISTRICT) {
                    props.setValue("homeProvince", parseInt(organizationAddress.province));
                    props.setValue("homeDistrict", parseInt(organizationAddress.district));
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                } else if (response.data.data == USER_LEVEL.LOCAL_LEVEL ||
                    response.data.data == USER_LEVEL.LOCAL_LEVEL_VERIFIER ||
                    response.data.data == USER_LEVEL.SOCIAL_SERVICE) {
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                    getTotalWard(organizationAddress.municipality);
                    props.setValue("homeProvince", parseInt(organizationAddress.province));
                    props.setValue("homeDistrict", parseInt(organizationAddress.district));
                    props.setValue("homeMunicipality", parseInt(organizationAddress.municipality));
                } else if (response.data.data == USER_LEVEL.WARD) {
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                    getTotalWard(organizationAddress.municipality);
                    props.setValue("homeProvince", parseInt(organizationAddress.province));
                    props.setValue("homeDistrict", parseInt(organizationAddress.district));
                    props.setValue("homeMunicipality", parseInt(organizationAddress.municipality));
                    props.setValue("homeWardNo", parseInt(organizationAddress.ward));
                }
                setUserLevel(response.data.data);
            });
        });
    }, []);

    useEffect(() => {
        if (props.provinceId) {
          getDistrictValue(props.getValues("homeProvince"));
          getMunicipalitiesList(props.getValues("homeDistrict"));
          getTotalWard(props.getValues("homeMunicipality"));
          props.setValue("homeProvince", parseInt(props.getValues("homeProvince")));
          props.setValue("homeDistrict", parseInt(props.getValues("homeDistrict")));
          props.setValue("homeMunicipality", parseInt(props.getValues("homeMunicipality")));
          props.setValue("homeWardNo", parseInt(props.getValues("homeWardNo")));
        }
      }, [props.provinceId]);

    const getDistrictValue = (provinceId) => {
        setMunicipalitiesList([]);
        setWardList([]);
        AddressService.getAllDistrictsByProvinceId(provinceId).then((response) => {
            setDistrictList(response.data.data);
        });
    };

    const getMunicipalitiesList = (districtId) => {
        console.log("called", districtId);
        if (districtId !== undefined && districtId !== NaN) {
            AddressService.getAllMunicipalitiessByDistrictId(districtId).then((response) => {
                setMunicipalitiesList(response.data.data);
            });
        }
    };

    const getTotalWard = (municipalityId) => {
        AddressService.getTotalWard(municipalityId).then((response) => {
            const totalWard = response.data.data;
            const list = [];
            for (var i = 1; i <= response.data.data; i++) {
                list.push({
                    wardId: i,
                });
            }
            setWardList(list);
        });
    };

    return (
        <div className="p-field p-col-12 p-md-12 ">
            <div className="p-field p-col-12 p-md-12 float-left main-label">{t("organization")}</div>
            <div className="p-field p-col-12 p-md-3 float-left">
                <Dropdown
                    filter
                    name="homeProvince"
                    placeholder={t("province")}
                    {...props.register("homeProvince")}
                    value={props.getValues("homeProvince")}
                    options={provincesList}
                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "provinceDescEng" : "provinceDescNep"}
                    optionValue="id"
                    onChange={(e) => {
                        props.setValue("homeProvince", e.value);
                        getDistrictValue(e.value);
                    }}
                    disabled={
                        userLevel == USER_LEVEL.PROVINCE ||
                        userLevel == USER_LEVEL.DISTRICT ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER ||
                        userLevel == USER_LEVEL.SOCIAL_SERVICE ||
                        userLevel == USER_LEVEL.WARD
                    }
                />
            </div>
            <div className="p-field p-col-12 p-md-3 float-left">
                <Dropdown
                    filter
                    name="homeDistrict"
                    value={props.getValues("homeDistrict")}
                    placeholder={t("district")}
                    {...props.register("homeDistrict")}
                    onChange={(e) => {
                        props.setValue("homeDistrict", e.value);
                        getMunicipalitiesList(e.value);
                    }}
                    options={districtsList}
                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                    optionValue="id"
                    disabled={
                        userLevel == USER_LEVEL.DISTRICT ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER ||
                        userLevel == USER_LEVEL.SOCIAL_SERVICE ||
                        userLevel == USER_LEVEL.WARD
                    }
                />
            </div>
            <div className="p-field p-col-12 p-md-3 float-left">
                <Dropdown
                    filter
                    name="homeMunicipality"
                    value={props.getValues("homeMunicipality")}
                    placeholder={t("municipality")}
                    {...props.register("homeMunicipality")}
                    onChange={(e) => {
                        props.setValue("homeMunicipality", e.value);
                        getTotalWard(e.value);
                        setAddressValue(e.value);
                    }}
                    options={municipalitiesList}
                    optionLabel={
                        i18n.language == LANGUAGE.ENGLISH ? "municipalityDescEng" : "municipalityDescNep"
                    }
                    optionValue="id"
                    disabled={
                        userLevel == USER_LEVEL.LOCAL_LEVEL ||
                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER ||
                        userLevel == USER_LEVEL.SOCIAL_SERVICE ||
                        userLevel == USER_LEVEL.WARD
                    }
                />
            </div>
            <div className="p-field p-col-12 p-md-3 float-left">
                <Dropdown
                    filter
                    name="homeWardNo"
                    value={props.getValues("homeWardNo")}
                    placeholder={t("wardNo")}
                    {...props.register("homeWardNo")}
                    onChange={(e) => {
                        props.setValue("homeWardNo", e.value);
                        setAddressValue(e.value);
                    }}
                    options={wardList}
                    optionLabel="wardId"
                    optionValue="wardId"
                />
            </div>
        </div>
    );
};
export default HttGbvHomeAddress;
