import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ACTION, DISABILITY_TYPE_ENGLISH, DISABILITY_TYPE_NEPALI, GENDER_NEPALI, ID_CARD_SIZE, ID_CATEGORY_ENGLISH, ID_CATEGORY_NEPALI, LANGUAGE, USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import DisabledIdCardService from "../api/services/DisabledIdCardService";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { trackPromise } from "react-promise-tracker";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { confirmDialog } from "primereact/confirmdialog";

import IdCardAddress from "../../utilities/components/IdCardAddress";
import UserService from "../../security/api/services/UserService";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { Dropdown } from "primereact/dropdown";
import i18n from "../../il8n/il8n";

const DisabilityIDCardList = () => {
  const { t } = useTranslation();
  const [disabilityIdCardList, setDisabilityIdCardList] = useState([]);
  const [cardTypeModal, setCardTypeModal] = useState(false);
  const [view, setView] = useState(false);
  const [cardSize, setCardSize] = useState();
  const [idCardNo, setIdCardNo] = useState();
  const [organization, setOrganization] = useState("");
  const history = useHistory();
  const toast = useRef(null);
  const [idCardTypeList, setIdCardTypeList] = useState([
    {
      value: "ka",
      engName: "(Ka) Completely disabled (Red)",
      nepName: "(क) पूर्ण अशक्त (रातो)",
      color: "Red",
    },
    {
      value: "kha",
      engName: "(Kha) Extremely disabled (Blue)",
      nepName: "(ख) अति अशक्त (निलो)",
      color: "Blue",
    },
    {
      value: "ga",
      engName: "(Ga) Moderate (Yellow)",
      nepName: "(ग) मध्यम (पहेंलो)",
      color: "Yellow",
    },
    {
      value: "gha",
      engName: "(Gha) General (White)",
      nepName: "(घ) सामान्य (सेतो)",
      color: "White",
    }
  ]);

  const options = {
    filter: true,
    print: false,
    filterType: "dropdown",
    responsive: "vertical",
    tableBodyHeight: "600px",
    tableBodyMaxHeight: "",
    fixedSelectColumn: false,
    selectableRows: false,
    onDownload: (buildHead, buildBody, columns, data) => {
      console.log("coluumns", columns);
      console.log("data", data);
      let list = [];
      data.forEach(subdata => {
        var gender = (i18n.language == LANGUAGE.ENGLISH) ? subdata.data[2] : GENDER_NEPALI[subdata.data[2]];
          var idCardType =(i18n.language == LANGUAGE.ENGLISH) ? ID_CATEGORY_ENGLISH[subdata.data[4]] : ID_CATEGORY_NEPALI[subdata.data[4]];
          var disabilityType =(i18n.language == LANGUAGE.ENGLISH) ? DISABILITY_TYPE_ENGLISH[subdata.data[5]] : DISABILITY_TYPE_NEPALI[subdata.data[5]];
        let subList = ["\n" + subdata.data[0], subdata.data[1],
        gender, subdata.data[3],
        idCardType, disabilityType,
        subdata.data[6], subdata.data[7], subdata.data[8], subdata.data[9], 
        subdata.data[10], subdata.data[11], subdata.data[12], subdata.data[13], subdata.data[14]];
        list.push(subList);
        console.log("list", list)
      })
      buildHead = () => {
        return [
          columns[0].label, columns[1].label,
          columns[2].label, columns[3].label,
          columns[4].label, columns[5].label,
          columns[6].label, columns[7].label,
          columns[8].label, columns[9].label,
          columns[10].label, columns[11].label,
          columns[12].label, columns[13].label,
          columns[14].label
        ]
      }
      buildBody = () => {
        return [list];
      }
      return "\uFEFF" + buildHead() + buildBody();
    }

  };
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });

  const columns = [
    {
      name: "idCardNo",
      label: t("enteredIdCardNo"),
      options: {},
    },
    {
      name: "fullName",
      label: t("name"),
      options: {
        //  customBodyRender: (value, tableMeta, updateValue) => {
        // console.log(tableMeta.rowData[0] + tableMeta.rowData[1] + tableMeta.rowData[2]);
        // return (
        //   tableMeta.rowData[0] +
        //   " " +
        //   (tableMeta.rowData[1] ? tableMeta.rowData[1] : "") +
        //   " " +
        //   tableMeta.rowData[2]
        // );
        //  },
      },
    },
    // {
    //   name: "middleNameEng",
    //   label: "Name",
    //   options: {
    //     display: false,
    //     filter: false,
    //     sort: false,
    //   },
    // },
    // {
    //   name: "lastNameEng",
    //   label: "Name",
    //   options: {
    //     display: false,
    //     filter: false,
    //     sort: false,
    //   },
    // },
    {
      name: "gender",
      label: t("gender"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (i18n.language == LANGUAGE.ENGLISH) {
            return value;
          } else {
            return GENDER_NEPALI[value];
          }
        }
      },
    },
    {
      name: "id",
      label: t("generatedIdCardNo"),
      options: {},
    },
    {
      name: "idCardType",
      label: t("idCardType"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (i18n.language == LANGUAGE.ENGLISH) {
            return ID_CATEGORY_ENGLISH[value];
          } else {
            return ID_CATEGORY_NEPALI[value];
          }
        }
      },
    },
    {
      name: "disabilityType",
      label: t("typesOfDisability"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (i18n.language == LANGUAGE.ENGLISH) {
            return DISABILITY_TYPE_ENGLISH[value];
          } else {
            return DISABILITY_TYPE_NEPALI[value];
          }
        }
      },
    },
    {
      name: "provinceDescEng",
      label: t("province"),
      options: { 
        display: (i18n.language == LANGUAGE.ENGLISH) ? true : false,
        filter: (i18n.language == LANGUAGE.ENGLISH) ? true : false,
        sort: (i18n.language == LANGUAGE.ENGLISH) ? true : false
      },
    },
    {
      name: "provinceDescNep",
      label: t("province"),
      options: { 
        display: (i18n.language == LANGUAGE.NEPALI) ? true : false,
        filter: (i18n.language == LANGUAGE.NEPALI) ? true : false,
        sort: (i18n.language == LANGUAGE.NEPALI) ? true : false
      },
    },
    {
      name: "districtDescEng",
      label: t("district"),
      options: { 
        display: (i18n.language == LANGUAGE.ENGLISH) ? true : false,
        filter: (i18n.language == LANGUAGE.ENGLISH) ? true : false,
        sort: (i18n.language == LANGUAGE.ENGLISH) ? true : false
      },
    },
    {
      name: "districtDescNep",
      label: t("district"),
      options: { 
        display: (i18n.language == LANGUAGE.NEPALI) ? true : false,
        filter: (i18n.language == LANGUAGE.NEPALI) ? true : false,
        sort: (i18n.language == LANGUAGE.NEPALI) ? true : false
      },
    },
    {
      name: "municipalityDescEng",
      label: t("municipality"),
      options: { 
        display: (i18n.language == LANGUAGE.ENGLISH) ? true : false,
        filter: (i18n.language == LANGUAGE.ENGLISH) ? true : false,
        sort: (i18n.language == LANGUAGE.ENGLISH) ? true : false
      },
    },
    {
      name: "municipalityDescNep",
      label: t("municipality"),
      options: { 
        display: (i18n.language == LANGUAGE.NEPALI) ? true : false,
        filter: (i18n.language == LANGUAGE.NEPALI) ? true : false,
        sort: (i18n.language == LANGUAGE.NEPALI) ? true : false
      },
    },
    {
      name: "dobEng",
      label: t("dateOfBirth"),
      options: {},
    },
    {
      name: "bloodGroup",
      label: t("bloodGroup"),
      options: {},
    },
    // {
    //   name: "typeOfDisabilityByNature",
    //   label: "Disability By Nature",
    //   options: {},
    // },
    // {
    //   name: "typeOfDisabilityBySeverity",
    //   label: "Disability By Severity",
    //   options: {},
    // },
    {
      name: "citizenshipNo",
      label: t("citizenshipNo"),
      options: {},
    },
    {
      name: "id",
      label: t("actions"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <React.Fragment>

              <Tooltip target=".tooltip-icon" />
              {
                view ?
                  <i
                    className="pi pi-eye tooltip-icon "
                    data-pr-tooltip="View Details"
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    onClick={() => {
                      setCardTypeModal(true);
                      setIdCardNo(value);
                    }}
                  />
                  :
                  <>
                    <i
                      className="pi pi-pencil tooltip-icon p-mr-2 "
                      data-pr-tooltip="Update Details"
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      onClick={() =>
                        history.push({
                          pathname: "/sims/disability-IDCard-form",
                          state: {
                            id: value,
                            action: ACTION.UPDATE,
                          },
                        })
                      }
                    />

                    <i
                      className="pi pi-eye tooltip-icon p-mr-2"
                      data-pr-tooltip="View Details"
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      onClick={() => {
                        setCardTypeModal(true);
                        setIdCardNo(value);
                      }}
                    />

                    <i
                      className="pi pi-trash tooltip-icon "
                      data-pr-tooltip="Delete"
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      onClick={() => {
                        confirmDeleteCard(value);
                      }}
                    />
                  </>
              }
            </React.Fragment>
          );
        },
      },
    },
  ];

  const acceptDelete = (cardId) => {
    DisabledIdCardService.deleteCard(cardId).then((response) => {
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success Message",
          detail: "Delete Successful",
          life: 3000,
        });
        window.location.reload(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Delete UnSuccessful",
          life: 3000,
        });
      }
    });
  };

  const confirmDeleteCard = (userId) => {
    confirmDialog({
      message: "Are you sure you want to Delete?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptDelete(userId),
      reject: () => rejectFunc(),
    });
  };

  const rejectFunc = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  useEffect(() => {
    UserService.getUserLevel().then((response) => {
      if (response.data.data === USER_LEVEL.LOCAL_LEVEL || response.data.data === USER_LEVEL.WARD) {
        setView(false);
      } else {
        setView(true);
      }
    });

    trackPromise(
      DisabledIdCardService.getAllDisabledIdCardList().then((response) => {
        console.log("response.data.data", response.data.data);
        setDisabilityIdCardList(response.data.data);
      })
    );
  }, []);

  const onSubmit = (data) => {
    console.log("data", data);
    trackPromise(
      DisabledIdCardService.searchDisabledIdCardDetails(
        data.name,
        data.district,
        data.citizenshipNo
      ).then((response) => {
        if (response.status === 200) {
          setDisabilityIdCardList(response.data.data);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error Message",
            detail: "Update UnSuccessful",
            life: 3000,
          });
        }
      })
    );
  };

  const onHide = () => {
    setCardTypeModal(false);
  };

  const idCardPage = () => {
    trackPromise(
      DisabledIdCardService.getDisabledIdCardDetailsById(idCardNo).then((response) => {
        if (response.status === 200) {
          cardSize == ID_CARD_SIZE.NORMAL
            ? history.push({
              pathname: "/sims/disability-IDCard",
              state: {
                data: response.data.data,
              },
            })
            : history.push({
              pathname: "/sims/disability-IDCard-smart",
              state: {
                data: response.data.data,
              },
            });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error Message",
            detail: "Update UnSuccessful",
            life: 3000,
          });
        }
      })
    );
  };

  const handleOrganization = (organizationId) => {
    setOrganization(organizationId);
  };

  const searchData = (data) => {
    trackPromise(
      DisabledIdCardService.getDisabledIdCardListByAddress(data).then((response) => {
        console.log("response.data", response.data.data);
        setDisabilityIdCardList(response.data.data);
      })
    );
  };

  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Toast ref={toast} />
      <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
        <div className=" p-card-content">
          <h4 className="p-pt-0">{t("Disabled ID Card List")}</h4>
        </div>
      </Card>
      <Card className="p-mt-0">
        <form className="p-grid p-fluid p-mt-3">
          <IdCardAddress register={register} error={errors} setValue={setValue} getValues={getValues} />

          <div className="p-field p-col-12 p-md-6 float-left">
            <div class="p-field p-col-12 p-md-12 float-left main-label">
              {t("idCardType")}
            </div>
            <div class="p-field p-col-12 p-md-12 float-left">
              <Controller
                name="idCardType"
                control={control}
                render={({ field, fieldState }) => (
                  <Dropdown
                    filter
                    id={field.name}
                    value={field.value}
                    placeholder={t("select")}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    style={{ width: "100%" }}
                    options={idCardTypeList}
                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "engName" : "nepName"}
                    optionValue="value"
                  />
                )}
              />
            </div>
          </div>
          <div className="p-field p-col-12 p-md-12 float-left">
            <div class="p-field p-col-12 p-md-6 float-left main-label">{t("fromDate")}  </div>
            <div class="p-field p-col-12 p-md-6 float-left main-label">{t("toDate")}  </div>
            {/* <div class="p-field p-col-12 p-md-6 float-left">
              <Controller
                name="dateNep"
                control={control}
                render={({ field, fieldState }) => (
                  <NepaliDatePicker
                    inputClassName="p-inputtext form-control"
                    className=""
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    options={{ calenderLocale: "ne", valueLocale: "en" }}
                  />
                )}
              />
            </div> */}
            <div class="p-field p-col-12 p-md-6 float-left">
              <Controller
                name="fromDate"
                control={control}
                render={({ field, fieldState }) => (
                  <NepaliDatePicker
                    inputClassName="p-inputtext form-control"
                    className=""
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    options={{ calenderLocale: "ne", valueLocale: "en" }}
                  />
                )}
              />
            </div>
            <div class="p-field p-col-12 p-md-6 float-left">
              <Controller
                name="toDate"
                control={control}
                render={({ field, fieldState }) => (
                  <NepaliDatePicker
                    inputClassName="p-inputtext form-control"
                    className=""
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    options={{ calenderLocale: "ne", valueLocale: "en" }}
                  />
                )}
              />
            </div>
          </div>

          <div className="p-field p-col-12 p-md-12 ">
            <div className="p-field p-col-10 p-md-10 float-left"></div>
            <div className="p-field p-col-2 p-md-2 float-right">
              <Button
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#1c80cf",
                  color: "#FFF",
                }}
                label={t("search")}
                onClick={handleSubmit(searchData)}
              />
            </div>
          </div>
          {/* <div className="p-grid p-col-12 p-md-12 ">
            <div className="p-col-4 p-md-4">
              <div className="p-field p-col-12 p-md-12 float-left main-label">{t("name")}:</div>
              <div className="p-field p-col-12 p-md-12 float-left">
                <Controller
                  name="name"
                  control={control}
                  // rules={{ required: "Disability By Nature is required." }}
                  render={({ field, fieldState }) => (
                    <InputText id={field.name} {...field} className="rounded-input p-mb-1" />
                  )}
                />
              </div>
            </div>
            <div className="p-col-4 p-md-4">
              <div className="p-field p-col-12 p-md-12 float-left main-label">{t("district")}:</div>
              <div className="p-field p-col-12 p-md-12 float-left">
                <Controller
                  name="district"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      placeholder={t("select")}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      style={{ width: "100%" }}
                      options={districtList}
                      optionLabel={
                        i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"
                      }
                      optionValue="id"
                    />
                  )}
                />
              </div>
            </div>
            <div className="p-col-4 p-md-4">
              <div className="p-field p-col-12 p-md-12 float-left main-label">
                {t("citizenshipNo")}:
              </div>
              <div className="p-field p-col-12 p-md-12 float-left">
                <Controller
                  name="citizenshipNo"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText id={field.name} {...field} className="rounded-input p-mb-1" />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="p-field p-col-12 p-md-12 float-left">
            <div class="p-field p-col-10 p-md-10 float-left">&nbsp;</div>
            <div class="p-field p-col-2 p-md-2 float-right">
              <Button
                style={{
                  background: "#4e70ae",
                  color: "#FFF",
                  justifyContent: "center",
                }}
                //onClick={() => alert()}
              >
                {t("generate")}
              </Button>
            </div>
          </div> */}
        </form>
        <div className=" p-card-content">
          <ThemeProvider
            theme={createTheme({
              overrides: {
                MUIDataTableToolbar: { root: { display: "none" } },
              },
            })}
          >
            <MUIDataTable
              //   title={"Labour Migration List"}
              data={disabilityIdCardList}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>
        <Dialog
          header="Card Type"
          visible={cardTypeModal}
          style={{ width: "25vw" }}
          modal
          onHide={onHide}
        >
          <div className="col-12 p-mb-2">
            <Checkbox
              inputId="cb1"
              value="Smart Card Size"
              onChange={(e) => {
                setCardSize(ID_CARD_SIZE.SMART);
              }}
              checked={cardSize == ID_CARD_SIZE.SMART}
            ></Checkbox>
            <label htmlFor="cb1" className="p-checkbox-label p-ml-2">
              Smart Card Size (8.5cm X 5.2cm)
            </label>
          </div>
          <div className="col-12 p-mb-2">
            <Checkbox
              inputId="cb2"
              value="Normal Size"
              onChange={(e) => {
                setCardSize(ID_CARD_SIZE.NORMAL);
              }}
              checked={cardSize == ID_CARD_SIZE.NORMAL}
            ></Checkbox>
            <label htmlFor="cb2" className="p-checkbox-label p-ml-2">
              Normal Size (10.7cm X 7.4cm)
            </label>
          </div>

          <Button
            style={{ background: "#4e70ae", color: "#FFF", justifyContent: "center", alignContent: "center" }}
            onClick={idCardPage}
          >
            Generate
          </Button>
        </Dialog>
      </Card>
    </div>
  );
};
export default DisabilityIDCardList;
