import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { trackPromise } from "react-promise-tracker";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import UserService from "../../security/api/services/UserService";
import { USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import DetailService from "../api/services/DetailService";
import HttGbvHomeAddress from "../../utilities/components/HttGbvHomeAddress";
import HomeDetailLookup from "../../utilities/components/HomeDetailLookup";

const GbvShelterHomeList = () => {
  const { t } = useTranslation();
  const [homeList, setHomeList] = useState([]);
  const [homeDetail, setHomeDetail] = useState({});
  const [personalDetailList, setPersonalDetailList] = useState([]);
  const [showPersonalDetail, setShowPersonalDetail] = useState(false);
  const [organization, setOrganization] = useState("");
  const [name, setName] = useState("");
  const [view, setView] = useState(false);
  const history = useHistory();

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    register
  } = useForm();

  const options = {
    filter: true,
    print: false,
    filterType: "dropdown",
    responsive: "vertical",
    fixedSelectColumn: false,
    selectableRows: false,
  };

  useEffect(() => {
    UserService.getUserLevel().then((response) => {
      if (response.data.data === USER_LEVEL.SOCIAL_SERVICE) {
        setView(true);
      } else {
        setView(false);
      }
    });
  });

  const columns = [
    {
      name: "caseId",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    }, {
      name: "homeName",
      label: t("shelterHome"),
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "fiscalYear",
      label: t("fiscalYear"),
      options: {},
    },
    {
      name: "quarter",
      label: t("quarter"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === '1') {
            return "Shrawan-Ashwin";
          } else if (value === '2') {
            return "Kartik-Poush";
          } else if (value === '3') {
            return "Magh-Chaitra";
          } else {
            return "Baishak-Ashad";
          }
        }
      },
    },
    {
      name: "maleGbvCases",
      label: t("maleGbvCase"),
      options: {},
    },
    {
      name: "femaleGbvCases",
      label: t("femaleGbvCase"),
      options: {},
    },
    {
      name: "otherGbvCases",
      label: t("otherGbvCase"),
      options: {},
    },
    {
      name: "personalDetail",
      label: t("actions"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <React.Fragment>
              {
                view ?
                  <>
                    <Button
                      className="pi pi-eye"
                      tooltip="View Personal Details"
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      style={{ marginRight: "4px", padding: "5px", width: "28px" }}
                      onClick={() => {
                        setPersonalDetailList(value);
                        setName(tableMeta.rowData[1]);
                        setShowPersonalDetail(true);
                        setHomeDetail({
                          caseId: tableMeta.rowData[0]
                        });
                      }}
                    />
                    <Button
                      className="pi pi-plus"
                      tooltip="Add Personal Details"
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      style={{ marginLeft: "4px", padding: "5px", width: "28px" }}
                      onClick={() => {
                        history.push({
                          pathname: "/sims/gbv-shelter-home-personal",
                          state: {
                            data: {
                              caseId: tableMeta.rowData[0]
                            },
                            view: false
                          },
                        })
                      }
                      }
                    /></>
                  :

                  <Button
                    className="pi pi-eye"
                    tooltip="View Personal Details"
                    tooltipOptions={{
                      position: "bottom"
                    }}
                    style={{ marginRight: "4px", padding: "5px", width: "28px" }}
                    onClick={() => {
                      setPersonalDetailList(value);
                      setName(tableMeta.rowData[1]);
                      setShowPersonalDetail(true);
                      setHomeDetail({
                        caseId: tableMeta.rowData[0]
                      });
                    }}
                  />
              }
            </React.Fragment>
          );
        },
      },
    },
  ];

  const searchData = (data) => {
    data.homeType = "ShelterHome";
    console.log("data ", data);
    trackPromise(
      DetailService.getHomeListByAddress(data).then((response) => {
        console.log("response.data", response.data.data);
        setHomeList(response.data.data);
      })
    );
  };

  const personalDetailColumn = [
    {
      name: "personalDetailId",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "firstName",
      label: t("name"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[1] + " " + tableMeta.rowData[2] + " " + tableMeta.rowData[3];
        }
      },
    },
    {
      name: "middleName",
      label: "Name",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "lastName",
      label: "Name",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "gender",
      label: t("gender"),
      options: {},
    },
    {
      name: "age",
      label: t("age"),
      options: {},
    },
    {
      name: "caste",
      label: t("casteEthnicity"),
      options: {},
    },
    {
      name: "status",
      label: t("status"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value==="Save") {
            return "Saved";
          } else if(value==="Request") {
            return "Requested for Verification to Local Level Verifier";
          } else if(value==="Verified") {
            return "Verified by Local Level Verifier";
          } else if(value==="NeedCorrection") {
            return "Send back for Correction by Local Level Verifier";
          }
        }
      },
    },
    {
      name: "Actions",
      label: t("actions"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <React.Fragment>
              {
                view ?
                  <>
                    <Button
                      className="pi pi-eye"
                      tooltip="View Detail"
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      style={{ marginRight: "4px", padding: "5px", width: "28px" }}
                      onClick={() => {
                        history.push({
                          pathname: "/sims/gbv-shelter-home-personal",
                          state: {
                            data: homeDetail,
                            personalDetailId: tableMeta.rowData[0],
                            view: true
                          },
                        })
                      }}
                    />
                    <Button
                      className="pi pi-pencil"
                      tooltip="Update Detail"
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      style={{ marginLeft: "4px", padding: "5px", width: "28px" }}
                      onClick={() => {
                        console.log("rowdata0", tableMeta.rowData);
                        history.push({
                          pathname: "/sims/gbv-shelter-home-personal",
                          state: {
                            data: homeDetail,
                            personalDetailId: tableMeta.rowData[0],
                            view: false
                          },
                        })
                      }
                      }
                    /></>
                  :
                  <Button
                    className="pi pi-eye"
                    tooltip="View Detail"
                    tooltipOptions={{
                      position: "bottom"
                    }}
                    style={{ marginRight: "4px", padding: "5px", width: "28px" }}
                    onClick={() => {
                      history.push({
                        pathname: "/sims/gbv-shelter-home-personal",
                        state: {
                          data: homeDetail,
                          personalDetailId: tableMeta.rowData[0],
                          view: true
                        },
                      })
                    }}
                  />
              }
            </React.Fragment>
          );
        },
      },
    },
  ];


  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
        <div className=" p-card-content">
          <h4 className="p-pt-0">{t("shelterHome")}</h4>
        </div>
      </Card>

      <div className="p-grid p-col-12 p-md-12">
        <div className="p-field p-col-12 p-md-3">
          <Button className="box-shadow"
            style={{
              justifyContent: "center",
              background: "rgb(75 125 181)",
              color: "#FFF",
              width: '100%',
              minHeight: '30px',
              border: '4px solid rgb(208 0 0)',
            }}
            label={t("homeDetails")}
            onClick={() =>
              history.push("/sims/gbv-shelter-home")
            }
          />
        </div>
        <div className="p-field p-col-12 p-md-3">
          <Button className="box-shadow"
            style={{
              justifyContent: "center",
              background: "rgb(75 125 181)",
              color: "#FFF",
              width: '100%',
              minHeight: '30px',
              border: '4px solid rgb(208 0 0)',
            }}
            label={t("list")}
            disabled
          />
        </div>
      </div>
      <Card className="p-mt-0">
        <div>
        <form className="p-grid p-fluid ">
          <HomeDetailLookup homeType="ShelterHome" register={register} error={errors} setValue={setValue} getValues={getValues} />

            <div className="p-field p-col-12 p-md-12 ">
              <div className="p-field p-col-10 p-md-10 float-left"></div>
              <div className="p-field p-col-2 p-md-2 float-right">
                <Button
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#1c80cf",
                    color: "#FFF",
                  }}
                  label={t("search")}
                  onClick={handleSubmit(searchData)}
                />
              </div>
            </div>
          </form>
        </div>

        <hr style={{ margin: "10px" }}></hr>

        <div className=" p-card-content">
          <ThemeProvider
            theme={createTheme({
              overrides: {
                MUIDataTableToolbar: { root: { display: "none" } },
              },
            })}
          >
            <MUIDataTable
              data={homeList}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>

        <hr style={{ marginTop: "40px" }}></hr>
        {showPersonalDetail === true ? <div className=" p-card-content">
          <ThemeProvider
            theme={createTheme({
              overrides: {
                MUIDataTableToolbar: { root: { display: "none" } },
              },
            })}
          >
            <MUIDataTable
              title={t("personalDetails")}
              data={personalDetailList}
              columns={personalDetailColumn}
              options={options}
            />
          </ThemeProvider>
        </div> : <></>
        }
      </Card>
    </div>
  );
};
export default GbvShelterHomeList;

