import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import UserCreationService from "../api/services/UserCreationService";
import { useForm, Controller } from "react-hook-form";
import { LANGUAGE, USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import OrganizationService from "../../security/api/services/OrganizationService";
import RoleService from "../../security/api/services/RoleService";
import AddressService from "../../security/api/services/AddressService";
import UserService from "../../security/api/services/UserService";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { trackPromise } from "react-promise-tracker";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { classNames } from "primereact/utils";
import { t } from "i18next";
import i18n from "../../il8n/il8n";
import HomeLookupService from "../../Lookup/api/services/HomeLookupService";
import { InputNumber } from "primereact/inputnumber";

const UserCreationForm = () => {
  const [userList, setUserList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [provincesList, setProvincesList] = useState([]);
  const [districtsList, setDistrictList] = useState([]);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);
  const [userLevel, setUserLevel] = useState();
  const [userLevelVal, setUserLevelVal] = useState();
  const [wardList, setWardList] = useState([
    { wardId: 1 },
    { wardId: 2 },
    { wardId: 3 },
    { wardId: 4 },
    { wardId: 5 },
  ]);

  const options = {
    filter: true,
    print: false,
    filterType: "dropdown",
    responsive: "vertical",
    fixedSelectColumn: false,
    selectableRows: "none",
  };

  const [userLevelList, setUserLevelList] = useState([
    {
      id: 1,
      value: "MINISTRY",
      engName: "MINISTRY",
      nepName: "MINISTRY",
    },
    {
      id: 2,
      value: "PROVINCE",
      engName: "PROVINCE",
      nepName: "PROVINCE",
    },
    {
      id: 3,
      value: "DISTRICT",
      engName: "DISTRICT",
      nepName: "DISTRICT",
    },
    {
      id: 4,
      value: "LOCAL LEVEL",
      engName: "LOCAL LEVEL",
      nepName: "LOCAL LEVEL",
    },
    {
      id: 4,
      value: "LOCAL LEVEL VERIFIER",
      engName: "LOCAL LEVEL VERIFIER",
      nepName: "LOCAL LEVEL VERIFIER",
    },
    {
      id: 5,
      value: "WARD",
      engName: "WARD",
      nepName: "WARD",
    },
    {
      id: 6,
      value: "SOCIAL SERVICE",
      engName: "SOCIAL SERVICE",
      nepName: "SOCIAL SERVICE",
    },
    // {
    //   value: "OTHERS",
    //   engName: "OTHERS",
    //   nepName: "OTHERS",
    // },
  ]);
  const [buttonLabel, setButtonLabel] = useState("Create");
  const toast = useRef(null);
  const [homeNameList, setHomeNameList] = useState([]);
  const [homeTypeList, setHomeTypeList] = useState([
    {
      value: "ShelterHome",
      nameEng: "Shelter Home",
      nameNep: "सुरक्षित आवास गृह",

    },
    {
      value: "ServiceCenter",
      nameEng: "Service Center",
      nameNep: "सेवा केन्द्र",

    },
    {
      value: "ChildCareHome",
      nameEng: "Child Care Home",
      nameNep: "बाल सेवा गृह",

    },
    {
      value: "ChildCorrectionHome",
      nameEng: "Child Correction Home",
      nameNep: "बाल सुधार गृह",

    },
  ]);

  useEffect(() => {
    UserCreationService.getUserList().then((response) => {
      setUserList(response.data.data);
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data.error_description);
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: error.response.data.error_description,
          life: 3000,
        });
      } else if (error.request) {
        console.log(error.request);
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Network Error",
          life: 3000,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    });
    // OrganizationService.getSocialServiceOrganization().then((response) => {
    //   setOrganizationList(response.data.data);
    // });
    AddressService.getAllProvinces().then((response) => {
      setProvincesList(response.data.data);
    });

    let organizationAddress = {};
    OrganizationService.getLoggedInUserOrganizaitonDetails().then((response) => {
      console.log("response.data.data.name", response.data);
      organizationAddress = response.data.data.organizationAddress;
      UserService.getUserLevel().then((response) => {
        setUserLevelVal(response.data.data);
        userLevelList.map(data => {
          if (data.value === response.data.data) {
            let index = data.id;
            const newList = userLevelList.filter(item => item.id >= index);
            setUserLevelList(newList);
          }
        });

        console.log("SDfhzdfhgfhgf");
        RoleService.getRolesList(response.data.data).then((response) => {
          console.log("role list>>>>>>>", response.data.data);
          setRoleList(response.data.data);
        });

        if (response.data.data == USER_LEVEL.PROVINCE) {
          getDistrictValue(organizationAddress.province);
          setValue("province", parseInt(organizationAddress.province));
        } else if (response.data.data == USER_LEVEL.DISTRICT) {
          getDistrictValue(organizationAddress.province);
          getMunicipalitiesList(organizationAddress.district);
          setValue("province", parseInt(organizationAddress.province));
          setValue("district", parseInt(organizationAddress.district));
        } else if (response.data.data == USER_LEVEL.LOCAL_LEVEL || response.data.data == USER_LEVEL.LOCAL_LEVEL_VERIFIER) {
          getDistrictValue(organizationAddress.province);
          getMunicipalitiesList(organizationAddress.district);
          getTotalWard(organizationAddress.municipality);
          setValue("province", parseInt(organizationAddress.province));
          setValue("district", parseInt(organizationAddress.district));
          setValue("municipality", parseInt(organizationAddress.municipality));
        }
        setUserLevel(response.data.data);
      });
    });


  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm({
    province: "",
  });
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };
  const getRoleId = (role) => {
    if (role == "SOCIAL SERVICE") {
      console.log("role user level>>>>", roleList);
      let filteredRoleList = roleList.filter(role=>role.roleId>2000);
      console.log("role user level>>>>", filteredRoleList);
      setRoleList(filteredRoleList);
    } else {
      RoleService.getRolesList(userLevelVal).then((response) => {
        console.log("role list>>>>>>>", response.data.data);
        setRoleList(response.data.data);
        getRoleByRoleDesc(role);
      });
    }
  };

  const getRoleByRoleDesc = (role) => {
    RoleService.getRoleByRoleDesc(role).then((response) => {
      console.log("roles>>>>", response.data.data);
      response.status == 200 && response.data.data
        ? setValue("role", response.data.data.roleId)
        : setValue("role", "");
    });
  };

  const onSubmit = (data) => {
    console.log("Data", JSON.stringify(data));
    trackPromise(
      buttonLabel == "Update"
        ? UserService.updateUser(data.userId, data)
          .then((response) => {
            if (response.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Success Message",
                detail: "Update Successful",
                life: 3000,
              });
              window.location.reload(false);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error Message",
                detail: "Update UnSuccessful",
                life: 3000,
              });
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.data.data) {
                if (error.response.data.data.phoneNo) {
                  toast.current.show({
                    severity: "error",
                    summary: "Error Message",
                    detail: error.response.data.data.phoneNo,
                    life: 3000
                  });
                } else if (error.response.data.data.email) {
                  toast.current.show({
                    severity: "error",
                    summary: "Error Message",
                    detail: error.response.data.data.email,
                    life: 3000
                  });
                }
              } else {
                console.log(error.response);
                toast.current.show({
                  severity: "error",
                  summary: "Error Message",
                  detail: error.response.data.error_description,
                  life: 3000
                });
              }
            } else if (error.request) {
              console.log(error.request);
              toast.current.show({
                severity: "error",
                summary: "Error Message",
                detail: "Network Error",
                life: 3000,
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
          })
        : UserService.saveUser(data)
          .then((response) => {
            if (response.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Success Message",
                detail: "Save Successful",
                life: 3000,
              });
              window.location.reload(false);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error Message",
                detail: "Save UnSuccessful",
                life: 3000,
              });
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.data.data) {
                if (error.response.data.data.phoneNo) {
                  toast.current.show({
                    severity: "error",
                    summary: "Error Message",
                    detail: error.response.data.data.phoneNo,
                    life: 3000
                  });
                } else if (error.response.data.data.email) {
                  toast.current.show({
                    severity: "error",
                    summary: "Error Message",
                    detail: error.response.data.data.email,
                    life: 3000
                  });
                }
              } else {
                console.log(error.response);
                toast.current.show({
                  severity: "error",
                  summary: "Error Message",
                  detail: error.response.data.error_description,
                  life: 3000
                });
              }
            } else if (error.request) {
              console.log(error.request);
              toast.current.show({
                severity: "error",
                summary: "Error Message",
                detail: "Network Error",
                life: 3000,
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
          })
    );
  };

  const getDistrictValue = (provinceId) => {
    AddressService.getAllDistrictsByProvinceId(provinceId).then((response) => {
      setDistrictList(response.data.data);
    });
  };

  const getMunicipalitiesList = (districtId) => {
    AddressService.getAllMunicipalitiessByDistrictId(districtId).then((response) => {
      setMunicipalitiesList(response.data.data);
    });
  };

  const getTotalWard = (municipalityId) => {
    AddressService.getTotalWard(municipalityId).then((response) => {
      const totalWard = response.data.data;
      const list = [];
      for (var i = 1; i <= response.data.data; i++) {
        list.push({
          wardId: i,
        });
      }
      setWardList(list);
    });
  };

  const columns = [
    {
      name: "fullName",
      label: t("fullName"),
      options: {}
    },
    {
      name: "username",
      label: t("userName"),
      options: {}
    },
    {
      name: "email",
      label: "email",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "phoneNo",
      label: t("mobileNo"),
      options: {}
    },
    {
      name: "userLevel",
      label: t("userLevel"),
      options: {}
    },
    {
      name: "userId",
      label: t("actions"),
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <React.Fragment>
              <i
                className="pi pi-user-edit"
                style={{ paddingRight: "5px" }}
                onClick={(e) => confirmUpdate(tableMeta.rowData, value)}
              ></i>
              <i
                className="pi pi-refresh"
                style={{ paddingRight: "6px", color: "red" }}
                onClick={(e) => confirmReset(value)}
              ></i>
              <i
                className="pi pi-trash"
                onClick={(e) => confirmDeleteUser(value)}
              ></i>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "address",
      label: "address",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "userRole",
      label: "userRole",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "organizationUser",
      label: "organizationUser",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    }
  ]

  const confirmUpdate = (user, userId) => {
    console.log("user data", user);
    confirmDialog({
      message: "Are you sure you want to Update?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptUpdate(user, userId),
      reject: () => rejectFunc(),
    });
  };

  const acceptUpdate = (user, userId) => {
    console.log("user update", user);
    setButtonLabel("Update");
    if (user[6] && user[6].province) getDistrictValue(user[6].province);
    if (user[6] && user[6].district) getMunicipalitiesList(user[6].district);
    let userRole = user[7].length > 0 ? user[7] : [];
    let organizationUser = user[8].length > 0 ? user[8] : [];
    if (organizationUser.length && organizationUser[0].homeType) {
      setValue("homeType", organizationUser[0].homeType);
      getHomeDetails();
    }
    reset({
      userId: userId,
      fullName: user[0],
      userName: user[1],
      email: user[2],
      phoneNo: user[3],
      userLevel: user[4],
      province: user[6] ? parseInt(user[6].province) : 0,
      district: user[6] ? parseInt(user[6].district) : 0,
      municipality: user[6] ? parseInt(user[6].municipality) : 0,
      ward: user[6] ? parseInt(user[6].ward) : 0,
      role: userRole.length > 0 ? userRole[0].roleId : 0,
      organizationId: organizationUser.length ? organizationUser[0].organizationId : 0,
      homeType: organizationUser.length ? organizationUser[0].homeType : "",
      homeId: organizationUser.length ? organizationUser[0].homeId : 0,
    });
  };

  const confirmReset = (userId) => {
    confirmDialog({
      message: "Are you sure you want to Reset password?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptReset(userId),
      reject: () => rejectFunc(),
    });
  };

  const acceptReset = (userId) => {
    UserService.resetUser(userId).then((response) => {
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success Message",
          detail: "Reset password Successful",
          life: 3000,
        });
        window.location.reload(false);
        setTimeout(() => {
          UserCreationService.getUserList().then((response) => {
            setUserList(response.data.data);
          });
        }, 3000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Reset password UnSuccessful",
          life: 3000,
        });
      }
    });
  }

  const acceptDelete = (userId) => {
    UserService.deleteUser(userId).then((response) => {
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success Message",
          detail: "Delete Successful",
          life: 3000,
        });
        window.location.reload(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Delete UnSuccessful",
          life: 3000,
        });
      }
    });
  };

  const confirmDeleteUser = (userId) => {
    confirmDialog({
      message: "Are you sure you want to Delete?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptDelete(userId),
      reject: () => rejectFunc(),
    });
  };

  const rejectFunc = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const getHomeDetails = () => {
    let data = {};
    data.province = getValues("province");
    data.district = getValues("district");
    data.municipality = getValues("municipality");
    data.homeType = getValues("homeType");
    trackPromise(
      HomeLookupService.getLookupDetails(data).then((response) => {
        console.log("response.data", response.data.data);
        setHomeNameList(response.data.data);
      })
    );
  }

  return (
    <>
      <div style={{ margin: "0 2.5rem" }}>
        <Toast ref={toast} />
        <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
          <div className=" p-card-content">
            <h4 className="p-pt-0">{t("User Management")}</h4>
          </div>
        </Card>

        <Card className="p-mt-0" style={{ overflowY: "auto" }}>
          <div className=" datatable-scroll-demo" style={{ overflowX: "scroll" }} >
            <div className="card p-col-12 p-md-8" style={{ float: "left", overflowX: "scroll" }}>
              <h4 style={{ paddingTop: "0px" }}> {t("User List")}</h4>
              <ThemeProvider
                theme={createTheme({
                  overrides: {
                    MUIDataTableToolbar: { root: { display: "none" } },
                  },
                })}
              >
                <MUIDataTable
                  data={userList}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </div>

            <div className="card p-col-12 p-md-4" style={{ float: "left" }}>
              <h4 className="p-ml-3" style={{ paddingTop: "0px" }}>
                {t("User Creation")}
              </h4>
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="p-field p-col-12 p-md-12 float-left">
                  <div class="p-field p-col-8 p-md-8 float-left">{t("fullName")}<span style={{ color: "#d00000" }}> * </span>:</div>
                  <div class="p-field p-col-8 p-md-8 float-left">
                    <Controller
                      name="fullName"
                      control={control}
                      rules={{ required: "FullName is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          autoFocus
                          className="rounded-input p-mb-1"
                        />
                      )}
                    />
                    {getFormErrorMessage("fullName")}
                  </div>
                </div>

                <div className="p-field p-col-12 p-md-12 float-left">
                  <div class="p-field p-col-8 p-md-8 float-left">{t("userName")}<span style={{ color: "#d00000" }}> * </span>:</div>
                  <div class="p-field p-col-10 p-md-10 float-left">
                    <Controller
                      name="userName"
                      control={control}
                      rules={{ required: "UserName is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          autoFocus
                          className="rounded-input p-mb-1"
                        />
                      )}
                    />
                    {getFormErrorMessage("userName")}
                    <p style={{ margin: "0px", color: "red" }}>{t("passwordUsernameNote")}</p>
                  </div>
                </div>


                <div className="p-field p-col-12 p-md-12 float-left">
                  <div class="p-field p-col-8 p-md-8 float-left">{t("email")}<span style={{ color: "#d00000" }}> * </span>:</div>
                  <div class="p-field p-col-8 p-md-8 float-left">
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: "Email is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          autoFocus
                          className="rounded-input p-mb-1"
                        />
                      )}
                    />
                    {getFormErrorMessage("email")}
                  </div>
                </div>


                <div className="p-field p-col-12 p-md-12 float-left">
                  <div class="p-field p-col-8 p-md-8 float-left">{t("mobileNo")} :</div>
                  <div class="p-field p-col-8 p-md-8 float-left">
                    <Controller
                      name="phoneNo"
                      control={control}
                      // rules={{ required: "Phone No is required." }}
                      render={({ field, fieldState }) => (
                        // <InputText
                        //   type="number"
                        //   id={field.name}
                        //   {...field}
                        //   autoFocus
                        //   className={classNames({
                        //     "p-invalid": fieldState.invalid,
                        //   })}
                        //   min="0"
                        //   maxLength={10}
                        // />

                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          useGrouping={false}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min={0}
                        />
                      )}
                    />
                    {getFormErrorMessage("phoneNo")}
                  </div>
                </div>

                <div className="p-field p-col-12 p-md-12 float-left">
                  <div class="p-field p-col-8 p-md-8 float-left">{t("userLevel")}<span style={{ color: "#d00000" }}> * </span>:</div>
                  <div class="p-field p-col-8 p-md-8 float-left">
                    <Controller
                      name="userLevel"
                      control={control}
                      rules={{ required: "User Level is  required." }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          filter
                          id={field.name}
                          value={field.value}
                          placeholder={"Select UserLevel"}
                          onChange={(e) => {
                            field.onChange(e.value);
                            getRoleId(e.value);
                          }}
                          style={{ width: "100%" }}
                          options={userLevelList}
                          optionLabel="engName"
                          optionValue="value"
                        />
                      )}
                    />
                    {getFormErrorMessage("userLevel")}
                  </div>
                </div>


                {getValues("userLevel") != USER_LEVEL.MINISTRY ? (
                  <>
                    <div className="p-field p-col-12 p-md-12 float-left">
                      <div class="p-field p-col-4 p-md-4 float-left">
                        <strong>{t("address")}</strong>
                      </div>
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                      <div class="p-field p-col-8 p-md-8 float-left">{t("province")}<span style={{ color: "#d00000" }}> * </span>:</div>
                      <div class="p-field p-col-8 p-md-8 float-left p-pr-0">
                        <Controller
                          name="province"
                          control={control}
                          rules={{ required: "Province is required." }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              filter
                              id={field.name}
                              value={field.value}
                              placeholder={"Select Province"}
                              onChange={(e) => {
                                field.onChange(e.value);
                                getDistrictValue(e.value);
                              }}
                              style={{ width: "100%" }}
                              options={provincesList}
                              optionLabel={i18n.language == LANGUAGE.ENGLISH ? "provinceDescEng" : "provinceDescNep"}
                              optionValue="id"
                              disabled={
                                userLevel == USER_LEVEL.PROVINCE ||
                                userLevel == USER_LEVEL.DISTRICT ||
                                userLevel == USER_LEVEL.LOCAL_LEVEL ||
                                userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER
                              }
                            />
                          )}
                        />
                        {getFormErrorMessage("province")}
                      </div>
                    </div>
                    {getValues("userLevel") != USER_LEVEL.PROVINCE ? (
                      <>
                        <div className="p-field p-col-12 p-md-12 float-left">
                          <div class="p-field p-col-8 p-md-8 float-left">{t("district")}<span style={{ color: "#d00000" }}> * </span>:</div>
                          <div class="p-field p-col-8 p-md-8 float-left p-pr-0">
                            <Controller
                              name="district"
                              control={control}
                              // rules={{ required: "District is required." }}
                              render={({ field, fieldState }) => (
                                <Dropdown
                                  filter
                                  id={field.name}
                                  value={field.value}
                                  placeholder={"Select District"}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    getMunicipalitiesList(e.value);
                                  }}
                                  style={{ width: "100%" }}
                                  options={districtsList}
                                  optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                  optionValue="id"
                                  disabled={userLevel == USER_LEVEL.DISTRICT || userLevel == USER_LEVEL.LOCAL_LEVEL ||
                                    userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER}
                                />
                              )}
                            />
                            {getFormErrorMessage("district")}
                          </div>
                        </div>
                        {getValues("userLevel") != USER_LEVEL.DISTRICT ? (
                          <>
                            <div className="p-field p-col-12 p-md-12 float-left">
                              <div class="p-field p-col-12 p-md-12 float-left">{t("municipality")}<span style={{ color: "#d00000" }}> * </span>:</div>
                              <div class="p-field p-col-8 p-md-8 float-left p-pr-0">
                                <Controller
                                  name="municipality"
                                  control={control}
                                  //  rules={{ required: "Municipal is required." }}
                                  render={({ field, fieldState }) => (
                                    <Dropdown
                                      filter
                                      id={field.name}
                                      value={field.value}
                                      placeholder={"Select Municipality"}
                                      onChange={(e) => {
                                        field.onChange(e.value);
                                        getTotalWard(e.value);
                                      }}
                                      style={{ width: "100%" }}
                                      options={municipalitiesList}
                                      optionLabel={
                                        i18n.language == LANGUAGE.ENGLISH ? "municipalityDescEng" : "municipalityDescNep"
                                      }
                                      optionValue="id"
                                      disabled={userLevel == USER_LEVEL.LOCAL_LEVEL ||
                                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER}
                                    />
                                  )}
                                />
                                {getFormErrorMessage("municipality")}
                              </div>
                            </div>
                            {getValues("userLevel") != USER_LEVEL.LOCAL_LEVEL && getValues("userLevel") != USER_LEVEL.LOCAL_LEVEL_VERIFIER
                              && getValues("userLevel") != USER_LEVEL.SOCIAL_SERVICE ? (
                              <>
                                {" "}
                                <div className="p-field p-col-12 p-md-12 float-left">
                                  <div class="p-field p-col-8 p-md-8 float-left">{t("ward")}<span style={{ color: "#d00000" }}> * </span>:</div>
                                  <div class="p-field p-col-8 p-md-8 float-left p-pr-0">
                                    <Controller
                                      name="ward"
                                      control={control}
                                      // rules={{ required: "Ward is required." }}
                                      render={({ field, fieldState }) => (
                                        <Dropdown
                                          filter
                                          id={field.name}
                                          value={field.value}
                                          placeholder={"Select Ward"}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                          style={{ width: "100%" }}
                                          options={wardList}
                                          optionLabel="wardId"
                                          optionValue="wardId"
                                        />
                                      )}
                                    />
                                    {getFormErrorMessage("ward")}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <div className="p-field p-col-12 p-md-12 float-left">
                  <div class="p-field p-col-12 p-md-8 float-left">{t("role")}<span style={{ color: "#d00000" }}> * </span>:</div>
                  <div class="p-field p-col-12 p-md-8 float-left">
                    <Controller
                      name="role"
                      control={control}
                      rules={{ required: "Role is required." }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          filter
                          id={field.name}
                          value={field.value}
                          placeholder={"Select Role"}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          style={{ width: "100%" }}
                          options={roleList}
                          optionLabel="roleDescription"
                          optionValue="roleId"
                        />
                      )}
                    />
                    {getFormErrorMessage("role")}
                  </div>
                </div>
                {getValues("userLevel") === USER_LEVEL.SOCIAL_SERVICE ?
                  <>
                    <div className="p-field p-col-12 p-md-12 float-left">
                      <div class="p-field p-col-12 p-md-8 float-left">{t("homeType")}<span style={{ color: "#d00000" }}> * </span>:</div>
                      <div class="p-field p-col-12 p-md-8 float-left">
                        <Controller
                          name="homeType"
                          control={control}
                          // rules={{ required: "Home Type is required." }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              filter
                              id={field.name}
                              value={field.value}
                              placeholder={"Select Home Type"}
                              onChange={(e) => {
                                field.onChange(e.value);
                                getHomeDetails(e.value);
                              }}
                              style={{ width: "100%" }}
                              options={homeTypeList}
                              optionLabel={
                                i18n.language == LANGUAGE.ENGLISH ? "nameEng" : "nameNep"
                              }
                              optionValue="value"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                      <div class="p-field p-col-12 p-md-8 float-left">{t("homeName")}<span style={{ color: "#d00000" }}> * </span>:</div>
                      <div class="p-field p-col-12 p-md-8 float-left">
                        <Controller
                          name="homeId"
                          control={control}
                          // rules={{ required: "Home Type is required." }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              filter
                              id={field.name}
                              value={field.value}
                              placeholder={"Select Home Name"}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              style={{ width: "100%" }}
                              options={homeNameList}
                              optionLabel={
                                i18n.language == LANGUAGE.ENGLISH ? "nameEng" : "nameNep"
                              }
                              optionValue="homeId"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </>
                  : <></>}

                <div className="p-field p-col-12 p-md-12 float-left">
                  <div class="p-field p-col-8 p-md-8 float-left"></div>

                  <div class="p-field p-col-4 p-md-4 float-right p-pr-0">
                    <Button
                      className="p-button-sm pull-right submitBtn"
                      style={{
                        background: "#4e70ae",
                        color: "#FFF",
                        // width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {buttonLabel}
                    </Button>
                  </div>
                </div>
              </form>
            </div>

          </div>
          <div style={{ clear: "both" }}></div>
        </Card>
      </div>
    </>
  );
};
export default UserCreationForm;
