import axios from 'axios';
import AppProps from '../../../../AppProps';

class ProsecutionService{

    // getListByFiscalYear(fiscalYear) {
    //     return axios.get(AppProps.API_BASE_URL + AppProps.PROSECUTION + fiscalYear, {
    //       headers: {
    //         Authorization: "Bearer " + sessionStorage.getItem("token"),
    //       },
    //     });
    //   }
      
    //   getListByFiscalYearAndQuarter(fiscalYear, quarter) {
    //     return axios.get(AppProps.API_BASE_URL + AppProps.PROSECUTION + fiscalYear + '/' + quarter, {
    //       headers: {
    //         Authorization: "Bearer " + sessionStorage.getItem("token"),
    //       },
    //     });
    //   }
    
    //   getListByOrganization(fiscalYear, quarter, organizationId) {
    //     return axios.get(AppProps.API_BASE_URL + AppProps.PROSECUTION + fiscalYear + '/' + quarter + '/' + organizationId, {
    //       headers: {
    //         Authorization: "Bearer " + sessionStorage.getItem("token"),
    //       },
    //     });
    //   }

    getData(data) {
      console.log("data",data);
      return axios.post(AppProps.API_BASE_URL + AppProps.PROSECUTION+"indicator",data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
    }
    
      saveData(data) {
        console.log(data, "inside post api");
        return axios.post(AppProps.API_BASE_URL + AppProps.PROSECUTION, data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
      }
    
      submitData(data) {
        console.log(data, "inside put api");
        return axios.put(AppProps.API_BASE_URL + AppProps.PROSECUTION, data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
      }    

      editData(data,prosecutionId) {
        console.log(data, "inside put api");
        return axios.put(AppProps.API_BASE_URL + AppProps.PROSECUTION+prosecutionId, data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
      }
}
export default new ProsecutionService()