import React, { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from "primereact/checkbox";

import { Dropdown } from 'primereact/dropdown';
import { YES_NO } from '../../utilities/constants/ITMISConstansts';
import { t } from 'i18next';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm, Controller } from "react-hook-form";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import GbvDetails2 from './GbvDetails2';

const GbvDetails = (props) => {
    const [typeOfGbvCase, setTypeOfGbvCase] = useState();

    const [gbvTypeVal, setGbvTypeVal] = useState("No");
    const [perpetratorVal, setPerpetratorVal] = useState("No");
    const [directServicesVal, setDirectServicesVal] = useState("No");
    const [val, setVal] = useState();

    const {
        register,
        formState: { errors },
        setValue,
        getValues, } = useForm({ mode: "all", });

    const typeList = [
        { label: t("physicalViolence"), value: "Physical violence" },
        { label: t("verbalAbuseViolence"), value: "Verbal abuse or violence" },
        { label: t("psychologicalViolence"), value: "Psychological violence" },
        { label: t("socioEconomicViolence"), value: "Socio economic violence" },
        { label: t("domesticViolence"), value: "Domestic violence" },
        { label: t("sexualViolenceAndHarassment"), value: "Sexual violence and harassment" },
        { label: t("forcedMarriage"), value: "Forced marriage" },
        { label: t("childMarriage"), value: "Child marriage" },
        { label: t("rape"), value: "Rape" },
        { label: t("othersPleaseSpecify"), value: "Others" },
    ];
    const perpetratorList = [
        { label: t("husbandWife"), value: "Husband or wife" },
        { label: t("immediateFamily"), value: "Immediate family members" },
        { label: t("relatives"), value: "Relatives" },
        { label: t("neighbors"), value: "Neighbors" },
        { label: t("unknowPerson"), value: "Unknow person" },
        { label: t("othersPleaseSpecify"), value: "Others" },
    ];

    const referredList = [
        { label: t("ocmc"), value: "OCMC" },
        { label: t("nepalPolice"), value: "Nepal Police" },
        { label: t("localGovernment"), value: "Local Government" },
        { label: t("ncrc"), value: "NCRC" },
        { label: t("nwc"), value: "NWC" },
        { label: t("csos"), value: "CSOs" },
        { label: t("familyCommunity"), value: "Family or Community" },
        { label: t("school"), value: "School" },
        { label: t("youthChildClub"), value: "Youth or Child Club" },
        { label: t("media"), value: "Media" },
        { label: t("othersPleaseSpecify"), value: "Others" },
    ];

    const handleGbvType = (value) => {
        (value.includes("Others") ?
            setGbvTypeVal("Yes") :
            setGbvTypeVal("No")
        )
    }

    const handlePerpetrator = (value) => {
        (value.includes("Others") ?
            setPerpetratorVal("Yes") :
            setPerpetratorVal("No")
        )
    }


    return (
        <>
            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("gbvType")} <span style={{ color: "#d00000" }}> * </span>
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <MultiSelect
                            name="gbvType"
                            placeholder={t("select")}
                            {...props.register("gbvDetail.gbvType", {
                                required: "GBV Type is Required",
                            })}
                            value={props.getValues("gbvDetail.gbvType")}
                            filter
                            options={typeList}
                            onChange={(e) => {
                                props.setValue("gbvDetail.gbvType", e.value);
                                handleGbvType(e.value)
                            }}
                        />
                        {props.error.gbvDetails && props.error.gbvDetail.gbvType.type === "required" && (
                            <small className="p-error">{props.error.gbvDetail.gbvType.message}</small>
                        )}
                    </div>
                </div>

                {props.getValues("gbvDetail.gbvType")?.includes("Others") ?
                    <div className="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("pleaseSpDetails")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="gbvTypeOthers"
                                value={props.getValues("gbvDetail.gbvTypeOthers")}
                                {...props.register("gbvDetail.gbvTypeOthers")}
                                onChange={(e) => {
                                    props.setValue("gbvDetail.gbvTypeOthers", e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>


            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("gbvPerpetrator")} <span style={{ color: "#d00000" }}> * </span>
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <MultiSelect
                            name="gbvPerpetrator"
                            placeholder={t("select")}
                            {...props.register("gbvDetail.gbvPerpetrator", {
                                required: "Perpetrator is Required",
                            })}
                            value={props.getValues("gbvDetail.gbvPerpetrator")}
                            filter
                            options={perpetratorList}
                            onChange={(e) => {
                                props.setValue("gbvDetail.gbvPerpetrator", e.value);
                                handlePerpetrator(e.value)
                            }}
                        />
                        {props.error.gbvDetails && props.error.gbvDetail.gbvPerpetrator.type === "required" && (
                            <small className="p-error">{props.error.gbvDetail.gbvPerpetrator.message}</small>
                        )}
                    </div>
                </div>
                {props.getValues("gbvDetail.gbvPerpetrator")?.includes("Others") ?
                    <div className="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("pleaseSpDetails")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="gbvPerpetratorOthers"
                                value={props.getValues("gbvDetail.gbvPerpetratorOthers")}
                                {...props.register("gbvDetail.gbvPerpetratorOthers")}
                                onChange={(e) => {
                                    props.setValue("gbvDetail.gbvPerpetratorOthers", e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("referredBy")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <Dropdown
                            name="referredBy"
                            filter
                            placeholder={t("select")}
                            {...props.register("gbvDetail.referredBy")}
                            value={props.getValues("gbvDetail.referredBy")}
                            options={referredList}
                            onChange={(e) => {
                                props.setValue("gbvDetail.referredBy", e.value);
                                setTypeOfGbvCase(e.value)
                            }}
                        />
                    </div>
                </div> 
                {(props.getValues("gbvDetail.referredBy") === "Others") ?
                    <div className="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("pleaseSpDetails")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="referredByOthers"
                                value={props.getValues("gbvDetail.referredByOthers")}
                                {...props.register("gbvDetail.referredByOthers")}
                                onChange={(e) => {
                                    props.setValue("gbvDetail.referredByOthers", e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>

            <div className="p-field p-col-12 p-md-12 ">
                <h3 style={{ textAlign: "center" }}>{t("caseHistory")}</h3>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <InputTextarea
                            name="caseHistory"
                            {...props.register("gbvDetail.caseHistory")}
                            rows={5}
                            cols={50}
                            autoResize
                            value={props.getValues("gbvDetail.caseHistory")}
                            onChange={(e) => {
                                props.setValue("gbvDetail.caseHistory", e.target.value);
                                setVal(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GbvDetails;