import axios from 'axios';
import AppProps from '../../../AppProps';

class DetailService {
  submitHomeDetail(data) {
    return axios.post(AppProps.API_BASE_URL + AppProps.GBV_HOME_DETAIL, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getAllHomeList(homeType) {
    return axios.get(AppProps.API_BASE_URL + AppProps.GBV_HOME_DETAIL+"?homeType="+homeType, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getHomeListByOrganization(organizationId, homeType) {
    return axios.get(AppProps.API_BASE_URL + AppProps.GBV_HOME_DETAIL + "/list-by-organization?organization="+ organizationId + "&homeType="+homeType, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  submitPersonalDetail(data) {
    return axios.post(AppProps.API_BASE_URL + AppProps.GBV_PERSONAL_DETAIL, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  editPersonalDetail(data, personalDetailId) {
    return axios.put(AppProps.API_BASE_URL + AppProps.GBV_PERSONAL_DETAIL+ personalDetailId, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getPersonalDetailById(id) {
    return axios.get(AppProps.API_BASE_URL + AppProps.GBV_PERSONAL_DETAIL + "/by-id?personalDetailId="+ id, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getHomeListByAddress(data) {
    console.log("data>>>", data);
    return axios.post(AppProps.API_BASE_URL +  AppProps.GBV_HOME_DETAIL+"/search-by-address", data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  localLevelVerification(personalDetailId) {
    return axios.post(AppProps.API_BASE_URL + AppProps.GBV_PERSONAL_DETAIL + "verification/" + personalDetailId, personalDetailId,{
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  
  sendBackForCorrection(data) {
    return axios.post(AppProps.API_BASE_URL + AppProps.GBV_PERSONAL_DETAIL + "send-back-for-correction" , data,{
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

}
export default new DetailService()