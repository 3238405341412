
import React, { useState, useRef } from "react";

import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

const PersonalCode = (props) => {
    const { t } = useTranslation();
    const [val, setVal] = useState();
    return (
        <div className="p-grid p-col-12 p-md-12 ">
            <div className="p-col-12 p-md-6">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("uniqueCaseCode")} <span style={{ color: "#d00000"}}> * </span> 
                </div>
                <div className="p-field p-col-12 p-md-6 float-left">
                    <InputText
                        name="personalCode"
                        value={props.getValues("personalCode")}
                        {...props.register("personalCode"
                        , {
                            required: "Personal Code is Required",
                        }
                        )}
                        onChange={(e) => {
                          props.setValue("personalCode", e.target.value);
                          setVal(e.target.value);
                        }}
                    />
                    {props.error.personalCode && props.error.personalCode.type === "required" && (
                        <small className="p-error">{props.error.personalCode.message}</small>
                    )}
                </div>
            </div>
        </div>
    )

}
export default PersonalCode;