
import React, { useState, useRef } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from "react-i18next";

const Education = (props) => {
    const { t } = useTranslation();
    const educationList = [
        { label: t("illiterate"), value: 'Illiterate' },
        { label: t("literate"), value: 'Literate' },
        { label: t("primaryTillGrade5"), value: 'Primary' },
        { label: t("basicTillGrade8"), value: 'Basic' },
        { label: t("higherSecondaryTillGrade12"), value: 'Higher Secondary' },
        // { label: t("secondaryTillGrade12"), value: 'Secondary' },
        { label: t("bachelor"), value: 'Bachelor' },
        { label: t("masters"), value: 'Masters' },
        { label: t("mphil"), value: 'Masters of Philosophy' },
        { label: t("phd"), value: 'PhD' },
        { label: t("othersPleaseSpecify"), value: 'Others' }
    ];
    const [val, setVal] = useState();

    const [education, setEducation] = useState();
    return (
        <div className="p-grid p-col-12 p-md-12 ">
            <div className="p-col-12 p-md-6">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("educationLevel")} <span style={{ color: "#d00000" }}> * </span>
                </div>
                <div className="p-field p-col-12 p-md-12 float-left">
                    <Dropdown
                        filter
                        name="educationLevel"
                        placeholder={t("educationLevel")}
                        {...props.register("educationLevel", {
                            required: "Education Level is Required",
                        }
                        )}
                        value={props.getValues("educationLevel")}
                        options={educationList}
                        onChange={(e) => {
                            props.setValue("educationLevel", e.value);
                            setEducation(e.value);
                        }}
                    />
                    {props.error.educationLevel && props.error.educationLevel.type === "required" && (
                        <small className="p-error">{props.error.educationLevel.message}</small>
                    )}
                </div>
            </div>

            {props.getValues("educationLevel") === "Others" ? (
                <div class="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("pleaseSpDetails")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <InputText
                            name="educationOthers"
                            value={props.getValues("educationOthers")}
                            {...props.register("educationOthers")}
                            onChange={(e) => {
                                props.setValue("educationOthers", e.target.value);
                                setVal(e.target.value);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    )

}
export default Education;