import axios from "axios";
import AppProps from "../../../AppProps";
class ReportService {
    getChildrenCumulativeData(fiscalYear) {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_CHILDREN + "cumulative/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getChildrenProvinceData(fiscalYear) {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_CHILDREN + "province-wise/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getChildrenDistrictData(fiscalYear, provinceId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_CHILDREN + "district-wise/" + fiscalYear + "/" + provinceId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getChildrenLocalWiseData(fiscalYear, provinceId, districtId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_CHILDREN + "municipality-wise/" + fiscalYear + "/" + provinceId + "/" + districtId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }
    getWomenCumulativeData(fiscalYear) {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_WOMEN + "cumulative/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }
    getWomenProvinceData(fiscalYear) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_WOMEN + "province-wise/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getWomenDistrictData(fiscalYear, provinceId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_WOMEN + "district-wise/" + fiscalYear + "/" + provinceId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getWomenLocalWiseData(fiscalYear, provinceId, districtId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_WOMEN + "municipality-wise/" + fiscalYear + "/" + provinceId + "/" + districtId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }
    getDisabledCumulativeData(fiscalYear) {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_DISABLED + "cumulative/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }
    getDisabledProvinceData(fiscalYear) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_DISABLED + "province-wise/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getDisabledDistrictData(fiscalYear, provinceId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_DISABLED + "district-wise/" + fiscalYear + "/" + provinceId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getDisabledLocalWiseData(fiscalYear, provinceId, districtId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_DISABLED + "municipality-wise/" + fiscalYear + "/" + provinceId + "/" + districtId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getSeniorCitizenCumulativeData(fiscalYear) {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_SENIOR_CITIZEN + "cumulative/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getSeniorCitizenProvinceData(fiscalYear) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_SENIOR_CITIZEN + "province-wise/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getSeniorCitizenDistrictData(fiscalYear, provinceId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_SENIOR_CITIZEN + "district-wise/" + fiscalYear + "/" + provinceId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getSeniorCitizenLocalWiseData(fiscalYear, provinceId, districtId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_SENIOR_CITIZEN + "municipality-wise/" + fiscalYear + "/" + provinceId + "/" + districtId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getShelterHomeCumulativeData(fiscalYear) {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_SHELTER_HOME + "cumulative/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getShelterHomeProvinceData(fiscalYear) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_SHELTER_HOME + "province-wise/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getShelterHomeDistrictData(fiscalYear, provinceId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_SHELTER_HOME + "district-wise/" + fiscalYear + "/" + provinceId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getShelterHomeLocalWiseData(fiscalYear, provinceId, districtId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_SHELTER_HOME + "municipality-wise/" + fiscalYear + "/" + provinceId + "/" + districtId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getLabourMigrationCumulativeData(fiscalYear) {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_LABOUR_MIGRATION + "cumulative/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getLabourMigrationProvinceData(fiscalYear) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_LABOUR_MIGRATION + "province-wise/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getLabourMigrationDistrictData(fiscalYear, provinceId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_LABOUR_MIGRATION + "district-wise/" + fiscalYear + "/" + provinceId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getLabourMigrationLocalWiseData(fiscalYear, provinceId, districtId) {
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_LABOUR_MIGRATION + "municipality-wise/" + fiscalYear + "/" + provinceId + "/" + districtId,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getComplaintRegistrationCumulativeData(fiscalYear) {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_COMPLAINT_REGISTRATION + "cumulative/" + fiscalYear,
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getIdCardProvinceData() {
        console.log("called here");
        return axios.get(
            AppProps.API_BASE_URL + AppProps.REPORT_ID_CARD + "province-wise",
            {
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token"),
                // },
            }
        );
    }

    getProvinceList() {
        return axios.get(AppProps.API_BASE_URL + "/lookup/address/provinces/", {
            // headers: {
            //     Authorization: "Bearer " + sessionStorage.getItem("token"),
            // },
        });
    }
}
export default new ReportService();
