import axios from "axios";
import AppProps from "../../../AppProps";
import qs from "qs";
import { trackPromise } from "react-promise-tracker";
class FileUploadService {
  postFile(file) {
    return axios.post(AppProps.API_BASE_URL + AppProps.FILE_UPLOAD_URL, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  getFile(fileName) {
    return axios.get(AppProps.API_BASE_URL + AppProps.GET_FILE + fileName, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
  }

  fileDownload(e, downloadFile, filename) {
    e.preventDefault();
    console.log("download filename" + filename);
    axios(downloadFile + filename, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((response) => {
      console.log("response", response.data);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  }

  viewFile(fileName) {
    var fileExt = fileName.split('.').pop();
    console.log("fileext", fileExt);
    if (fileExt === 'pdf') {
      axios({
        url: AppProps.API_BASE_URL + '/file?imageName=' + fileName, //your url
        method: "GET",
        responseType: "blob",  // important
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }

      }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      });
    }
  };

  getFileBlob(imageName) {
    axios
      .get(AppProps.API_BASE_URL + AppProps.GET_FILE_OPEN, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          imageName
        },
        paramsSerializer: (params) => {
          console.log("params", params);
          //qs.stringify({ orgArr: orgArrValue }, { indices: false }))
          return qs.stringify(
            { imageName: imageName },
            { indices: false }
          );
        },
      })
      .then((response) => {
        console.log("response", response);
        const file = new Blob([response.data]);
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      });
  }
}
export default new FileUploadService();
