import React, { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";

import { RadioButton } from 'primereact/radiobutton';

import { Dropdown } from 'primereact/dropdown';
import { LANGUAGE, YES_NO } from '../../../utilities/constants/ITMISConstansts';
import { t } from 'i18next';
import { MultiSelect } from 'primereact/multiselect';
import { useForm, Controller } from "react-hook-form";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { InputTextarea } from 'primereact/inputtextarea';
import i18n from '../../../il8n/il8n';

const TipDetailOld = (props) => {
    const [name, setName] = useState();
    const { control, } = useForm({ mode: "all", });
    const [flag, setFlag] = useState();
    const handleDirectServices = (value) => {
        (value.includes("Others") ?
            setDirectServicesVal("Yes") :
            setDirectServicesVal("No")
        )
    }
    const [directServicesVal, setDirectServicesVal] = useState("No");
    const [val, setVal] = useState();


    const directServicesList = [
        { label: t("shelterAccommodation"), value: "Shelter or Accommodation" },
        { label: t("food"), value: "Food" },
        { label: t("clothes"), value: "Clothes" },
        { label: t("psychosocialCounseling"), value: "Psychosocial counseling" },
        { label: t("skillOrCapacityDevelopmentTraining"), value: "Skill or capacity development training" },
        { label: t("formalEducation"), value: "Formal education" },
        { label: t("informalEducationMotivationalSession"), value: "Informal education or motivational session" },
        { label: t("healthAndMedicalService"), value: "Health and medical service" },
        { label: t("jobPlacement"), value: "Job placement" },
        { label: t("businessSupport"), value: "Business support" },
        { label: t("legalServices"), value: "Legal services" },
        { label: t("othersPleaseSpecify"), value: "Others" },
    ];

    const [referralList, setValueReferralList] = useState([
        { labelEng: "Health and medical service", labelNep: "स्वास्थ्य र चिकित्सा", value: "Health service" },
        { labelEng: "Skill or capacity development training", labelNep: "सीप तथा क्षमता विकास तालिम", value: "Skill training" },
        { labelEng: "Job placement", labelNep: "जागिर नियुक्ति", value: "Job placement" },
        { labelEng: "Business support", labelNep: "पेसा व्यवसाय सहायता", value: "Business support" },
        { labelEng: "Psychosocial counseling", labelNep: "मनोसामाजिक परामर्श", value: "Psychosocial counseling" },
        { labelEng: "Legal and paralegal services", labelNep: "कानूनी र पारालीगल सेवाहरू", value: "Legal services" },
        { labelEng: "Formal education", labelNep: "औपचारिक शिक्षा", value: "Formal education" },
        { labelEng: "Informal education/motivational session", labelNep: "अनौपचारिक शिक्षा/प्रेरणा सत्र", value: "Informal education" },
        { labelEng: "Others (Please specify)", labelNep: "अन्य (कृपया उल्लेख गर्नुहोस्)", value: "Others" },
        { labelEng: "Not referred yet", labelNep: "सेवाका लागि रेफर गरिएको छैन", value: "Not referred yet" },
    ]);

    let referals = (props.getValues("tipDetail.referral") || []).join(',');
    console.log('referals: ', referals);

    useEffect(() => {
        if (flag) return;
        const refData = props.getValues("tipDetail.referral") || [];

        if (refData.length > 0) {
            let newList = referralList.map(a => {
                if (refData.find(val => val === a.value)) {
                    if (!a.checked) {
                        a.checked = !a.checked;
                    }
                }
                return a;
            });
            setFlag(true);
            setValueReferralList(newList);
            setReferralVal(refData);
        }
    }, [referals]);

    const [referralVal, setReferralVal] = useState([]);

    const checkboxReferralListFunction = (val) => {
        console.log("referralList", referralList);
        let list = [];
        let newList = referralList.map(a => {
            if (a.value === val) {
                a.checked = !a.checked;
            }
            return a;
        });
        console.log("newList", newList);
        let newReferralList = newList.map(data => {
            if (data.checked && data.checked === true) {
                console.log("list", list);
                list.push(data.value);
            }
            return list;
        });
        console.log("newReferralList", newReferralList);

        setReferralVal(newReferralList[0]);
        props.setValue("tipDetail.referral", newReferralList[0]);
        setValueReferralList(newList);
    }

    const reintegrationStatusList = [
        { label: t("Family"), value: "Family" },
        { label: t("relatives"), value: "Relatives" },
        { label: t("livingAlone"), value: "Living alone" },
        { label: t("Community"), value: "Community" },
        { label: t("outsideOfCommunity"), value: "Outside of community" }
    ];

    return (
        <>
            <div className="p-field p-col-12 p-md-12 ">
                <h3 style={{ textAlign: "center" }}>{t("servicesRegistered")}</h3>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("directServices")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <MultiSelect
                            name="directServices"
                            placeholder={t("select")}
                            {...props.register("tipDetail.directServices")}
                            value={props.getValues("tipDetail.directServices")}
                            filter
                            options={directServicesList}
                            onChange={(e) => {
                                props.setValue("tipDetail.directServices", e.value);
                                handleDirectServices(e.value)
                            }}
                        />
                    </div>
                </div>
                {(directServicesVal === "Yes") ?
                    <div className="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("pleaseSpDetails")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="directServicesOthers"
                                {...props.register("tipDetail.directServicesOthers")}
                                value={props.getValues("tipDetail.directServicesOthers")}
                                onChange={(e) => {
                                    props.setValue("tipDetail.directServicesOthers", e.target.value);
                                    setName(e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6" style={{ width: "100%" }}>
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("referral")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left" style={{ marginTop: "10px" }}>
                        <div className="p-col-12 p-md-6 float-left">
                            {referralList.map((referral) => {
                                return (
                                    <div key={referral.key} className="p-col-12 p-md-12 flex align-items-center">
                                        <Checkbox
                                            name="referral"
                                            value={props.getValues("tipDetail.referral")}
                                            {...props.register("tipDetail.referral")}
                                            // checked={tipDetail.value === referral}
                                            checked={referral.checked}
                                            onChange={(e) => {
                                                checkboxReferralListFunction(referral.value)
                                                console.log(referral);

                                            }}
                                        />
                                        <label>{i18n.language == LANGUAGE.ENGLISH ? referral.labelEng : referral.labelNep}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="p-col-12 p-md-6 float-right">
                            {referralVal?.includes("Health service") ?
                                <InputText
                                    name="healthOrganization"
                                    value={props.getValues("tipDetail.healthOrganization")}
                                    {...props.register("tipDetail.healthOrganization")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.healthOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("healthAndMedicalService")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Skill training") ?
                                <InputText
                                    name="skillTrainingOrganization"
                                    value={props.getValues("tipDetail.skillTrainingOrganization")}
                                    {...props.register("tipDetail.skillTrainingOrganization")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.skillTrainingOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("skillOrCapacityDevelopmentTraining")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Job placement") ?
                                <InputText
                                    name="jobPlacementOrganization"
                                    value={props.getValues("tipDetail.jobPlacementOrganization")}
                                    {...props.register("tipDetail.jobPlacementOrganization")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.jobPlacementOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("jobPlacement")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Business support") ?
                                <InputText
                                    name="businessSupportOrganization"
                                    value={props.getValues("tipDetail.businessSupportOrganization")}
                                    {...props.register("tipDetail.businessSupportOrganization")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.businessSupportOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("businessSupport")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Psychosocial counseling") ?
                                <InputText
                                    name="psychoCounselingOrganization"
                                    value={props.getValues("tipDetail.psychoCounselingOrganization")}
                                    {...props.register("tipDetail.psychoCounselingOrganization")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.psychoCounselingOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("psychosocialCounseling")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Legal services") ?
                                <InputText
                                    name="legalServiceOrganization"
                                    value={props.getValues("tipDetail.legalServiceOrganization")}
                                    {...props.register("tipDetail.legalServiceOrganization")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.legalServiceOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("legalServices")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Formal education") ?
                                <InputText
                                    name="formalEduOrganization"
                                    value={props.getValues("tipDetail.formalEduOrganization")}
                                    {...props.register("tipDetail.formalEduOrganization")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.formalEduOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("formalEducation")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Informal education") ?
                                <InputText
                                    name="informalEduOrganization"
                                    value={props.getValues("tipDetail.informalEduOrganization")}
                                    {...props.register("tipDetail.informalEduOrganization")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.informalEduOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("informalEducationMotivationalSession")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }

                            {referralVal?.includes("Others") ?
                                <>  <InputText
                                    name="othersReferral"
                                    value={props.getValues("tipDetail.othersReferral")}
                                    {...props.register("tipDetail.othersReferral")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.othersReferral", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("specifyOtherReferral")}
                                    tooltip={t("specifyOtherReferral")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                    <InputText
                                        name="othersOrganization"
                                        value={props.getValues("tipDetail.othersOrganization")}
                                        {...props.register("tipDetail.othersOrganization")}
                                        onChange={(e) => {
                                            props.setValue("tipDetail.othersOrganization", e.target.value);
                                            setVal(e.target.value);
                                        }}
                                        style={{ marginTop: "5px" }}
                                        placeholder={t("organizationName")}
                                        tooltip={t("others")}
                                        tooltipOptions={{
                                            position: "bottom"
                                        }} />
                                </>
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="p-field p-col-12 p-md-12 ">
                <h3 style={{ textAlign: "center" }}>{t("statusOfTipCase")}</h3>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("tipCaseRegistrationStatus")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value="Case Registered"
                            name="Case Registered"
                            onChange={(e) => {
                                props.setValue("tipDetail.statusOfTip", "Case Registered");
                                setName(e.value);
                            }}
                            checked={props.getValues("tipDetail.statusOfTip") === "Case Registered"}
                        /> {t("caseRegistered")}
                    </div>
                    <div className="p-field p-col-12 p-md-6 float-left">
                        <RadioButton
                            value="Case Not Registered"
                            name="Case Not Registered"
                            onChange={(e) => {
                                props.setValue("tipDetail.statusOfTip", "Case Not Registered");
                                setName(e.value);
                            }}
                            checked={props.getValues("tipDetail.statusOfTip") === "Case Not Registered"}
                        /> {t("caseNotRegistered")}
                    </div>

                    {props.getValues("tipDetail.statusOfTip") === "Case Registered"
                        ? <div className="p-grid p-col-12 p-md-12 ">
                            <div className="p-col-12 p-md-12">
                                <div className="p-field p-col-12 p-md-12 float-left main-label">
                                    {t("caseRegistered")}
                                </div>

                                <div className="p-field p-col-12 p-md-4 float-left">
                                    <Checkbox
                                        value="FIRTIP Case Registration"
                                        name="FIRTIP Case Registration"
                                        onChange={(e) => {
                                            let selectedFormsCaseOfTip = props.getValues("tipDetail.caseRegistered") || [];
                                            if (e.checked) {
                                                selectedFormsCaseOfTip.push(e.value);
                                            } else {
                                                selectedFormsCaseOfTip = selectedFormsCaseOfTip.filter(form => form !== e.value);
                                            }
                                            props.setValue("tipDetail.caseRegistered", selectedFormsCaseOfTip);
                                        }}
                                        checked={props.getValues("tipDetail.caseRegistered")?.includes("FIRTIP Case Registration")}
                                    /> {t("firTipCaseRegistration")}
                                </div>
                                <div className="p-field p-col-12 p-md-4 float-left">
                                    <Checkbox
                                        value="Prosecution"
                                        name="Prosecution"
                                        onChange={(e) => {
                                            let selectedFormsCaseOfTip = props.getValues("tipDetail.caseRegistered") || [];
                                            if (e.checked) {
                                                selectedFormsCaseOfTip.push(e.value);
                                            } else {
                                                selectedFormsCaseOfTip = selectedFormsCaseOfTip.filter(form => form !== e.value);
                                            }
                                            props.setValue("tipDetail.caseRegistered", selectedFormsCaseOfTip);
                                        }}
                                        checked={props.getValues("tipDetail.caseRegistered")?.includes("Prosecution")}
                                    /> {t("prosecution")}
                                </div>
                                <div className="p-field p-col-12 p-md-4 float-left">
                                    <Checkbox
                                        value="Case Decided"
                                        name="Case Decided"
                                        onChange={(e) => {
                                            let selectedFormsCaseOfTip = props.getValues("tipDetail.caseRegistered") || [];
                                            if (e.checked) {
                                                selectedFormsCaseOfTip.push(e.value);
                                            } else {
                                                selectedFormsCaseOfTip = selectedFormsCaseOfTip.filter(form => form !== e.value);
                                            }
                                            props.setValue("tipDetail.caseRegistered", selectedFormsCaseOfTip);
                                        }}
                                        checked={props.getValues("tipDetail.caseRegistered")?.includes("Case Decided")}
                                    /> {t("caseDecided")}
                                </div>
                            </div>
                            {/* If selected prosecution/adjudication  */}
                            {props.getValues("tipDetail.caseRegistered")?.includes("Prosecution")
                                ? <div className="p-grid p-col-12 p-md-12 ">
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                                            {t("prosecutionAt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="DistrictCourt"
                                                name="DistrictCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.prosecutionAt", "DistrictCourt");
                                                    setName(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.prosecutionAt") === "DistrictCourt"}
                                            /> {t("districtCourt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="HighCourt"
                                                name="HighCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.prosecutionAt", "HighCourt");
                                                    setName(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.prosecutionAt") === "HighCourt"}
                                            /> {t("highCourt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="SupremeCourt"
                                                name="SupremeCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.prosecutionAt", "SupremeCourt");
                                                    setName(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.prosecutionAt") === "SupremeCourt"}
                                            /> {t("supremeCourt")}
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }
                            {/* If selected Case Decided  */}
                            {props.getValues("tipDetail.caseRegistered")?.includes("Case Decided")
                                ? <div className="p-grid p-col-12 p-md-12 ">
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                                            {t("caseDecidedBy")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="DistrictCourt"
                                                name="DistrictCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.caseDecidedBy", "DistrictCourt");
                                                    setName(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.caseDecidedBy") === "DistrictCourt"}
                                            /> {t("districtCourt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="HighCourt"
                                                name="HighCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.caseDecidedBy", "HighCourt");
                                                    setName(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.caseDecidedBy") === "HighCourt"}
                                            /> {t("highCourt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="SupremeCourt"
                                                name="SupremeCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.caseDecidedBy", "SupremeCourt");
                                                    setName(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.caseDecidedBy") === "SupremeCourt"}
                                            /> {t("supremeCourt")}
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }
                        </div> : <></>}
                </div>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6 ">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("caseReintegrationStatus")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.YES}
                            name={YES_NO.YES}
                            onChange={(e) => {
                                props.setValue("tipDetail.caseReintegrationStatus", YES_NO.YES);
                                setName(e.value);
                            }}
                            checked={props.getValues("tipDetail.caseReintegrationStatus") === YES_NO.YES}
                        /> {t("yes")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.NO}
                            name={YES_NO.NO}
                            onChange={(e) => {
                                props.setValue("tipDetail.caseReintegrationStatus", YES_NO.NO);
                                setName(e.value);
                            }}
                            checked={props.getValues("tipDetail.caseReintegrationStatus") === YES_NO.NO} /> {t("no")}
                    </div>
                </div>
                {props.getValues("tipDetail.caseReintegrationStatus") === 'No' ?
                    <div className="p-col-12 p-md-6 float-left ">
                        <div className="p-field p-col-12 p-md-12 main-label">
                            {t("whyNot")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="noReintegrationReason"
                                {...props.register("tipDetail.noReintegrationReason")}
                                value={props.getValues("tipDetail.noReintegrationReason")}
                                onChange={(e) => {
                                    props.setValue("tipDetail.noReintegrationReason", e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    : <></>
                }
            </div>
            {props.getValues("tipDetail.caseReintegrationStatus") === 'Yes' ?
                <div className="p-grid p-col-12 p-md-12 ">
                    <div className="p-col-12 p-md-6 ">
                        <div className="p-field p-col-12 p-md-12 main-label">
                            {t("reintegratedTo")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <Dropdown
                                name="reintegratedTo"
                                filter
                                placeholder={t("select")}
                                {...props.register("tipDetail.reintegratedTo")}
                                value={props.getValues("tipDetail.reintegratedTo")}
                                options={reintegrationStatusList}
                                onChange={(e) => {
                                    props.setValue("tipDetail.reintegratedTo", e.value);
                                    setName(e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 ">
                        <div className="p-field p-col-12 p-md-12 main-label">
                            {t("reintegratedDate")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <NepaliDatePicker
                                name="reintegratedDate"
                                {...props.register("tipDetail.reintegratedDate")}
                                value={props.getValues("tipDetail.reintegratedDate")}
                                onChange={(value) => {
                                    props.setValue("tipDetail.reintegratedDate", value);
                                }}
                                options={{ calenderLocale: "en", valueLocale: "en" }}
                            />
                        </div>
                    </div>

                </div>
                :
                <></>
            }
        </>
    )
}

export default TipDetailOld;