import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";

import { useTranslation } from "react-i18next";

import Source from "../../utilities/components/Source";

import WomenAndMinoritiesService from "../api/services/WomenAndMinoritiesService";
import Organization from "../../utilities/components/Organization";
import UserService from "../../security/api/services/UserService";

import { trackPromise } from "react-promise-tracker";
import { USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import FiscalQuarterMandatory from "../../utilities/components/FiscalQuarterMandatory";
import { useHistory } from "react-router-dom";

function WomenGenderAndSexualMinorities() {
  //for English and Nepali localization
  const { t } = useTranslation();

  const [fiscalYear, setFiscalYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [womenAndMinoritiesId, setWomenAndMinoritiesId] = useState();
  const [organization, setOrganization] = useState("");
  const [organizationList, setOrganizationList] = useState([]);

  const [update, setUpdate] = useState("No");
  const [showBtn, setShowBtn] = useState("Yes");
  const [hideBtn, setHideBtn] = useState("No");
  const [enableForm, setEnableForm] = useState(true);
  const [localLevelVerifier, setLocalLevelVerifier] = useState(false);
  const history = useHistory();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger
  } = useForm({});
  const toast = useRef(null);

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };

  useEffect(() => {
    //To check whether the logged in user's role is local level or others
    UserService.getUserLevel().then((response) => {
      if (response.data.data === USER_LEVEL.LOCAL_LEVEL) {
        setHideBtn("No");
        setLocalLevelVerifier(false);
      } else if (response.data.data === USER_LEVEL.LOCAL_LEVEL_VERIFIER) {
        setHideBtn("Yes");
        setLocalLevelVerifier(true);
      } else {
        setLocalLevelVerifier(false);
        setHideBtn("Yes");
      }
    });
  }, []);

  //To save data
  const saveData = (e) => {
    e.preventDefault();
    let data = getValues();
    data.fiscalYear = fiscalYear;
    data.quarter = quarter;
    data.status = "Save";

    console.log("data", data);

    if (update == "No") {
      console.log("saveData no update");
      //trackPromise is used for loading
      trackPromise(
        WomenAndMinoritiesService.saveData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Save Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Save UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    } else {
      console.log("saveData yes update");
      data.womenAndMinoritiesId = womenAndMinoritiesId;
      console.log("data ", data);
      trackPromise(
        WomenAndMinoritiesService.updateData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Update Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Update UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    }
  };

  //To update data
  const submitData = (data) => {
    data.fiscalYear = fiscalYear;
    data.quarter = quarter;
    data.status = "Submit";
    console.log("data", data);
    if (update == "No") {
      console.log("submitData no update");
      //trackPromise is used for loading
      trackPromise(
        WomenAndMinoritiesService.saveData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Submit Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Submit UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    } else {
      data.womenAndMinoritiesId = womenAndMinoritiesId;
      console.log("submitData ys update");
      console.log("submitData data", data);
      trackPromise(
        WomenAndMinoritiesService.updateData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Submit Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Submit UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    }
  };

  const handleFiscalYear = (fiscalYearVal) => {
    console.log("fiscal year  ", fiscalYearVal);
    setFiscalYear(fiscalYearVal);
    console.log("quarter in fiscalYear", quarter);
    if (quarter !== "" && organization != "") {
      getListByOrganization(fiscalYearVal, quarter, organization);
    } else if (quarter !== "") {
      getListByFiscalYearAndQuarter(fiscalYearVal, quarter);
    }
  };

  const handleQuarter = (quarterVal) => {
    console.log("quarter  ", quarterVal);
    console.log("fiscalYear in quarter", fiscalYear);
    console.log("iin organization", organization);
    setQuarter(quarterVal);
    if (fiscalYear !== "" && organization != "") {
      getListByOrganization(fiscalYear, quarterVal, organization);
    } else if (fiscalYear !== "") {
      getListByFiscalYearAndQuarter(fiscalYear, quarterVal);
    }
  };

  //to get data of particular fiscal year(for user with local level role)
  const getListByFiscalYearAndQuarter = (fiscalYear, quarter) => {
    trackPromise(
      WomenAndMinoritiesService.getListByFiscalYearAndQuarter(fiscalYear, quarter).then(
        (response) => {
          console.log("response", response.data);
          if (response.data) {
            setFiscalYear(response.data.fiscalYear);
            setQuarter(response.data.quarter);
            setWomenAndMinoritiesId(response.data.womenAndMinoritiesId);
            //To set all response data in their particular fields
            reset({
              womenKhasAryaPop: response.data.womenKhasAryaPop,
              womenHillJanjatiPop: response.data.womenHillJanjatiPop,
              womenTharuPop: response.data.womenTharuPop,
              womenHillDalitPop: response.data.womenHillDalitPop,
              // womenTeraiBrahminPop: response.data.womenTeraiBrahminPop,
              womenTeraiJanjatiPop: response.data.womenTeraiJanjatiPop,
              womenMadhesiPop: response.data.womenMadhesiPop,
              womenTeraiDalitPop: response.data.womenTeraiDalitPop,
              womenMuslimPop: response.data.womenMuslimPop,
              womenOthersPop: response.data.womenOthersPop,
              womenPopSource:
                response.data.womenPopSource[0] != "" &&
                  response.data.womenPopSource != ""
                  ? response.data.womenPopSource
                  : null,
              womenPopSourceOthers: response.data.womenPopSourceOthers,

              girlsKhasAryaPop: response.data.girlsKhasAryaPop,
              girlsHillJanjatiPop: response.data.girlsHillJanjatiPop,
              girlsTharuPop: response.data.girlsTharuPop,
              girlsHillDalitPop: response.data.girlsHillDalitPop,
              // girlsTeraiBrahminPop: response.data.girlsTeraiBrahminPop,
              girlsTeraiJanjatiPop: response.data.girlsTeraiJanjatiPop,
              girlsMadhesiPop: response.data.girlsMadhesiPop,
              girlsTeraiDalitPop: response.data.girlsTeraiDalitPop,
              girlsMuslimPop: response.data.girlsMuslimPop,
              girlsOthersPop: response.data.girlsOthersPop,
              girlsPopSource:
                response.data.girlsPopSource[0] != "" &&
                  response.data.girlsPopSource != ""
                  ? response.data.girlsPopSource
                  : null,
              marriedWomen: response.data.marriedWomen,
              singledWomen: response.data.singledWomen,
              divorceeWomen: response.data.divorceeWomen,
              widowedWomen: response.data.widowedWomen,
              separatedWomen: response.data.separatedWomen,
              othersSingleWomen: response.data.othersSingleWomen,
              singleWomenSource:
                response.data.singleWomenSource[0] != "" &&
                  response.data.singleWomenSource != ""
                  ? response.data.singleWomenSource
                  : null,
              sexualGenderMinorities: response.data.sexualGenderMinorities,
              sexualGenderMinoritiesSource:
                response.data.sexualGenderMinoritiesSource[0] != "" &&
                  response.data.sexualGenderMinoritiesSource != ""
                  ? response.data.sexualGenderMinoritiesSource
                  : null,
              womenLiteracyRate: response.data.womenLiteracyRate,
              womenLiteracyRateSource:
                response.data.womenLiteracyRateSource[0] != "" &&
                  response.data.womenLiteracyRateSource != ""
                  ? response.data.womenLiteracyRateSource
                  : null,
              electedWomenRep: response.data.electedWomenRep,
              electedWomenRepSource:
                response.data.electedWomenRepSource[0] != "" &&
                  response.data.electedWomenRepSource != ""
                  ? response.data.electedWomenRepSource
                  : null,
              singledWomenRecSpa: response.data.singledWomenRecSpa,
              divorceeWomenRecSpa: response.data.divorceeWomenRecSpa,
              widowedWomenRecSpa: response.data.widowedWomenRecSpa,
              separatedWomenRecSpa: response.data.separatedWomenRecSpa,
              othersSingleWomenRecSpa: response.data.othersSingleWomenRecSpa,
              womenRecSpaSource:
                response.data.womenRecSpaSource[0] != "" &&
                  response.data.womenRecSpaSource != ""
                  ? response.data.womenRecSpaSource
                  : null,
              regGbvIncidents: response.data.regGbvIncidents,
              regGbvIncidentsSource:
                response.data.regGbvIncidentsSource[0] != "" &&
                  response.data.regGbvIncidentsSource != ""
                  ? response.data.regGbvIncidentsSource
                  : null,
              missingWomen: response.data.missingWomen,
              missingWomenSource:
                response.data.missingWomenSource[0] != "" &&
                  response.data.missingWomenSource != ""
                  ? response.data.missingWomenSource
                  : null,
              absenteeWomen: response.data.absenteeWomen,
              absenteeWomenSource:
                response.data.absenteeWomenSource[0] != "" &&
                  response.data.absenteeWomenSource != ""
                  ? response.data.absenteeWomenSource
                  : null,
              womenAffectedByCalamities:
                response.data.womenAffectedByCalamities,
              womenAffectedByCalamitiesSource:
                response.data.womenAffectedByCalamitiesSource[0] != "" &&
                  response.data.womenAffectedByCalamitiesSource != ""
                  ? response.data.womenAffectedByCalamitiesSource
                  : null,

              womenPopSourceOthers: response.data.womenPopSourceOthers,
              girlsPopSourceOthers: response.data.girlsPopSourceOthers,
              singleWomenSourceOthers: response.data.singleWomenSourceOthers,
              sexualGenderMinoritiesSourceOthers: response.data.sexualGenderMinoritiesSourceOthers,
              womenLiteracyRateSourceOthers: response.data.womenLiteracyRateSourceOthers,
              electedWomenRepSourceOthers: response.data.electedWomenRepSourceOthers,
              womenRecSpaSourceOthers: response.data.womenRecSpaSourceOthers,
              regGbvIncidentsSourceOthers: response.data.regGbvIncidentsSourceOthers,
              missingWomenSourceOthers: response.data.missingWomenSourceOthers,
              absenteeWomenSourceOthers: response.data.absenteeWomenSourceOthers,
              womenAffectedByCalamitiesSourceOthers: response.data.womenAffectedByCalamitiesSourceOthers,

              unemployedWomen: response.data.unemployedWomen,
              employedWomen: response.data.employedWomen,
              selfEmployedWomen: response.data.selfEmployedWomen,
              foreignEmployedWomen: response.data.foreignEmployedWomen,
              employmentStatusSource:
                response.data.employmentStatusSource[0] != "" &&
                  response.data.employmentStatusSource != ""
                  ? response.data.employmentStatusSource
                  : null,
              employmentStatusSourceOthers: response.data.employmentStatusSourceOthers,

              totalPopulation: response.data.totalPopulation,
              totalPopulationSource:
                response.data.totalPopulationSource[0] != "" &&
                  response.data.totalPopulationSource != ""
                  ? response.data.totalPopulationSource
                  : null,
              totalPopulationSourceOthers: response.data.totalPopulationSourceOthers
            });
            setUpdate("Yes");
            if (response.data.status === "Submit") {
              setShowBtn("No");
            } else {
              setShowBtn("Yes");
            }
          } else {
            console.log("no data");
            //To reset field's value if there is no response
            reset({
              womenPopSource: [],
              girlsPopSource: [],
              singleWomenSource: [],
              sexualGenderMinoritiesSource: [],
              womenLiteracyRateSource: [],
              electedWomenRepSource: [],
              womenRecSpaSource: [],
              regGbvIncidentsSource: [],
              missingWomenSource: [],
              absenteeWomenSource: [],
              womenAffectedByCalamitiesSource: [],
              employmentStatusSource: [],
              totalPopulationSource: []
            });
            setUpdate("No");
            setShowBtn("Yes");
          }
        }
      )
    );
  };

  const handleOrganization = (organizationId) => {
    console.log("quarter  ", quarter);
    console.log("fiscalYear in quarter", fiscalYear);
    setOrganization(organizationId);
    if (fiscalYear !== "" && quarter !== "") {
      getListByOrganization(fiscalYear, quarter, organizationId);
    }
  };

  //To get data of particular fiscal year and organization selected(for user with role other than local level)
  const getListByOrganization = (fiscalYear, quarter, organization) => {
    trackPromise(
      WomenAndMinoritiesService.getListByOrganization(
        fiscalYear,
        quarter,
        organization
      ).then((response) => {
        console.log("response", response.data);
        if (response.data) {
          setFiscalYear(response.data.fiscalYear);
          setQuarter(response.data.quarter);
          setWomenAndMinoritiesId(response.data.womenAndMinoritiesId);
          reset({
            womenKhasAryaPop: response.data.womenKhasAryaPop,
            womenHillJanjatiPop: response.data.womenHillJanjatiPop,
            womenTharuPop: response.data.womenTharuPop,
            womenHillDalitPop: response.data.womenHillDalitPop,
            // womenTeraiBrahminPop: response.data.womenTeraiBrahminPop,
            womenTeraiJanjatiPop: response.data.womenTeraiJanjatiPop,
            womenMadhesiPop: response.data.womenMadhesiPop,
            womenTeraiDalitPop: response.data.womenTeraiDalitPop,
            womenMuslimPop: response.data.womenMuslimPop,
            womenOthersPop: response.data.womenOthersPop,
            womenPopSource:
              response.data.womenPopSource[0] != "" &&
                response.data.womenPopSource != ""
                ? response.data.womenPopSource
                : null,
            womenPopSourceOthers: response.data.womenPopSourceOthers,

            girlsKhasAryaPop: response.data.girlsKhasAryaPop,
            girlsHillJanjatiPop: response.data.girlsHillJanjatiPop,
            girlsTharuPop: response.data.girlsTharuPop,
            girlsHillDalitPop: response.data.girlsHillDalitPop,
            // girlsTeraiBrahminPop: response.data.girlsTeraiBrahminPop,
            girlsTeraiJanjatiPop: response.data.girlsTeraiJanjatiPop,
            girlsMadhesiPop: response.data.girlsMadhesiPop,
            girlsTeraiDalitPop: response.data.girlsTeraiDalitPop,
            girlsMuslimPop: response.data.girlsMuslimPop,
            girlsOthersPop: response.data.girlsOthersPop,
            girlsPopSource:
              response.data.girlsPopSource[0] != "" &&
                response.data.girlsPopSource != ""
                ? response.data.girlsPopSource
                : null,
            marriedWomen: response.data.marriedWomen,
            singledWomen: response.data.singledWomen,
            divorceeWomen: response.data.divorceeWomen,
            widowedWomen: response.data.widowedWomen,
            separatedWomen: response.data.separatedWomen,
            othersSingleWomen: response.data.othersSingleWomen,
            singleWomenSource:
              response.data.singleWomenSource[0] != "" &&
                response.data.singleWomenSource != ""
                ? response.data.singleWomenSource
                : null,
            sexualGenderMinorities: response.data.sexualGenderMinorities,
            sexualGenderMinoritiesSource:
              response.data.sexualGenderMinoritiesSource[0] != "" &&
                response.data.sexualGenderMinoritiesSource != ""
                ? response.data.sexualGenderMinoritiesSource
                : null,
            womenLiteracyRate: response.data.womenLiteracyRate,
            womenLiteracyRateSource:
              response.data.womenLiteracyRateSource[0] != "" &&
                response.data.womenLiteracyRateSource != ""
                ? response.data.womenLiteracyRateSource
                : null,
            electedWomenRep: response.data.electedWomenRep,
            electedWomenRepSource:
              response.data.electedWomenRepSource[0] != "" &&
                response.data.electedWomenRepSource != ""
                ? response.data.electedWomenRepSource
                : null,
            singledWomenRecSpa: response.data.singledWomenRecSpa,
            divorceeWomenRecSpa: response.data.divorceeWomenRecSpa,
            widowedWomenRecSpa: response.data.widowedWomenRecSpa,
            separatedWomenRecSpa: response.data.separatedWomenRecSpa,
            othersSingleWomenRecSpa: response.data.othersSingleWomenRecSpa,
            womenRecSpaSource:
              response.data.womenRecSpaSource[0] != "" &&
                response.data.womenRecSpaSource != ""
                ? response.data.womenRecSpaSource
                : null,
            regGbvIncidents: response.data.regGbvIncidents,
            regGbvIncidentsSource:
              response.data.regGbvIncidentsSource[0] != "" &&
                response.data.regGbvIncidentsSource != ""
                ? response.data.regGbvIncidentsSource
                : null,
            missingWomen: response.data.missingWomen,
            missingWomenSource:
              response.data.missingWomenSource[0] != "" &&
                response.data.missingWomenSource != ""
                ? response.data.missingWomenSource
                : null,
            absenteeWomen: response.data.absenteeWomen,
            absenteeWomenSource:
              response.data.absenteeWomenSource[0] != "" &&
                response.data.absenteeWomenSource != ""
                ? response.data.absenteeWomenSource
                : null,
            womenAffectedByCalamities:
              response.data.womenAffectedByCalamities,
            womenAffectedByCalamitiesSource:
              response.data.womenAffectedByCalamitiesSource[0] != "" &&
                response.data.womenAffectedByCalamitiesSource != ""
                ? response.data.womenAffectedByCalamitiesSource
                : null,

            womenPopSourceOthers: response.data.womenPopSourceOthers,
            girlsPopSourceOthers: response.data.girlsPopSourceOthers,
            singleWomenSourceOthers: response.data.singleWomenSourceOthers,
            sexualGenderMinoritiesSourceOthers: response.data.sexualGenderMinoritiesSourceOthers,
            womenLiteracyRateSourceOthers: response.data.womenLiteracyRateSourceOthers,
            electedWomenRepSourceOthers: response.data.electedWomenRepSourceOthers,
            womenRecSpaSourceOthers: response.data.womenRecSpaSourceOthers,
            regGbvIncidentsSourceOthers: response.data.regGbvIncidentsSourceOthers,
            missingWomenSourceOthers: response.data.missingWomenSourceOthers,
            absenteeWomenSourceOthers: response.data.absenteeWomenSourceOthers,
            womenAffectedByCalamitiesSourceOthers: response.data.womenAffectedByCalamitiesSourceOthers,

            unemployedWomen: response.data.unemployedWomen,
            employedWomen: response.data.employedWomen,
            selfEmployedWomen: response.data.selfEmployedWomen,
            foreignEmployedWomen: response.data.foreignEmployedWomen,
            employmentStatusSource:
              response.data.employmentStatusSource[0] != "" &&
                response.data.employmentStatusSource != ""
                ? response.data.employmentStatusSource
                : null,
            employmentStatusSourceOthers: response.data.employmentStatusSourceOthers,

            totalPopulation: response.data.totalPopulation,
            totalPopulationSource:
              response.data.totalPopulationSource[0] != "" &&
                response.data.totalPopulationSource != ""
                ? response.data.totalPopulationSource
                : null,
            totalPopulationSourceOthers: response.data.totalPopulationSourceOthers
          });
        } else {
          console.log("no data");
          reset({
            womenPopSource: [],
            girlsPopSource: [],
            singleWomenSource: [],
            sexualGenderMinoritiesSource: [],
            womenLiteracyRateSource: [],
            electedWomenRepSource: [],
            womenRecSpaSource: [],
            regGbvIncidentsSource: [],
            missingWomenSource: [],
            absenteeWomenSource: [],
            womenAffectedByCalamitiesSource: [],
            employmentStatusSource: [],
            totalPopulationSource: []
          });
        }
      })
    );
  };

  const fiscalYearValidation = () => {
    if (fiscalYear === "" || quarter === "") {
      toast.current.show({
        severity: "warn",
        summary: t("selectFiscalYearQuarter"),
        life: 3000,
      });
    } else {
      setEnableForm(false);
    }
  }

  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Toast ref={toast} />
      <Card
        className="p-mb-1"
        style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}
      >
        <div className=" p-card-content">
          <h4 className="p-pt-0">{t("Women and Minorities")}</h4>
        </div>
      </Card>
      <Card className="p-mt-0"
      // style={{ height: "72vh", overflowY: "auto" }}
      >
        <div className=" p-card-content">
          <form className="p-grid p-fluid " autoComplete="off">
            <Organization submitOrganizationId={handleOrganization} />
            <FiscalQuarterMandatory
              fiscalYearValue={fiscalYear}
              handleFiscalYearState={handleFiscalYear}
              quarterValue={quarter}
              handleQuarterState={handleQuarter}
            />
            <div className="p-field p-col-12 p-md-12 ">
              <div className="p-field p-col-12 p-md-12 ">
                <hr style={{ marginTop: "5px" }}></hr>
              </div>
            </div>

            {/* <div className="p-field p-col-12 p-md-12 ">
              <div className="p-field p-col-12 p-md-12 ">
                <h4 className="HeadingTitle" style={{}}>
                  {t("generalInfoForm")}
                </h4>
              </div>
            </div> */}
            <div className="main-form" onClick={fiscalYearValidation} disabled={enableForm}>
              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totalPopulation")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totalPopulationDesc")}
                    />
                  </div>
                 <div className="p-field p-col-3 p-md-3 float-left">
                    <Controller
                      name="totalPopulation"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="totalPopulationSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("totalPopulationSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("totalPopulationSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("totalPopulationSource")}
                  </div>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totalWomenPopulation")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totalWomenPopulationDesc")}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byEthnicity")}
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("khasArya")}
                    </div>
                    <Controller
                      name="womenKhasAryaPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("khasArya")}
                          tooltip={t("khasArya")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillJanajati")}
                    </div>
                    <Controller
                      name="womenHillJanjatiPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("hillJanajati")}
                          tooltip={t("hillJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillDalit")}
                    </div>
                    <Controller
                      name="womenHillDalitPop"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("hillDalit")}
                          tooltip={t("hillDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiMadhesiOther")}
                    </div>
                    <Controller
                      name="womenMadhesiPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiMadhesiOther")}
                          tooltip={t("teraiMadhesiOther")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiJanajati")}
                    </div>
                    <Controller
                      name="womenTeraiJanjatiPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiJanajati")}
                          tooltip={t("teraiJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiDalit")}
                    </div>
                    <Controller
                      name="womenTeraiDalitPop"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("teraiDalit")}
                          tooltip={t("teraiDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("tharu")}
                    </div>
                    <Controller
                      name="womenTharuPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("tharu")}
                          tooltip={t("tharu")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("muslim")}
                    </div>
                    <Controller
                      name="womenMuslimPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("muslim")}
                          tooltip={t("muslim")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("other")}
                    </div>
                    <Controller
                      name="womenOthersPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("otherCaste")}
                          tooltip={t("otherCaste")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      name="womenPopTot"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("womenKhasAryaPop") ? getValues("womenKhasAryaPop") : 0)
                            + (getValues("womenHillJanjatiPop") ? getValues("womenHillJanjatiPop") : 0)
                            + (getValues("womenTharuPop") ? getValues("womenTharuPop") : 0)
                            + (getValues("womenHillDalitPop") ? getValues("womenHillDalitPop") : 0)
                            + (getValues("womenTeraiJanjatiPop") ? getValues("womenTeraiJanjatiPop") : 0)
                            + (getValues("womenMadhesiPop") ? getValues("womenMadhesiPop") : 0)
                            + (getValues("womenTeraiDalitPop") ? getValues("womenTeraiDalitPop") : 0)
                            + (getValues("womenMuslimPop") ? getValues("womenMuslimPop") : 0)
                            + (getValues("womenOthersPop") ? getValues("womenOthersPop") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          // placeholder={t("total")}
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="womenPopSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("womenPopSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("womenPopSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("womenPopSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totalGirlsPopulation")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totalGirlsPopulationDesc")}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byEthnicity")}
                  </div>

                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("khasArya")}
                    </div>
                    <Controller
                      name="girlsKhasAryaPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("khasArya")}
                          tooltip={t("khasArya")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillJanajati")}
                    </div>
                    <Controller
                      name="girlsHillJanjatiPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("hillJanajati")}
                          tooltip={t("hillJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillDalit")}
                    </div>
                    <Controller
                      name="girlsHillDalitPop"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("hillDalit")}
                          tooltip={t("hillDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  {/* <div className="p-field p-col-3 p-md-3 float-left">
                    <Controller
                      name="girlsTeraiBrahminPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiMadhesiBrahman")}
                          tooltip={t("teraiMadhesiBrahman")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div> */}
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiMadhesiOther")}
                    </div>
                    <Controller
                      name="girlsMadhesiPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiMadhesiOther")}
                          tooltip={t("teraiMadhesiOther")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiJanajati")}
                    </div>
                    <Controller
                      name="girlsTeraiJanjatiPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiJanajati")}
                          tooltip={t("teraiJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiDalit")}
                    </div>
                    <Controller
                      name="girlsTeraiDalitPop"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("teraiDalit")}
                          tooltip={t("teraiDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("tharu")}
                    </div>
                    <Controller
                      name="girlsTharuPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("tharu")}
                          tooltip={t("tharu")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("muslim")}
                    </div>
                    <Controller
                      name="girlsMuslimPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("muslim")}
                          tooltip={t("muslim")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("other")}
                    </div>
                    <Controller
                      name="girlsOthersPop"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("otherCaste")}
                          tooltip={t("otherCaste")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      name="girlsPopTot"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("girlsKhasAryaPop") ? getValues("girlsKhasAryaPop") : 0)
                            + (getValues("girlsHillJanjatiPop") ? getValues("girlsHillJanjatiPop") : 0)
                            + (getValues("girlsTharuPop") ? getValues("girlsTharuPop") : 0)
                            + (getValues("girlsHillDalitPop") ? getValues("girlsHillDalitPop") : 0)
                            + (getValues("girlsTeraiJanjatiPop") ? getValues("girlsTeraiJanjatiPop") : 0)
                            + (getValues("girlsMadhesiPop") ? getValues("girlsMadhesiPop") : 0)
                            + (getValues("girlsTeraiDalitPop") ? getValues("girlsTeraiDalitPop") : 0)
                            + (getValues("girlsMuslimPop") ? getValues("girlsMuslimPop") : 0)
                            + (getValues("girlsOthersPop") ? getValues("girlsOthersPop") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          // placeholder={t("total")}
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="girlsPopSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("girlsPopSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("girlsPopSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("girlsPopSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("total10plusWomen")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("total10plusWomenDesc")}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byCase")}
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("unmarried")}
                    </div>
                    <Controller
                      name="singledWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("unmarried")}
                          tooltip={t("unmarried")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("married")}
                    </div>
                    <Controller
                      name="marriedWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("married")}
                          tooltip={t("married")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("widow")}
                    </div>
                    <Controller
                      name="widowedWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("widow")}
                          tooltip={t("widow")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("divorcee")}
                    </div>
                    <Controller
                      name="divorceeWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("divorcee")}
                          tooltip={t("divorcee")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("separated")}
                    </div>
                    <Controller
                      name="separatedWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("separated")}
                          tooltip={t("separated")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("others")}
                    </div>
                    <Controller
                      name="othersSingleWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("others")}
                          tooltip={t("others")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      name="singleWomenTot"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("marriedWomen") ? getValues("marriedWomen") : 0)
                            + (getValues("singledWomen") ? getValues("singledWomen") : 0)
                            + (getValues("divorceeWomen") ? getValues("divorceeWomen") : 0)
                            + (getValues("widowedWomen") ? getValues("widowedWomen") : 0)
                            + (getValues("separatedWomen") ? getValues("separatedWomen") : 0)
                            + (getValues("othersSingleWomen") ? getValues("othersSingleWomen") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          // placeholder={t("total")}
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="singleWomenSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("singleWomenSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("singleWomenSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("singleWomenSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totWomenReceivingSSA")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totWomenReceivingSSADesc")}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("unmarried")}
                    </div>
                    <Controller
                      name="singledWomenRecSpa"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value > getValues("singledWomen") ? setValue("singledWomenRecSpa", 0) : field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("unmarried")}
                          tooltip={t("unmarried")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          max={getValues("singledWomen")}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("widow")}
                    </div>
                    <Controller
                      name="widowedWomenRecSpa"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value > getValues("widowedWomen") ? setValue("widowedWomenRecSpa", 0) : field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("widow")}
                          tooltip={t("widow")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          max={getValues("widowedWomen")}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("divorcee")}
                    </div>
                    <Controller
                      name="divorceeWomenRecSpa"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value > getValues("divorceeWomen") ? setValue("divorceeWomenRecSpa", 0) : field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("divorcee")}
                          tooltip={t("divorcee")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          max={getValues("divorceeWomen")}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("separated")}
                    </div>
                    <Controller
                      name="separatedWomenRecSpa"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value > getValues("separatedWomen") ? setValue("separatedWomenRecSpa", 0) : field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("separated")}
                          tooltip={t("separated")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          max={getValues("separatedWomen")}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("others")}
                    </div>
                    <Controller
                      name="othersSingleWomenRecSpa"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value > getValues("othersSingleWomen") ? setValue("othersSingleWomenRecSpa", 0) : field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("others")}
                          tooltip={t("others")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          max={getValues("othersSingleWomen")}
                        />
                      )}
                    />
                  </div>

                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      name="singleWomenTotRecSpa"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("singledWomenRecSpa") ? getValues("singledWomenRecSpa") : 0)
                            + (getValues("divorceeWomenRecSpa") ? getValues("divorceeWomenRecSpa") : 0)
                            + (getValues("widowedWomenRecSpa") ? getValues("widowedWomenRecSpa") : 0)
                            + (getValues("separatedWomenRecSpa") ? getValues("separatedWomenRecSpa") : 0)
                            + (getValues("othersSingleWomenRecSpa") ? getValues("othersSingleWomenRecSpa") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          // placeholder={t("total")}
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left">
                    <p style={{ margin: "0px", color: "red" }}>{t("singleWomenReceivingSpaNote")}</p>
                  </div>

                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="womenRecSpaSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("womenRecSpaSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("womenRecSpaSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("womenRecSpaSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totSexualGenderMinorities")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totSexualGenderMinoritiesDesc")}
                    />
                  </div>
                 <div className="p-field p-col-3 p-md-3 float-left">
                    <Controller
                      name="sexualGenderMinorities"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="sexualGenderMinoritiesSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("sexualGenderMinoritiesSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("sexualGenderMinoritiesSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("sexualGenderMinoritiesSource")}
                  </div>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("womenLiteracyRate")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("womenLiteracyRateDesc")}
                    />
                  </div>
                  <div className="p-field p-col-8 p-md-3 float-left">
                    <div className="p-inputgroup">
                      <Controller
                        name="womenLiteracyRate"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            id={field.name}
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            min="0"
                            max="100"
                            mode="decimal"
                            minFractionDigits={2}
                            maxFracionDigits={2}
                          />
                        )}
                      />
                      <span className="p-inputgroup-addon">%</span>
                    </div>
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="womenLiteracyRateSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("womenLiteracyRateSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("womenLiteracyRateSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("womenLiteracyRateSource")}
                  </div>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("electedWomenRep")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("electedWomenRepDesc")}
                    />
                  </div>
                  <div className="p-field p-col-8 p-md-3 float-left">
                    <div className="p-inputgroup">
                      <Controller
                        name="electedWomenRep"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            id={field.name}
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            min="0"
                            max="100"
                            mode="decimal"
                            minFractionDigits={2}
                            maxFracionDigits={2}
                          />
                        )}
                      />
                      <span className="p-inputgroup-addon">%</span>
                    </div>
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="electedWomenRepSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("electedWomenRepSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("electedWomenRepSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("electedWomenRepSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>



              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totRegGBVincidents")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totRegGBVincidentsDesc")}
                    />
                  </div>
                 <div className="p-field p-col-3 p-md-3 float-left">
                    <Controller
                      name="regGbvIncidents"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="regGbvIncidentsSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("regGbvIncidentsSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("regGbvIncidentsSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("regGbvIncidentsSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totMissingWomen")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totMissingWomenDesc")}
                    />
                  </div>
                 <div className="p-field p-col-3 p-md-3 float-left">
                    <Controller
                      name="missingWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="missingWomenSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("missingWomenSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("missingWomenSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("missingWomenSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("employmentStatus")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("employmentStatus")}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byCase")}
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("unemployed")}
                    </div>
                    <Controller
                      name="unemployedWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("unemployed")}
                          tooltip={t("unemployed")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("employed")}
                    </div>
                    <Controller
                      name="employedWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("employed")}
                          tooltip={t("employed")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("selfEmployed")}
                    </div>
                    <Controller
                      name="selfEmployedWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("selfEmployed")}
                          tooltip={t("selfEmployed")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("foreignEmployed")}
                    </div>
                    <Controller
                      name="foreignEmployedWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("foreignEmployed")}
                          tooltip={t("foreignEmployed")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      name="employmentStatusTot"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("unemployedWomen") ? getValues("unemployedWomen") : 0)
                            + (getValues("employedWomen") ? getValues("employedWomen") : 0)
                            + (getValues("selfEmployedWomen") ? getValues("selfEmployedWomen") : 0)
                            + (getValues("foreignEmployedWomen") ? getValues("foreignEmployedWomen") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          // placeholder={t("total")}
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="employmentStatusSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("employmentStatusSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("employmentStatusSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("employmentStatusSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totAbsenteeWomen")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totAbsenteeWomenDesc")}
                    />
                  </div>
                 <div className="p-field p-col-3 p-md-3 float-left">
                    <Controller
                      name="absenteeWomen"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="absenteeWomenSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("absenteeWomenSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("absenteeWomenSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("absenteeWomenSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totAffectedByNaturalCalamities")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totAffectedByNaturalCalamitiesDesc")}
                    />
                  </div>
                 <div className="p-field p-col-3 p-md-3 float-left">
                    <Controller
                      name="womenAffectedByCalamities"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="womenAffectedByCalamitiesSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("womenAffectedByCalamitiesSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("womenAffectedByCalamitiesSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("womenAffectedByCalamitiesSource")}
                  </div>
                </div>
              </div>

              {hideBtn === "No" ? (
                <>
                  {showBtn === "Yes" ? (
                    <div className="p-grid p-col-12 p-md-12">

                      <div className="p-col-12 p-md-8"></div>
                      <div className="p-col-12 p-md-2">
                        <Button label={t("save")}
                          className="p-button-sm pull-right submitBtn"
                          onClick={(e) => saveData(e)} />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Button label={t("submit")}
                          className="p-button-sm pull-right submitBtn"
                          onClick={handleSubmit(submitData)} />
                      </div>
                    </div>
                  ) :
                    <></>
                  }</>
              ) : (
                <></>
              )}

              {localLevelVerifier ? (
                <div className="p-grid p-col-12 p-md-12">
                  <div className="p-col-12 p-md-8"></div>
                  <div className="p-col-12 p-md-2">
                    <Button label={t("synchronizeData")}
                      className="p-button-sm pull-right submitBtn"
                      tooltip={t("synchronizeNote")}
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      onClick={() =>
                        history.push({
                          pathname: "/sims/synchronization-module"
                        })
                      }
                    />
                  </div>
                  <div className="p-col-12 p-md-2">
                    <Button label={t("revertData")}
                      className="p-button-sm pull-right submitBtn"
                      tooltip={t("revertNote")}
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      onClick={() =>
                        history.push({
                          pathname: "/sims/revert-module"
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}

            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}

export default WomenGenderAndSexualMinorities;
