export const ActionTypes = {
  //ITEM LIST
  ADD_ITEM_LIST: "ADD_ITEM_LIST",
  EDIT_ITEM_LIST_FIELD: "EDIT_ITEM_LIST_FIELD",
  RESET_ITEM_LIST: "RESET_ITEM_LIST",
  DELETE_ITEM_LIST: "DELETE_ITEM_LIST",
  ADD_ALL_ITEM_LIST: "ADD_ALL_ITEM_LIST",

  //OPERATOR LIST
  ADD_OPERATOR_LIST: "ADD_OPERATOR_LIST",
  EDIT_OPERATOR_LIST_FIELD: "EDIT_OPERATOR_LIST_FIELD",
  RESET_OPERATOR_LIST: "RESET_OPERATOR_LIST",
  DELETE_OPERATOR_LIST: "DELETE_OPERATOR_LIST",
  ADD_ALL_OPERATOR_LIST: "ADD_ALL_OPERATOR_LIST",
  //VOIP FORM
  ADD_VOIP_FORM: "ADD_VOIP_FORM",

  //DYNAMIIC FORM FIELD
  ADD_DYNAMIC_FIELD_LIST: "ADD_DYNAMIC_FIELD_LIST",
  EDIT_DYNAMIC_FIELD_LIST: "EDIT_DYNAMIC_FIELD_LIST",
  RESET_DYNAMIC_FIELD_LIST: "RESET_DYNAMIC_FIELD_LIST",
  DELETE_DYNAMIC_FIELD_LIST: "DELETE_DYNAMIC_FIELD_LIST",
  ADD_ALL_DYNAMIC_FIELD_LIST: "ADD_ALL_DYNAMIC_FIELD_LIST",

  //DOCUMENTS_LIST
  ADD_DOCUMENT_LIST: "ADD_DOCUMENT_LIST",
  EDIT_DOCUMENT_LIST: "EDIT_DOCUMENT_LIST",
  RESET_DOCUMENT_LIST: "RESET_DOCUMENT_LIST",
  DELETE_DOCUMENT_LIST: "DELETE_DOCUMENT_LIST",
  ADD_ALL_DOCUMENT_LIST: "ADD_ALL_DOCUMENT_LIST",

  //MONITORING_TEAM_LIST
  ADD_MONITORING_TEAM_LIST: "ADD_MONITORING_TEAM_LIST",
  EDIT_MONITORING_TEAM_LIST: "EDIT_MONITORING_TEAM_LIST",
  RESET_MONITORING_TEAM_LIST: "RESET_MONITORING_TEAM_LIST",
  DELETE_MONITORING_TEAM_LIST: "DELETE_MONITORING_TEAM_LIST",
  ADD_ALL_MONITORING_TEAM_LIST: "ADD_ALL_MONITORING_TEAM_LIST",

  //LOCATIONS_LIST
  ADD_LOCATION_LIST: "ADD_LOCATION_LIST",
  EDIT_LOCATION_LIST: "EDIT_LOCATION_LIST",
  RESET_LOCATION_LIST: "RESET_LOCATION_LIST",
  DELETE_LOCATION_LIST: "DELETE_LOCATION_LIST",
  ADD_ALL_LOCATION_LIST: "ADD_ALL_LOCATION_LIST",

  // Monitoring Dates
  ADD_MONITORING_DATES_LIST: "ADD_MONITORING_DATES_LIST",

  //  SUB LOCATION SPECIFIC FINDINGS
  ADD_SUB_LOCATION_SPECIFIC_LIST: "ADD_SUB_LOCATION_SPECIFIC_LIST",
  EDIT_SUB_LOCATION_SPECIFIC_LIST: "EDIT_SUB_LOCATION_SPECIFIC_LIST",
  DELETE_SUB_LOCATION_SPECIFIC_LIST: "DELETE_SUB_LOCATION_SPECIFIC_LIST",
};
