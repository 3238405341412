import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";

import Source from "../../utilities/components/Source";

import LabourMigrationService from "../api/services/LabourMigrationService";
import Organization from "../../utilities/components/Organization";
import { t } from "i18next";

import { trackPromise } from "react-promise-tracker";
import UserService from "../../security/api/services/UserService";
import { USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import { useHistory } from "react-router";
import FiscalQuarterMandatory from "../../utilities/components/FiscalQuarterMandatory";

function LabourMigrationIndicator() {
  const history = useHistory();
  const [fiscalYear, setFiscalYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [labourMigrationId, setLabourMigrationId] = useState();

  const [update, setUpdate] = useState("No");
  const [showBtn, setShowBtn] = useState("Yes");
  const [hideBtn, setHideBtn] = useState("No");
  const [enableForm, setEnableForm] = useState(true);
  const [organization, setOrganization] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [localLevelVerifier, setLocalLevelVerifier] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm();
  const toast = useRef(null);
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {
    UserService.getUserLevel().then((response) => {
      if (response.data.data === USER_LEVEL.LOCAL_LEVEL) {
        setHideBtn("No");
        setLocalLevelVerifier(false);
      } else if (response.data.data === USER_LEVEL.LOCAL_LEVEL_VERIFIER) {
        setHideBtn("Yes");
        setLocalLevelVerifier(true);
      } else {
        setLocalLevelVerifier(false);
        setHideBtn("Yes");
      }
    });
  }, []);

  //To save data
  const saveData = (e) => {
    e.preventDefault();
    let data = getValues();
    data.fiscalYear = fiscalYear;
    data.quarter = quarter;
    data.status = "Save";
    console.log("data", data);

    if (update == "No") {
      //trackPromise is used for loading
      trackPromise(
        LabourMigrationService.saveData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Save Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Save UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    } else {
      data.labourMigrationId = labourMigrationId;
      trackPromise(
        LabourMigrationService.updateData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Update Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Update UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    }
  };

  //To update data
  const submitData = (data) => {
    data.fiscalYear = fiscalYear;
    data.quarter = quarter;
    data.status = "Submit";
    console.log("data", data);

    if (update == "No") {
      //trackPromise is used for loading
      trackPromise(
        LabourMigrationService.saveData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Submit Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Submit UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    } else {
      data.labourMigrationId = labourMigrationId;
      trackPromise(
        LabourMigrationService.updateData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Submit Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Submit UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    }
  };

  const handleFiscalYear = (fiscalYearVal) => {
    console.log("fiscal year  ", fiscalYearVal);
    setFiscalYear(fiscalYearVal);
    console.log("quarter in fiscalYear", quarter);
    if (quarter !== "" && organization != "") {
      getListByOrganization(fiscalYearVal, quarter, organization);
    } else if (quarter !== "") {
      getListByFiscalYearAndQuarter(fiscalYearVal, quarter);
    }
  };

  const handleQuarter = (quarterVal) => {
    console.log("quarter  ", quarterVal);
    console.log("fiscalYear in quarter", fiscalYear);
    console.log("iin organization", organization);
    setQuarter(quarterVal);
    if (fiscalYear !== "" && organization != "") {
      getListByOrganization(fiscalYear, quarterVal, organization);
    } else if (fiscalYear !== "") {
      getListByFiscalYearAndQuarter(fiscalYear, quarterVal);
    }
  };

  const getListByFiscalYearAndQuarter = (fiscalYear, quarter) => {
    trackPromise(
      LabourMigrationService.getListByFiscalYearAndQuarter(fiscalYear, quarter).then(
        (response) => {
          console.log("response", response.data);
          if (response.data) {
            setFiscalYear(response.data.fiscalYear);
            setQuarter(response.data.quarter);
            setLabourMigrationId(response.data.labourMigrationId);
            reset({
              internalMigrantLabour: response.data.internalMigrantLabour,
              externalMigrantLabour: response.data.externalMigrantLabour,
              maleMigrantLabour: response.data.maleMigrantLabour,
              femaleMigrantLabour: response.data.femaleMigrantLabour,
              otherMigrantLabour: response.data.otherMigrantLabour,
              khasAryaMigrantLabour: response.data.khasAryaMigrantLabour,
              hillJanjatiMigrantLabour: response.data.hillJanjatiMigrantLabour,
              tharuMigrantLabour: response.data.tharuMigrantLabour,
              hillDalitMigrantLabour: response.data.hillDalitMigrantLabour,
              teraiJanjatiMigrantLabour: response.data.teraiJanjatiMigrantLabour,
              madhesiMigrantLabour: response.data.madhesiMigrantLabour,
              teraiDalitMigrantLabour: response.data.teraiDalitMigrantLabour,
              muslimMigrantLabour: response.data.muslimMigrantLabour,
              otherCasteMigrantLabour: response.data.otherCasteMigrantLabour,

              migrantLabourSource:
                response.data.migrantLabourSource[0] != "" &&
                  response.data.migrantLabourSource != ""
                  ? response.data.migrantLabourSource
                  : null,
              maleMigrantToIndia: response.data.maleMigrantToIndia,
              femaleMigrantToIndia: response.data.femaleMigrantToIndia,
              otherMigrantToIndia: response.data.otherMigrantToIndia,

              khasAryaMigrantToIndia: response.data.khasAryaMigrantToIndia,
              hillJanjatiMigrantToIndia: response.data.hillJanjatiMigrantToIndia,
              tharuMigrantToIndia: response.data.tharuMigrantToIndia,
              hillDalitMigrantToIndia: response.data.hillDalitMigrantToIndia,
              teraiJanjatiMigrantToIndia: response.data.teraiJanjatiMigrantToIndia,
              madhesiMigrantToIndia: response.data.madhesiMigrantToIndia,
              teraiDalitMigrantToIndia: response.data.teraiDalitMigrantToIndia,
              muslimMigrantToIndia: response.data.muslimMigrantToIndia,
              otherCasteMigrantToIndia: response.data.otherCasteMigrantToIndia,

              migrantToIndiaSource:
                response.data.migrantToIndiaSource[0] != "" &&
                  response.data.migrantToIndiaSource != ""
                  ? response.data.migrantToIndiaSource
                  : null,
              maleReturneeMigrant: response.data.maleReturneeMigrant,
              femaleReturneeMigrant: response.data.femaleReturneeMigrant,
              otherReturneeMigrant: response.data.otherReturneeMigrant,
              khasAryaReturneeMigrant: response.data.khasAryaReturneeMigrant,
              hillJanjatiReturneeMigrant: response.data.hillJanjatiReturneeMigrant,
              tharuReturneeMigrant: response.data.tharuReturneeMigrant,
              hillDalitReturneeMigrant: response.data.hillDalitReturneeMigrant,
              teraiJanjatiReturneeMigrant: response.data.teraiJanjatiReturneeMigrant,
              madhesiReturneeMigrant: response.data.madhesiReturneeMigrant,
              teraiDalitReturneeMigrant: response.data.teraiDalitReturneeMigrant,
              muslimReturneeMigrant: response.data.muslimReturneeMigrant,
              otherCasteReturneeMigrant: response.data.otherCasteReturneeMigrant,
              returneeMigrantSource:
                response.data.returneeMigrantSource[0] != "" &&
                  response.data.returneeMigrantSource != ""
                  ? response.data.returneeMigrantSource
                  : null,

              muslimMigrantLabour: response.data.muslimMigrantLabour,
              muslimMigrantToIndia: response.data.muslimMigrantToIndia,
              muslimReturneeMigrant: response.data.muslimReturneeMigrant,

              migrantLabourSourceOthers: response.data.migrantLabourSourceOthers,
              migrantToIndiaSourceOthers: response.data.migrantToIndiaSourceOthers,
              returneeMigrantSourceOthers: response.data.returneeMigrantSourceOthers,
            });
            setUpdate("Yes");
            if (response.data.status === "Submit") {
              setShowBtn("No");
            } else {
              setShowBtn("Yes");
            }
          } else {
            console.log("no data");
            reset({
              migrantLabourSource: [],
              migrantToIndiaSource: [],
              returneeMigrantSource: [],
            });
            setUpdate("No");
            setShowBtn("Yes");
          }
        }
      )
    );
  };

  const handleOrganization = (organizationId) => {
    console.log("quarter  ", quarter);
    console.log("fiscalYear in quarter", fiscalYear);
    setOrganization(organizationId);
    if (fiscalYear !== "" && quarter !== "") {
      getListByOrganization(fiscalYear, quarter, organizationId);
    }
  };

  const getListByOrganization = (fiscalYear, quarter, organization) => {
    trackPromise(
      LabourMigrationService.getListByOrganization(
        fiscalYear,
        quarter,
        organization
      ).then((response) => {
        console.log("response", response.data);
        if (response.data) {
          setFiscalYear(response.data.fiscalYear);
          setQuarter(response.data.quarter);
          setLabourMigrationId(response.data.labourMigrationId);
          reset({
            internalMigrantLabour: response.data.internalMigrantLabour,
            externalMigrantLabour: response.data.externalMigrantLabour,
            maleMigrantLabour: response.data.maleMigrantLabour,
            femaleMigrantLabour: response.data.femaleMigrantLabour,
            otherMigrantLabour: response.data.otherMigrantLabour,

            khasAryaMigrantLabour: response.data.khasAryaMigrantLabour,
            hillJanjatiMigrantLabour: response.data.hillJanjatiMigrantLabour,
            tharuMigrantLabour: response.data.tharuMigrantLabour,
            hillDalitMigrantLabour: response.data.hillDalitMigrantLabour,
            teraiJanjatiMigrantLabour: response.data.teraiJanjatiMigrantLabour,
            madhesiMigrantLabour: response.data.madhesiMigrantLabour,
            teraiDalitMigrantLabour: response.data.teraiDalitMigrantLabour,
            muslimMigrantLabour: response.data.muslimMigrantLabour,
            otherCasteMigrantLabour: response.data.otherCasteMigrantLabour,


            migrantLabourSource:
              response.data.migrantLabourSource[0] != "" &&
                response.data.migrantLabourSource != ""
                ? response.data.migrantLabourSource
                : null,
            maleMigrantToIndia: response.data.maleMigrantToIndia,
            femaleMigrantToIndia: response.data.femaleMigrantToIndia,
            otherMigrantToIndia: response.data.otherMigrantToIndia,

            khasAryaMigrantToIndia: response.data.khasAryaMigrantToIndia,
            hillJanjatiMigrantToIndia: response.data.hillJanjatiMigrantToIndia,
            tharuMigrantToIndia: response.data.tharuMigrantToIndia,
            hillDalitMigrantToIndia: response.data.hillDalitMigrantToIndia,
            teraiJanjatiMigrantToIndia: response.data.teraiJanjatiMigrantToIndia,
            madhesiMigrantToIndia: response.data.madhesiMigrantToIndia,
            teraiDalitMigrantToIndia: response.data.teraiDalitMigrantToIndia,
            muslimMigrantToIndia: response.data.muslimMigrantToIndia,
            otherCasteMigrantToIndia: response.data.otherCasteMigrantToIndia,

            migrantToIndiaSource:
              response.data.migrantToIndiaSource[0] != "" &&
                response.data.migrantToIndiaSource != ""
                ? response.data.migrantToIndiaSource
                : null,
            maleReturneeMigrant: response.data.maleReturneeMigrant,
            femaleReturneeMigrant: response.data.femaleReturneeMigrant,
            otherReturneeMigrant: response.data.otherReturneeMigrant,
            khasAryaReturneeMigrant: response.data.khasAryaReturneeMigrant,
            hillJanjatiReturneeMigrant: response.data.hillJanjatiReturneeMigrant,
            tharuReturneeMigrant: response.data.tharuReturneeMigrant,
            hillDalitReturneeMigrant: response.data.hillDalitReturneeMigrant,
            teraiJanjatiReturneeMigrant: response.data.teraiJanjatiReturneeMigrant,
            madhesiReturneeMigrant: response.data.madhesiReturneeMigrant,
            teraiDalitReturneeMigrant: response.data.teraiDalitReturneeMigrant,
            muslimReturneeMigrant: response.data.muslimReturneeMigrant,
            otherCasteReturneeMigrant: response.data.otherCasteReturneeMigrant,
            returneeMigrantSource:
              response.data.returneeMigrantSource[0] != "" &&
                response.data.returneeMigrantSource != ""
                ? response.data.returneeMigrantSource
                : null,

            muslimMigrantLabour: response.data.muslimMigrantLabour,
            muslimMigrantToIndia: response.data.muslimMigrantToIndia,
            muslimReturneeMigrant: response.data.muslimReturneeMigrant,

            migrantLabourSourceOthers: response.data.migrantLabourSourceOthers,
            migrantToIndiaSourceOthers: response.data.migrantToIndiaSourceOthers,
            returneeMigrantSourceOthers: response.data.returneeMigrantSourceOthers,
          });
        } else {
          console.log("no data");
          reset({
            migrantLabourSource: [],
            migrantToIndiaSource: [],
            returneeMigrantSource: [],
          });
        }
      })
    );
  };

  const fiscalYearValidation = () => {
    if (fiscalYear === "" || quarter === "") {
      toast.current.show({
        severity: "warn",
        summary: t("selectFiscalYearQuarter"),
        life: 3000,
      });
    } else {
      setEnableForm(false);
    }
  }

  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Toast ref={toast} />
      <Card
        className="p-mb-1"
        style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}
      >
        <div className=" p-card-content">
          <h4 className="p-pt-0">{t("labourMigration")}</h4>
        </div>
      </Card>

      <div className="p-grid p-col-12 p-md-12">
        <div className="p-field p-col-12 p-md-3">
          <Button className="box-shadow"
            style={{
              justifyContent: "center",
              background: "rgb(75 125 181)",
              color: "#FFF",
              width: '100%',
              minHeight: '30px',
              border: '4px solid rgb(208 0 0)',
            }}
            label={t("Indicator")}
            disabled
          />
        </div>
        <div className="p-field p-col-12 p-md-3">
          <Button className="box-shadow"
            style={{
              justifyContent: "center",
              background: "rgb(75 125 181)",
              color: "#FFF",
              width: '100%',
              minHeight: '30px',
              border: '4px solid rgb(208 0 0)',
            }}
            label={t("Case form")}
            onClick={() =>
              history.push("/sims/labour-migration")
            }
          />
        </div>
        <div className="p-field p-col-12 p-md-3">
          <Button className="box-shadow"
            style={{
              justifyContent: "center",
              background: "rgb(75 125 181)",
              color: "#FFF",
              width: '100%',
              minHeight: '30px',
              border: '4px solid rgb(208 0 0)',
            }}
            label={t("Case form List")}
            onClick={() =>
              history.push("/sims/labour-migration-list")
            }
          />
        </div>
      </div>

      <Card className="p-mt-0"
      // style={{ height: "72vh", overflowY: "auto" }}
      >
        <div className=" p-card-content">
          <form className="p-grid p-fluid" autoComplete="off">
            <Organization submitOrganizationId={handleOrganization} />
            {hideBtn === "Yes" ? <></> : <></>}

            <FiscalQuarterMandatory
              fiscalYearValue={fiscalYear}
              handleFiscalYearState={handleFiscalYear}
              quarterValue={quarter}
              handleQuarterState={handleQuarter}
            />
            <div className="p-field p-col-12 p-md-12 ">
              <div className="p-field p-col-12 p-md-12 ">
                <hr style={{ marginTop: "5px" }}></hr>
              </div>
            </div>

            <div className="main-form" onClick={fiscalYearValidation} disabled={enableForm}>
              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totMigrantLabour")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totMigrantLabourDesc")}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byCase")}
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("internal")}
                    </div>
                    <Controller
                      name="internalMigrantLabour"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("internal")}
                          tooltip={t("internal")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("external")}
                    </div>
                    <Controller
                      name="externalMigrantLabour"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("external")}
                          tooltip={t("external")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("internalMigrantLabour") ? getValues("internalMigrantLabour") : 0)
                            + (getValues("externalMigrantLabour") ? getValues("externalMigrantLabour") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byGender")}
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("male")}
                    </div>
                    <Controller
                      name="maleMigrantLabour"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("male")}
                          tooltip={t("male")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("female")}
                    </div>
                    <Controller
                      name="femaleMigrantLabour"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("female")}
                          tooltip={t("female")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("others")}
                    </div>
                    <Controller
                      name="otherMigrantLabour"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("others")}
                          tooltip={t("others")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      name="totalMigrantLabour"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("maleMigrantLabour") ? getValues("maleMigrantLabour") : 0)
                            + (getValues("femaleMigrantLabour") ? getValues("femaleMigrantLabour") : 0)
                            + (getValues("otherMigrantLabour") ? getValues("otherMigrantLabour") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byEthnicity")}
                  </div> <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("khasArya")}
                    </div>
                    <Controller
                      name="khasAryaMigrantLabour"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("khasArya")}
                          tooltip={t("khasArya")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillJanajati")}
                    </div>
                    <Controller
                      name="hillJanjatiMigrantLabour"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("hillJanajati")}
                          tooltip={t("hillJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillDalit")}
                    </div>
                    <Controller
                      name="hillDalitMigrantLabour"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("hillDalit")}
                          tooltip={t("hillDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiJanajati")}
                    </div>
                    <Controller
                      name="teraiJanjatiMigrantLabour"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiJanajati")}
                          tooltip={t("teraiJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiMadhesiOther")}
                    </div>
                    <Controller
                      name="madhesiMigrantLabour"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiMadhesiOther")}
                          tooltip={t("teraiMadhesiOther")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiDalit")}
                    </div>
                    <Controller
                      name="teraiDalitMigrantLabour"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("teraiDalit")}
                          tooltip={t("teraiDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("tharu")}
                    </div>
                    <Controller
                      name="tharuMigrantLabour"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("tharu")}
                          tooltip={t("tharu")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("muslim")}
                    </div>
                    <Controller
                      name="muslimMigrantLabour"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("muslim")}
                          tooltip={t("muslim")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("otherCaste")}
                    </div>
                    <Controller
                      name="otherCasteMigrantLabour"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("otherCaste")}
                          tooltip={t("otherCaste")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                   <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("khasAryaMigrantLabour") ? getValues("khasAryaMigrantLabour") : 0)
                            + (getValues("hillJanjatiMigrantLabour") ? getValues("hillJanjatiMigrantLabour") : 0)
                            + (getValues("tharuMigrantLabour") ? getValues("tharuMigrantLabour") : 0)
                            + (getValues("hillDalitMigrantLabour") ? getValues("hillDalitMigrantLabour") : 0)
                            + (getValues("teraiJanjatiMigrantLabour") ? getValues("teraiJanjatiMigrantLabour") : 0)
                            + (getValues("madhesiMigrantLabour") ? getValues("madhesiMigrantLabour") : 0)
                            + (getValues("teraiDalitMigrantLabour") ? getValues("teraiDalitMigrantLabour") : 0)
                            + (getValues("muslimMigrantLabour") ? getValues("muslimMigrantLabour") : 0)
                            + (getValues("otherCasteMigrantLabour") ? getValues("otherCasteMigrantLabour") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          // placeholder={t("total")}
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="migrantLabourSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("migrantLabourSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("migrantLabourSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("migrantLabourSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totMigrantLabourToIndia")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totMigrantLabourToIndiaDesc")}
                    />
                  </div>

                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byGender")}
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("male")}
                    </div>
                    <Controller
                      name="maleMigrantToIndia"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("male")}
                          tooltip={t("male")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("female")}
                    </div>
                    <Controller
                      name="femaleMigrantToIndia"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("female")}
                          tooltip={t("female")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("others")}
                    </div>
                    <Controller
                      name="otherMigrantToIndia"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("others")}
                          tooltip={t("others")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      name="totalMigrantToIndia"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("maleMigrantToIndia") ? getValues("maleMigrantToIndia") : 0)
                            + (getValues("femaleMigrantToIndia") ? getValues("femaleMigrantToIndia") : 0)
                            + (getValues("otherMigrantToIndia") ? getValues("otherMigrantToIndia") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byEthnicity")}
                  </div>

                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("khasArya")}
                    </div>
                    <Controller
                      name="khasAryaMigrantToIndia"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("khasArya")}
                          tooltip={t("khasArya")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillJanajati")}
                    </div>
                    <Controller
                      name="hillJanjatiMigrantToIndia"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("hillJanajati")}
                          tooltip={t("hillJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillDalit")}
                    </div>
                    <Controller
                      name="hillDalitMigrantToIndia"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("hillDalit")}
                          tooltip={t("hillDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiJanajati")}
                    </div>
                    <Controller
                      name="teraiJanjatiMigrantToIndia"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiJanajati")}
                          tooltip={t("teraiJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiMadhesiOther")}
                    </div>
                    <Controller
                      name="madhesiMigrantToIndia"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiMadhesiOther")}
                          tooltip={t("teraiMadhesiOther")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiDalit")}
                    </div>
                    <Controller
                      name="teraiDalitMigrantToIndia"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("teraiDalit")}
                          tooltip={t("teraiDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("tharu")}
                    </div>
                    <Controller
                      name="tharuMigrantToIndia"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("tharu")}
                          tooltip={t("tharu")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("muslim")}
                    </div>
                    <Controller
                      name="muslimMigrantToIndia"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("muslim")}
                          tooltip={t("muslim")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("otherCaste")}
                    </div>
                    <Controller
                      name="otherCasteMigrantToIndia"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("otherCaste")}
                          tooltip={t("otherCaste")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                   <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                     control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("khasAryaMigrantToIndia") ? getValues("khasAryaMigrantToIndia") : 0)
                            + (getValues("hillJanjatiMigrantToIndia") ? getValues("hillJanjatiMigrantToIndia") : 0)
                            + (getValues("tharuMigrantToIndia") ? getValues("tharuMigrantToIndia") : 0)
                            + (getValues("hillDalitMigrantToIndia") ? getValues("hillDalitMigrantToIndia") : 0)
                            + (getValues("teraiJanjatiMigrantToIndia") ? getValues("teraiJanjatiMigrantToIndia") : 0)
                            + (getValues("madhesiMigrantToIndia") ? getValues("madhesiMigrantToIndia") : 0)
                            + (getValues("teraiDalitMigrantToIndia") ? getValues("teraiDalitMigrantToIndia") : 0)
                            + (getValues("muslimMigrantToIndia") ? getValues("muslimMigrantToIndia") : 0)
                            + (getValues("otherCasteMigrantToIndia") ? getValues("otherCasteMigrantToIndia") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          // placeholder={t("total")}
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="migrantToIndiaSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("migrantToIndiaSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("migrantToIndiaSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("migrantToIndiaSource")}
                  </div>
                </div>
              </div>

              <div className="p-field p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 ">
                  <hr
                    style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                  ></hr>
                </div>
              </div>

              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totReturneeMigrant")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totReturneeMigrantDesc")}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byGender")}
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("male")}
                    </div>
                    <Controller
                      name="maleReturneeMigrant"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("male")}
                          tooltip={t("male")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("female")}
                    </div>
                    <Controller
                      name="femaleReturneeMigrant"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("female")}
                          tooltip={t("female")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("others")}
                    </div>
                    <Controller
                      name="otherReturneeMigrant"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("others")}
                          tooltip={t("others")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      name="totalReturneeMigrant"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("maleReturneeMigrant") ? getValues("maleReturneeMigrant") : 0)
                            + (getValues("femaleReturneeMigrant") ? getValues("femaleReturneeMigrant") : 0)
                            + (getValues("otherReturneeMigrant") ? getValues("otherReturneeMigrant") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-12 p-md-12 float-left sub-label">
                    {t("byEthnicity")}
                  </div> <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("khasArya")}
                    </div>
                    <Controller
                      name="khasAryaReturneeMigrant"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("khasArya")}
                          tooltip={t("khasArya")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillJanajati")}
                    </div>
                    <Controller
                      name="hillJanjatiReturneeMigrant"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("hillJanajati")}
                          tooltip={t("hillJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillDalit")}
                    </div>
                    <Controller
                      name="hillDalitReturneeMigrant"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("hillDalit")}
                          tooltip={t("hillDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiMadhesiOther")}
                    </div>
                    <Controller
                      name="madhesiReturneeMigrant"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiMadhesiOther")}
                          tooltip={t("teraiMadhesiOther")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiJanajati")}
                    </div>
                    <Controller
                      name="teraiJanjatiReturneeMigrant"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("teraiJanajati")}
                          tooltip={t("teraiJanajati")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiDalit")}
                    </div>
                    <Controller
                      name="teraiDalitReturneeMigrant"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // placeholder={t("teraiDalit")}
                          tooltip={t("teraiDalit")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("tharu")}
                    </div>
                    <Controller
                      name="tharuReturneeMigrant"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("tharu")}
                          tooltip={t("tharu")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("muslim")}
                    </div>
                    <Controller
                      name="muslimReturneeMigrant"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("muslim")}
                          tooltip={t("muslim")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("otherCaste")}
                    </div>
                    <Controller
                      name="otherCasteReturneeMigrant"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          // placeholder={t("otherCaste")}
                          tooltip={t("otherCaste")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                   <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                    <Controller
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={(getValues("khasAryaReturneeMigrant") ? getValues("khasAryaReturneeMigrant") : 0)
                            + (getValues("hillJanjatiReturneeMigrant") ? getValues("hillJanjatiReturneeMigrant") : 0)
                            + (getValues("tharuReturneeMigrant") ? getValues("tharuReturneeMigrant") : 0)
                            + (getValues("hillDalitReturneeMigrant") ? getValues("hillDalitReturneeMigrant") : 0)
                            + (getValues("teraiJanjatiReturneeMigrant") ? getValues("teraiJanjatiReturneeMigrant") : 0)
                            + (getValues("madhesiReturneeMigrant") ? getValues("madhesiReturneeMigrant") : 0)
                            + (getValues("teraiDalitReturneeMigrant") ? getValues("teraiDalitReturneeMigrant") : 0)
                            + (getValues("muslimReturneeMigrant") ? getValues("muslimReturneeMigrant") : 0)
                            + (getValues("otherCasteReturneeMigrant") ? getValues("otherCasteReturneeMigrant") : 0)}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          min="0"
                          disabled
                          // placeholder={t("total")}
                          tooltip={t("total")}
                          tooltipOptions={{
                            position: "bottom"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div class="p-col-12 p-md-3">
                  <Controller
                    name="returneeMigrantSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("returneeMigrantSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("returneeMigrantSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div class="p-col-12 p-md-12">
                    {getFormErrorMessage("returneeMigrantSource")}
                  </div>
                </div>
              </div>

              {hideBtn === "No" ? (
                <>
                  {showBtn === "Yes" ? (
                    <div className="p-grid p-col-12 p-md-12">

                      <div className="p-col-12 p-md-8"></div>
                      <div className="p-col-12 p-md-2">
                        <Button label={t("save")}
                          className="p-button-sm pull-right submitBtn"
                          onClick={(e) => saveData(e)} />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Button label={t("submit")}
                          className="p-button-sm pull-right submitBtn"
                          onClick={handleSubmit(submitData)} />
                      </div>
                    </div>
                  ) :
                    <></>
                  }</>
              ) : (
                <></>
              )}

              {localLevelVerifier ? (
                <div className="p-grid p-col-12 p-md-12">
                  <div className="p-col-12 p-md-8"></div>
                  <div className="p-col-12 p-md-2">
                    <Button label={t("synchronizeData")}
                      className="p-button-sm pull-right submitBtn"
                      tooltip={t("synchronizeNote")}
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      onClick={() =>
                        history.push({
                          pathname: "/sims/synchronization-module"
                        })
                      }
                    />
                  </div>
                  <div className="p-col-12 p-md-2">
                    <Button label={t("revertData")}
                      className="p-button-sm pull-right submitBtn"
                      tooltip={t("revertNote")}
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      onClick={() =>
                        history.push({
                          pathname: "/sims/revert-module"
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}

            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}

export default LabourMigrationIndicator;
