import React, { useState, useRef } from "react"
import { Button } from "primereact/button"
import { useForm, Controller } from "react-hook-form";
import { t } from "i18next";
import Organization from "../../utilities/components/Organization";
import InputText from "../../utilities/components/InputText";
import DisabilityCardDetailService from "../api/services/DisabilityCardDetailService";
import { Tooltip } from "react-bootstrap";

const DisabilityCardDetail = () => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({});
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const [organization, setOrganization] = useState("");

    const handleOrganization = (organizationId) => {
        setOrganization(organizationId);
    };
    
    const columns = [
        {
          name: "idCardNo",
          label: t("enteredIdCardNo"),
          options: {},
        },
        {
          name: "fullName",
          label: t("name"),
          options: {},
        },
        {
          name: "id",
          label: t("generatedIdCardNo"),
          options: {},
        },
        {
          name: "dobEng",
          label: t("dateOfBirth"),
          options: {},
        },
        {
          name: "bloodGroup",
          label: t("bloodGroup"),
          options: {},
        },
        {
          name: "id",
          label: t("actions"),
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (<></>
                // <React.Fragment>
    
                //   <Tooltip target=".tooltip-icon" />
                //   {
                //     view ?
                //       <i
                //         className="pi pi-eye tooltip-icon "
                //         data-pr-tooltip="View Details"
                //         data-pr-position="right"
                //         data-pr-at="right+5 top"
                //         data-pr-my="left center-2"
                //         onClick={() => {
                //           setCardTypeModal(true);
                //           setIdCardNo(value);
                //         }}
                //       />
                //       :
                //       <>
                //         <i
                //           className="pi pi-pencil tooltip-icon p-mr-2 "
                //           data-pr-tooltip="Update Details"
                //           data-pr-position="right"
                //           data-pr-at="right+5 top"
                //           data-pr-my="left center-2"
                //           onClick={() =>
                //             history.push({
                //               pathname: "/sims/disability-IDCard-form",
                //               state: {
                //                 id: value,
                //                 action: ACTION.UPDATE,
                //               },
                //             })
                //           }
                //         />
    
                //         <i
                //           className="pi pi-eye tooltip-icon p-mr-2"
                //           data-pr-tooltip="View Details"
                //           data-pr-position="right"
                //           data-pr-at="right+5 top"
                //           data-pr-my="left center-2"
                //           onClick={() => {
                //             setCardTypeModal(true);
                //             setIdCardNo(value);
                //           }}
                //         />
    
                //         <i
                //           className="pi pi-trash tooltip-icon "
                //           data-pr-tooltip="Delete"
                //           data-pr-position="right"
                //           data-pr-at="right+5 top"
                //           data-pr-my="left center-2"
                //           onClick={() => {
                //             confirmDeleteCard(value);
                //           }}
                //         />
                //       </>
                //   }
                // </React.Fragment>
              );
            },
          },
        },
      ];

    // if (props.location.state && props.location.state.id) {
    //     (props.location.state && props.location.state.id
    //     ).then((response) => {
    //         if (response.status == 200) {
    //             reset({
    //                 id: response.data.data.id,
    //                 nameEng: response.data.data.disabilityApprovedDetail.nameEng,
    //                 nameNep: response.data.data.disabilityApprovedDetail.nameNep,
    //                 designationEng: response.data.data.disabilityApprovedDetail.designationEng,
    //                 designationNep: response.data.data.disabilityApprovedDetail.designationNep,
    //                 dateEng: new Date(response.data.data.disabilityApprovedDetail.dateEng),
    //                 dateNep: response.data.data.disabilityApprovedDetail.dateNep,
    //             })
    //         }
    //     }
    //     )
    // }

    const toast = useRef(null);

    //To Save Data
    const submitData = (data) => {
        console.log("data", data);
        data.status = "Submit";

        DisabilityCardDetailService.saveDate(data)
            .then((response) => {
                console.log("response", response);
                if (response.status === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "Success Message",
                        detail: "Save successful",
                        life: 3000
                    })
                    // window.location.reload(false);
                }
            }).catch((error) => {
                // We want to handle globally
                toast.current.show({
                  severity: "error",
                  summary: "Error Message",
                  detail: error.response.data.msg,
                  life: 3000
                });
            })

    }
    
    return (
        <>
            <div class="p-card p-component p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
                <div class="p-card-body">
                    <div className="p-card-content">
                        <h4 className="p-pt-0">{t("Disability Approved Officer Detail")}</h4>
                    </div>
                </div>
            </div>
            <div class="p-card p-component p-mt-0">
                <div className="p-card-body">
                    <div className="p-card-content">
                        <form className="p-grid p-fluid">
                            <Organization submitOrganizationId={handleOrganization} />
                            <div className="p-field p-col-12 p-md-12 ">
                                <div className="p-field p-col-10 p-md-10 float-left"></div>
                                <div className="p-field p-col-2 p-md-2 float-right">
                                    <Button
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#1c80cf",
                                            color: "#FFF",
                                        }}
                                        label={t("search")}
                                        // onClick={handleSubmit(searchData)}
                                    />
                                </div>
                            </div>
                            <div className="p-field p-col-12 p-md-12 ">
                                <hr style={{ margin: "5px" }}></hr>
                            </div>
                            <div className="p-field p-col-12 p-md-12 float-left">
                                <strong style={{ paddingLeft: "10px" }}>{t("approvedBy")} </strong>
                            </div>
                            <div className="p-field p-col-12 p-md-12 float-left">
                                <div class="p-field p-col-12 p-md-12 float-left main-label">{t("name")}<span style={{ color: "#d00000" }}> * </span></div>
                                <div class="p-field p-col-6 p-md-6 float-left">
                                    <Controller
                                        name="nameEng"
                                        control={control}
                                        rules={{ required: "Name is required." }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className="rounded-input p-mb-1"
                                                placeholder="English"
                                            />
                                        )}
                                    />
                                    {getFormErrorMessage("nameEng")}
                                </div>
                                <div class="p-field p-col-6 p-md-6 float-left">
                                    <Controller
                                        name="nameNep"
                                        control={control}
                                        rules={{ required: "Name is required." }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className="rounded-input p-mb-1"
                                                placeholder="नेपाली"
                                            />
                                        )}
                                    />
                                    {getFormErrorMessage("nameNep")}
                                </div>
                            </div>
                            <div className="p-field p-col-12 p-md-12 float-left">
                                <div class="p-field p-col-12 p-md-12 float-left main-label">{t("designation")}<span style={{ color: "#d00000" }}> * </span> </div>
                                <div class="p-field p-col-6 p-md-6 float-left">
                                    <Controller
                                        name="designationEng"
                                        control={control}
                                        rules={{ required: "Designation is required." }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className="rounded-input p-mb-1"
                                                placeholder="English"
                                            />
                                        )}
                                    />
                                    {getFormErrorMessage("designationEng")}
                                </div>
                                <div class="p-field p-col-6 p-md-6 float-left">
                                    <Controller
                                        name="designationNep"
                                        control={control}
                                        rules={{ required: "Designation is required." }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className="rounded-input p-mb-1"
                                                placeholder="नेपाली"
                                            />
                                        )}
                                    />
                                    {getFormErrorMessage("designationNep")}
                                </div>
                            </div>
                            <div className="p-field p-col-12 p-md-12 float-left">
                                <div class="p-field p-col-10 p-md-10 float-left">&nbsp;</div>
                                <div class="p-field p-col-2 p-md-2 float-right">
                                    <Button label={t("save")} onClick={handleSubmit(submitData)}
                                        style={{ background: "#4e70ae", color: "#FFF", justifyContent: "center" }}
                                    >  </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    );
}
export default DisabilityCardDetail;
