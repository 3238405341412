import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import HomeLookupService from "../api/services/HomeLookupService";
import { useForm } from "react-hook-form";
import { USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import OrganizationService from "../../security/api/services/OrganizationService";
import AddressService from "../../security/api/services/AddressService";
import UserService from "../../security/api/services/UserService";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { trackPromise } from "react-promise-tracker";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { t } from "i18next";
import { Dialog } from "primereact/dialog";
import LookupAddress from "../../utilities/components/LookupAddress";
import XLSX from 'xlsx';

const ShelterHomeLookup = () => {
    const [homeList, setHomeList] = useState([]);
    const [provincesList, setProvincesList] = useState([]);
    const [districtsList, setDistrictList] = useState([]);
    const [municipalitiesList, setMunicipalitiesList] = useState([]);
    const [userLevel, setUserLevel] = useState();
    const [editModal, setEditModal] = useState(false);
    const [nameEng, setNameEng] = useState("");
    const [nameNep, setNameNep] = useState("");
    const [homeId, setHomeId] = useState();

    const options = {
        filter: true,
        print: false,
        filterType: "dropdown",
        responsive: "vertical",
        fixedSelectColumn: false,
        selectableRows: "none",
    };

    const [buttonLabel, setButtonLabel] = useState("Create");
    const toast = useRef(null);

    useEffect(() => {
        AddressService.getAllProvinces().then((response) => {
            setProvincesList(response.data.data);
        });

        let organizationAddress = {};
        OrganizationService.getLoggedInUserOrganizaitonDetails().then((response) => {
            console.log("response.data.data.name", response.data);
            organizationAddress = response.data.data.organizationAddress;
            UserService.getUserLevel().then((response) => {
                if (response.data.data == USER_LEVEL.PROVINCE) {
                    getDistrictValue(organizationAddress.province);
                    setValue("province", parseInt(organizationAddress.province));
                } else if (response.data.data == USER_LEVEL.DISTRICT) {
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                    setValue("province", parseInt(organizationAddress.province));
                    setValue("district", parseInt(organizationAddress.district));
                } else if (response.data.data == USER_LEVEL.LOCAL_LEVEL || response.data.data == USER_LEVEL.LOCAL_LEVEL_VERIFIER) {
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                    setValue("province", parseInt(organizationAddress.province));
                    setValue("district", parseInt(organizationAddress.district));
                    setValue("municipality", parseInt(organizationAddress.municipality));
                }
                setUserLevel(response.data.data);
            });
        });
    }, []);

    const {
        formState: { errors },
        handleSubmit,
        register,
        getValues,
        setValue,
    } = useForm({
        province: "",
    });


    const getDistrictValue = (provinceId) => {
        AddressService.getAllDistrictsByProvinceId(provinceId).then((response) => {
            setDistrictList(response.data.data);
        });
    };

    const getMunicipalitiesList = (districtId) => {
        AddressService.getAllMunicipalitiessByDistrictId(districtId).then((response) => {
            setMunicipalitiesList(response.data.data);
        });
    };

    const columns = [
        {
            name: "nameEng",
            label: t("nameEnglish"),
            options: {}
        },
        {
            name: "nameNep",
            label: t("nameNepali"),
            options: {}
        },
        {
            name: "homeId",
            label: t("actions"),
            options: {
                customBodyRender: (value, tableMeta) => {
                    return (
                        <React.Fragment>
                            <i
                                className="pi pi-user-edit"
                                style={{ paddingRight: "5px" }}
                                onClick={() => confirmUpdate(tableMeta.rowData, value)}
                            ></i>
                            <i
                                className="pi pi-trash"
                                onClick={() => confirmDelete(value)}
                            ></i>
                        </React.Fragment>
                    );
                },
            },
        }
    ]

    const confirmUpdate = (data, homeId) => {
        setHomeId(homeId);
        setNameEng(data[0]);
        setNameNep(data[1]);
        setEditModal(true);
    };

    const acceptUpdate = () => {
        let data = {};
        data.nameEng = nameEng;
        data.nameNep = nameNep;
        trackPromise(
            HomeLookupService.update(data, homeId)
                .then((response) => {
                    if (response.status === 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Update Successful",
                            life: 3000,
                        });
                        window.location.reload(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Update UnSuccessful",
                            life: 3000,
                        });
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.data.error_description);
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: error.response.data.error_description,
                            life: 3000,
                        });
                    } else if (error.request) {
                        console.log(error.request);
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Network Error",
                            life: 3000,
                        });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                    }
                })
        );
    };

    const acceptDelete = (homeId) => {
        HomeLookupService.delete(homeId).then((response) => {
            if (response.status === 200) {
                toast.current.show({
                    severity: "success",
                    summary: "Success Message",
                    detail: "Delete Successful",
                    life: 3000,
                });
                window.location.reload(false);
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "Error Message",
                    detail: "Delete UnSuccessful",
                    life: 3000,
                });
            }
        });
    };

    const confirmDelete = (homeId) => {
        confirmDialog({
            message: "Are you sure you want to Delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => acceptDelete(homeId),
            reject: () => rejectFunc(),
        });
    };

    const rejectFunc = () => {
        toast.current.show({
            severity: "warn",
            summary: "Rejected",
            detail: "You have rejected",
            life: 3000,
        });
    };

    const footer = (
        <div>
            <Button label={t("submit")} icon="pi pi-check" onClick={() => { acceptUpdate() }} />
        </div>
    );

    const onHide = () => {
        setEditModal(false);
    }

    const searchData = (data) => {
        data.homeType = "ShelterHome";
        console.log("data", data);
        trackPromise(
            HomeLookupService.getLookupDetails(data).then((response) => {
                console.log("response.data", response.data.data);
                setHomeList(response.data.data);
            })
        );
    };

    return (
        <>
            <div style={{ margin: "0 2.5rem" }}>
                <Toast ref={toast} />
                <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
                    <div className=" p-card-content">
                        <h4 className="p-pt-0">{t("Shelter Home Lookup")}</h4>
                    </div>
                </Card>

                <Card className="p-mt-0">
                    <form className="p-grid p-fluid p-mt-3">
                        <LookupAddress register={register} error={errors} setValue={setValue} getValues={getValues} />

                        <div className="p-field p-col-12 p-md-12 ">
                            <div className="p-field p-col-10 p-md-10 float-left"></div>
                            <div className="p-field p-col-2 p-md-2 float-right">
                                <Button
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: "#1c80cf",
                                        color: "#FFF",
                                    }}
                                    label={t("search")}
                                    onClick={handleSubmit(searchData)}
                                />
                            </div>
                        </div>
                    </form>
                    <div className=" p-card-content">
                        <ThemeProvider
                            theme={createTheme({
                                overrides: {
                                    MUIDataTableToolbar: { root: { display: "none" } },
                                },
                            })}
                        >
                            <MUIDataTable
                                data={homeList}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>
                    </div>
                    <Dialog header={t("editDetails")}
                        footer={footer}
                        visible={editModal}
                        style={{ width: '50vw' }}
                        onHide={() => onHide()}>
                        <div class="p-field p-col-12 p-md-12" style={{ justifyContent: "center" }}>
                            <div className="p-field p-col-12 p-md-3 float-left main-label"> {t("nameEnglish")}:</div>
                            <div className="p-field p-col-12 p-md-9 float-left ">
                                <InputText
                                    autoFocus
                                    value={nameEng}
                                    onChange={(e) => setNameEng(e.target.value)}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3 float-left main-label">{t("nameNepali")}:</div>
                            <div className="p-field p-col-12 p-md-9 float-left">
                                <InputText
                                    autoFocus
                                    value={nameNep}
                                    onChange={(e) => setNameNep(e.target.value)}
                                />
                            </div>
                        </div>
                    </Dialog>

                    <div style={{ clear: "both" }}></div>
                </Card>
            </div>
        </>
    );
};
export default ShelterHomeLookup;