import React, { useState } from "react";

import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { useTranslation } from "react-i18next";
import { YES_NO } from "../constants/ITMISConstansts";

const DiseaseSeniorCitizenId = (props) => {
  const { t } = useTranslation();
  const [disease, setDisease] = useState();

  const diseaseList = [
    { label: t("heart"), value: 'Heart' },
    { label: t("kidney"), value: 'Kidney' },
    { label: t("cancer"), value: 'Cancer' },
    { label: t("alzheimer"), value: 'Alzheimer' },
    { label: t("parkinson"), value: 'Parkinson' },
    { label: t("headInjury"), value: 'Head Injury' },
    { label: t("spinalInjury"), value: 'Spinal Injury' },
    { label: t("sickleCellAnemia"), value: 'Sickle Cell Anemia' },
    { label: t("others"), value: 'Others' }
  ];

  return (
    <div className="p-grid p-col-12 p-md-12 ">
      <div class="p-col-12 p-md-6">
        <div className="p-field p-col-12 p-md-12 float-left main-label">{t("disease")} </div>
        <div className="p-field p-col-6 p-md-6 float-left">
          <RadioButton
            value={YES_NO.YES}
            name={YES_NO.YES}
            onChange={(e) => {
              props.setValue("disease", YES_NO.YES);
              setDisease(e.value);
            }}
            checked={props.getValues("disease") == YES_NO.YES}
          /> {t("cha")}
        </div>
        <div className="p-field p-col-6 p-md-6 float-left">
          <RadioButton
            value={YES_NO.NO}
            name={YES_NO.NO}
            onChange={(e) => {
              props.setValue("disease", YES_NO.NO);
              setDisease(e.value);
            }}
            checked={props.getValues("disease") == YES_NO.NO}
          /> {t("chaina")}
        </div>
      </div>
      {props.getValues("disease") === "Yes" ? (
        <div class="p-col-12 p-md-6">
          <div className="p-field p-col-12 p-md-12 float-left main-label">
            {t("diseaseType")}
          </div>
          <div className="p-field p-col-12 p-md-12 float-left">
            <Dropdown
              filter
              name="pleaseSpDetails"
              placeholder={t("select")}
              value={props.getValues("pleaseSpDetails")}
              {...props.register("pleaseSpDetails"
                // , {
                //   required: "Details is Required",
                // }
              )}
              options={diseaseList}
              onChange={(e) => {
                props.setValue("pleaseSpDetails", e.value);
                setDisease(e.value);
              }}
            />
            {/* {props.error.diseaseDetail && props.error.diseaseDetail.type === "required" && (
              <small className="p-error">{props.error.diseaseDetail.message}</small>
            )} */}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default DiseaseSeniorCitizenId;
