const AppProps = {
  // API_BASE_URL: "http://localhost:8081",
  // API_BASE_URL: "http://103.140.1.83:8081",
  API_BASE_URL: "https://sims.gov.np/api",

  //WOMEN_AND_MINORITIES
  WOMEN_AND_MINORITIES: "/women-and-minorities/",

  //CHILDREN
  CHILDREN: "/children/",

  //DISABLED
  DISABLED: "/disabled/",

  //SENIOR_CITIZEN
  SENIOR_CITIZEN: "/senior-citizen/",

  //CHILD_HOME
  CHILD_HOME: "/child-home/",

  //SEWA_KENDRA
  SEWA_KENDRA: "/sewa-kendra/",

  //CHILD_CORRECTON_HOME
  CHILD_CORRECTON_HOME: "/child-correction-home/",

  //OLD_AGE_HOME
  OLD_AGE_HOME: "/old-age-home/",

  //COMPLAINT_REGISTRATION
  COMPLAINT_REGISTRATION: "/complaint-registration/",

  //LABOUR_MIGRATION
  LABOUR_MIGRATION: "/labour-migration/",

  //SOCIAL_SERVICE
  CHILD_HOME_CASE: "/social-service/child-home/",
  SHELTER_HOME_CASE: "/social-service/shelter-home/",
  JUVENILE_CHILD_HOME: "/social-service/child-correction-home/",
  OLD_AGE_HOME_CASE: "/social-service/old-age-home/",

  //HTTPSHELTEHOME
  HTTP_SHELTER_HOME: "http-shelter-home/",

  //REPORT
  REPORT_CHILDREN: "/report/children/",
  REPORT_WOMEN: "/report/women/",
  REPORT_DISABLED: "/report/disabled/",
  REPORT_SENIOR_CITIZEN: "/report/senior-citizen/",
  REPORT_CHILD_HOME: "/report/child-home/",
  REPORT_CHILD_CORRECTION_HOME: "/report/child-correction-home/",
  REPORT_OLD_AGE_HOME: "/report/old-age-home/",
  REPORT_SHELTER_HOME: "/report/shelter-home/",
  REPORT_SEWA_KENDRA: "/report/sewa-kendra/",
  REPORT_LABOUR_MIGRATION: "/report/labour-migration/",
  REPORT_COMPLAINT_REGISTRATION: "/report/complaint-registration/",
  REPORT_CONSOLIDATED: "/report/consolidated/",
  REPORT_ID_CARD: "/report/id-card/",

  //JUDICIAL_COMMITTEE
  JUDICIAL_COMMITTEE: "/gbv/judicial-committee/",

  //GBV_DETAIL_SERVICE
  GBV_HOME_DETAIL: "/gbv/home-detail/",
  GBV_PERSONAL_DETAIL: "/gbv/personal-detail/",

  //HTT_DETAIL_SERVICE
  HTT_DETAIL: "/htt/detail/",
  HTT_OLD_DETAIL: "/htt/old-detail/",

  //FIR_TIP_CASE_REGISTRATION
  FIR_TIP_REGISTRATION: "/htt/fir-registration/",
  PROSECUTION: "/htt/prosecution/",

  //PROSECUTION
  // PROSECUTION: "/gbv/judicial-committee/",
  //FILE URL
  FILE_UPLOAD_URL: "/file/upload",
  GET_FILE: "/file/",
  GET_FILE_OPEN: "/file/open/",

  NOTIFICATION: "/report/notification/"
};

export default AppProps;
