import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';

import Gender from "../../utilities/components/Gender";
import Caste from "../../utilities/components/Caste";
import Education from "../../utilities/components/Education";
import NoOfChildren from "../../utilities/components/NoOfChildren";
import MaritalStatus from "../../utilities/components/MaritalStatus";
import Disability from "../../utilities/components/Disability";

import { useTranslation } from "react-i18next";
import GbvDetails from "./GbvDetails";
import { InputText } from "primereact/inputtext";
import i18n from "../../il8n/il8n";
import { LANGUAGE, USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import { Dropdown } from "primereact/dropdown";
import AddressService from "../../security/api/services/AddressService";
import GbvDetails2 from "./GbvDetails2";
import DetailService from "../api/services/DetailService";
import { trackPromise } from "react-promise-tracker";
import FileUpload from "../../GbvHttFile/components/GbvHttFileUpload";
import HttGbvName from "../../utilities/components/HttGbvName";
import HttGbvPermanentAddress from "../../utilities/components/HttGbvPermanentAddress";
import HttGbvTemporaryAddress from "../../utilities/components/HttGbvTemporaryAddress";
import PersonalCode from "../../utilities/components/PersonalCode";
import HomeName from "../../utilities/components/HomeName";
import HttGbvHomeAddress from "../../utilities/components/HttGbvHomeAddress";
import UserService from "../../security/api/services/UserService";

const ShelterHomePersonal = (props) => {
    const homeDetails = props.location.state.data;
    const view = props.location.state ? props.location.state.view : false;

    const [fileName, setFileName] = useState();
    const [storageFileName, setStorageFileName] = useState();

    const { t } = useTranslation();
    const history = useHistory();

    const [update, setUpdate] = useState(false);
    const [showBtn, setShowBtn] = useState(true);
    const [showName, setShowName] = useState(true);
    const [hideBtn, setHideBtn] = useState(false);

    const location = useLocation();
    const {
        control,
        register,
        formState: { errors, touchedFields },
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = useForm();
    const toast = useRef(null);
    const getFormErrorMessage = (name) => {
        return (
            errors[name] && <small className="p-error">{errors[name].message}</small>
        );
    };
    
    useEffect(() => {
        //To check whether the logged in user's role is local level or others
        UserService.getUserLevel().then((response) => {
            if (response.data.data === USER_LEVEL.SOCIAL_SERVICE) {
                setShowBtn(true);
            } else {
                setShowBtn(false);
            }
        });
    }, []);

    useEffect(() => {
        if (props.location.state && props.location.state.personalDetailId) {
            DetailService.getPersonalDetailById(
                props.location.state && props.location.state.personalDetailId
            ).then((response) => {
                if (response.status == 200) {
                    console.log("personal detail response", response.data.data);
                    reset({
                        firstName: response.data.data.firstName,
                        middleName: response.data.data.middleName,
                        lastName: response.data.data.lastName,
                        permanentProvince: parseInt(response.data.data.permanentProvince),
                        permanentDistrict: parseInt(response.data.data.permanentDistrict),
                        permanentMunicipality: parseInt(response.data.data.permanentMunicipality),
                        permanentWardNo: parseInt(response.data.data.permanentWardNo),
                        temporaryProvince: parseInt(response.data.data.temporaryProvince),
                        temporaryDistrict: parseInt(response.data.data.temporaryDistrict),
                        temporaryMunicipality: parseInt(response.data.data.temporaryMunicipality),
                        temporaryWardNo: parseInt(response.data.data.temporaryWardNo),
                        gender: response.data.data.gender,
                        age: response.data.data.age,
                        caste: response.data.data.caste,
                        educationLevel: response.data.data.educationLevel,
                        educationOthers: response.data.data.educationOthers,
                        disability: response.data.data.disability,
                        typesOfDisability: response.data.data.typesOfDisability,
                        maritalStatus: response.data.data.maritalStatus,
                        noOfSon: response.data.data.noOfSon,
                        gbvDetail: response.data.data.gbvDetail,
                        personalCode: response.data.data.personalCode
                    });
                    setShowName(response.data.data.showName);
                    setUpdate(true); 
                    if (response.data.data.status === "Request" || response.data.data.status === "Verified") {
                        setHideBtn(true);
                    } else {
                        setHideBtn(false);
                    }
                } else {
                    setUpdate(false);
                }
            });
        }
    }, [props.location.state && props.location.state.personalDetailId]);

    const handleFileName = (response) => {
        console.log("fileName", response.fileName);
        console.log("storage fileName", response.storageFileName);
        setFileName(response.fileName);
        setStorageFileName(response.storageFileName);
    };

    const requestData = (data) => {
        data.status = "Request";
        submit(data);
    }

    //To save data
    const saveData = (data) => {
        data.status = "Save";
        submit(data);
    };

    const submit = (data) => {
        console.log("data", data);
        let validation = false;
        data.showName = showName;
        data.fileName = fileName;
        data.storageFileName = storageFileName;
        {
            if (showName) {
                if (data.firstName === "" || data.lastName === "" || data.permanentProvince === undefined
                    || data.permanentDistrict === undefined || data.permanentDistrict === NaN || data.temporaryProvince === undefined ||
                    data.temporaryDistrict === undefined || data.temporaryDistrict === NaN) {
                    toast.current.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please fill Name or Permanent Address or Temporary Address fields",
                        life: 3000,
                    });
                    validation = false;
                } else {
                    validation = true;
                }
            } else {
                if (data.permanentProvince === undefined
                    || data.permanentDistrict === undefined || data.permanentDistrict === NaN) {
                    toast.current.show({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Please fill Unique Case Code or Address fields",
                        life: 3000,
                    });
                    validation = false;
                } else {
                    validation = true;
                }
            }
        }
        let formData = new FormData();
        // if (data.gbvFile != undefined) {
        //     formData.append("gbvFile", data.gbvFile);
        //     delete data.gbvFile;
        // }
        // formData.append("gbvFile", null);

        data.homeType = "ShelterHome";

        formData.append("homeDetailId", homeDetails.caseId);
        formData.append("personalDetail", JSON.stringify(data));
        if (validation) {
            update ?
                trackPromise(
                    DetailService.editPersonalDetail(formData, props.location.state.personalDetailId).then((response) => {
                        console.log("response", response);
                        if (response.status == 200) {
                            toast.current.show({
                                severity: "success",
                                summary: "Success Message",
                                detail: "Save Successful",
                                life: 3000,
                            });
                            window.location.reload(false);
                        } else {
                            toast.current.show({
                                severity: "error",
                                summary: "Error Message",
                                detail: "Save UnSuccessful",
                                life: 3000,
                            });
                        }
                    })
                        .catch((error) => {
                            // We want to handle globally
                            error.handleGlobally && error.handleGlobally();
                            toast.current.show({
                                severity: "error",
                                summary: "Error Message",
                                detail: error.response.data.data.msg,
                                life: 3000,
                            });
                        })
                )
                :
                trackPromise(
                    DetailService.submitPersonalDetail(formData).then((response) => {
                        console.log("response", response);
                        if (response.status == 200) {
                            toast.current.show({
                                severity: "success",
                                summary: "Success Message",
                                detail: "Save Successful",
                                life: 3000,
                            });
                            window.location.reload(false);
                        } else {
                            toast.current.show({
                                severity: "error",
                                summary: "Error Message",
                                detail: "Save UnSuccessful",
                                life: 3000,
                            });
                        }
                    })
                        .catch((error) => {
                            // We want to handle globally
                            error.handleGlobally && error.handleGlobally();
                            toast.current.show({
                                severity: "error",
                                summary: "Error Message",
                                detail: error.response.data.data.msg,
                                life: 3000,
                            });
                        })
                );
        }
    };

    return (
        <div style={{ marginLeft: "2.5rem" }}>
            <Toast ref={toast} />
            <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
                <div className=" p-card-content">
                    <h4 className="p-pt-0"> {t("shelterHome")} </h4>
                </div>
            </Card>

            <div className="p-grid p-col-12 p-md-12">
                <div className="p-field p-col-12 p-md-3">
                    <Button className="box-shadow"
                        style={{
                            justifyContent: "center",
                            background: "rgb(75 125 181)",
                            color: "#FFF",
                            width: '100%',
                            minHeight: '30px',
                            border: '4px solid rgb(208 0 0)',
                        }}
                        label={t("Personal Detail")}
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Button className="box-shadow"
                        style={{
                            justifyContent: "center",
                            background: "rgb(75 125 181)",
                            color: "#FFF",
                            width: '100%',
                            minHeight: '30px',
                            border: '4px solid rgb(208 0 0)',
                        }}
                        label={t("homeDetails")}
                        onClick={() =>
                            history.push("/sims/gbv-shelter-home")
                        }
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Button className="box-shadow"
                        style={{
                            justifyContent: "center",
                            background: "rgb(75 125 181)",
                            color: "#FFF",
                            width: '100%',
                            minHeight: '30px',
                            border: '4px solid rgb(208 0 0)',
                        }}
                        label={t("list")}
                        onClick={() =>
                            history.push("/sims/gbv-shelter-home-list")
                        }
                    />
                </div>
            </div>

            <Card className="p-mt-0">
                <form className="p-grid p-fluid"
                    autoComplete="off">
                    <div className="p-field p-col-12 p-md-12 ">
                        <div className="p-field p-col-12 p-md-12 ">
                            <h5 className="HeadingTitle">
                                {t("homeDetails")}
                            </h5>
                        </div>
                    </div>

                    <HomeName register={register} error={errors} setValue={setValue} getValues={getValues} />
                    <HttGbvHomeAddress register={register} error={errors} setValue={setValue} getValues={getValues} />
            
                    {/* <div className="p-grid p-col-12 p-md-12 ">
                        <div class="p-col-12 p-md-6">
                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                {t("name")}
                            </div>
                            <div className="p-field p-col-12 p-md-12 float-left">
                                <InputText
                                    name="homeName"
                                    value={homeDetails.homeName}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-col-12 p-md-12 ">
                        <div class="p-col-12 p-md-12">
                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                {t("address")}
                            </div>
                            <div className="p-field p-col-12 p-md-3 float-left">
                                <Dropdown
                                    filter
                                    name="homeProvince"
                                    value={parseInt(homeDetails.homeProvince)}
                                    options={provincesList}
                                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "provinceDescEng" : "provinceDescNep"}
                                    optionValue="id"
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3 float-left">
                                <Dropdown
                                    filter
                                    name="homeDistrict"
                                    value={parseInt(homeDetails.homeDistrict)}
                                    options={districtsList}
                                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                    optionValue="id"
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3 float-left">
                                <Dropdown
                                    filter
                                    name="homeMunicipality"
                                    value={parseInt(homeDetails.homeMunicipality)}
                                    options={municipalitiesList}
                                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "municipalityDescEng" : "municipalityDescNep"}
                                    optionValue="id"
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3 float-left">
                                <Dropdown
                                    filter
                                    name="homeWardNo"
                                    value={parseInt(homeDetails.homeWardNo)}
                                    options={wardList}
                                    optionLabel="wardId"
                                    optionValue="wardId"
                                    disabled
                                />
                            </div>
                        </div>
                    </div> */}
                    <div className="p-field p-col-12 p-md-12 ">
                        <div className="p-field p-col-12 p-md-10 float-left">
                            <h5 className="HeadingTitle">
                                {t("personalDetails")}
                            </h5>
                        </div>
                        <div className="p-field p-col-12 p-md-1 float-right">
                            {showName ?
                                <Button icon="pi pi-lock" rounded text severity="secondary"
                                    aria-label="Lock" size="small"
                                    tooltip="Do no want to disclose name and address"
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowName(false)
                                    }} />
                                : <Button icon="pi pi-unlock" rounded text severity="secondary"
                                    aria-label="Unlock" size="small"
                                    tooltip="Want to disclose name and address"
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowName(true)
                                    }} />
                            }
                        </div>
                    </div>
                    {showName
                        ? <HttGbvName register={register} error={errors} setValue={setValue} getValues={getValues} show={showName} />
                        : <> </>
                    }
                    <PersonalCode register={register} error={errors} setValue={setValue} getValues={getValues} />
                    <HttGbvPermanentAddress provinceId={getValues("permanentProvince")} register={register} error={errors} setValue={setValue} getValues={getValues} />
                    {showName
                        ? <HttGbvTemporaryAddress provinceId={getValues("temporaryProvince")} register={register} error={errors} setValue={setValue} getValues={getValues} />
                        : <> </>
                    }
                    <Controller
                        name="gender"
                        control={control}
                        rules={{ required: "Gender is required." }}
                        render={({ field, fieldState }) => (
                            <Gender
                                id={field.name}
                                {...field}
                                onValueChange={(value) => {
                                    setValue("gender", value);
                                }}
                                value={getValues("gender")}
                                getValues={getValues}
                            />
                        )}
                    />
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <div class="p-field p-col-12 p-md-12 float-left">
                            {getFormErrorMessage("gender")}
                        </div>
                    </div>

                    <div className="p-grid p-col-12 p-md-12 ">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field p-col-12 p-md-12 float-left main-label">{t("age")}
                                <span style={{ color: "#d00000" }}> * </span>
                            </div>
                            <div className="p-field p-col-12 p-md-12 float-left">
                                <Controller
                                    name="age"
                                    control={control}
                                    rules={{ required: "Age is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                "p-invalid": fieldState.invalid,
                                            })}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                            }}
                                            placeholder={t("age")}
                                        />
                                    )}
                                />
                                {getFormErrorMessage("age")}
                            </div>
                        </div>
                    </div>

                    <Controller
                        name="caste"
                        control={control}
                        rules={{ required: "Caste is required." }}
                        render={({ field, fieldState }) => (
                            <Caste
                                id={field.name}
                                {...field}
                                onValueChange={(value) => {
                                    setValue("caste", value);
                                }}
                                value={getValues("caste")}
                                getValues={getValues}
                            />
                        )}
                    />
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <div class="p-field p-col-12 p-md-12 float-left">
                            {getFormErrorMessage("caste")}
                        </div>
                    </div>

                    <Disability register={register} error={errors} setValue={setValue} getValues={getValues} />
                    <MaritalStatus register={register} error={errors} setValue={setValue} getValues={getValues} />
                    <NoOfChildren register={register} error={errors} setValue={setValue} getValues={getValues} />

                    <Education register={register} error={errors} setValue={setValue} getValues={getValues} />

                    <GbvDetails register={register} error={errors} setValue={setValue} getValues={getValues} />

                    {/* <div className="p-field p-col-12 p-md-12 ">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">{t("gbvFileUpload")} </div>
                        <div className="p-field p-col-6 p-md-6 float-left p-mr-3">
                            <FileUpload
                                handleUploadState={handleFileName}
                            />
                            <p style={{ margin: "2px", color: "red" }}>{t("fileNote")}</p>
                        </div>
                    </div> */}

                    <GbvDetails2 register={register} touchedFields={touchedFields} error={errors} setValue={setValue} getValues={getValues} />

                    {!hideBtn ? <>
                        {!view && showBtn ? (
                            <div className="p-grid p-col-12 p-md-12">
                                <div className="p-col-12 p-md-8"></div>
                                <div className="p-col-12 p-md-2">
                                    <Button label={t("requestForVerification")}
                                        className="p-button-sm pull-right submitBtn"
                                        onClick={handleSubmit(requestData)} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Button label={t("save")}
                                        className="p-button-sm pull-right submitBtn"
                                        onClick={handleSubmit(saveData)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </> : <></>
                    }
                </form>
            </Card>
        </div>
    );
};

export default ShelterHomePersonal;
