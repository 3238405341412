import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Chart } from "primereact/chart";
import "primeflex/primeflex.css";
import Sidebar from "./Sidebar";
import jsPDF from "jspdf";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router";
import "../assets/css/DataScrollerDemo.css";
import { Card } from "primereact/card";
import { trackPromise } from "react-promise-tracker";
import ReportService from "../../reports/api/services/ReportService";
import AddressService from "../../security/api/services/AddressService";
import { useTranslation } from "react-i18next";
import i18n from "../../il8n/il8n";
import { LANGUAGE } from "../../utilities/constants/ITMISConstansts";
import FiscalYear from "../../utilities/components/FiscalYear";
import html2canvas from "html2canvas";

const Dashboard = () => {

    const { t } = useTranslation();

    const [childrenShowData, setChildrenShowData] = useState([]);
    const [womenShowData, setWomenShowData] = useState([]);
    const [disabledShowData, setDisabledShowData] = useState([]);
    const [seniorCitizenShowData, setSeniorCitizenShowData] = useState([]);
    const [shelterHomeShowData, setShelterHomeShowData] = useState([]);
    const [labourMigrationShowData, setLabourMigrationShowData] = useState([]);
    const [complaintRegistrationShowData, setComplaintRegistrationShowData] = useState([]);
    const [idcardShowData, setIdcardShowData] = useState([]);
    const [fiscalYear, setFiscalYear] = useState('2078-79');

    const [provinceList, setProvinceList] = useState([]);

    const location = useLocation();
    const history = useHistory();
    const methods = useForm();
    const fiscalYearList = [
        { label: t("2078/79"), value: '2078-79' },
        { label: t("2079/80"), value: '2079-80' },
        { label: t("2080/81"), value: '2080-81' },
        { label: t("2081/82"), value: '2081-82' }
    ];
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = methods;
    const toast = useRef(null);
    const getFormErrorMessage = (name) => {
        return (
            errors[name] && <small className="p-error">{errors[name].message}</small>
        );
    };

    // useEffect(() => {
    //     {
    //         i18n.language == LANGUAGE.ENGLISH
    //         ? window.location.reload()
    //         : window.location.reload();
    //     }
    // }, [t]);

    useEffect(() => {
        getDataByFiscalYear();
    }, []);

    const getDataByFiscalYear = () => {
        trackPromise(
            ReportService.getChildrenCumulativeData(fiscalYear).then((response) => {
                if (response.data.length > 0) {
                    console.log("response.data", response.data);
                    console.log("boys data", response.data[0].boys_count);
                    let childData = {
                        labels: [t("boys"), t("girls"), t("others")],
                        datasets: [
                            {
                                label: t("population"),
                                backgroundColor: '#6e98e5',
                                hoverBackgroundColor: "#5980c8",
                                data: [
                                    response.data[0].boys_population,
                                    response.data[0].girls_population,
                                    response.data[0].others_population
                                ]
                            },
                            {
                                label: t("birthCertificateissued"),
                                backgroundColor: '#0f6ad1',
                                hoverBackgroundColor: "#1958a0",
                                data: [
                                    response.data[0].boys_birth_cert_isssued,
                                    response.data[0].girls_birth_cert_isssued,
                                    response.data[0].others_birth_cert_isssued
                                ]
                            },
                        ],
                    }
                    setChildrenShowData(childData);
                }
            }),

            ReportService.getWomenCumulativeData(fiscalYear).then((response) => {
                if (response.data.length > 0) {
                    console.log("response.data", response.data);
                    let womData = {
                        labels: [t("unmarried"), t("married"), t("widow"), t("divorcee"), t("separated")],
                        datasets: [
                            {
                                type: 'bar',
                                label: t("total"),
                                data: [
                                    response.data[0].singled_women,
                                    response.data[0].married_women,
                                    response.data[0].widowed_women,
                                    response.data[0].divorcee_women,
                                    response.data[0].separated_women
                                ],
                                backgroundColor: [
                                    "#90cd93"
                                ],
                                hoverBackgroundColor: [
                                    "#90cd93"
                                ]
                            },
                        ],
                    }
                    setWomenShowData(womData);
                }
            }),

            ReportService.getDisabledCumulativeData(fiscalYear).then((response) => {
                if (response.data.length > 0) {
                    console.log("response.data", response.data);
                    let disData = {
                        labels: [t("male"), t("female"), t("others"), t("children"), t("adult"), t("Senior Citizen")],
                        datasets: [
                            {
                                type: 'bar',
                                label: t("total"),
                                backgroundColor: '#ef2222',
                                hoverBackgroundColor: "#ef2222",
                                data: [
                                    response.data[0].disabled_male_total,
                                    response.data[0].disabled_female_total,
                                    response.data[0].disabled_others_total,
                                    response.data[0].disabled_children_total,
                                    response.data[0].disabled_adult_total,
                                    response.data[0].disabled_senior_citizen_total
                                ],
                            }
                        ]
                    }
                    setDisabledShowData(disData);
                }
            }),

            ReportService.getSeniorCitizenCumulativeData(fiscalYear).then((response) => {
                if (response.data.length > 0) {
                    console.log("response.data", response.data);
                    let scData = {
                        labels: [t("male"), t("female"), t("others")],
                        datasets: [
                            {
                                label: t("population"),
                                backgroundColor: '#FF6384',
                                hoverBackgroundColor: "#FF6384",
                                data: [
                                    response.data[0].male_count,
                                    response.data[0].female_count,
                                    response.data[0].others_count
                                ],
                            },
                            {
                                label: t("citizenGettingSSA"),
                                backgroundColor: '#36A2EB',
                                hoverBackgroundColor: "#36A2EB",
                                data: [
                                    response.data[0].male_senior_getting_spa,
                                    response.data[0].female_senior_getting_spa,
                                    response.data[0].others_senior_getting_spa
                                ]
                            },
                        ],
                    }
                    setSeniorCitizenShowData(scData);
                }
            }),

            // ReportService.getShelterHomeCumulativeData(fiscalYear).then((response) => {
            //     if (response.data.length > 0) {
            //         console.log("response.data", response.data);
            //         let shData = {
            //             labels: [
            //                 // t("male"), 
            //                 t("female"),
            //                 t("others")
            //             ],
            //             datasets: [
            //                 {
            //                     type: 'bar',
            //                     label: t("rescued"),
            //                     backgroundColor: '#FF6384',
            //                     hoverBackgroundColor: "#FF6384",
            //                     data: [
            //                         // response.data[0].male_tip_survivors_rescued,
            //                         response.data[0].female_tip_survivors_rescued,
            //                         response.data[0].others_tip_survivors_rescued
            //                     ],
            //                 },
            //                 {
            //                     type: 'bar',
            //                     label: t("suspected"),
            //                     backgroundColor: '#36A2EB',
            //                     hoverBackgroundColor: "#36A2EB",
            //                     data: [
            //                         //   response.data[0].male_tip_survivors_suspected,
            //                         response.data[0].female_tip_survivors_suspected,
            //                         response.data[0].others_tip_survivors_suspected
            //                     ]
            //                 },
            //                 {
            //                     type: 'bar',
            //                     label: t("intercepted"),
            //                     backgroundColor: '#FFCE56',
            //                     hoverBackgroundColor: "#FFCE56",
            //                     data: [
            //                         // response.data[0].male_tip_survivors_intercepted,
            //                         response.data[0].female_tip_survivors_intercepted,
            //                         response.data[0].others_tip_survivors_intercepted
            //                     ]
            //                 },
            //             ],
            //         }
            //         setShelterHomeShowData(shData);
            //     }
            // }),

            // ReportService.getLabourMigrationCumulativeData(fiscalYear).then((response) => {
            //     if (response.data.length > 0) {
            //         console.log("response.data", response.data);
            //         let lmData = {
            //             labels: [t("male"), t("female"), t("others")],
            //             datasets: [
            //                 {
            //                     data: [
            //                         response.data[0].male_migrant_labour,
            //                         response.data[0].female_migrant_labour,
            //                         response.data[0].other_migrant_labour
            //                     ],
            //                     backgroundColor: [
            //                         "#FF6384",
            //                         "#36A2EB",
            //                         "#FFCE56"
            //                     ],
            //                     hoverBackgroundColor: [
            //                         "#FF6384",
            //                         "#36A2EB",
            //                         "#FFCE56"
            //                     ]
            //                 },
            //             ],
            //         }
            //         setLabourMigrationShowData(lmData);
            //     }
            // }),

            // ReportService.getComplaintRegistrationCumulativeData(fiscalYear).then((response) => {
            //     if (response.data.length > 0) {
            //         console.log("response.data", response.data);
            //         let crData = {
            //             labels: [t("tip"), t("gbv")],
            //             datasets: [
            //                 {
            //                     label: t("caseProsecutedByCourt"),
            //                     backgroundColor: '#0f6ad1',
            //                     hoverBackgroundColor: "#1958a0",
            //                     data: [
            //                         response.data[0].tip_case_prosecuted_by_court,
            //                         response.data[0].gbv_case_prosecuted_by_court
            //                     ]
            //                 },
            //                 {
            //                     label: t("caseDecidedByCourt"),
            //                     backgroundColor: '#6e98e5',
            //                     hoverBackgroundColor: "#5980c8",
            //                     data: [
            //                         response.data[0].tip_case_decided_by_court,
            //                         response.data[0].gbv_case_decided_by_court
            //                     ]
            //                 },
            //             ],
            //         }
            //         setComplaintRegistrationShowData(crData);
            //     }
            // }),

            ReportService.getIdCardProvinceData().then((response) => {
                if (response.data.length > 0) {
                    console.log("id card ", response.data);
                    let provList = [], idcardData = [];
                    AddressService.getAllProvinces().then((res) => {
                        provList = res.data.data;

                        res.data.data.forEach(provList => {
                            var found = false;
                            response.data.forEach(data => {
                                if (provList.id == data.province_id) {
                                    found = true;
                                    idcardData.push({
                                        province_id: data.province_id,
                                        province_desc_eng: data.province_desc_eng,
                                        province_desc_nep: data.province_desc_nep,
                                        disabled: data.disabled,
                                        senior_citizen: data.senior_citizen
                                    });
                                    console.log("card 1", idcardData);
                                    return;
                                }
                            })
                            if (found === false) {
                                idcardData.push({
                                    province_id: provList.id,
                                    province_desc_eng: provList.provinceDescEng,
                                    province_desc_nep: provList.provinceDescNep,
                                    disabled: 0,
                                    senior_citizen: 0
                                });
                                console.log("card 2", idcardData);
                            }
                        });

                        let idData = {
                            labels: [
                                i18n.language == LANGUAGE.ENGLISH ? idcardData[0].province_desc_eng : idcardData[0].province_desc_nep,
                                i18n.language == LANGUAGE.ENGLISH ? idcardData[1].province_desc_eng : idcardData[1].province_desc_nep,
                                i18n.language == LANGUAGE.ENGLISH ? idcardData[2].province_desc_eng : idcardData[2].province_desc_nep,
                                i18n.language == LANGUAGE.ENGLISH ? idcardData[3].province_desc_eng : idcardData[3].province_desc_nep,
                                i18n.language == LANGUAGE.ENGLISH ? idcardData[4].province_desc_eng : idcardData[4].province_desc_nep,
                                i18n.language == LANGUAGE.ENGLISH ? idcardData[5].province_desc_eng : idcardData[5].province_desc_nep,
                                i18n.language == LANGUAGE.ENGLISH ? idcardData[6].province_desc_eng : idcardData[6].province_desc_nep
                            ],
                            datasets: [
                                {
                                    type: 'bar',
                                    label: t("Disabled"),
                                    backgroundColor: '#FF6384',
                                    hoverBackgroundColor: "#FF6384",
                                    data: [
                                        idcardData[0].disabled,
                                        idcardData[1].disabled,
                                        idcardData[2].disabled,
                                        idcardData[3].disabled,
                                        idcardData[4].disabled,
                                        idcardData[5].disabled,
                                        idcardData[6].disabled
                                    ],
                                },
                                {
                                    type: 'bar',
                                    label: t("Senior Citizen"),
                                    backgroundColor: '#36A2EB',
                                    hoverBackgroundColor: "#36A2EB",
                                    data: [
                                        idcardData[0].senior_citizen,
                                        idcardData[1].senior_citizen,
                                        idcardData[2].senior_citizen,
                                        idcardData[3].senior_citizen,
                                        idcardData[4].senior_citizen,
                                        idcardData[5].senior_citizen,
                                        idcardData[6].senior_citizen
                                    ]
                                }
                            ],
                        }
                        setIdcardShowData(idData);

                    });
                }
            })
        );
    }


    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            animation: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let volumeOptions = {
            maintainAspectRatio: false,
            animation: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: "index",
                    intersect: false,
                },
                legend: {
                    labels: {
                        color: "#495057",
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                    labels: {},
                },
            },
            tooltips: {
                displayColors: false,
                titleFontSize: 16,
                bodyFontSize: 14,
                xPadding: 10,
                yPadding: 10,
                callbacks: {
                    label: (tooltipItem, data) => {
                        return `$ ${tooltipItem.value}`;
                    },
                },
            },
        };

        let lightOptions = {
            animation: false,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            }
        };

        return {
            basicOptions,
            volumeOptions,
            lightOptions
        };
    }

    const { basicOptions, volumeOptions, lightOptions } = getLightTheme();

    const handleFiscalYear = (fiscalYearVal) => {
        console.log("fiscal year  ", fiscalYearVal);
        setFiscalYear(fiscalYearVal);
    };

    return (
        <Container>
            <div className="p-grid">
                {/* {
                    <div className="main-card p-col-2">
                        <Sidebar />
                    </div>
                } */}
                <div className="p-grid p-col-10 p40 content card">
                    <div className="p-col-6 titleHeading">
                        <h3 style={{ paddingTop: '5px' }}>
                            {" "}
                            <i className="pi pi-table"></i> {t("Dashboard")}
                        </h3>
                    </div>

                    <div className="p-col-6 " style={{ paddingTop: '10px' }} >
                        <button class="p-button p-component p-button-mm pull-right  p-c" type="button" onClick={() => print(document.getElementById("dashboardchart"))}>
                            {t("print")}
                        </button>
                    </div>

                    <div className="p-col-12" style={{ display: "flex", flexDirection: "row" }}>
                        <div className="p-col-12 p-md-12">
                            <div className="p-field p-col-12 p-md-2 float-left main-label"> {t("fiscalYear")}</div>
                            <div className="p-field p-col-12 p-md-4 float-left">
                                <Dropdown
                                    filter
                                    value={fiscalYear}
                                    placeholder={t("select")}
                                    onChange={(e) => {
                                        setFiscalYear(e.value);
                                    }}
                                    style={{ width: "100%" }}
                                    options={fiscalYearList}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2 float-right">
                                <Button
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: "#1c80cf",
                                        color: "#FFF",
                                    }}
                                    label={t("search")}
                                    onClick={getDataByFiscalYear}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="dashboardchart" className="p-col-12" style={{
                        float: 'left', display: "flex",
                        justifyContent: "space-around", flexWrap: "wrap", flexDirection: "row"
                    }}>
                        <div className="p-col-12 p-md-6" style={{ float: 'left' }} onClick={() =>
                            history.push({
                                pathname: "/sims/women-province-wise",
                                state: {
                                    fiscalYear: fiscalYear
                                }
                            })
                        }>
                            <Card className="margin-0">
                                <h5>{t("Women and Minorities")}</h5>
                                <Chart type="bar" data={womenShowData} options={basicOptions} />
                            </Card>
                        </div>

                        <div className="p-col-12 p-md-6" style={{ float: 'left' }} onClick={() =>
                            history.push({
                                pathname: "/sims/children-province-wise",
                                state: {
                                    fiscalYear: fiscalYear
                                }
                            })}>
                            <Card className="margin-0" >
                                <h5>{t("Children")}</h5>
                                <Chart
                                    type="bar"
                                    data={childrenShowData}
                                    options={basicOptions}
                                    background="#000"
                                />
                            </Card>
                        </div>


                        <div className="p-col-12 p-md-6" style={{ float: 'left' }} onClick={() =>
                            history.push({
                                pathname: "/sims/disabled-province-wise",
                                state: {
                                    fiscalYear: fiscalYear
                                }
                            })
                        }>
                            <Card className="margin-0">
                                <h5>{t("Disabled")}</h5>
                                <Chart
                                    type="bar"
                                    data={disabledShowData}
                                    options={basicOptions}
                                />
                            </Card>
                        </div>

                        <div className="p-col-12 p-md-6" style={{ float: 'left' }} onClick={() =>
                            history.push({
                                pathname: "/sims/senior-citizen-province-wise",
                                state: {
                                    fiscalYear: fiscalYear
                                }
                            })
                        }>
                            <Card className="margin-0">
                                <h5>{t("Senior Citizen")}</h5>
                                <Chart
                                    type="bar"
                                    data={seniorCitizenShowData}
                                    options={basicOptions}
                                    background="#000"
                                />
                            </Card>
                        </div>

                        {/* <div className="p-col-12 p-md-4" style={{ float: 'left' }} onClick={() =>
                            history.push({
                                pathname: "/sims/shelter-home-province-wise",
                                state: {
                                    fiscalYear: fiscalYear
                                }
                            })
                        }>
                            <Card className="margin-0">
                                <h5>{t("shelterHomeTIP")}</h5>
                                <Chart
                                    type="bar"
                                    data={shelterHomeShowData}
                                    options={volumeOptions}
                                />
                            </Card>
                        </div> */}

                        {/* <div className="p-col-12 p-md-4" style={{ float: 'left' }} onClick={() =>
                            history.push({
                                pathname: "/sims/labour-migration-province-wise",
                                state: {
                                    fiscalYear: fiscalYear
                                }
                            })
                        }>
                            <Card className="margin-0">
                                <h5>{t("migrantLabour")}</h5>
                                <div className="card flex justify-content-center">
                                    <Chart type="pie" data={labourMigrationShowData} options={lightOptions} />
                                </div>
                            </Card>
                        </div>

                        <div className="p-col-12 p-md-4" style={{ float: 'left' }} onClick={() =>
                            history.push({
                                pathname: "/sims/complaint-registration-province-wise",
                                state: {
                                    fiscalYear: fiscalYear
                                }
                            })
                        }>
                            <Card className="margin-0">
                                <h5>{t("Complaint Registration")}</h5>
                                <Chart
                                    type="bar"
                                    data={complaintRegistrationShowData}
                                    options={basicOptions}
                                />
                            </Card>
                        </div> */}

                        <div className="p-col-12 p-md-12" style={{ float: 'left' }} onClick={() =>
                            history.push({
                                pathname: "/sims/id-card-province-wise"
                            })
                        }>
                            <Card className="margin-0">
                                <h5>{t("ID Card")}</h5>
                                <Chart
                                    type="bar"
                                    data={idcardShowData}
                                    options={volumeOptions}
                                />
                            </Card>
                        </div>

                    </div>

                    <div className="p-col-6" style={{ float: 'left' }}>

                    </div>

                </div>
            </div>
        </Container >
    );
}

export default Dashboard;

// function print(dashboardchart) {
//     console.log("dashboardchart", dashboardchart);
//     var doc = new jsPDF("l", "mm", [150, 100]);

//     doc.html(document.createElement("div"),
//         {
//             callback: function (doc) {
//                 const html2canvas = require('html2canvas');
//                 html2canvas(dashboardchart, { scale: "5" }).then(canvas => {
//                     doc.addImage(canvas, "JPEG", 0, 0, 150, 100);
//                     doc.output("dataurlnewwindow");
//                 });
//             }
//         })
// }

function print(dashboardchart) {
    console.log("dashboardchart", dashboardchart);
    var doc = new jsPDF("l", "mm", [150, 100]);

    // Use html2canvas to render the dashboardchart element as a canvas
    html2canvas(dashboardchart, { scale: 5 }).then(canvas => {
        var imgData = canvas.toDataURL("image/jpeg");
        doc.addImage(imgData, "JPEG", 0, 0, 150, 100);
        doc.output("dataurlnewwindow"); // Opens the PDF in a new browser window
    }).catch(function (error) {
        console.error("Error rendering canvas:", error);
    });
}
