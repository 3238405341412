import React, { useEffect, useRef, useState } from "react";

import { Card } from "primereact/card";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FiscalQuarterReport from "../../utilities/components/FiscalQuarterReport";

import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
// import OrganizationService from "../../security/api/services/OrganizationService";
import { Toast } from "primereact/toast";
import { trackPromise } from "react-promise-tracker";
import Organization from "../../utilities/components/Organization";

import UserService from "../../security/api/services/UserService";
import { USER_LEVEL } from "../../utilities/constants/ITMISConstansts";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import JudicialCommitteeService from "../api/services/JudicialCommitteeService";
import { Dropdown } from 'primereact/dropdown';


function JudicialCommittee() {
  const { t } = useTranslation();
  const [fiscalYear, setFiscalYear] = useState("");
  const [quarter, setQuarter] = useState(0);
  const [judicialCommitteeId, setJudicialCommitteeId] = useState();

  const [update, setUpdate] = useState("No");
  const [showBtn, setShowBtn] = useState("Yes");
  const [hideBtn, setHideBtn] = useState("No");

  const [gbvTypeVal, setGbvTypeVal] = useState("No");
  const [enableForm, setEnableForm] = useState(true);
  const [organization, setOrganization] = useState("");
  const [organizationList, setOrganizationList] = useState([]);

  const handleFiscalYear = (fiscalYearVal) => {
    setFiscalYear(fiscalYearVal);
  };
  const handleQuarter = (quarterVal) => {
    setQuarter(quarterVal);
  };
  const handleOrganization = (organizationId) => {
    // console.log("quarter  ", quarter);
    // console.log("fiscalYear in quarter", fiscalYear);
    setOrganization(organizationId);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues
  } = useForm();
  const toast = useRef(null);
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };
  const gbvTypeList = [
    { label: t("physicalViolence"), value: "Physical violence" },
    { label: t("verbalAbuseViolence"), value: "Verbal abuse or violence" },
    { label: t("psychologicalViolence"), value: "Psychological violence" },
    { label: t("socioEconomicViolence"), value: "Socio economic violence" },
    { label: t("domesticViolence"), value: "Domestic violence" },
    { label: t("sexualViolenceAndHarassment"), value: "Sexual violence and harassment" },
    { label: t("forcedMarriage"), value: "Forced marriage" },
    { label: t("childMarriage"), value: "Child marriage" },
    { label: t("rape"), value: "Rape" },
    { label: t("othersPleaseSpecify"), value: "Others" },
  ];

  const referredToList = [
    { label: t("ocmc"), value: "OCMC" },
    { label: t("nepalPolice"), value: "Nepal Police" },
    { label: t("nwc"), value: "NWC" },
    { label: t("districtCourt"), value: "District Court" },
    { label: t("ncrc"), value: "NCRC" },
    { label: t("csos"), value: "CSOs" },
    { label: t("youthChildClub"), value: "Youth or Child Club" },
    { label: t("media"), value: "Media" },
    { label: t("othersPleaseSpecify"), value: "Others" },
  ];

  useEffect(() => {
    UserService.getUserLevel().then((response) => {
      if (response.data.data === USER_LEVEL.LOCAL_LEVEL) {
        setHideBtn("No");
      } else {
        setHideBtn("Yes");
      }
    });
  }, []);

  const [displayValue, setDisplayValue] = useState("No");
  const searchData = () => {
    let searchData = {
      fiscalYear: fiscalYear,
      quarter: quarter,
      userOrganization: organization
    };
    if (fiscalYear !== "") {
      setDisplayValue("Yes");
      trackPromise(
        JudicialCommitteeService.getData(searchData).then((response) => {
          console.log("response>>>>.", response.data);
          if (response.data.length > 0) {
            setJudicialCommitteeId(response.data[0].judicialCommitteeId);
            setUpdate(true);
            reset({
              // gbvRegistered: response.data[0].gbvRegistered,             

              gbvType: response.data[0].gbvType,

              maleVictims: response.data[0].maleVictims,

              femaleVictims: response.data[0].femaleVictims,

              othersVictims: response.data[0].othersVictims,

              gbvCasesMediated: response.data[0].gbvCasesMediated,

              referredTo: response.data[0].referredTo,

              referredToOthers: response.data[0].referredToOthers,
            });
            setSelectedGbvTypeList(response.data[0].gbvTypeList);
          } else {
            reset({

            });
            setSelectedGbvTypeList([]);
            setUpdate(false);
          }
        })
      )
    } else {
      setDisplayValue("No");
      toast.current.show({
        severity: "warn",
        summary: t("selectFiscalYear"),
        life: 3000,
      });
    }
  };

  const handleGbvType = (value) => {
    (value.includes("Others") ?
      setGbvTypeVal("Yes") :
      setGbvTypeVal("No")
    )
  }

  const [selectedGbvTypeList, setSelectedGbvTypeList] = useState([]);
  const selectedGbvType = (e, name) => {
    const newList = e.map((data) => {
      const existingGbvType = selectedGbvTypeList.find((d) => d.gbvType === data);
      if (existingGbvType) {
        return existingGbvType;
      } else {
        return {
          gbvType: data,
          totCases: "",
        };
      }
    });
    setSelectedGbvTypeList(newList);
  };
  const gbvTypeEditor = (colName, props) => {
    return (
      <InputText
        type="text"
        style={{ width: "80%" }}
        value={props.rowData[colName]}
        onChange={(e) => addFinalGbvTypeData(e.target.value, props, colName, props.rowData.gbvType)}
      />
    );
  };

  const addFinalGbvTypeData = (optvalue, props, colName, gbvType) => {
    const newList = selectedGbvTypeList.map(data => {
      if (data.gbvType === gbvType) {
        return {
          ...data,
          [colName]: optvalue
        };
      }
      return data;
    });
    //to update SelectedGbvTypeList with the new list
    setSelectedGbvTypeList(newList);
    console.log("tabledata", newList);
  };

  //function to calculate sum of totalCases
  const [gbvTypeTotal, setGbvTypeTotal] = useState(0);
  const calculateTotalCases = () => {
    let totalCases = 0;
    selectedGbvTypeList.forEach((district) => {
      totalCases += parseInt(district.totCases) || 0;
    }); setValue("gbvTypeTotal", totalCases);
    return totalCases;
  };

  //To update GbvTypeTotal whenever selectedGbvTypeList changes
  useEffect(() => {
    const totalCases = calculateTotalCases();
    setGbvTypeTotal(totalCases);
  }, [selectedGbvTypeList]);


  //To save data
  const saveData = (e) => {
    e.preventDefault();
    let data = getValues();
    data.fiscalYear = fiscalYear;
    // data.quarter = quarter;
    data.status = "Save";
    console.log("data", data);
  };

  const submitData = (data) => {
    data.fiscalYear = fiscalYear;
    data.quarter = quarter;
    data.organization = organization;
    data.status = "Submit";
    data.gbvTypeList = selectedGbvTypeList;
    console.log("data", data);
    if (update) {
      trackPromise(
        JudicialCommitteeService.editData(data, judicialCommitteeId).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Submit Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Submit UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      )
    } else {
      trackPromise(
        JudicialCommitteeService.saveData(data).then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Submit Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Submit UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    }
  }

  // const handleOrganization = (organizationId) => {
  //   console.log("quarter  ", quarter);
  //   console.log("fiscalYear in quarter", fiscalYear);
  //   setOrganization(organizationId);
  //   if (fiscalYear !== "" && quarter !== "") {
  //     getListByOrganization(fiscalYear, quarter, organizationId);
  //   }
  // };
  // const getListByOrganization = (fiscalYear, quarter, organization) => {
  //   trackPromise(
  //     JudicialCommitteeService.getListByOrganization(
  //       fiscalYear,
  //       quarter,
  //       organization
  //     ).then((response) => {
  //       console.log("response", response.data);
  //       if (response.data) {
  //         setFiscalYear(response.data.fiscalYear);
  //         setQuarter(response.data.quarter);
  //         setJudicialCommitteeId(response.data.judicialCommitteeId);
  //         reset({
  //           gbvRegistered: response.data.gbvRegistered,
  //           gbvRegisteredSource:
  //             response.data.gbvRegisteredSource[0] != "" &&
  //               response.data.gbvRegisteredSource != ""
  //               ? response.data.gbvRegisteredSource
  //               : null,

  //           gbvType: response.data.gbvType,
  //           gbvTypeSource:
  //             response.data.gbvTypeSource[0] != "" &&
  //               response.data.gbvTypeSource != ""
  //               ? response.data.gbvTypeSource
  //               : null,
  //           maleVictims: response.data.maleVictims,
  //           maleVictimsSource:
  //             response.data.maleVictimsSource[0] != "" &&
  //               response.data.maleVictimsSource != ""
  //               ? response.data.maleVictimsSource
  //               : null,

  //           femaleVictims: response.data.femaleVictims,
  //           femaleVictimsSource:
  //             response.data.femaleVictimsSource,
  //           femaleVictimsSource:
  //             response.data.femaleVictimsSource[0] != "" &&
  //               response.data.femaleVictimsSource != ""
  //               ? response.data.femaleVictimsSource
  //               : null,

  //           othersVictims: response.data.othersVictims,
  //           othersVictimsSource:
  //             response.data.othersVictimsSource,
  //           othersVictimsSource:
  //             response.data.othersVictimsSource[0] != "" &&
  //               response.data.othersVictimsSource != ""
  //               ? response.data.othersVictimsSource
  //               : null,

  //           gbvCasesMediated: response.data.gbvCasesMediated,
  //           gbvCasesMediatedSource:
  //             response.data.gbvCasesMediatedSource,
  //           gbvCasesMediatedSource:
  //             response.data.gbvCasesMediatedSource[0] != "" &&
  //               response.data.gbvCasesMediatedSource != ""
  //               ? response.data.gbvCasesMediatedSource
  //               : null,

  //           gbvRegisteredSourceOthers: response.data.gbvRegisteredSourceOthers,
  //           gbvTypeSourceOthers: response.data.gbvTypeSourceOthers,
  //           maleVictimsSourceOthers: response.data.maleVictimsSourceOthers,
  //           femaleVictimsSourceOthers: response.data.femaleVictimsSourceOthers,
  //           othersVictimsSourceOthers: response.data.othersVictimsSourceOthers,
  //           gbvCasesMediatedSourceOthers: response.data.gbvCasesMediatedSourceOthers,
  //         });
  //         setUpdate("Yes");
  //         if (response.data.status === "Submit") {
  //           setShowBtn("No");
  //         } else {
  //           setShowBtn("Yes");
  //         }
  //       } else {
  //         console.log("no data");
  //         reset({
  //           gbvRegisteredSource: [],
  //           gbvTypeSource: [],
  //           maleVictimsSource: [],
  //           femaleVictimsSource: [],
  //           othersVictimsSource: [],
  //           gbvCasesMediatedSource: [],
  //         });
  //         setUpdate("No");
  //         setShowBtn("Yes");
  //       }
  //     })
  //   );
  // };


  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Toast ref={toast} />
      <Card
        className="p-mb-1"
        style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}
      >
        <div className=" p-card-content">
          <h4 className="p-pt-0">{t("Judicial Committee")}</h4>
        </div>
      </Card>

      <Card
        className="p-mt-0"
      >
        <div className=" p-card-content">
          <form className="p-grid p-fluid " autoComplete="off">
            <Organization submitOrganizationId={handleOrganization} />

            <FiscalQuarterReport
              fiscalYearValue={fiscalYear}
              handleFiscalYearState={handleFiscalYear}
              quarterValue={quarter}
              handleQuarterState={handleQuarter}
            />
            <div className="p-field p-col-12 p-md-12 ">
              <div className="p-field p-col-10 p-md-10 float-left"></div>
              <div className="p-field p-col-2 p-md-2 float-right">
                <Button
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#1c80cf",
                    color: "#FFF",
                  }}
                  label={t("search")}
                  onClick={handleSubmit(searchData)}
                />
              </div>
            </div>

            {displayValue === "Yes" ?
              <>
                <div className="p-field p-col-12 p-md-12 ">
                  <div className="p-field p-col-12 p-md-12 ">
                    <hr style={{ marginTop: "5px" }}></hr>
                  </div>
                </div>

                <div className="main-form p-col-12 p-md-12">
                  <div className="p-grid p-col-12 p-md-12 ">
                    <div class="p-col-12 p-md-9">
                      <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("TotalGBVregistered")}
                        <i
                          className="pi pi-question-circle tooltip-style"
                          title={t("TotalGBVregistered")}
                        />
                      </div>
                      <div className="p-field p-col-8 p-md-3 float-left">
                        {/* <div className="p-inputgroup"> */}
                        <Controller
                          // name="gbvRegistered"
                          name="gbvTypeTotal"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              // value={field.value}
                              // onChange={(e) => {
                              //   field.onChange(e.value); }}
                              value={gbvTypeTotal}
                              onChange={(e) => setGbvTypeTotal(e.target.value)}
                              min="0"
                              disabled
                            />
                          )}
                        />
                        {/* </div> */}
                      </div>
                    </div>
                    {/* <div class="p-col-12 p-md-3">
                      <Controller
                        name="gbvRegisteredSource"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Source
                            id={field.name}
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            value={field.value}
                            handleSourceState={(e) => {
                              console.log("e", e);
                              field.onChange(e);
                            }}
                            sourceOtherValue={getValues("gbvRegisteredSourceOthers")}
                            handleSourceOtherState={(e) => {
                              setValue("gbvRegisteredSourceOthers", e);
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                    </div> */}
                  </div>

                  <div className="p-field p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 ">
                      <hr
                        style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                      ></hr>
                    </div>
                  </div>

                  <div className="p-grid p-col-12 p-md-12 ">
                    <div class="p-col-12 p-md-9">
                      <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("gbvType")}
                        <i
                          className="pi pi-question-circle tooltip-style"
                          title={t("gbvType")}
                        />
                      </div>
                      <div className="p-field p-col-12 p-md-9 float-left">
                        <Controller
                          name="gbvType"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <MultiSelect
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              filter
                              value={field.value}
                              options={gbvTypeList}
                              onChange={(e) => {
                                field.onChange(e.value);
                                handleGbvType(e.value);
                                selectedGbvType(e.value, "GbvType");
                                console.log("data gbv", e.value);
                              }}
                              display="chip"
                            />
                          )}
                        />
                        {(gbvTypeVal === "Yes") ?
                          <div className="p-field p-col-12 p-md-8 ">
                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                              {t("othersPleaseSpecify")}
                            </div>
                            <br />
                            <div className="p-inputgroup">
                              <Controller
                                name="gbvTypeOthers"
                                control={control}
                                autoFocus
                                render={({ field, fieldState }) => (
                                  <InputText
                                    id={field.name}
                                    {...field}
                                    value={field.value}
                                    className="rounded-input p-mb-1"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          :
                          <></>
                        }
                        <div className="p-field p-col-12 p-md-12">
                          <DataTable value={selectedGbvTypeList}
                            responsiveLayout="scroll"
                            editMode="cell"
                            resizableColumns
                            className="editable-cells-table p-datatable-sm"
                            showGridlines>
                            <Column field="gbvType" header={t("gbvType")}></Column>
                            <Column field="totCases"
                              header={t("totalCases")}
                              editor={(options) => gbvTypeEditor("totCases", options)}
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                      {/* <div className="p-field p-col-12 p-md-3 float-left">
                        <Controller
                          name="gbvTypeTotal"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={gbvTypeTotal}
                              onChange={(e) => setGbvTypeTotal(e.target.value)}
                              min="0"
                              disabled
                            />
                          )}
                        />
                      </div> */}
                    </div>
                  </div>

                  <div className="p-field p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 ">
                      <hr
                        style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                      ></hr>
                    </div>
                  </div>

                  <div className="p-grid p-col-12 p-md-12 ">
                    <div class="p-col-12 p-md-9">
                      <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("victimsSurvivorsInformation")}
                        <i
                          className="pi pi-question-circle tooltip-style"
                          title={t("victimsSurvivorsInformation")}
                        />
                      </div>
                      <div className="p-field p-col-12 p-md-12 float-left sub-label">
                        {t("byGender")}
                      </div>
                      <div className="p-field p-col-2 p-md-2 float-left">
                        <Controller
                          name="maleVictims"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              placeholder={t("male")}
                              tooltip={t("male")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="p-field p-col-2 p-md-2 float-left">
                        <Controller
                          name="femaleVictims"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              placeholder={t("female")}
                              tooltip={t("female")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="p-field p-col-2 p-md-2 float-left">
                        <Controller
                          name="othersVictims"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              placeholder={t("others")}
                              tooltip={t("others")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className="p-field p-col-2 p-md-2 float-left">
                        <Controller
                          name="genderVictimsTot"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={(getValues("maleVictims") ? getValues("maleVictims") : 0)
                                + (getValues("femaleVictims") ? getValues("femaleVictims") : 0)
                                + (getValues("othersVictims") ? getValues("othersVictims") : 0)}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              disabled
                              tooltip={t("total")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/* <div class="p-col-12 p-md-3">
                      <Controller
                        name="victimsInfoSource"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Source
                            id={field.name}
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            value={field.value}
                            handleSourceState={(e) => {
                              console.log("e", e);
                              field.onChange(e);
                            }}
                            sourceOtherValue={getValues("victimsInfoSourceOthers")}
                            handleSourceOtherState={(e) => {
                              setValue("victimsInfoSourceOthers", e);
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                    </div> */}
                  </div>

                  <div className="p-field p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 ">
                      <hr
                        style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                      ></hr>
                    </div>
                  </div>

                  <div className="p-grid p-col-12 p-md-12 ">
                    <div class="p-col-12 p-md-9">
                      <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("Total Number of GBV cases mediated in Judicial Committee")}
                        <i
                          className="pi pi-question-circle tooltip-style"
                          title={t("Total Number of GBV cases mediated in Judicial Committee")}
                        />
                      </div>
                      <div className="p-field p-col-8 p-md-3 float-left">
                        <div className="p-inputgroup">
                          <Controller
                            name="gbvCasesMediated"
                            control={control}
                            autoFocus
                            render={({ field, fieldState }) => (
                              <InputNumber
                                id={field.name}
                                {...field}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                min="0"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div class="p-col-12 p-md-3">
                      <Controller
                        name="gbvCasesMediatedSource"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Source
                            id={field.name}
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            value={field.value}
                            handleSourceState={(e) => {
                              console.log("e", e);
                              field.onChange(e);
                            }}
                            sourceOtherValue={getValues("gbvCasesMediatedSourceOthers")}
                            handleSourceOtherState={(e) => {
                              setValue("gbvCasesMediatedSourceOthers", e);
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                    </div> */}
                  </div>

                  <div className="p-field p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 ">
                      <hr
                        style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                      ></hr>
                    </div>
                  </div>


                  <div className="p-grid p-col-12 p-md-12 ">
                    <div class="p-col-12 p-md-6">
                      <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("referredTo")}
                      </div>
                      <div className="p-field p-col-12 p-md-12 float-left">
                        <Dropdown
                          filter
                          name="referredTo"
                          placeholder={t("select")}
                          value={getValues("referredTo")}
                          options={referredToList}
                          onChange={(e) => {
                            setValue("referredTo", e.value);
                          }}
                        />
                      </div>
                    </div>

                    {getValues("referredTo") === "Others" ? (
                      <div class="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                          {t("pleaseSpDetails")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                          <InputText
                            name="referredToOthers"
                            value={getValues("referredToOthers")}
                            // {...props.register("referredToOthers")}
                            onChange={(e) => {
                              setValue("referredToOthers", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>


                </div>
                <div className="p-grid p-col-12 p-md-12">
                  <div className="p-col-12 p-md-10"></div>
                  <div className="p-col-12 p-md-2">
                    <Button label={t("submit")}
                      className="p-button-sm pull-right submitBtn"
                      // onClick={() => { saveData() }}
                      onClick={handleSubmit(submitData)}
                    />
                  </div>
                </div>


              </> : ""}
          </form>
        </div>
      </Card >
    </div>
  );
}

export default JudicialCommittee;
