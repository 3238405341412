import axios from 'axios';
import AppProps from '../../../../AppProps';

class HttOldDetailService{
    saveData(data) {
        console.log(data, "inside post api");
        return axios.post(AppProps.API_BASE_URL + AppProps.HTT_OLD_DETAIL, data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });
    }
    
    getDetailById(id, homeType) {
      return axios.get(AppProps.API_BASE_URL + AppProps.HTT_OLD_DETAIL + "/by-id?caseId="+ id + "&homeType="+homeType, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
    }

    editData(data, caseId) {
      console.log(data, "inside post api");
      return axios.put(AppProps.API_BASE_URL + AppProps.HTT_OLD_DETAIL + caseId, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
    }
}

export default new HttOldDetailService()